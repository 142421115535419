import { useCallback, useRef, useState, useEffect, useMemo } from 'react';
import { ILoad, ILoadState, IComponent } from 'components/general/types/power';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useEntityDialogControl } from 'hooks';
import { useGuidance } from './guidance';
import WidgetTable from 'components/general/widgets/WidgetTable';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import LoadDialog from './LoadDialog';
import { CdhAccent, MdAccent } from 'components/general/Accent/variants';
import { IOperationalMode } from 'components/general/types/cdh';
import { translateOut } from 'utils/forms';
import * as Yup from 'yup';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { InputAdornment } from '@material-ui/core';
import { TBlockId } from 'components/general/types';

const validation = Yup.object().shape({
  name: Yup.string()
    .required('A load state name is required.')
    .max(32, 'Load state name must be no more than 32 characters.'),
  efficiency: Yup.number()
    .required('A load state efficiency is required')
    .min(0, 'Load state efficiency must be greater than or equal to 0%')
    .max(100, 'Load state efficiency must be less than or equal to 100%'),
});

const formatLoadDefParams = (rowData: ILoad) =>
  'power' in rowData.loadDefParams
    ? `${rowData.loadDefParams.power} W`
    : `${rowData.loadDefParams.resistance} Ω`;

const loadsColumns = [
  { title: 'Name', field: 'name' },
  {
    render: (rowData: ILoad) => formatLoadDefParams(rowData),
  },
];

const opModeTableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

// operationalModes is an optional field as it is not needed on create but will be set on edit
interface IForm {
  name: string;
  operationalModes?: IOperationalMode[];
}

const defaultValues = {
  name: '',
  efficiency: 0,
};

interface IParsedOperationalMode {
  id: TBlockId;
  name: string;
  tableData: {
    checked: boolean;
  };
}

interface IProps {
  control: TEntityDialogControl<ILoadState>;
  component: IComponent;
}
const LoadStateDialog = (props: IProps) => {
  // Handle props
  const { control, component } = props;
  const { dialogConfig } = control;
  const { action } = dialogConfig;
  const { entity: loadState } = dialogConfig;

  // Grab entities and actions
  const associatedLoads = useMemo(() => (loadState ? loadState.loads : []), [loadState]);
  const siblingStates = component.loadStates;

  // Set up table data
  const tableRef = useRef(null);
  const { operationalModes } = useActiveEntities();
  const [parsedOpModes, setParsedOpModes] = useState<IParsedOperationalMode[]>([]);

  // Finds opModes not connected to component's other load states to ensure that an opMode can't be attached to more than one load state per component
  const attachableOpModes = useMemo(() => {
    let opModes = operationalModes;
    for (const siblingState of siblingStates) {
      if (siblingState.id !== loadState?.id)
        opModes = opModes.filter(
          (o) =>
            !siblingState.operationalModes
              .map((opMode: IOperationalMode) => opMode.id)
              .includes(o.id)
        );
    }
    return opModes;
    // putting component.loadStates in dep array instead of siblingStates because siblingStates's useSelector leads to unnecessary recreation despite load states not changing
  }, [component.loadStates, loadState, operationalModes]); //eslint-disable-line react-hooks/exhaustive-deps

  // Due to the load state dialog loading the loads as a prop, whenever a load is changed the load state dialog will also rerender
  // During this rerender we want to ensure we are keeping the op modes that have been selected by the user
  // So a boolean switch is used to determine if it is the first render and op modes should be filtered over
  // This gives the desired behaviour of initializing the op modes on load and reset, while maintaining selection on load changes
  const initOpModes = useCallback(
    (inUseEffect = false) => {
      setParsedOpModes((prev) => {
        if (inUseEffect && (!operationalModes || prev.length)) return prev;
        return attachableOpModes.map((o) => ({
          id: o.id,
          name: o.name,
          tableData: {
            checked: !!loadState?.operationalModes
              .map((opMode: IOperationalMode) => opMode.id)
              .includes(o.id),
          },
        }));
      });
    },
    [loadState, operationalModes, setParsedOpModes, attachableOpModes]
  );

  useEffect(() => {
    initOpModes(true);
  }, [initOpModes]);

  // Set up styles
  const classes = useStyles();

  // Set up config for loads dialog
  const loadsDialogControl = useEntityDialogControl<ILoad>();
  const { openDialogForExisting, openDialogForNew } = loadsDialogControl;

  // Custom translateOut for edit action only as create does not require loads or op modes
  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options, datetimes, percentages) => {
      if (action === 'edit') {
        values.operationalModes = parsedOpModes
          .filter((o: IParsedOperationalMode) => o.tableData.checked)
          .map((o) => o.id);
      }
      return translateOut(values, allowedEmptyFields, options, datetimes, percentages);
    },
    [parsedOpModes, action]
  );

  const entityForm = useEntityForm<ILoadState, IForm>({
    entityTypeText: 'Load State',
    entityDialogControl: control,
    defaultValues,
    additionalCreateValues: { component: component.id },
    validationSchema: validation,
    extendReset: initOpModes,
    editAfterCreate: true,
    formikOptionalParams: {
      translateOut: customTranslateOut,
      percentages: ['efficiency'],
      useGuidance,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, setFieldValue } = formik;

  return (
    <>
      <EntityDialog entityForm={entityForm} keepSaveEnabled>
        <div className={classes.inputs}>
          <div className={classes.inputGroup}>
            <LabeledInput
              {...getFieldProps('name')}
              label="Load State Name"
              type="text"
              placeholder="Load State Name"
              autoFocus
            />
          </div>
        </div>
        {action === 'create' ? (
          <p>Create a load state to associate loads to operational modes. </p>
        ) : (
          <>
            <div className={classes.inputGroup}>
              <MdAccent header="Loads">
                <WidgetTable
                  columns={loadsColumns}
                  data={associatedLoads}
                  modelName="load"
                  onActionClick={openDialogForExisting}
                  onFabClick={openDialogForNew}
                />
              </MdAccent>
            </div>
            <div className={classes.inputGroup}>
              <LabeledInput
                {...getFieldProps('efficiency')}
                label="Efficiency"
                type="number"
                placeholder="Efficiency"
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
              />
            </div>
            <div className={classes.inputGroup}>
              <CdhAccent header="Operational Modes">
                <WidgetTable
                  tableRef={tableRef}
                  className={classes.table}
                  columns={opModeTableColumns}
                  data={parsedOpModes}
                  setData={setParsedOpModes}
                  emptyMessage="No unassociated operational modes"
                  // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
                  // It is used through a level of indirection here to set the value on the form data and therefore mark
                  // the form as dirty. This will enable the save button for the form.
                  onSelectionChange={(newTableData) =>
                    setFieldValue('operationalModes', newTableData)
                  }
                  selection={true}
                  title="Select Operational Modes"
                  search={true}
                />
              </CdhAccent>
            </div>
          </>
        )}
      </EntityDialog>
      {/* Similar to load state dialog, only load the dialog once the config is opened to avoid many dialogs being rendered */}
      {loadsDialogControl.dialogConfig.open && (
        <LoadDialog loadState={loadState} control={loadsDialogControl} />
      )}
    </>
  );
};

export default LoadStateDialog;
