import { ISelectOption } from 'components/general/types';
import { SurfaceVables } from 'utils/vable';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string()
    .required('A name is required.')
    .max(32, 'Surface name must be no more than 32 characters.'),
  area: Yup.number()
    .required('Surface area is required.')
    .moreThan(0, 'Area must be greater than 0.'),
  trackedVector: Yup.object().when('motionType', {
    is: (motionType: ISelectOption) =>
      motionType?.value === SurfaceVables.MotionTypes.VECTOR_TRACKING.value,
    then: Yup.object().required('Tracked vector is required.'),
  }),
  motionType: Yup.object().required('A motion type is required.'),
  bodyFrameVector: Yup.object().required('A body frame vector is required.'),
  surfaceCentroid: Yup.array().of(
    Yup.number().required('Centeroid x, y, and z components are all required.')
  ),
  surfaceMaterial: Yup.object().required('A surface material is required.'),
});

export default validation;
