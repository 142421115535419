import * as Yup from 'yup';
import { ISelectOption } from 'components/general/types';
import { TempControllerVables } from 'utils/vable';
import getThermalProps from 'hooks/getThermalProps';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  name: Yup.string().required('A temperature controller name is required.'),
  manufacturer: Yup.string(),
  partNumber: Yup.string(),
  controlledComponent: Yup.object().required('Select a controlled component.'),
  componentType: Yup.object().required('Select a component type.'),
  efficiency: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === TempControllerVables.ComponentType.COOLER.value,
    then: Yup.number()
      .required('Efficiency is required.')
      .moreThan(0, 'Efficiency must be greater than 0%.')
      .max(100, 'Efficiency cannot be above 100%.'),
  }),
  onRegHeatFlowRate: Yup.number().required('Heat flow rate is required.'),
  ...thermalValidation,
});

export default validation;
