import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Routes, { routePathsCommon } from 'routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCodeBranch, faLink, faDownload, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@material-ui/core';
import useStyles from './styles';
import { IconButton } from '@material-ui/core';
import { useActiveEntities, useInReadOnlyBranch, useInCollaboratedRepo } from 'hooks';
import ShareableLinkDialog from './ShareableLinkDialog';
import SpacecraftDialogButton from 'components/general/SpacecraftDialogButton';
import { RepoVables } from 'utils/vable';
import { useHotkeys } from 'react-hotkeys-hook';
import { hotkeys, IS_CES } from 'config';
import { getSearchParams } from 'routes';
import mdLogo from 'multimedia/icons/md.png';

const { BRANCH, SCENARIO } = Routes;

const BranchIcons = () => {
  const history = useHistory();
  const classes = useStyles();
  const { repo, branch } = useActiveEntities();
  const { agentId, share } = getSearchParams();

  const inReadOnlyBranch = useInReadOnlyBranch();
  const inCollaboratedRepo = useInCollaboratedRepo();
  const inAnAgentTemplate = repo?.dataType === RepoVables.DataTypes.AGENT_TEMPLATE.value;
  const inAgentAnalyze = !!agentId;

  const [shareLinkDialogOpen, setShareLinkDialogOpen] = useState(false);

  const goBranches = () => history.push(BRANCH(repo?.id));
  const goScenario = () => {
    let scenarioPath = SCENARIO(branch.id, routePathsCommon.EDIT);
    if (share) scenarioPath += '?share=' + share;
    history.push(scenarioPath);
  };

  useHotkeys(hotkeys.BRANCHES, goBranches, [goBranches]);

  return (
    <>
      <div>
        {inAnAgentTemplate && (
          <SpacecraftDialogButton iconButton className={classes.branchNavIcon} />
        )}
        {inAgentAnalyze && (
          <Tooltip arrow title={`Edit Scenario`}>
            <IconButton onClick={goScenario} className={classes.branchNavIcon}>
              <img src={mdLogo} className={classes.branchNavImg} alt="scenario-logo" />
            </IconButton>
          </Tooltip>
        )}
        {!IS_CES && (
          <Tooltip arrow title="Shareable Link">
            <IconButton
              className={classes.branchNavIcon}
              disabled={inReadOnlyBranch}
              onClick={() => setShareLinkDialogOpen(true)}
            >
              <FontAwesomeIcon icon={faLink} />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip arrow title={`Repository's Branches [${hotkeys.BRANCHES}]`}>
          <IconButton
            disabled={inReadOnlyBranch && !inCollaboratedRepo}
            onClick={goBranches}
            className={classes.branchNavIcon}
          >
            <FontAwesomeIcon icon={faCodeBranch} />
          </IconButton>
        </Tooltip>
        <Tooltip arrow title="Save As">
          {/* TODO: Fill out onClick, remove disabled prop, and remove react fragment wraping IconButton*/}
          <>
            <IconButton className={classes.branchNavIcon} disabled onClick={() => undefined}>
              <FontAwesomeIcon icon={faDownload} />
            </IconButton>
          </>
        </Tooltip>
        <Tooltip arrow title="Open">
          {/* TODO: Fill out onClick, remove disabled prop, and remove react fragment wraping IconButton*/}
          <>
            <IconButton className={classes.branchNavIcon} disabled onClick={() => undefined}>
              <FontAwesomeIcon icon={faFolderOpen} />
            </IconButton>
          </>
        </Tooltip>
      </div>
      <ShareableLinkDialog
        open={shareLinkDialogOpen}
        closeDialog={() => setShareLinkDialogOpen(false)}
      />
    </>
  );
};

export default BranchIcons;
