import { useMemo } from 'react';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { IColumn, IMission } from 'components/general/types';
import { IUser } from 'components/general/types/general';
import moment from 'moment-timezone';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import useStyles from './styles';
import { Moment } from 'moment';
import { useUser } from 'hooks';

interface IProps {
  repo: IMission;
  collaboratorsDialogControl: TEntityDialogControl<IUser>;
}

interface IPendingInvite {
  email: string;
  expMoment: Moment;
}

// @ts-ignore // typescript doesn't know that arithmetic operations can be used on Moment type
const expirationDateSort = (a: IPendingInvite, b: IPendingInvite) => a.expMoment - b.expMoment;

const pendingInviteColumns: IColumn[] = [
  {
    title: 'Email',
    field: 'email',
  },
  {
    title: 'Expiration Date',
    render: (pendingInvite: IPendingInvite) => pendingInvite.expMoment.calendar(),
  },
];

const PendingInvitesTable = (props: IProps) => {
  const { repo, collaboratorsDialogControl } = props;
  const { setDialogConfig: setCollaboratorDialogConfig } = collaboratorsDialogControl;
  const user = useUser();

  const pendingInvites = useMemo(() => {
    const currentMoment = moment();

    // Simultaneoulsy filter out expired invitations and convert the format of pending invites to be readable by the widget table
    const _pendingInvites = Object.entries(repo.pendingInvites).flatMap(([email, expDate]) => {
      const expMoment = moment(expDate);
      return currentMoment > expMoment ? [] : { email, expMoment };
    });

    return _pendingInvites.sort(expirationDateSort);
  }, [repo]);

  const classes = useStyles();

  return (
    <>
      <h3 className={classes.title}>Pending Invites</h3>
      <WidgetTable
        data={pendingInvites}
        columns={pendingInviteColumns}
        emptyMessage="No current pending invites. If you would like to invite a collaborator, please use the collaborator table."
        onActionClick={(rowData) =>
          setCollaboratorDialogConfig({
            open: true,
            action: 'delete',
            entity: rowData as IUser,
          })
        }
        disableActionMenuOptions={{
          actions: ['edit'],
        }}
        actionTooltip="Cancel pending invitation"
        className={classes.pendingInvitesTable}
        disableActionsMenu={user.id !== repo.user}
        noXray
      />
    </>
  );
};

export default PendingInvitesTable;
