import { useMemo, useEffect, useCallback, ReactNode } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import Grid from '@material-ui/core/Grid';
import GuidanceCard from 'components/general/GuidanceCard';
import { toSentenceCase, withArticle } from 'utils/strings';
import { IGenericObject } from 'components/general/types';
import useErrorStyles from 'components/general/dialogs/DeleteEntityDialog/styles';
import useWizardSegmentStyles from 'components/general/wizards/WizardSegment/styles';

interface IProps {
  entityForm: IGenericObject;
  customDirty?: boolean;
  keepSaveEnabled?: boolean;
  additionalLoading?: boolean;
  children: ReactNode;
  xlarge?: boolean;
  childrenRight?: JSX.Element | boolean;
}

const EntityDialog = (props: IProps) => {
  const {
    entityForm,
    customDirty,
    keepSaveEnabled,
    additionalLoading,
    children,
    childrenRight,
    ...rest
  } = props;
  const wizardSegmentClasses = useWizardSegmentStyles();
  const errorClasses = useErrorStyles();

  const {
    formik,
    guidance,
    loading,
    deleteErrorMessage,
    entityTypeText,
    dialogConfig,
    onClose,
    extendReset,
  } = entityForm;
  const { action, open, entity } = dialogConfig;
  const { resetForm, dirty, handleSubmit } = formik;

  const reset = useCallback(() => {
    resetForm();
    extendReset();
  }, [resetForm, extendReset]);

  // instead of calling resetForm in the onClose function, we reset it using a useEffect to prevent unwanted UX flash when closing the form
  // reset is not  in the dependency array as the formik form is rerendered when this happens, causing an infinite rerender
  useEffect(() => {
    if (!open) {
      reset();
    }
  }, [open]); // eslint-disable-line

  const isDelete = useMemo(() => action === 'delete', [action]);

  const renderChildren = () => {
    if (action && !isDelete) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12} md={5} className={wizardSegmentClasses.swapRight}>
            {children}
          </Grid>
          <Grid item xs={12} md={7} className={wizardSegmentClasses.swapLeft}>
            <GuidanceCard guidance={guidance} />
            {childrenRight}
          </Grid>
        </Grid>
      );
    } else {
      return (
        <>
          <p>
            Are you sure you'd like to delete {entityTypeText.toLowerCase()}: '
            {entity?.name ?? entity?.partNumber}'? This cannot be undone.
          </p>
          <p className={errorClasses.note}>
            <strong>Note:</strong> Deleting this entity will simultaneously delete all related
            simulation data.
          </p>
          {deleteErrorMessage && (
            <Alert severity="error" icon={false} variant="outlined" className={errorClasses.error}>
              <AlertTitle className={errorClasses.errorHeading}>Deletion Error</AlertTitle>
              {deleteErrorMessage}
            </Alert>
          )}
        </>
      );
    }
  };

  return (
    <Dialog
      prompt={
        action &&
        (action === 'create'
          ? `Create ${withArticle(entityTypeText.toLowerCase(), false)}`
          : toSentenceCase(`${action} ${entityTypeText}`))
      }
      open={open}
      onSubmit={!deleteErrorMessage && handleSubmit}
      submitActionText={!isDelete ? 'Save' : 'Delete'}
      secondaryActionText={!isDelete && 'Reset'}
      onSecondaryAction={!isDelete && reset}
      disableSubmit={keepSaveEnabled ? false : !isDelete && !dirty && !customDirty}
      loading={loading || additionalLoading}
      onClose={onClose}
      large={!isDelete && !props.xlarge}
      xray={{ ...entity, ...formik.values }}
      {...rest}
    >
      {renderChildren()}
    </Dialog>
  );
};

export default EntityDialog;
