import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  collaboratorsTableContainer: {
    // Used to hide the widget table menu for the owner of the repo as owners should not be able to delete themselves
    '& tr:first-child .MuiTableCell-body': {
      '& div': {
        display: 'none!important',
      },
    },
  },
}));

export default useStyles;
