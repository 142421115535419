import { SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';
import { COMMS } from 'components/AgentTemplateEditView/menu/comms';

const COMING_SOON = 'coming-soon';

const agentCommsMenu: IMenu['items'] = {
  [COMMS]: {
    [TITLE]: 'Data Handling',
    [SUB_ITEMS]: {
      [COMING_SOON]: {
        [TITLE]: 'Coming soon...',
      },
    },
  },
};
export const wGroupIndicesAgentComms = {
  COMMS: `${COMMS}*`,
};

export default agentCommsMenu;
