import ReactJson from 'react-json-view';
import theme from 'theme';
import Dialog from 'components/general/dialogs/Dialog';

const exclude = new Set(['satellite', 'conOps', 'rels']);
const normalize = (data, depth = 0) => {
  if (depth > 1 && data?.id) {
    return data.id;
  } else if (Array.isArray(data)) {
    return data.map((d) => normalize(d, depth));
  } else if (data && typeof data === 'object') {
    const result = {};
    for (const key in data) {
      if (data[key] != null && !exclude.has(key)) result[key] = normalize(data[key], depth + 1);
    }
    return result;
  }
  return data;
};

const Xray = ({ data, title, open, onClose }) => {
  return (
    <Dialog noButtons prompt={`SedaroML: ${title || ''}`} open={open} onClose={onClose} medium>
      <ReactJson
        style={{ backgroundColor: theme.palette.background.default }}
        src={normalize(data)}
        collapsed={Array.isArray(data) ? 2 : 1}
        displayDataTypes={false}
        theme="brewer"
        name={false}
        enableClipboard={false}
      />
    </Dialog>
  );
};

export default Xray;
