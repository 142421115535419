import Routes from 'routes';
import { ITEMS, SUB_ITEMS } from 'config';
import { IMenu } from 'components/general/types';

// Menus from modules
import aTTargetsMenu from './targets';
import aTGNCMenu from './gnc';
import aTCDHMenu from './cdh';
import aTPowerMenu, { POWER, SUBSYSTEM_LOADING } from './power';
import aTThermalMenu from './thermal';
import aTCommsMenu from './comms';

const AgentTemplateEditMenu: IMenu = {
  routeFn: Routes.AGENT_TEMPLATE_EDIT,
  dynamicItems: [
    {
      entityKey: 'subsystems',
      keysToLoc: `${ITEMS}.${POWER}.${SUB_ITEMS}.${SUBSYSTEM_LOADING}.${SUB_ITEMS}`,
    },
  ],
  [ITEMS]: {
    ...aTTargetsMenu,
    ...aTGNCMenu,
    ...aTCDHMenu,
    ...aTPowerMenu,
    ...aTThermalMenu,
    ...aTCommsMenu,
  },
};

export default AgentTemplateEditMenu;
