import { SUB_ITEMS, TITLE, TOGGLE } from 'config';
import { IMenu } from 'components/general/types';
import { SatelliteApi } from 'middleware/SatelliteApi/api';

export const THERMAL = 'thermal';
const TEMP_CONT = 'temperature-controllers';
const THERM_INTER_MAT = 'thermal-interface-materials';
const THERM_INTER = 'thermal-interfaces';

const {
  Satellite: {
    actions: { updateSpacecraft },
  },
} = SatelliteApi;

const aTThermalMenu: IMenu['items'] = {
  [THERMAL]: {
    [TITLE]: 'Thermal',
    [TOGGLE]: { label: 'Simulate Thermal Module (on/off)', action: updateSpacecraft },
    [SUB_ITEMS]: {
      [TEMP_CONT]: {
        [TITLE]: 'Temperature Controllers',
      },
      [THERM_INTER_MAT]: {
        [TITLE]: 'Thermal Interface Materials',
      },
      [THERM_INTER]: {
        [TITLE]: 'Thermal Interfaces',
      },
    },
  },
};
export const wGroupIndicesATThermal = {
  THERMAL: `${THERMAL}*`,
  TEMP_CONT: `${THERMAL}.${TEMP_CONT}`,
  THERM_INTER_MAT: `${THERMAL}.${THERM_INTER_MAT}`,
  THERM_INTER: `${THERMAL}.${THERM_INTER}`,
};

export default aTThermalMenu;
