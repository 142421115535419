import { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useActiveEntities, useSnackbar } from 'hooks';
import targetSchema from './validation';
import useGuidance from './guidance';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import GuidanceCard from 'components/general/GuidanceCard';
import Grid from '@material-ui/core/Grid';
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import useFormikForm from 'hooks/FormikHook';
import Dialog from 'components/general/dialogs/Dialog';
import { TargetVables } from 'utils/vable';

const defaultValues = {
  name: '',
  targetType: '',
  polynomialEphemerisBody: '',
};

const options = {
  targetType: TargetVables.TargetType.options,
  polynomialEphemerisBody: TargetVables.PolynomialEphemerisBody.options,
};

const TargetDialog = ({ control }) => {
  const { dialogConfig, closeDialog: onClose } = control;
  const { action, entity: target, open } = dialogConfig;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const { conOps, branch } = useActiveEntities();

  const {
    Target: {
      actions: { createTarget, updateTarget, deleteTarget },
    },
  } = SatelliteApi;

  const addTarget = useCallback(
    (values) => {
      setLoading(true);
      const valuesToDispatch = { ...values, branchId: branch.id };
      const { targetType } = values;
      // Delete values not needed for target request
      for (let val of ['targetType']) {
        delete valuesToDispatch[val];
      }
      dispatch(
        createTarget({
          conOps: conOps.id,
          ...valuesToDispatch,
          entity: { targetType },
          successCallback: (target) => {
            onClose();
            enqueueSnackbar('Target created successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response) => {
            enqueueSnackbar(response.error.message);
            setLoading(false);
          },
        })
      );
    },
    [branch, conOps, enqueueSnackbar, onClose, createTarget, dispatch]
  );

  const editTarget = useCallback(
    (values) => {
      setLoading(true);
      // Delete values not needed for target patch request
      for (let val of ['targetType']) {
        delete values[val];
      }
      dispatch(
        updateTarget({
          id: target.id,
          branchId: branch.id,
          ...values,
          entity: target,
          successCallback: (target) => {
            onClose();
            enqueueSnackbar('Target updated successfully', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response) => {
            enqueueSnackbar(response.error.message);
            setLoading(false);
          },
        })
      );
    },
    [target, enqueueSnackbar, onClose, updateTarget, dispatch, branch]
  );

  const { formik, guidance } = useFormikForm(
    defaultValues,
    action === 'create' ? addTarget : editTarget,
    targetSchema,
    target,
    { useGuidance, options }
  );
  const { values, getFieldProps, resetForm, dirty, handleSubmit } = formik;
  const { targetType } = values;

  // instead of calling resetForm in the onClose function, we reset it using a useEffect to prevent unwanted UX flash when closing the form
  // reset is not  in the dependency array as the formik form is rerendered when this happens, causing an infinite rerender
  useEffect(() => {
    if (!dialogConfig.open) {
      resetForm();
    }
  }, [dialogConfig.open]); // eslint-disable-line

  if (action === 'delete' && target) {
    return (
      <DeleteEntityDialog
        action={deleteTarget}
        entity={target}
        entityTypeText={'Target'}
        onClose={onClose}
        open={open}
      />
    );
  }

  return (
    <Dialog
      prompt={action === 'create' ? 'Create a target' : 'Edit target'}
      open={open}
      onSubmit={handleSubmit}
      onClose={onClose}
      loading={loading}
      submitActionText="Save"
      secondaryActionText="Reset"
      onSecondaryAction={resetForm}
      disableSubmit={!dirty}
      large
      xray={values}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <div className={classes.inputGroup}>
              <LabeledInput
                {...getFieldProps('name')}
                label="Target Name"
                placeholder="Target Name"
                autoFocus
              />
            </div>
            <div className={classes.inputGroup}>
              <LabeledSelect
                label="Target Type"
                options={options.targetType}
                isDisabled={action === 'edit'}
                {...getFieldProps('targetType')}
              />
              <div className={classes.indent}>
                {targetType?.value === TargetVables.TargetType.CELESTIAL_TARGET.value && (
                  <LabeledSelect
                    label="Celestial Bodies"
                    options={options.polynomialEphemerisBody}
                    {...getFieldProps('polynomialEphemerisBody')}
                  />
                )}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard guidance={guidance} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default TargetDialog;
