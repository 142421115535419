import useStyles from './styles';

import StyledButton from 'components/general/StyledButton';

export interface IIconProps {
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}

export interface IButtonProps extends IIconProps {
  text: string;
}

interface IProps {
  name: string;
  description: string;
  rowsTitle: string;
  primaryButtonProps?: IButtonProps;
  secondaryButtonProps?: IButtonProps;
  children: JSX.Element[];
}

const RowedExplorer = (props: IProps) => {
  const { name, description, rowsTitle, primaryButtonProps, secondaryButtonProps, children } =
    props;

  const classes = useStyles();
  const {
    text: prmBtnTxt,
    onClick: prmBtnOnClick,
    tooltip: prmBtnTooltip,
  } = primaryButtonProps || {};
  const {
    text: scndBtnTxt,
    onClick: scndBtnOnClick,
    tooltip: scndBtnTooltip,
  } = secondaryButtonProps || {};

  return (
    <div className={classes.branchExplorerRoot}>
      <div className={classes.headerContainer}>
        <div className={classes.info}>
          <h1>{name}</h1>
          <p>{description}</p>
        </div>
        {prmBtnOnClick && (
          <StyledButton onClick={prmBtnOnClick} tooltip={prmBtnTooltip}>
            {prmBtnTxt}
          </StyledButton>
        )}
      </div>
      <div className={classes.title}>
        <h1>{rowsTitle}</h1>
        {scndBtnOnClick && (
          <StyledButton onClick={scndBtnOnClick} tooltip={scndBtnTooltip} framed>
            {scndBtnTxt}
          </StyledButton>
        )}
      </div>
      {children}
    </div>
  );
};

export default RowedExplorer;
