import { useEffect, useState, useCallback, useMemo } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { translateOut } from 'utils/forms';
import { useGuidance } from './guidance';
import SelectPriorityQueue from 'components/general/PriorityQueue/SelectPriorityQueue';
import MdAccent from 'components/general/Accent/variants/MdAccent';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import validation from './validation';
import { useActiveEntities, useEntityForm } from 'hooks';
import { IManySideData, ISelectOption } from 'components/general/types';
import { ITarget, ITargetGroup } from 'components/general/types/target';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TargetVables } from 'utils/vable';

interface IForm {
  name: string;
  targetType: ISelectOption | '';
}
const defaultValues: IForm = {
  name: '',
  targetType: '',
};

interface IProps {
  control: TEntityDialogControl<ITargetGroup>;
}

const TargetGroupDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action, entity: targetGroup },
  } = control;
  const classes = useStyles();
  const { model, targets, conOps } = useActiveEntities();

  const [queueDirty, setQueueDirty] = useState(false);
  // This array is the actual target objects
  const [targetGroupPriorityArray, setTargetGroupPriorityArray] = useState<
    (ITarget & IManySideData)[]
  >([]);
  // Grid state is the array of target id's in the correct order. It's used in place of targetGroupPriorityArray to avoid rerendering when priorities are changed.
  // if targetGroupPriorityArray changes then a new grid is made and causes the dialog to flicker briefly so we avoid that with gridState
  const [gridState, setGridState] = useState([]);

  const targetGroupAssociations = useMemo(
    () => (targetGroup ? targetGroup.targetAssociations : []),
    [targetGroup]
  );

  // Uses the target group association to set an array of target objects
  useEffect(() => {
    setTargetGroupPriorityArray(
      [...targetGroupAssociations].sort(
        (t1, t2) => t2.manySideData.priority - t1.manySideData.priority
      )
    );
  }, [setTargetGroupPriorityArray, targetGroupAssociations, model]);

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      // Grid is listed top to bottom, in reverse priority order
      const priorityList = gridState.reverse();
      const targetAssociations: { [index: number]: { priority: number } } = {};
      for (let i = 0; i < priorityList.length; i++)
        targetAssociations[priorityList[i]] = { priority: i };

      return translateOut(
        {
          name: values.name,
          targetType: values.targetType,
          targetAssociations,
        },
        allowedEmptyFields,
        options
      );
    },
    [gridState]
  );

  const onReset = useCallback(() => {
    if (targetGroup !== null) {
      // loop through the associations and add them in order to reset the priority order
      setTargetGroupPriorityArray(
        [...targetGroupAssociations].sort(
          (t1, t2) => t2.manySideData.priority - t1.manySideData.priority
        )
      );
    }
    setQueueDirty(false);
  }, [targetGroup, targetGroupAssociations]);

  const options = useMemo(() => {
    return {
      targets: targets.map((t) => {
        return { value: t.id, label: t.name, ...t };
      }),
      targetType: TargetVables.TargetType.options,
    };
  }, [targets]);

  const entityForm = useEntityForm<ITargetGroup, IForm>({
    entityTypeText: 'Target Group',
    entityDialogControl: control,
    defaultValues,
    additionalCreateValues: { conOps: conOps.id },
    validationSchema: validation,
    extendReset: onReset,
    formikOptionalParams: {
      useGuidance,
      options,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  const filteredTargetOptions = useMemo(
    () =>
      options.targets.filter(
        (target) =>
          typeof values.targetType === 'object' &&
          target.targetType === values.targetType.value &&
          targetGroupPriorityArray.every((previousTarget) => previousTarget.id !== target.id)
      ),
    [values, options, targetGroupPriorityArray]
  );

  return (
    <EntityDialog entityForm={entityForm} customDirty={queueDirty}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Target Group Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          <LabeledSelect
            {...getFieldProps('targetType')}
            // target type for a target group is only selected at creation, it cannot be updated
            isDisabled={values.targetType && action !== 'create'}
            label="Type of Targets in this Group"
            placeholder="Select Target Type..."
            options={options.targetType}
          />
        </div>
        {values.targetType && (
          <div className={classes.inputGroup}>
            <MdAccent header="Targets">
              <SelectPriorityQueue
                setGridState={setGridState}
                name="target"
                selectPlaceholder="Add Target..."
                options={filteredTargetOptions}
                data={targetGroupPriorityArray}
                getFieldProps={getFieldProps}
                setData={setTargetGroupPriorityArray}
                setQueueDirty={setQueueDirty}
              />
            </MdAccent>
          </div>
        )}
      </div>
    </EntityDialog>
  );
};

export default TargetGroupDialog;
