import { useEffect } from 'react';
import { useHistory, withRouter, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import Routes from 'routes';
import { useParams } from 'react-router';
import AuthWidget from 'components/general/widgets/AuthWidget';
import WaveContainer from 'components/general/WaveContainer';
import StyledButton from 'components/general/StyledButton';
import LoadingView from 'components/LoadingView';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useUser, useSnackbar } from 'hooks';
import { IErrorResponse, IUrlParams } from 'components/general/types';

const CollaboratorView = () => {
  const {
    Collaborator: {
      actions: { verifyCollaborator },
    },
    User: {
      actions: { getUser },
    },
  } = SatelliteApi;
  const user = useUser();
  const dispatch = useDispatch();

  const { token } = useParams<IUrlParams>();
  const history = useHistory();
  const location = useLocation();

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (!user) {
      history.push({
        pathname: Routes.LOGIN(),
        state: { from: location },
      });
      enqueueSnackbar(
        'In order to be added as a collaborator, please login to your Sedaro Satellite account or create an account.'
      );
    } else if (token !== ':token') {
      dispatch(
        verifyCollaborator({
          collaborationToken: token,
          // TODO: future optimization, make verifyCollaborator return updated user and then update the store, so don't
          // need 2nd dispatch.
          successCallback: () => {
            dispatch(
              // dispatching get user, so we can have the updated user with updated `accessibleRepos`, to properly
              // trigger the useEffect in `RepoExplorer`
              getUser({
                successCallback: () => {
                  // Display a notification for successful email verifications
                  enqueueSnackbar('Successfully added as collaborator.', {
                    variant: 'success',
                  });
                  history.replace(Routes.ROOT());
                },
                failureCallback: () => {
                  // This means successfully verfied collaborator, but were unable to successfully get updated user. A
                  // refresh should fix that.
                  enqueueSnackbar('Please refresh the page to view repositories');
                },
              })
            );
          },
          failureCallback: (response: IErrorResponse) => {
            history.replace(Routes.VERIFY());
            enqueueSnackbar(response.error.message);
          },
        })
      );
    }
  }, [token]); //eslint-disable-line

  if (token !== ':token') {
    return <LoadingView />;
  }
  return (
    <WaveContainer>
      <AuthWidget>
        <form>
          <h2 className={classes.heading}>Collaboration</h2>
          <h5>
            Please check your email for the link to join the Sedaro Satellite repository. If you
            have lost this email or the link is not working, please reach out to the owner of the
            repository.
          </h5>
          <div className={classes.buttonWrapper}>
            <StyledButton type="button" onClick={() => history.push(Routes.ROOT())} fullWidth>
              Back To Home
            </StyledButton>
          </div>
        </form>
      </AuthWidget>
    </WaveContainer>
  );
};

export default withRouter(CollaboratorView);
