/**
 *
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 *
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 *
 * This file uses RECHARTS (ewwww) and is therefore OLD AND OBSOLETE
 *
 * DO NOT USE THIS COMPONENT UNTIL IT IS UPDATED TO USE ECHARTS
 *
 * If you are updating it yourself, please remove this notice when finished.
 *
 *
 */
/* eslint-disable */

import { useEffect, useState } from 'react';
import { ReferenceArea } from 'recharts';
import useStyles from './styles';
import StyledButton from 'components/general/StyledButton';
import StyledSlider, { ThumbComponent } from 'components/general/StyledSlider';

// USAGE:
// (Use components/charts/TimeSeriesChart as a reference)
// Chart must take in zoomProps, which contains domain and mouseEvents, and children as props.
// LineChart must include contents of mouseEvents as properties and {children} as a child
// XAxis must allowDataOverflow, type="number", scale="auto", domain={domain}
// YAxis must allowDataOverflow, yAxisId="left"
// args must contain a defaultHeight if no height is specified by widget
const withZoom = (WrappedComponent, args = {}) => {
  return (props) => {
    let { noZoom, children, height, data } = props;
    const { defaultHeight, overrideZoomTimeout } = args;

    const _height = height ?? defaultHeight;

    const initialState = {
      data: data,
      left: null,
      right: null,
      refAreaLeft: null,
      refAreaRight: null,
      sliderPosition: null,
    };

    let [zoomed, setZoomed] = useState(false);
    let [config, setConfig] = useState(initialState);
    // pass the zoomed slider height as props to allow the charts to transition on zoom
    const classes = useStyles({ zoomed });

    // Zooming breaks and adds extraneous lines if zoomed in too far.
    // "Too far" is relative to how many data points there are.
    // These limits are based on trial-and-error tests, can be adjusted if necessary.
    let minChartWidth;
    if (data.length > 400) {
      minChartWidth = (data[12].times.mjd - data[0].times.mjd) / 4; // 3 full data points, averaged to generalize
    } else if (data.length > 150) {
      minChartWidth = (data[12].times.mjd - data[0].times.mjd) / 6; // 2 full data points
    } else {
      minChartWidth = 0; // 1 full data point
    }

    const zoomProps = {
      domain: [
        (dataMin) => {
          return config.left || dataMin;
        },
        (dataMax) => {
          return config.right || dataMax;
        },
      ],

      // These mouse events are part of the chart
      // They should only activate when the mouse is on the chart
      // Do not activate if zoom not allowed
      mouseEvents: {
        onMouseDown: (e) => {
          if (e && !noZoom) {
            setConfig({ ...config, refAreaLeft: e.activeLabel });
          }
        },
        onMouseMove: (e) => {
          if (e && e.activeLabel && config.refAreaLeft && !noZoom) {
            setConfig({ ...config, refAreaRight: e.activeLabel });
          }
        },
      },
    };

    // This mouse event is part of the whole window
    // It activates wherever you release the zoom
    useEffect(() => {
      const zoomIn = () => {
        let { refAreaLeft, refAreaRight, noZoom } = config;

        // zoomIn called by a mouseUp event intended for another chart OR zoom not allowed
        if (!refAreaLeft || noZoom) return;

        setConfig(() => ({
          ...config,
          refAreaLeft: null,
          refAreaRight: null,
        }));

        // Empty zoom area or zoom not allowed
        if (refAreaLeft === refAreaRight || !refAreaRight) return;

        // Access the pan slider and zoom out button
        setZoomed(true);

        // Ensures left bound of zoom is less than right bound of zoom
        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        // Check if chart width is within an epsilon of defined min width
        if (refAreaRight - refAreaLeft - minChartWidth > -0.000001) {
          setConfig(() => ({
            ...config,
            left: refAreaLeft,
            right: refAreaRight,
            refAreaLeft: null,
            refAreaRight: null,
            sliderPosition: refAreaLeft + (refAreaRight - refAreaLeft) / 2,
          }));
        }
      };

      window.addEventListener('mouseup', zoomIn);
      return () => {
        window.removeEventListener('mouseup', zoomIn);
      };
    }, [config, minChartWidth, overrideZoomTimeout, _height, zoomed]);

    // If liveMode is turned on while something is zoomed in, unzoom
    useEffect(() => {
      if (noZoom && zoomed) {
        setZoomed(false);
        setConfig(initialState);
      }
    }, [noZoom, zoomed, setZoomed, setConfig, initialState]);

    return (
      <div className={classes.plotWrapper}>
        <WrappedComponent zoomProps={zoomProps} {...props} height={_height}>
          {config.refAreaLeft && config.refAreaRight && (
            // Highlights portion of chart to zoom
            <ReferenceArea
              yAxisId={'left'}
              x1={config.refAreaLeft}
              x2={config.refAreaRight}
              fillOpacity={0.2} // Recharts documentation lists this prop as strokeOpacity ... documentation is wrong
              stroke="cadetblue"
            />
          )}
          {children}
        </WrappedComponent>
        {config.data.length > 0 && (
          // The zoom component should be loaded and just hidden in order to render a transition however we don't load until the data is loaded
          <div className={classes.zoomControls}>
            <StyledSlider
              className={classes.slider}
              ThumbComponent={ThumbComponent}
              value={[config.sliderPosition]}
              onChange={(e, v) => {
                let chartWidth = config.right - config.left;
                if (chartWidth % 2 === 1) chartWidth++;
                if (chartWidth) {
                  setConfig({
                    ...config,
                    sliderPosition: v[0],
                    left: v[0] - chartWidth / 2,
                    right: v[0] + chartWidth / 2,
                  });
                }
              }}
              // Setting max and min like this makes slider stop when data[0] reaches left end of chart, not center of chart
              // Same with data[data.length-1] at right end of chart
              min={config.data[0].times.mjd + (config.right - config.left) / 2} // dataMin + chartWidth/2
              max={config.data[config.data.length - 1].times.mjd - (config.right - config.left) / 2} // dataMax - chartWidth/2
              step={0.00001}
            />
            <StyledButton
              type="button"
              onClick={() => {
                setZoomed(false);
                setConfig(initialState);
              }}
              tooltip="Reset zoom to the full window width"
              framed
              dontDisableInReadOnly
            >
              Zoom Full
            </StyledButton>
          </div>
        )}
      </div>
    );
  };
};

export default withZoom;
