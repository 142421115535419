import TargetGroupDialog from './TargetGroupDialog';
import { useActiveEntities } from 'hooks';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { ITargetGroup } from 'components/general/types/target';
import guidance from './guidance';
import { TargetVables, TTargetTypes } from 'utils/vable';

interface IProps {
  index: string;
}

const targetGroupsColumns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (row: ITargetGroup) =>
      TargetVables.TargetType[row.targetType as TTargetTypes].label.split(' ')[0],
  },
  {
    title: 'Targets',
    field: 'targetAssociations.length',
  },
];

const TargetGroupsSegment = ({ index }: IProps) => {
  const { targetGroups } = useActiveEntities();

  return (
    <EntityTableSegment<ITargetGroup>
      title="Target Groups"
      index={index}
      guidance={guidance}
      entityColumns={targetGroupsColumns}
      entityData={targetGroups}
      DialogComponent={TargetGroupDialog}
      modelName="target group"
    ></EntityTableSegment>
  );
};

export default TargetGroupsSegment;
