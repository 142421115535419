import { IAgent, IColumn } from 'components/general/types';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { useActiveEntities } from 'hooks';
import guidance from './guidance';
import AgentDialog from './AgentDialog';
import { AgentVables } from 'utils/vable';

const agentColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (rowData: IAgent) =>
      rowData.peripheral
        ? AgentVables.AgentType.PERIPHERAL.label
        : AgentVables.AgentType.TEMPLATED.label,
  },
];

interface IProps {
  index: string;
}

const AgentSegment = ({ index }: IProps) => {
  const { agents } = useActiveEntities();

  return (
    <EntityTableSegment<IAgent>
      title="Agents"
      index={index}
      guidance={guidance}
      entityColumns={agentColumns}
      entityData={agents}
      modelName="agent"
      DialogComponent={AgentDialog}
    ></EntityTableSegment>
  );
};

export default AgentSegment;
