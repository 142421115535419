import { IUser } from 'components/general/types/general';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useAppSelector } from './typedHooks';

const useUser = () => {
  const { User } = SatelliteApi;
  const user = useAppSelector(User.selectors.selectFirst) as IUser;
  return user;
};

export default useUser;
