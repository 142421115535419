import { TDPSchemaList } from 'components/general/types';

const tsEnergyStorageDPSchema: TDPSchemaList = [
  {
    name: 'Battery State of Charge (SoC)', 
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'State of Charge',
          unit: '',
          keys: ['Battery.$0.soc'],
          legend: ['State of Charge (SoC)'],
        },
      },
    ],
  },
  {
    name: 'Battery Voltage and Current', 
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Voltage',
          unit: 'V',
          keys: ['Battery.$0.voltage', 'Battery.$0.voc'],
          legend: ['Voltage', 'Open Circuit Voltage'],
        },
        right: {
          label: 'Current',
          unit: 'A',
          keys: ['Battery.$0.current'],
          legend: ['Current'],
        },
      },
    ],
  },
];

export default tsEnergyStorageDPSchema;
