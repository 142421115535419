import { makeGuidance } from 'hooks';
import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';

const sensorsHeading = 'Create and Edit Sensor';

const sensorsMainParagraph =
  'Sensors are used by Algorithms to quantify position, velocity, and attitude. Different Algorithms require different types of sensors. The available types are: Optical Attitude (e.g. Star Tracker), Position (e.g. GPS), Direction (e.g. Earth Sensor), Angular Velocity (e.g. Gyroscope), and Vector (e.g. Magnetic Field Sensor).';
//TODO: Add Drawings
export const sensorsGuidance = {
  _default: {
    heading: sensorsHeading,
    body: [
      {
        subHeading: 'Optical Attitude',
        chunk:
          'For the optical attitude sensor, the simulation requires inputs on planar and roll accuracy. One Sigma Cross-Axis Error is the sensor’s cross-axis standard deviation for attitude, and the One Sigma Boresight-Axis Error is the radial error (standard deviation) around the center vector of the sensor. Use the FoV select drop down to assign an FoV and FoV constraints as well as orient the sensor. These are used for Attitude Determination.',
      },
      {
        subHeading: 'Position',
        chunk:
          'Position sensors provide a 3D-vector position estimate for the satellite. Input the standard deviation of distance error, assumed to be the same for each dimension.  These are used for Orbit Determination.',
      },
      {
        subHeading: 'Direction',
        chunk:
          'Direction sensors provide information on the orientation of an external reference with respect to the satellite. Input a Reference Vector, the angle error (standard deviation) between the actual direction and measured, and optionally select an FoV to limit sense scope and direction. These are used for Attitude Determination.',
      },
      {
        subHeading: 'Angular Velocity',
        chunk:
          'Angular velocity sensors measure the angular rate of the spacecraft in the body frame. Input the standard deviation of the error and optionally select an FoV to limit sense scope and direction. These are used for Attitude Determination.',
      },
      {
        subHeading: 'Vector',
        chunk:
          'Vector sensors provide information on the orientation and magnitude of an external reference vector with respect to the satellite. Input a Reference Vector for the vector sensor to measure, the standard deviation of the error and optionally select an FoV to limit sense scope and direction. These are used for Attitude Determination.',
      },
    ],
  },

  ...componentThermalGuidance,
};

export const useGuidance = makeGuidance(sensorsGuidance);

const sensorsSegmentGuidance = {
  heading: 'Sensors',
  body: [
    {
      chunk: sensorsMainParagraph,
    },
  ],
};

export default sensorsSegmentGuidance;
