/**
 *
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 *
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 *
 * This file uses RECHARTS (ewwww) and is therefore OLD AND OBSOLETE
 *
 * DO NOT USE THIS COMPONENT UNTIL IT IS UPDATED TO USE ECHARTS
 *
 * If you are updating it yourself, please remove this notice when finished.
 *
 *
 */
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chartTitle: {
    textAlign: 'center',
    marginTop: '1em',
    color: 'red',
  },
});

export default useStyles;
