import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('A control state name is required.'),
  constantTemperature: Yup.number()
    .required('A constant temperature is required.')
    .min(-273.15, 'Temperatures must be no less than absolute zero.'),
});

export default validation;
