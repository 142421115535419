import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  compareContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  selectContainer: {
    width: '50%',
    margin: '20px 20px 0px 0px',
  },
  select: {
    maxWidth: 400,
  },
  diffContainer: {
    marginTop: 30,
    maxHeight: '70vh',
    overflowY: 'auto',
  },
}));

export default useStyles;
