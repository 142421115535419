import { useMemo } from 'react';
import { useIsUsersBranch } from '.';
import { useLocation } from 'react-router-dom';
import { routePathsCommon } from 'routes';
const { AGENT_TEMPLATE_EDIT, SCENARIO, AGENT_ANALYZE } = routePathsCommon;

/**
 *
 * @returns `true` if user is currently in AGENT_TEMPLATE_EDIT, SCENARIO, or AGENT_ANALYZE viewing a branch they don't
 * own (i.e. via collaboration access or shareable links), otherwise returns `false`.
 */
const useInReadOnlyBranch = (): boolean => {
  const { pathname } = useLocation();

  const inReadOnlyAllowedView = [AGENT_TEMPLATE_EDIT, SCENARIO, AGENT_ANALYZE].includes(
    pathname.split('/')[1]
  );
  const isUsersBranch = useIsUsersBranch();

  return useMemo(
    () => inReadOnlyAllowedView && !isUsersBranch,
    [inReadOnlyAllowedView, isUsersBranch]
  );
};

export default useInReadOnlyBranch;
