import { faArrowsLeftRightToLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, CircularProgress, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import Dialog from 'components/general/dialogs/Dialog';
import InfoBadge from 'components/general/InfoBadge';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IJob } from 'components/general/types';
import { useFormikForm, useLatestJob, useSelectById } from 'hooks';
import { useDataContext } from 'providers';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'routes';
import theme from 'theme';
import { dateFormatLong, mjd2Moment, msToDuration } from 'utils/time';
import * as Yup from 'yup';
import { IInlineBadgeProps } from '../../InlineBadge';
import StyledSlider from '../../StyledSlider';
import useStyles from './styles';

interface IConfig {
  positions: number[];
  open: boolean;
}

interface IProps {
  config: IConfig;
  setConfig: (config: IConfig) => void;
  progressPct: number;
  metadata: {
    id?: number;
    binWidth?: number;
  };
  dataIsCurrent?: boolean;
  bounds: number[];
}

interface ISearch {
  limit?: number;
  start?: number;
  stop?: number;
}

interface IForm {
  limit: number | '';
}

const defaultValues: IForm = {
  limit: '',
};

const fetchSchema = Yup.object().shape({
  limit: Yup.number()
    .required('An upper limit on the number of data points to fetch is required.')
    .min(2, 'Limit must be greater than or equal to 2.'),
});

const clampSliderPositions = (positions: number[], bounds: number[]) => [
  Math.max(bounds[0], positions[0] || 0),
  Math.min(bounds[1], positions[1] || Number.MAX_SAFE_INTEGER),
];

const limitInputLabel = 'Max Data Points';

const DataWindowDialog = ({
  config,
  setConfig,
  progressPct,
  metadata,
  dataIsCurrent,
  bounds,
}: IProps) => {
  const classes = useStyles();
  const latestJob = useLatestJob();
  const { fetchData, fetching } = useDataContext();

  const [search] = useSearchParams();

  const submit = ({ limit }: IForm) => {
    fetchData(config.positions[0], config.positions[1], limit);
    setConfig({ ...config, open: false });
  };

  const { formik } = useFormikForm<ISearch, IForm>(defaultValues, submit, fetchSchema, search);
  const { handleSubmit, getFieldProps, resetForm } = formik;

  const _positions = [Number(search.start), Number(search.stop)];

  return (
    <Dialog
      title="Data Window"
      open={config.open}
      onClose={() => {
        resetForm();
        setConfig({
          ...config,
          positions: clampSliderPositions(_positions, bounds),
          open: false,
        });
      }}
      submitActionText="Fetch Window"
      disableSubmit={
        progressPct === 0 ||
        (dataIsCurrent &&
          config.positions[0] === _positions[0] &&
          config.positions[1] === _positions[1] &&
          Number(formik.values.limit) === Number(search.limit))
      }
      large
      onSubmit={handleSubmit}
      loading={fetching}
      dontDisableInReadOnly
    >
      {search.start && search.stop && dataIsCurrent && (
        <>
          <h5>Most Recent Window:</h5>
          <div className={classes.dialogTitle}>
            <p className={classes.dialogParagraph}>
              {mjd2Moment(search.start).format(dateFormatLong)} to{' '}
              {mjd2Moment(search.stop).format(dateFormatLong)} | Limit: {search.limit}{' '}
            </p>
            <SamplingBadge style={{ marginLeft: 10 }} binWidth={metadata.binWidth} />
          </div>
        </>
      )}
      <h5 style={{ marginTop: 20 }}>New Window:</h5>
      <p className={classes.dialogParagraph}>
        Select a window of data to view. When viewing large regions of the simulation, downsampling
        can be used to reduce data volumes. Enter a value for "{limitInputLabel}" to adjust the
        downsampling rate. Only data from the completed portion of the simulation is available and
        will be returned in the query result.
      </p>

      {progressPct > 0 ? (
        <>
          <LabeledInput
            {...getFieldProps('limit')}
            label={limitInputLabel}
            type="number"
            placeholder="Limit"
            dontDisableInReadOnly
          />
          <div className={classes.sliderWrapper}>
            <div className={classes.sliderTotal}></div>
            <StyledSlider
              value={config.positions}
              valueLabelDisplay="on"
              valueLabelFormat={(value) => mjd2Moment(value).format(dateFormatLong)}
              onChange={(e, v) => {
                if (Array.isArray(v))
                  setConfig({
                    ...config,
                    positions: v,
                  });
              }}
              min={bounds[0]}
              max={bounds[1]}
              step={1 / 86400} // 1 second
              style={{ width: `${progressPct}%` }}
              className={classes.slider}
            />
          </div>
          <div className={classes.limitsWrapper}>
            <p>{mjd2Moment(latestJob.startTime).format(dateFormatLong)}</p>
            <p>{mjd2Moment(latestJob.stopTime).format(dateFormatLong)}</p>
          </div>
        </>
      ) : (
        <p style={{ display: 'flex', alignItems: 'center' }}>
          <b>Please wait until the simulation has progressed further </b>
          <InfoBadge
            style={{ marginLeft: 5 }}
            content="If the simulation is running, please wait until sufficient progress is reported by all Agents before fetching. If the simulation terminated or was aborted, please resimulate. An interactive windowing interface will display here once data is available."
          />
        </p>
      )}
    </Dialog>
  );
};

const DataWindow = () => {
  const classes = useStyles();
  const latestJob = useLatestJob();
  const [search] = useSearchParams();
  const { meta: metadata, jobId, fetching } = useDataContext();
  const latestJobWithDataFetched = {
    startTime: 0,
    stopTime: 0,
    dataId: null,
    ...(useSelectById('Job', jobId) as IJob | null),
  };

  const start = Number(search.start);
  const stop = Number(search.stop);
  let progressPct = Math.max(0, latestJob?.progress?.percentComplete || 0);
  const duration = latestJobWithDataFetched.stopTime - latestJobWithDataFetched.startTime;
  if (progressPct !== 100) {
    progressPct =
      (((latestJob?.progress?.currentTime || latestJob.stopTime) - latestJob.startTime) * 100) /
      (latestJob.stopTime - latestJob.startTime);
  }
  const progressLeft = ((start - latestJobWithDataFetched.startTime) * 100) / duration;
  const progressWidth = ((stop - start) * 100) / duration;

  const bounds = [
    latestJob.startTime,
    latestJob.startTime + ((latestJob.stopTime - latestJob.startTime) * progressPct) / 100,
  ];

  const dataIsCurrent = latestJob.id === jobId;

  const [config, setConfig] = useState<IConfig>({
    positions: [start, stop],
    open: false,
  });

  useEffect(() => {
    if (latestJob) {
      setConfig((curr) => ({
        ...curr,
        positions: clampSliderPositions(config.positions, bounds),
      }));
    }
  }, [latestJob]); // eslint-disable-line react-hooks/exhaustive-deps

  const notifyOutdated = Boolean(!dataIsCurrent && progressPct && !fetching);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <h5 className={classes.header}>
            Analysis Window
            {latestJobWithDataFetched.dataId && (
              <InfoBadge
                content={`Data Array ID: ${latestJobWithDataFetched.dataId}`}
                style={{ marginLeft: 5 }}
              />
            )}
          </h5>
          <Tooltip
            title={
              notifyOutdated
                ? 'Currently viewing outdated results. Fetch the latest.'
                : 'Fetch new window'
            }
          >
            <IconButton
              className={classes.expandBtn}
              onClick={() =>
                setConfig({
                  ...config,
                  positions: clampSliderPositions([start, stop], bounds),
                  open: true,
                })
              }
            >
              <FontAwesomeIcon icon={faArrowsLeftRightToLine} style={{ height: 18 }} />
              {notifyOutdated && <div className={classes.notificationBubble}></div>}
            </IconButton>
          </Tooltip>
        </div>
        <div>
          <div className={classes.miniBarWrapper}>
            <div className={classes.miniBarTotal}></div>
            {dataIsCurrent && (
              <div className={classes.miniBarProgress} style={{ width: `${progressPct}%` }}></div>
            )}
            <div
              className={classes.miniBarWindow}
              style={{
                left: `${progressLeft}%`,
                width: `${progressWidth}%`,
              }}
            ></div>
          </div>
          <p className={classes.dates}>
            {search.start &&
              search.stop &&
              (latestJobWithDataFetched.dataId || fetching) &&
              `${mjd2Moment(start).format(dateFormatLong)} to ${mjd2Moment(stop).format(
                dateFormatLong
              )}`}
          </p>
          <div className={classes.sampleWrapper}>
            {(latestJobWithDataFetched.dataId || fetching) && (
              <SamplingBadge binWidth={metadata.binWidth} />
            )}
          </div>
        </div>
      </div>
      <DataWindowDialog
        config={config}
        setConfig={setConfig}
        progressPct={progressPct}
        metadata={metadata}
        dataIsCurrent={dataIsCurrent}
        bounds={bounds}
      />
    </>
  );
};

interface ISamplingBadgeProps extends IInlineBadgeProps {
  binWidth?: number;
  fetching?: boolean;
  downsampled?: boolean;
}

const useBadgeStyles = makeStyles((theme) => ({
  samplingBadge: (props: ISamplingBadgeProps) => ({
    textAlign: 'center',
    marginTop: 3,
    '& > span': {
      backgroundColor: props.fetching
        ? theme.palette.primary.light
        : props.downsampled && props.binWidth != null
        ? theme.palette.warning.main
        : theme.palette.success.light,
      color: theme.palette.background.main,
      top: 'unset',
      right: 'unset',
      transform: 'none',
      transformOrigin: 'center',
      position: 'relative',
      cursor: 'default',
    },
  }),
  samplingTooltip: {
    ...theme.typography.body,
  },
}));

const SamplingBadge = (props: ISamplingBadgeProps) => {
  const { binWidth, style, ...restOfProps } = props;
  const { meta: metadata, fetching } = useDataContext();
  const downsampled =
    metadata.resolutions &&
    Object.values(metadata.resolutions as { [key: string]: number }).some((r) => r < 1);
  const classes = useBadgeStyles({ ...restOfProps, downsampled, fetching });

  const title =
    !fetching && downsampled
      ? `Your current window requires downsampling because the selected data set is larger than the specified "${limitInputLabel}". This creates risk of aliasing and other issues. For full-resolution analytics, choose either a smaller window of time or enter a larger limit in the "${limitInputLabel}" input. For analyzing slower parameters on a larger timescale (e.g., beta angle), downsampling is a great tool for avoiding excessive data queries.`
      : '';

  return (
    <Tooltip arrow title={title} classes={{ tooltip: classes.samplingTooltip }}>
      <Badge
        badgeContent={
          fetching ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              Fetching Data{' '}
              <CircularProgress
                style={{
                  height: 10,
                  width: 10,
                  marginLeft: 5,
                  color: theme.palette.background.main,
                }}
              />
            </div>
          ) : downsampled && binWidth != null ? (
            `Downsampled to ${msToDuration(binWidth * 86400 * 1000, false).replaceAll(
              ' ',
              ''
            )} bins`
          ) : (
            'Full resolution'
          )
        }
        className={classes.samplingBadge}
        color="primary"
        style={style}
      />
    </Tooltip>
  );
};

export default DataWindow;
