import AuthRoute from 'app/AuthRoute';
import {
  AgentAnalyzeView,
  AgentTemplateEditView,
  AuthorizeView,
  BranchesView,
  CollaboratorView,
  LoginView,
  ManagementConsoleView,
  NotFoundView,
  PasswordResetView,
  RootView,
  ScenarioView,
  ShareAuthView,
  SignUpView,
  SubscriptionLoadingView,
  VerifyView,
  WelcomeView,
} from 'components';
import { DISABLED_RECAPTCHA, isProd, RECAPTCHA_SITE_KEY } from 'config';
import { useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import {
  ActiveBranchProvider,
  ContextNavProvider,
  COTSProvider,
  DataProvider,
  SpacecraftProvider,
} from 'providers';
import RepositoriesProvider from 'providers/RepositoriesProvider';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { useDispatch } from 'react-redux';
import { Route, Switch, useLocation } from 'react-router-dom';
import Routes from 'routes';
import { ModuleVables } from 'utils/vable';
import useStyles from './styles';

ReactGA.initialize(isProd() ? 'UA-145830285-2' : 'UA-145830285-3');

const renderRecaptcha = (children) => {
  if (DISABLED_RECAPTCHA) return children;
  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_SITE_KEY} scriptProps={{ async: true }}>
      {children}
    </GoogleReCaptchaProvider>
  );
};

const App = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();

  const dispatch = useDispatch();

  const {
    User: {
      actions: { getUser },
    },
  } = SatelliteApi;

  const user = useUser();

  const page = location.pathname + location.search;
  useEffect(() => {
    if (user) {
      ReactGA.set({ userId: user.id });
    }
    ReactGA.pageview(page);
  }, [page, user]);

  useEffect(() => {
    dispatch(
      getUser({
        successCallback: () => {
          setLoading(false);
        },
        failureCallback: () => {
          setLoading(false);
        },
      })
    );
  }, []); //eslint-disable-line

  return (
    <div className={classes.root}>
      {!loading && (
        <Switch>
          <AuthRoute
            exact
            requiredSub={ModuleVables.ModuleTypes.PLATFORM.value}
            path={Routes.ROOT()}
            render={() => (
              <RepositoriesProvider>
                <RootView />
              </RepositoriesProvider>
            )}
          />
          <AuthRoute
            exact
            requiredSub={ModuleVables.ModuleTypes.PLATFORM.value}
            path={Routes.BRANCH()}
            render={() => <BranchesView />}
          />
          <AuthRoute
            path={Routes.AGENT_TEMPLATE_EDIT()}
            render={() => (
              <ActiveBranchProvider>
                <ContextNavProvider>
                  <COTSProvider>
                    <SpacecraftProvider>
                      <AgentTemplateEditView />
                    </SpacecraftProvider>
                  </COTSProvider>
                </ContextNavProvider>
              </ActiveBranchProvider>
            )}
          />
          <AuthRoute
            path={Routes.AGENT_ANALYZE()}
            render={() => (
              <ActiveBranchProvider>
                <ContextNavProvider>
                  <DataProvider>
                    <AgentAnalyzeView />
                  </DataProvider>
                </ContextNavProvider>
              </ActiveBranchProvider>
            )}
          />
          <AuthRoute
            path={Routes.SCENARIO()}
            render={() => (
              <RepositoriesProvider>
                <ActiveBranchProvider>
                  <ContextNavProvider>
                    <ScenarioView />
                  </ContextNavProvider>
                </ActiveBranchProvider>
              </RepositoriesProvider>
            )}
          />
          <Route path={Routes.SHARE_AUTH()} render={() => <ShareAuthView />} />
          <AuthRoute path={Routes.ACCOUNT()} render={() => <ManagementConsoleView />} />
          <AuthRoute path={Routes.VERIFY()} render={() => <VerifyView />} />
          <AuthRoute path={Routes.AUTHORIZE()} render={() => <AuthorizeView />} />
          <AuthRoute path={Routes.LOADING()} render={() => <SubscriptionLoadingView />} />
          <Route path={Routes.COLLABORATE()} render={() => <CollaboratorView />} />
          <Route path={Routes.WELCOME()} render={() => <WelcomeView />} />
          <Route path={Routes.TRIAL()} render={() => <WelcomeView />} />
          <Route path={Routes.LOGIN()} component={LoginView} />
          <Route path={Routes.SIGNUP()} render={() => renderRecaptcha(<SignUpView />)} />
          <Route path={Routes.PASSWORD_RESET()} component={PasswordResetView} />
          <Route path={Routes.NOT_FOUND()} component={NotFoundView} />
          <Route component={NotFoundView} />
        </Switch>
      )}
    </div>
  );
};

export default App;
