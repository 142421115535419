import { useCallback, useState, useMemo } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { AxisTickProps } from '@nivo/axes';
import useStyles, { barTheme } from './styles';
import {
  IPowerBarGraphProps,
  TExtendedPowerBarDatum,
} from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import HoverHighlightLayer from './HoverHighlightLayer';
import CustomTick from './CustomTick';
import InfoLabelLayer from './InfoLabelLayer';
import theme from 'theme';

// Defines the max height for the graph, currently infinity to disable max height
const defaultMaxHeight = Infinity;

// Margin between label and end of bars
const labelMargin = 4;

// Constants used to calculate side margins
// Distance between graph labels/bars and ticks/expand icons
const graphPadding = 12;
// Width of expansion icon and its padding/margin
const expandWidth = 24;

const barPalette = barTheme.bars.palette;

const PowerBarGraph = (props: IPowerBarGraphProps) => {
  const { colorBySign, unit, height, maxHeight, data } = props;
  const classes = useStyles(props);
  const [[leftMaxLabelWidth, rightMaxLabelWidth], setMaxLabelWidths] = useState<number[]>([0, 0]);
  const [maxTickWidth, setMaxTickWidth] = useState<number>(0);
  const [barData, setBarData] = useState<TExtendedPowerBarDatum[]>(
    data.map(
      (d, i) =>
        // adding 1 to data.indexInBars because nivo will delete data fields with values of 0
        ({ ...d, indexInBars: i + 1, visible: 1, barName: d.barName + '|' + d.hierarchy }) // Quick fix for items with the same name
    )
  );
  // Finds side margins based on widest possible values and labels, to prevent overflow
  const [leftMargin, rightMargin] = useMemo(
    () => [
      leftMaxLabelWidth + maxTickWidth + graphPadding,
      rightMaxLabelWidth + expandWidth + graphPadding,
    ],
    [leftMaxLabelWidth, rightMaxLabelWidth, maxTickWidth]
  );
  const colorSelector = useCallback(
    (bar) => {
      if (colorBySign)
        return bar.value >= 0
          ? barPalette.booleanColors.positive
          : barPalette.booleanColors.negative;
      return (
        barPalette.barColors?.[bar.data.hierarchy - 1] || theme.palette.background.contrastText
      );
    },
    [colorBySign]
  );

  // maps bar names to their indexInBars so that each CustomTick has access to their bar's data
  const nameIndexMap = useMemo(() => {
    const obj: { [key: string]: number } = {};
    barData.forEach((datum) => (obj[datum.barName] = datum.indexInBars));
    return obj;
    // we only want to recreate this when new data is passed, not when barData is updated on expansion and collapse, so only `data` in dependencies
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classes.barWrapper}
      style={{
        height:
          height ||
          Math.min(barData.filter((d) => d.visible).length * 25, maxHeight || defaultMaxHeight),
      }}
    >
      <ResponsiveBar
        // reverse the data because nivo flips the data for some reason?
        data={barData.filter((d) => d.visible).reverse()}
        theme={barTheme}
        keys={['value']}
        indexBy="barName"
        margin={{ top: 16, right: rightMargin, bottom: 24, left: leftMargin }}
        // nivo unforunately does not have a max bar width property, so need to manually scale padding to adjust width
        padding={barData.filter((data) => data.visible).length < 3 ? 0.6 : 0.3}
        layout="horizontal"
        colors={colorSelector}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        // this removes the tooltips which may be useful but unnecessary for the MVP. set isInteractive to true to re-enable them
        isInteractive={false}
        axisBottom={null}
        axisLeft={{
          renderTick: (tick: AxisTickProps<string>) => (
            <CustomTick
              tick={tick}
              barData={barData}
              nameIndexMap={nameIndexMap}
              leftMargin={leftMargin}
              setMaxTickWidth={setMaxTickWidth}
            />
          ),
        }}
        enableGridX
        gridXValues={[0]}
        enableGridY={false}
        enableLabel={false}
        layers={[
          (layers) => (
            <HoverHighlightLayer
              layers={layers}
              barData={barData}
              leftMargin={leftMargin}
              setBarData={setBarData}
            />
          ),
          'grid',
          'axes',
          'bars',
          (layers) => (
            <InfoLabelLayer
              setMaxLabelWidths={setMaxLabelWidths}
              layers={layers}
              unit={unit}
              labelMargin={labelMargin}
            />
          ),
        ]}
      />
    </div>
  );
};

export default PowerBarGraph;
