/* Application Config
 * Production and Development
 */

const {
  REACT_APP_SEDARO_API_URL,
  REACT_APP_VERSION_NUMBER,
  REACT_APP_CES,
  REACT_APP_DISABLE_RECAPTCHA,
  NODE_ENV,
} = process.env;

// Returns true if environment is Production, false otherwise
export function isProd() {
  return NODE_ENV === 'production';
}

export const IS_CES = Boolean(Number(REACT_APP_CES));
export const DISABLED_RECAPTCHA = Boolean(Number(REACT_APP_DISABLE_RECAPTCHA));

// reCAPTCHA Site Key
export const RECAPTCHA_SITE_KEY = isProd()
  ? '6Ld48rMbAAAAAG-fiu_UYlWgwabgF7G9RSH4o9nL'
  : '6LfE9bMbAAAAAEoV_AVrT-ILyorzy52zmnTeF7l7';
export const RECAPTCHA_PRIVACY_URL = 'https://policies.google.com/privacy';
export const RECAPTCHA_TERMS_URL = 'https://policies.google.com/terms';

// reusable url portions
const localhost = 'http://localhost:';
const companyUrl = (base = 'www') => `https://${base}.sedaro.com`;

// URL for the Sedaro Satellite API
export const SATELLITE_API_URL = isProd() ? REACT_APP_SEDARO_API_URL : localhost + '80';

export const TERMS_OF_USE_URL = companyUrl() + '/terms-of-use';
export const PRIVACY_POLICY_URL = companyUrl() + '/privacy-policy';
export const LICENSE_AGREEMENT_URL = companyUrl() + '/license-agreement';
export const DOCUMENTATION_URL = isProd() ? companyUrl('docs') : localhost + '4000';
export const COMMUNITY_SLACK_URL =
  'https://join.slack.com/t/sedaro-community/shared_invite/zt-1jps4i711-mXy88AZQ9AV7YcEXr8x7Ow';

// contact
const atCompanyEmailCom = '@sedarotech.com';
export const SUPPORT_EMAIL = 'support' + atCompanyEmailCom;
export const SALES_EMAIL = 'sales' + atCompanyEmailCom;
export const SUPPORT_PHONE = '+1.781.573.3276';
export const FEEDBACK_FORM_URL =
  'https://forms.clickup.com/36141388/f/12eyac-2087/OLAAXL4VLFP2IV171I';

export const timing = {
  lineAnimationDuration: 600,
  maxStripeLoadDuration: 15000,
};

export const DATA_LIMIT = 2500;

// strings used in menus
export const ITEMS = 'items';
export const SUB_ITEMS = 'subItems';
export const TITLE = 'title';
export const DP_SCHEMA = 'dataPlottingSchema';
export const TOGGLE = 'toggle';

export const VERSION_NUMBER = REACT_APP_VERSION_NUMBER || '0.0.0';

export const hotkeys = {
  HOME: 'q',
  BRANCHES: 'w',
  SC_DIALOG: 's',
};

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i;
