import { useState, useCallback } from 'react';
import _ from 'lodash';

const makeGuidance = (guidance) => {
  return (values) => {
    const [guidanceText, setGuidanceText] = useState();
    const guide = useCallback(
      (e, name, heading) => {
        // If the name passed to guidance ends in a number then check if it's in an array
        // Typically we want the arrays to have the same guidance for all entries
        // So set the name to the parent key for guidance to display correctly
        if (name?.match(/[0-9]$/)) {
          let splitName = name?.split('.');
          if (!isNaN(splitName[splitName.length - 1])) {
            splitName.pop();
            name = splitName.join('.');
          }
        }
        let g = _.get(guidance, name, guidance['_default']);
        let props = [];
        if (typeof g === 'function') {
          g = g(values);
        }
        if (_.has(g, 'alias')) {
          if (_.has(g, 'props')) {
            props = _.get(g, 'props');
          }
          g = _.get(guidance, _.get(g, 'alias'));
        }
        if (typeof g === 'function') {
          g = g(values, ...props);
        }
        let body = g;
        if (g.heading) {
          heading = g.heading;
        }
        if (g.body) {
          body = g.body;
        }
        setGuidanceText({
          heading: heading,
          body: body,
        });
      },
      [setGuidanceText, values]
    );
    if (!guidanceText) {
      guide();
    }
    return { guidance: guidanceText, guide };
  };
};

export default makeGuidance;
