import * as Yup from 'yup';

const conditionSchema = Yup.object().shape({
  name: Yup.string()
    .max(32, 'Condition name must be no more than 32 characters.')
    .required('A condition name is required'),

  paramACategory: Yup.object().required('Select a parameter A type'),

  paramBCategory: Yup.object().required('Select a parameter B type'),

  relationship: Yup.object().required('Select a relationship'),

  targetA: Yup.object().when('paramACategory', {
    is: (targetA) => targetA?.value === 'TARGET',
    then: Yup.object().required('Select a target A'),
  }),

  paramA: Yup.object().when('paramACategory', {
    is: (paramA) => paramA?.value !== 'TIME',
    then: Yup.object().required('Select a parameter A'),
  }),

  targetB: Yup.object().when('paramBCategory', {
    is: (targetB) => targetB?.value === 'TARGET',
    then: Yup.object().required('Select a target B'),
  }),

  paramB: Yup.object().when('paramBCategory', {
    is: (paramB) => paramB?.value !== 'SCALAR',
    then: Yup.object().required('Select a parameter B'),
  }),

  // Scalar can be multiple types so we must used the mixed data type from Yup and validate conditionally based on other fields.
  scalar: Yup.mixed()
    // Scalar should be a regular input
    .when(['paramBCategory', 'paramA'], {
      is: (paramBCat, paramA) => {
        return (
          paramBCat?.value === 'SCALAR' &&
          paramA?.value !== 'LO_SIGHT' &&
          paramA?.value !== 'SHADOW'
        );
      },
      then: Yup.mixed().required('A constant value is required.'),
    })

    // Mission time required for scalar
    .when(['paramACategory', 'paramBCategory'], {
      is: (paramACat, paramBCat) => {
        return paramACat?.value === 'TIME' && paramBCat?.value === 'SCALAR';
      },
      then: Yup.mixed()
        .nullable()
        .required('A mission time is required.')
        .test('validateTime', 'Mission time is invalid.', (val) => val?.isValid()),
    })

    // Percent should be within 0 and 100
    .when('paramA', {
      is: (paramA) => paramA?.units === '%',
      then: Yup.mixed()
        .required('A constant value is required.')
        .test(
          'properPercentageRange',
          'Percentage must be between 0 and 100, inclusive.',
          (val) => typeof val === 'number' && val >= 0 && val <= 100
        ),
    }),
});

export default conditionSchema;
