import { SUPPORT_EMAIL } from 'config';
import { useLatestJob, useSelectById, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { createContext, useMemo, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Routes, { getSearchParams } from 'routes';
import {
  setActiveMissionVersionId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';
import {
  IAgent,
  IClockConfig,
  IMission,
  IMissionVersion,
  IErrorResponse,
  IJob,
} from 'components/general/types';
import {
  ActuatorVables,
  ComponentVables,
  RepoVables,
  SensorVables,
  SubsystemVables,
  TempControllerVables,
} from 'utils/vable';
import {
  IBatteryPack,
  IBattery,
  ISolarPanel,
  ISubsystem,
  IPowerProcessor,
  ISolarCell,
  IBatteryCell,
  IBusRegulator,
  IComponent,
  ISolarArray,
} from 'components/general/types/power';
import {
  IControlState,
  ITempController,
  IThermalInterface,
  IThermalInterfaceMaterial,
} from 'components/general/types/thermal';
import { IAlgorithm, IActuator, ISensor, IPointingMode } from 'components/general/types/gnc';
import { IMissionOrbit, ISatellite, IConOps, IOrbit } from 'components/general/types/general';
import {
  IBodyFrameVector,
  IFieldOfView,
  IFovConstraint,
  IReferenceVector,
  ISurface,
  ISurfaceMaterial,
} from 'components/general/types/spacecraft';
import { ITarget, ITargetGroup } from 'components/general/types/target';
import { TCompiledModel, useModel } from 'middleware/SatelliteApi/template';
import { ICondition, IOperationalMode } from 'components/general/types/cdh';
import { isJobRunning } from 'components/general/SimulationControls';

interface IProps {
  children: ReactNode;
}

const defaultState = {
  branch: {} as IMissionVersion,
  activeEntities: {
    repo: {} as unknown as IMission,
    branch: {} as unknown as IMissionVersion,
    model: {} as unknown as TCompiledModel,
    agents: [] as IAgent[],
    orbits: [] as IOrbit[],
    clockConfig: {} as unknown as IClockConfig,
    bodyFrameVectors: [] as IBodyFrameVector[],
    conditions: [] as ICondition[],
    conOps: {} as unknown as IConOps,
    components: [] as IComponent[],
    missionOrbit: {} as unknown as IMissionOrbit,
    operationalModes: [] as IOperationalMode[],
    pointingModes: [] as IPointingMode[],
    satellite: {} as unknown as ISatellite,
    targets: [] as ITarget[],
    targetGroups: [] as ITargetGroup[],
    battery: {} as unknown as IBattery,
    batteryCells: [] as IBatteryCell[],
    batteryPacks: [] as IBatteryPack[],
    busRegulators: [] as IBusRegulator[],
    solarArrays: [] as ISolarArray[],
    solarCells: [] as ISolarCell[],
    solarPanels: [] as ISolarPanel[],
    subsystems: [] as ISubsystem[],
    surfaces: [] as ISurface[],
    surfaceMaterials: [] as ISurfaceMaterial[],
    powerProcessor: {} as unknown as IPowerProcessor,
    algorithms: [] as IAlgorithm[],
    tempControllers: [] as ITempController[],
    tempControllerStates: [] as IControlState[],
    thermalInterfaceMaterials: [] as IThermalInterfaceMaterial[],
    thermalInterfaces: [] as IThermalInterface[],
    actuators: [] as IActuator[],
    sensors: [] as ISensor[],
    angularVelocitySensors: [] as ISensor[],
    directionSensors: [] as ISensor[],
    vectorSensors: [] as ISensor[],
    opticalAttitudeSensors: [] as ISensor[],
    positionSensors: [] as ISensor[],
    fieldsOfView: [] as IFieldOfView[],
    fovConstraints: [] as IFovConstraint[],
    referenceVectors: [] as IReferenceVector[],
  },
};

const { Categories } = SubsystemVables;
const categoriesIndex: { [key: string]: number } = {};
let i = 0;
for (const cat of Categories._keys) {
  categoriesIndex[cat] = i;
  i++;
}

/**
 * Ensures that all children of ActiveBranchProvider have what they need from the current branch.
 * - Fetches the currently viewed branch, INCLUDING all model data.
 * - Compiles and separates model data into active entities, which the useActiveEntities() hook accesses.
 * - Subscribing to this provider makes a component rerender whenever the current branch is set or updated.
 * - Fetches the latestJob for this branch and stores its id in Redux.
 */
export const ActiveBranchContext = createContext(defaultState);

const ActiveBranchProvider = ({ children }: IProps) => {
  const { id } = useParams<{ id: string }>();
  const branch =
    useSelectById('MissionVersion', parseInt(id)) || (defaultState.branch as IMissionVersion);
  const repo =
    useSelectById('Mission', branch.repository) || (defaultState.activeEntities.repo as IMission);
  const {
    MissionVersion: {
      actions: { getMissionVersion, updateAnalyzeState },
    },
    Job: {
      actions: { getJob },
    },
  } = SatelliteApi;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const latestJob = useLatestJob();

  // Get active branch WITH model data, if not done already
  useEffect(() => {
    const { share } = getSearchParams();
    // If model data isn't here
    if (!branch.model?.ready) {
      dispatch(
        // Get branch
        getMissionVersion({
          id,
          queryParams: { expand: { repository: {} }, share },
          failureCallback: (response: IErrorResponse) => {
            if (response.error?.code === 'RESOURCE_NOT_FOUND') {
              // The RESOURCE_NOT_FOUND error is sent back if the branch doesn't exist, or the user is not an owner or
              // collaborator, or the shareable link is not valid.

              const error = share
                ? 'Invalid shareable link.'
                : 'The branch does not exist or is not accessible from this account. Please select a different branch.';

              enqueueSnackbar(error);
              history.replace(Routes.ROOT());
            } else if (
              response.error?.code === 'REQUIRED_PARAMETER' &&
              response.error?.message.includes('password')
            ) {
              // the REQUIRED_PARAMETER error is sent back when there is a password required and the shareJwt doesn't
              // exist in the cookies.
              enqueueSnackbar('This url is password protected.');
              history.push({
                pathname: Routes.SHARE_AUTH(),
                state: { from: history.location },
              });
            } else {
              enqueueSnackbar(
                `Failed to retrieve branch data. Please refresh the page and reach out to ${SUPPORT_EMAIL} if error persists.`
              );
            }
          },
        })
      );
    } else {
      // If model data is already here, just set the active branch id
      dispatch(setActiveMissionVersionId(id));
    }
  }, [dispatch, getMissionVersion, enqueueSnackbar, branch.model?.ready, id, history]);

  // Get latest job for this branch if current repo is a scenario
  useEffect(() => {
    const { share } = getSearchParams();
    // Don't get if latestJob is already stored (i.e. switching from scenario analyze to agent analyze)
    if (!latestJob?.id && repo?.dataType === RepoVables.DataTypes.SCENARIO.value) {
      dispatch(
        getJob({
          branchId: id,
          queryParams: { latest: undefined, share },
          successCallback: (job: IJob) => {
            // Job id === undefined means this fetch hasn't finished (or this is an agent template branch)
            // Job id === null means there is no latest job for this scenario branch
            dispatch(setLatestJobId(job.id || null));
            if (job.id) dispatch(updateAnalyzeState({ id, fetchWhenTrue: !isJobRunning(job) }));
          },
          failureCallback: (response: IErrorResponse) => {
            enqueueSnackbar(
              response?.error?.message ||
                'Error fetching simulation status. Please refresh the page, or contact us if this issue persists.'
            );
          },
        })
      );
    }
  }, [repo?.dataType, dispatch, getJob, id, enqueueSnackbar, latestJob?.id, updateAnalyzeState]);

  /************************************** USE ACTIVE ENTITIES **************************************/
  // compile all model data for this branch into useful entities
  const model = useModel(branch.model);
  const agents = model.Agent.all() as IAgent[];
  const orbits = model.Orbit.all() as IOrbit[];
  const clockConfig = model.clockConfig as IClockConfig;

  const conOps = model.conOps as IConOps;
  const satellite = model.satellite as ISatellite;
  const missionOrbit = model.missionOrbit as IMissionOrbit;
  const components = model.Component.all() as IComponent[];

  const targets = model.Target.all() as ITarget[];
  const targetGroups = model.TargetGroup.all() as ITargetGroup[];
  const pointingModes = model.PointingMode.all() as IPointingMode[];
  const conditions = model.Condition.all() as ICondition[];
  const operationalModes = model.OperationalMode.all() as IOperationalMode[];
  const bodyFrameVectors = model.BodyFrameVector.all() as IBodyFrameVector[];
  const surfaces = model.Surface.all() as ISurface[];
  const surfaceMaterials = model.SurfaceMaterial.all() as ISurfaceMaterial[];
  const powerProcessor = satellite?.powerProcessor as IPowerProcessor;
  const _subsystems = model.Subsystem.all() as ISubsystem[];

  // Quasi-deterministic ordering (after cloning)
  const subsystems: ISubsystem[] = useMemo(() => {
    const result = Array(_subsystems.length);
    for (const s of _subsystems) {
      let index = categoriesIndex[s.category];
      if (!result[index]) result[index] = s;
      else {
        // Find next available custom slot
        while (result[index]) index++;
        result[index] = s;
      }
    }
    return result;
  }, [_subsystems]);

  const algorithms = model.Algorithm.all() as IAlgorithm[];
  const actuators = useMemo(
    () =>
      components?.filter((c) =>
        ActuatorVables.ComponentType._keys.includes(c.componentType)
      ) as IActuator[],
    [components]
  );
  const fieldsOfView = model.FieldOfView.all() as IFieldOfView[];
  const fovConstraints = model.FovConstraint.all() as IFovConstraint[];
  const referenceVectors = model.ReferenceVector.all() as IReferenceVector[];
  const sensors = useMemo(
    () =>
      components?.filter((c) =>
        SensorVables.ComponentType._keys.includes(c.componentType)
      ) as ISensor[],
    [components]
  );
  const {
    angularVelocitySensors,
    directionSensors,
    vectorSensors,
    opticalAttitudeSensors,
    positionSensors,
  } = useMemo(() => {
    const result = {
      angularVelocitySensors: [] as ISensor[],
      directionSensors: [] as ISensor[],
      vectorSensors: [] as ISensor[],
      opticalAttitudeSensors: [] as ISensor[],
      positionSensors: [] as ISensor[],
    };
    sensors.forEach((sensor) => {
      switch (sensor.componentType) {
        case SensorVables.ComponentType.ANGULAR_VELOCITY_SENSOR.value:
          result.angularVelocitySensors.push(sensor);
          break;
        case SensorVables.ComponentType.DIRECTION_SENSOR.value:
          result.directionSensors.push(sensor);
          break;
        case SensorVables.ComponentType.VECTOR_SENSOR.value:
          result.vectorSensors.push(sensor);
          break;
        case SensorVables.ComponentType.OPTICAL_ATTITUDE_SENSOR.value:
          result.opticalAttitudeSensors.push(sensor);
          break;
        case SensorVables.ComponentType.POSITION_SENSOR.value:
          result.positionSensors.push(sensor);
          break;
      }
    });
    return result;
  }, [sensors]);

  const battery = powerProcessor?.battery as IBattery;
  const solarCells = model.SolarCell.all() as ISolarCell[];
  const batteryCells = model.BatteryCell.all() as IBatteryCell[];
  const busRegulators = model.BusRegulator.all() as IBusRegulator[];
  const batteryPacks = battery?.packs as IBatteryPack[];
  const solarArrays = model.SolarArray.all() as ISolarArray[];
  const solarPanels = useMemo(
    () =>
      components?.filter(
        (c) => c.componentType === ComponentVables.ComponentType.SOLAR_PANEL.value
      ) as ISolarPanel[],
    [components]
  );

  const tempControllers = useMemo(
    () =>
      components?.filter((c) =>
        TempControllerVables.ComponentType._keys.includes(c.componentType)
      ) as ITempController[],
    [components]
  );
  const tempControllerStates = model.ControlState.all() as IControlState[];
  const thermalInterfaceMaterials =
    model.ThermalInterfaceMaterial.all() as IThermalInterfaceMaterial[];
  const thermalInterfaces = model.ThermalInterface.all() as IThermalInterface[];

  /*********************************************************************************/

  const contextValue = useMemo(() => {
    return {
      branch,
      activeEntities: {
        repo,
        branch,
        model,
        agents,
        orbits,
        clockConfig,
        bodyFrameVectors,
        conditions,
        conOps,
        components,
        missionOrbit,
        operationalModes,
        pointingModes,
        satellite,
        targets,
        targetGroups,
        battery,
        batteryCells,
        batteryPacks,
        busRegulators,
        solarArrays,
        solarCells,
        solarPanels,
        subsystems,
        surfaces,
        surfaceMaterials,
        powerProcessor,
        algorithms,
        tempControllers,
        tempControllerStates,
        thermalInterfaceMaterials,
        thermalInterfaces,
        actuators,
        sensors,
        angularVelocitySensors,
        directionSensors,
        vectorSensors,
        opticalAttitudeSensors,
        positionSensors,
        fieldsOfView,
        fovConstraints,
        referenceVectors,
      },
    };
  }, [
    repo,
    branch,
    model,
    agents,
    orbits,
    clockConfig,
    bodyFrameVectors,
    conditions,
    conOps,
    components,
    missionOrbit,
    operationalModes,
    pointingModes,
    satellite,
    targets,
    targetGroups,
    battery,
    batteryCells,
    batteryPacks,
    busRegulators,
    solarArrays,
    solarCells,
    solarPanels,
    subsystems,
    surfaces,
    surfaceMaterials,
    powerProcessor,
    algorithms,
    tempControllers,
    tempControllerStates,
    thermalInterfaceMaterials,
    thermalInterfaces,
    actuators,
    sensors,
    angularVelocitySensors,
    directionSensors,
    vectorSensors,
    opticalAttitudeSensors,
    positionSensors,
    fieldsOfView,
    fovConstraints,
    referenceVectors,
  ]);

  return (
    <ActiveBranchContext.Provider value={contextValue}>{children}</ActiveBranchContext.Provider>
  );
};

export default ActiveBranchProvider;
