import { TDPSchemaList } from 'components/general/types';

const tsPowProcessingDPSchema: TDPSchemaList = [
  {
    name: 'Power Controller',
    subtitle: 'Output powers and total output power margin',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Power',
          unit: 'W',
          keys: [
            'satellite.powerProcessor.outputPowers.busRegulators',
            'satellite.powerProcessor.outputPowers.loads',
            'satellite.powerProcessor.outputPowers.total',
          ],
          legend: ['To Bus Regulators', 'To Loads', 'Total'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Output Power Margin',
          unit: '',
          keys: ['satellite.powerProcessor.outputPowerMargin'],
          legend: ['Output Power Margin'],
        },
      },
    ],
  },
  {
    name: '"{name}" Bus Regulator',
    subtitle: 'Output power and total output power margin',
    each: 'BusRegulator',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Power',
          unit: 'W',
          keys: ['outputPowers.busRegulators', 'outputPowers.loads', 'outputPowers.total'],
          legend: ['To Bus Regulators', 'To Loads', 'Total'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Output Power Margin',
          unit: '',
          keys: ['outputPowerMargin'],
          legend: ['Output Power Margin'],
        },
      },
    ],
  },
];

export default tsPowProcessingDPSchema;
