// @ts-nocheck

import { ICondition, IOperationalMode } from 'components/general/types/cdh';
import _ from 'lodash';
import { TCompiledModel } from 'middleware/SatelliteApi/template';
import { useStream } from 'providers/DataProvider';
import { useMemo } from 'react';

export const useConOpsData = (model: TCompiledModel, agentId: string) => {
  const _conditions = model.Condition.all();
  const {
    series: [t, ...conditionSeries],
  } = useStream(agentId, ..._conditions.map(({ id }) => `${id}.compliance`));
  const conditions: ICondition[] = _conditions.map((e, i) => ({
    ...e,
    series: conditionSeries[i],
  }));

  const _operationalModes = model.OperationalMode.all();
  const {
    series: [, ...opModeSeries],
  } = useStream(agentId, ..._operationalModes.map(({ id }) => `${id}.compliance`));
  const operationalModes: IOperationalMode[] = _operationalModes.map((e, i) => ({
    ...e,
    series: opModeSeries[i],
  }));

  const {
    series: [, activeOpModeSeries],
  } = useStream(agentId, `${model.conOps?.id}.activeOpMode`);

  return useMemo(() => {
    const barData: unknown[] = [];
    const pieData: unknown[] = [];
    const conOpsPieUnits = '';

    // Restructuring condition compliance data for plotting
    // Adding an "Extra" time series to each compliance with a one step truth period beyond complaince
    // change from compliant-to-non-compliant - this will convey that the true status continues through
    // the following time step in plotting
    let conditionCompliances = conditions.map((condition) =>
      condition.series?.map((compliance, ind, arr) => {
        return {
          compliance: compliance ? 1 : 0,
        };
      })
    );

    let conditionCompliancesExtra = conditions.map((condition) =>
      condition.series?.map((compliance, ind, arr) => {
        return {
          compliance:
            (ind !== arr.length - 1 && compliance && !arr[ind + 1]) ||
            (ind !== 0 && !compliance && arr[ind - 1])
              ? 1
              : 0,
        };
      })
    );

    // Spreading into n timeseries of objects with a "compliance" at each timestep
    // For each condition at each timestep, setting compliance undefined if false
    // zipping together for an array of n complainces at each time step
    conditionCompliances = _.zipWith(...conditionCompliances, (...args) => {
      return args.map((arg) => (arg?.compliance ? 1 : undefined));
    });

    conditionCompliancesExtra = _.zipWith(...conditionCompliancesExtra, (...args) => {
      return args.map((arg) => (arg?.compliance ? 1 : undefined));
    });

    // Restructuring and creating "Extra" time series to extend plotting of truth periods
    // for operational mode complaince viz. Same process as above for condition compliance.
    let operationalModeCompliances = operationalModes.map((operationalMode) =>
      operationalMode.series?.map((compliance, ind, arr) => {
        return {
          compliance: compliance ? 1 : 0,
        };
      })
    );
    operationalModeCompliances = _.zipWith(...operationalModeCompliances, (...args) => {
      return args.map((arg) => (arg?.compliance ? 1 : undefined));
    });

    let operationalModeCompliancesExtra = operationalModes.map((operationalMode) =>
      operationalMode.series?.map((compliance, ind, arr) => {
        return {
          compliance:
            (ind !== arr.length - 1 && compliance && !arr[ind + 1]) ||
            (ind !== 0 && !compliance && arr[ind - 1])
              ? 1
              : 0,
        };
      })
    );
    operationalModeCompliancesExtra = _.zipWith(...operationalModeCompliancesExtra, (...args) => {
      return args.map((arg) => (arg?.compliance ? 1 : undefined));
    });

    // Pulling op mode status for each mode at each time step
    // Adding "Extra" time series to be plotted and extend mode status one step beyond
    // the end of each active period
    const operationalModeStatuses = activeOpModeSeries.map((activeOpMode, ind, arr) =>
      operationalModes.map((oM) => (activeOpMode === oM.id ? 1 : undefined))
    );

    const operationalModeStatusesExtra = activeOpModeSeries.map((activeOpMode, ind, arr) =>
      operationalModes.map((oM) =>
        (ind !== arr.length - 1 && activeOpMode === oM.id && activeOpMode !== arr[ind + 1]) ||
        (ind !== 0 && activeOpMode !== arr[ind - 1] && arr[ind - 1] === oM.id)
          ? 1
          : undefined
      )
    );

    return {
      conOpsData: {
        timeSeries: _.zipWith(
          t,
          conditionCompliances,
          conditionCompliancesExtra,
          operationalModeCompliances,
          operationalModeCompliancesExtra,
          operationalModeStatuses,
          operationalModeStatusesExtra,
          activeOpModeSeries,
          (
            _t,
            conditionCompliances,
            conditionCompliancesExtra,
            operationalModeCompliances,
            operationalModeCompliancesExtra,
            operationalModeStatuses,
            operationalModeStatusesExtra,
            activeOpMode
          ) => {
            return {
              times: {
                mjd: _t,
              },
              conditionCompliances,
              conditionCompliancesExtra,
              operationalModeCompliances,
              operationalModeCompliancesExtra,
              operationalModeStatuses,
              operationalModeStatusesExtra,
              activeOpMode,
            };
          }
        ),
        bars: barData,
        pie: pieData,
      },
      conOpsPieUnits,
    };
  }, [conditions, operationalModes, activeOpModeSeries, t]);
};
