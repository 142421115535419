import { Fragment, useState, useMemo } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useSelectById, useSnackbar } from 'hooks';
import { IGenericObject, TDialogConfig } from 'components/general/types';
import { IUser } from 'components/general/types/general';
import useStyles from './styles';
import _ from 'lodash';

interface IProps {
  config: TDialogConfig<IUser>;
  repoId: number;
  onClose: () => void;
}

const CollaborationDeleteDialog = (props: IProps) => {
  const { config, repoId, onClose } = props;
  const { open, entity: collaborator } = config;

  const dispatch = useDispatch();
  const {
    Mission: {
      actions: { removeCollaborator },
    },
  } = SatelliteApi;

  const repo = useSelectById('Mission', repoId);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const dispatchParams = useMemo(() => {
    if (collaborator) {
      // The collaborator delete dialog is used for two very similar scenarios
      // First is removing a collaborator from a repo
      if (collaborator.id) {
        return {
          collaborators: repo?.collaborators.filter((collab: number) => collab !== collaborator.id),
        };
      } // The Second scenario is removing a pending invitation from a repo
      else {
        const _dispatchParams: IGenericObject = {
          pendingInvites: _.cloneDeep(repo?.pendingInvites),
        };
        delete _dispatchParams.pendingInvites[collaborator.email];
        return _dispatchParams;
      }
    }
  }, [collaborator, repo]);

  const handleDelete = () => {
    setLoading(true);
    dispatch(
      removeCollaborator({
        id: repoId,
        ...dispatchParams,
        successCallback: () => {
          setLoading(false);
          onClose();
          enqueueSnackbar(`${collaborator?.email} removed successfully.`, {
            variant: 'success',
          });
        },
        failureCallback: (response: IGenericObject) => {
          setErrorMessage(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  return (
    <Fragment>
      <Dialog
        onClose={onClose}
        open={open}
        loading={loading}
        prompt="Remove Collaborator"
        submitActionText="Remove"
        onSubmit={handleDelete}
      >
        <p>
          {collaborator?.id
            ? `Are you sure you want to remove ${collaborator?.firstName} ${collaborator?.lastName} as a collaborator from repository "${repo?.name}"?`
            : `Are you sure you want to cancel your invitation to ${collaborator?.email} to join repository "${repo?.name}"`}
        </p>
        {errorMessage && (
          <Alert severity="error" icon={false} variant="outlined" className={classes.error}>
            <AlertTitle className={classes.errorHeading}>Deletion Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </Dialog>
    </Fragment>
  );
};

export default CollaborationDeleteDialog;
