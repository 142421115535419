import { TDPSchemaList } from 'components/general/types';

const tsTempControllerDPSchema: TDPSchemaList = [
  {
    name: '"{name}" Temperature Controller - COOLER',
    subtitle: '',
    each: 'satellite.coolers',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Heat Flow Rate',
          unit: 'W',
          keys: ['regHeatFlowRate'],
          legend: ['"{name}" Heat Flow Rate'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Regulated Component Temperature',
          unit: 'C',
          keys: ['commandedTemperature.celsius', 'temperature.celsius'],
          legend: ['Target Temperature', '"{name}" Actual Temperature'],
        },
      },
    ],
  },
  {
    name: '"{name}" Temperature Controller - HEATER',
    subtitle: '',
    each: 'satellite.heaters',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Heat Flow Rate',
          unit: 'W',
          keys: ['regHeatFlowRate'],
          legend: ['"{name}" Heat Flow Rate'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Regulated Component Temperature',
          unit: 'C',
          keys: ['commandedTemperature.celsius', 'temperature.celsius'],
          legend: ['Commanded Temperature', '"{name}" Actual Temperature'],
        },
      },
    ],
  },
];

export default tsTempControllerDPSchema;
