import { TDPSchemaList } from 'components/general/types';

const tsComponentTempsDPSchema: TDPSchemaList = [
  {
    name: '"{name}"',
    subtitle: '',
    each: 'Subsystem',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Temperature',
          unit: 'C',
          keys: ['components.$each.temperature.celsius'],
          legend: ['"{name}" Temp'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Hot Margin',
          unit: 'C',
          keys: ['components.$each.hotMargin'],
          legend: ['"{name}" Hot Margin'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Cold Margin',
          unit: 'C',
          keys: ['components.$each.coldMargin'],
          legend: ['"{name}" Cold Margin'],
        },
      },
    ],
  },
];

export default tsComponentTempsDPSchema;
