import { useMemo, useCallback, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import { IMissionVersion } from 'components/general/types';
import StyledButton from 'components/general/StyledButton';
import useStyles from './styles';
import { useUser } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPencilAlt,
  faChevronDown,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment-timezone';
import { toTitleCase } from 'utils/strings';
import clsx from 'clsx';
import theme from 'theme';
import { useSelectById } from 'hooks';
import { TClickEvent } from 'components/general/types';
import Routes, { routePathsCommon } from 'routes';
import { RepoVables } from 'utils/vable';
import { TARGETS } from 'components/AgentTemplateEditView/menu/targets';

interface IProps {
  branch: IMissionVersion;
  openBranchDialog: (branch: IMissionVersion, action: string) => void;
  openMergeDialog: (branch: IMissionVersion, action: string) => void;
  openCommitDialog: (branchId: number, action: string) => void;
  fetchGitHistAndOpenDialog: (branch: IMissionVersion) => void;
  loadingGitHistBrId: number;
  openBranchId: number;
  setOpenBranchId: Dispatch<SetStateAction<number>>;
}

const dataTypeToRoute = {
  [RepoVables.DataTypes.AGENT_TEMPLATE.value]: {
    routeFn: Routes.AGENT_TEMPLATE_EDIT,
    initialSubPath: TARGETS,
  },
  [RepoVables.DataTypes.SCENARIO.value]: {
    routeFn: Routes.SCENARIO,
    initialSubPath: routePathsCommon.EDIT,
  },
};

const BranchRow = (props: IProps) => {
  const {
    branch,
    openBranchDialog,
    openMergeDialog,
    openCommitDialog,
    fetchGitHistAndOpenDialog,
    loadingGitHistBrId,
    openBranchId,
    setOpenBranchId,
  } = props;

  const history = useHistory();
  const user = useUser();
  const owner = useSelectById('Collaborator', branch.user);

  const mission = useSelectById('Mission', branch.mission);
  const isLastBranchOnRepo = mission.versions.length === 1;

  const rowIsUsersBranch = useMemo(() => branch.user === user?.id, [user, branch]);

  const navToAgentTemplateEdit = () => {
    const { routeFn, initialSubPath } = dataTypeToRoute[mission.dataType];
    const path = routeFn(branch.id, initialSubPath);
    history.push(path);
  };

  const tooltip = useCallback(
    (action) => {
      return !rowIsUsersBranch
        ? `Only the branch owner can ${action} a branch.`
        : action === 'delete' && isLastBranchOnRepo
        ? 'Every repository must have at least one branch. You can only delete this final branch by deleting the whole repository.'
        : toTitleCase(action);
    },
    [rowIsUsersBranch, isLastBranchOnRepo]
  );

  const isOpenBranch = openBranchId === branch.id;

  const classes = useStyles({
    enabledDelete: rowIsUsersBranch,
    selected: isOpenBranch,
  });

  return (
    <div className={classes.row} onClick={() => navToAgentTemplateEdit()}>
      <div className={classes.infoContainer}>
        <h3>{branch.name}</h3>
        {isOpenBranch && (
          <>
            <div className={classes.branchDescription}>{branch.description}</div>
            <div className={classes.dateText}>
              Created {moment(branch.dateCreated).fromNow()} by {owner?.firstName} {owner?.lastName}{' '}
            </div>
          </>
        )}
        <div className={classes.dateText}>
          ID: {branch.id} | Updated {moment(branch.dateModified).fromNow()}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <StyledButton
          framed
          style={{ backgroundColor: theme.palette.background.dark }}
          tooltip={tooltip(isOpenBranch ? 'hide description' : 'show description')}
          className={classes.iconButton}
          onClick={(e: TClickEvent) => {
            e.stopPropagation();
            setOpenBranchId((_prev) => (isOpenBranch ? 0 : branch.id));
          }}
        >
          <FontAwesomeIcon icon={isOpenBranch ? faChevronUp : faChevronDown} />
        </StyledButton>
        {rowIsUsersBranch && (
          <>
            <StyledButton
              onClick={(e: TClickEvent) => {
                e.stopPropagation();
                openMergeDialog(branch, 'create');
              }}
              framed
              style={{ backgroundColor: theme.palette.background.dark }}
              tooltip="Pull in updated changes from a target branch to this branch"
            >
              Merge In
            </StyledButton>
            <StyledButton
              onClick={(e: TClickEvent) => {
                e.stopPropagation();
                openCommitDialog(branch.id, 'create');
              }}
              framed
              style={{ backgroundColor: theme.palette.background.dark }}
              tooltip="Commit changes to save history and allow for merging changes into other branches"
            >
              Commit
            </StyledButton>
          </>
        )}
        <StyledButton
          framed
          onClick={(e: TClickEvent) => {
            e.stopPropagation();
            openBranchDialog(branch, 'branch');
          }}
          style={{ backgroundColor: theme.palette.background.dark }}
          tooltip="Create a new branch using this branch as a starting point"
        >
          Branch
        </StyledButton>
        <StyledButton
          framed
          onClick={(e: TClickEvent) => {
            e.stopPropagation();
            fetchGitHistAndOpenDialog(branch);
          }}
          style={{ backgroundColor: theme.palette.background.dark }}
          loading={loadingGitHistBrId === branch.id}
          replaceSpinner
          tooltip="View all previous commits"
        >
          History
        </StyledButton>
        <StyledButton
          framed
          style={{ backgroundColor: theme.palette.background.dark }}
          disabled={!rowIsUsersBranch}
          tooltip={tooltip('update')}
          className={classes.iconButton}
          onClick={(e: TClickEvent) => {
            e.stopPropagation();
            openBranchDialog(branch, 'edit');
          }}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </StyledButton>
        <StyledButton
          disabled={!rowIsUsersBranch || isLastBranchOnRepo}
          tooltip={tooltip('delete')}
          className={clsx(classes.iconButton, classes.deleteButton)}
          onClick={(e: TClickEvent) => {
            e.stopPropagation();
            openBranchDialog(branch, 'delete');
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </StyledButton>
      </div>
    </div>
  );
};

export default BranchRow;
