import { TableRow, TableCell, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useStyles from './styles';
import { TPowerTableDatum } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';

type TDefaultPowerTableRowProps = {
  datum: TPowerTableDatum;
  unit?: string;
  className?: string;
};

type TExpandablePowerTableRowProps = TDefaultPowerTableRowProps & {
  expanded: boolean;
  onExpandClick: () => void;
};

type TNonExpandablePowerTableRowProps = TDefaultPowerTableRowProps & {
  expanded?: undefined;
  onExpandClick?: undefined;
};

export type TPowerTableRowProps = TNonExpandablePowerTableRowProps | TExpandablePowerTableRowProps;

const PowerTableRow = (props: TPowerTableRowProps) => {
  const { datum, unit, expanded } = props;
  const classes = useStyles(props);

  return (
    <TableRow
      key={datum.name}
      onClick={props.onExpandClick}
      className={clsx(classes.powerTableRow, props.className)}
    >
      <TableCell className={clsx(classes.powerTableCell, classes.powerTableNameCell)}>
        <div>{datum.name}</div>
      </TableCell>
      <TableCell className={clsx(classes.powerTableCell, classes.powerTableValueCell)}>
        {datum.value !== undefined ? `${datum.value} ${unit ?? ''}` : ''}
      </TableCell>
      <TableCell className={clsx(classes.powerTableCell, classes.powerTableExpandCell)}>
        {expanded !== undefined && (
          <IconButton disableFocusRipple disableRipple>
            <ExpandMoreIcon style={{ transform: `rotate(${expanded ? '180deg' : '0deg'})` }} />
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default PowerTableRow;
