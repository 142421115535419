import { TDPSchemaList } from 'components/general/types';

const tsPowGenerationDPSchema: TDPSchemaList = [
  {
    name: 'Power Generation Overview',
    subtitle: 'Total power for all solar arrays',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Power',
          unit: 'W',
          keys: [
            'totalSolarPowerGeneration',
            'totalMaxSolarPower',
          ],
          legend: [
            'Total Power',
            'Total Available Power',
          ],
        },
        right: {
          label: 'Utilization',
          unit: '',
          keys: ['totalSolarPowerUtilization'],
          legend: ['Total Utilization'],
        },
      },
    ],
  },
  {
    name: '"{name}" Solar Array',
    subtitle: 'For the array and all constituent solar panels',
    each: 'SolarArray',
    plots: [
      {
        type: 'time-series',
        name: 'Operating Point',
        left: {
          label: 'Current',
          unit: 'A',
          keys: ['current'],
          legend: ['Current'],
        },
        right: {
          label: 'Voltage',
          unit: 'V',
          keys: ['voltage'],
          legend: ['Voltage'],
        },
      },
      {
        type: 'time-series',
        name: 'Power Generation',
        left: {
          label: 'Power',
          unit: 'W',
          keys: ['power', 'maxPower'],
          legend: ['Power', 'Available Power'],
        },
        right: {
          label: 'Utilization',
          unit: '',
          keys: ['utilization'],
          legend: ['Utilization'],
        },
      },
      {
        type: 'time-series',
        name: 'Current By Panel',
        left: {
          label: 'Current',
          unit: 'A',
          keys: ['panels.$each.current'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        name: 'Voltage By Panel',
        left: {
          label: 'Voltage',
          unit: 'V',
          keys: ['panels.$each.voltage'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        name: 'Power By Panel',
        left: {
          label: 'Power',
          unit: 'W',
          keys: ['panels.$each.power'],
          legend: ['{name}'],
        },
      },
    ],
  },
];

export default tsPowGenerationDPSchema;
