import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pendingInvitesTable: {
    width: '100%',
    marginBottom: 0,
    '& > div': {
      boxShadow: 'none',
    },
  },
  title: {
    marginTop: 10,
    fontSize: 17,
  },
}));

export default useStyles;
