import * as Cesium from 'cesium';
import { Entity, LabelGraphics, PolylineGraphics } from 'resium';
import { cadScale } from './utils';

const configMap = {
  SPACE_TARGET: {
    colors: {
      primary: Cesium.Color.RED,
      path: Cesium.Color.TOMATO,
    },
    namePrefix: 'Space',
    width: 1,
  },
  GROUND_TARGET: {
    colors: {
      primary: Cesium.Color.MAGENTA,
    },
    namePrefix: 'Ground',
    width: 1,
  },
  CELESTIAL_TARGET: {
    colors: {
      primary: Cesium.Color.ORANGE,
    },
    namePrefix: 'Celestial',
    width: 1,
  },
};
const TargetEntity = (props) => {
  const {
    target,
    data,
    orbitTailFactor,
    showOrbitTails,
    showLabels,
    labelTextScale,
    viewerRef,
    cadSignedUrl,
    cadScaleFactor,
  } = props;
  const config = configMap[target.targetType];
  const keyPrefix = `${target.targetType.charAt(0)}T_`;
  const perigeeAlt = target.perigeeAlt;
  return [
    <Entity
      name={`${config.namePrefix} Target: ${target.name}`}
      key={`${keyPrefix}${target.name}`}
      availability={
        new Cesium.TimeIntervalCollection([
          new Cesium.TimeInterval({
            start: data.startTime,
            stop: data.stopTime,
          }),
        ])
      }
      position={target.position3D}
      point={{
        pixelSize: 10,
        color: config.colors.primary,
      }}
      model={
        target.targetType === 'SPACE_TARGET' && {
          uri: cadSignedUrl,
          maximumScale: 2000000 * cadScaleFactor,
          scale: cadScale(perigeeAlt, cadScaleFactor),
        }
      }
      path={
        target.targetType === 'SPACE_TARGET'
          ? {
              // resolution prop for path is the max seconds to step when sampling the position, so we set to infinity to not limit the sampling
              resolution: Infinity,
              show: showOrbitTails,
              trailTime: target.orbitalPeriod * orbitTailFactor,
              leadTime: 0,
              material: config.colors.path,
              width: config.width,
            }
          : undefined
      }
      orientation={target.targetType === 'SPACE_TARGET' ? target.quaternion : undefined}
    >
      <LabelGraphics
        text={target.name}
        show={showLabels}
        scale={labelTextScale}
        fillColor={config.colors.primary}
        pixelOffset={Cesium.Cartesian2.fromElements(20, 20, new Cesium.Cartesian2())}
      />
    </Entity>,
    <Entity
      key={`${keyPrefix}LoS_${target.name}`}
      availability={
        new Cesium.TimeIntervalCollection([
          new Cesium.TimeInterval({
            start: data.startTime,
            stop: data.stopTime,
          }),
        ])
      }
      show={
        target.targetType === 'CELESTIAL_TARGET'
          ? viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
            Cesium.SceneMode.SCENE2D
          : true
      }
    >
      {data.satellite && (
        <PolylineGraphics
          positions={
            new Cesium.PositionPropertyArray([target.position3D, data.satellite.position3D])
          }
          show={showLabels && target.lineOfSight}
          width={config.width}
          material={
            new Cesium.PolylineDashMaterialProperty({
              color: Cesium.Color.WHITE,
            })
          }
          arcType={Cesium.ArcType.NONE}
        />
      )}
    </Entity>,
  ];
};

export default TargetEntity;
