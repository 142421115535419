import * as Yup from 'yup';

const pointingModeSchema = Yup.object().shape({
  name: Yup.string()
    .required('A pointing mode name is required.')
    .max(32, 'Pointing mode name must be no more than 32 characters.'),
  pointingModeType: Yup.object().required('Select a pointing mode type.'),

  acAlgorithm: Yup.mixed().when('pointingModeType', {
    is: (pointingModeType) => pointingModeType?.value !== 'PASSIVE',
    then: Yup.object().required('Select an attitude control algorithm.'),
  }),

  lockBodyFrameVector: Yup.mixed().when('pointingModeType', {
    is: (pointingModeType) => pointingModeType?.value !== 'PASSIVE',
    then: Yup.object().required('Select a locked body frame vector.'),
  }),

  lockVector: Yup.object().when('pointingModeType', {
    is: (pointingModeType) => pointingModeType?.value !== 'PASSIVE',
    then: Yup.object().required('Select a lock direction.'),
  }),

  maxAlignBodyFrameVector: Yup.mixed().when('pointingModeType', {
    is: (pointingModeType) => {
      return pointingModeType?.value === 'MAX_SECONDARY_ALIGN';
    },
    then: Yup.object().nullable().required('Select a max aligned body frame vector.'),
  }),

  maxAlignVector: Yup.object().when('pointingModeType', {
    is: (pointingModeType) => pointingModeType?.value === 'MAX_SECONDARY_ALIGN',
    then: Yup.object().required('Select a max align direction direction.'),
  }),

  spinRateBool: Yup.bool(),

  spinRate: Yup.number().when('spinRateBool', {
    is: (spinRateBool) => spinRateBool,
    then: Yup.number()
      .required('Spin rate is required')
      .max(
        10,
        'The magnitude of the spin rate must be within 10 RPM for sufficient attitude simulation sampling.'
      )
      .min(
        -10,
        'The magnitude of the spin rate must be within 10 RPM for sufficient attitude simulation sampling.'
      ),
  }),
});

export default pointingModeSchema;
