import { makeGuidance } from 'hooks';
import { orbitGuidance } from '../OrbitForm/guidance';

const groundTargetDescription =
  'Ground Target - Ground targets are fixed to the surface of the Earth, specifically the WGS84 ellipsoid.';
const spaceTargetDescription =
  'Space Target - Space targets are other Earth-orbiting objects. These could be other satellites targeted by your payload or used for communications crosslink or relay.';
const celestialTargetDescription =
  "Celestial Target - Celestial targets are natural objects which are located outside of Earth's atmosphere, such as the Moon and Sun.";

const agentPermanentGuidance = [
  {
    subHeading: 'Peripheral',
    chunk:
      'Peripheral agents are simple targets. Only their position and velocity are modeled during a simulation. They are propagated based on their type: Ground Target and Space Target. Ground Targets are propagated on the surface of the Earth at their defined latitude, longitude, and altitude. Space Targets are propagated based on their given initial orbit. \n Note: Celestial Targets are not agents and are created at the Agent Template level, since they require no inital state specification.',
  },
  {
    subHeading: 'Templated',
    chunk:
      'Templated Agents are full Digital Twins. Digital Twins are instantiated from a specific branch of an Agent Template repository. The initial state used to differentiate Agent Templates currently includes Target assignments and an initial orbit. You may instantiate a Twin from any branch you have read access to.',
  },
];

export const useGuidance = makeGuidance({
  name: {
    heading: 'Agent Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the agent and select the appropriate agent type from the options below.',
      },
      ...agentPermanentGuidance,
    ],
  },
  agentType: {
    alias: 'name',
  },

  targetType: {
    heading: 'Peripheral Agent Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the target and select the appropriate target type from the options below. Note that celestial targets need not be instantiated as Peripheral Agents as they are directly created on the Agent Template.',
      },
      {
        subHeading: 'Peripheral Agent Types',
        chunk: groundTargetDescription,
      },
      {
        chunk: spaceTargetDescription,
      },
      {
        chunk: celestialTargetDescription,
      },
    ],
  },
  template: {
    heading: 'Template Agent Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the agent and select the appropriate Agent Template repository.',
      },
      {
        subHeading: 'Orbits',
        chunk: 'Select the initial orbit for the templated agent.',
      },
    ],
  },
  branch: {
    heading: 'Choose Template Branch',
    body: [
      {
        chunk: 'Select the repository branch to use for this agent.',
      },
    ],
  },

  lat: {
    heading: 'Ground Target',
    body: [
      {
        chunk: groundTargetDescription,
      },
      {
        subHeading: 'Notes',
        chunk:
          'Latitude and altitude inputs are geodetic, which is typical for terrestrial latitude and altitude. \nNegative altitudes are not allowed. If your desired target location is below mean sea level (MSL), set altitude to zero. This is unlikely to impact simulation accuracy, and you can account for the impact of negative altitude when setting target-based conditions for your ConOps (e.g. setting a minimum elevation constraint based on the geometry of local terrain or other obstructions).',
      },
    ],
  },

  lon: {
    alias: 'lat',
  },

  alt: {
    alias: 'lat',
  },
  orbit: {
    heading: 'Orbiting Agent',
    body: [
      {
        chunk:
          'Select one of the orbits defined in the Orbits Segment to be assigned to this Agent.',
      },
    ],
  },

  ...orbitGuidance,

  _default: {
    heading: 'Resolve an Agent`s Targets to Other Agents',
    body: [
      {
        chunk:
          'The abstract Targets of each Agent must now be resolved to other Agents in the scenario.  Resolve each Target to an Agent using this interface.  Note that Ground Targets may only be resolved to Peripheral Ground Agents and Space Targets may only be resolved to Peripheral Space Agents or Spacecraft Digital Twins.',
      },
    ],
  },

  //TODO: Dynamically retrieve the field names for the target mapping instead of having to use default like this
});

const agentSegmentGuidance = {
  heading: 'Agents',
  body: agentPermanentGuidance,
};

export default agentSegmentGuidance;
