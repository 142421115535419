import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  branchNavIcon: {
    fontSize: 12,
    color: theme.palette.background.contrastText,
    margin: 0,
  },
  branchNavImg: {
    height: 12,
  },
}));

export default useStyles;
