import { createSlice } from '@reduxjs/toolkit';

interface IMissionExplorerState {
  activeMissionId: number | undefined;
  activeMissionVersionId: number | undefined;
  latestJobId: number | undefined;
}

const initialState: IMissionExplorerState = {
  activeMissionId: undefined,
  activeMissionVersionId: undefined,
  latestJobId: undefined,
};

const missionExplorerSlice = createSlice({
  name: 'missionExplorer',
  initialState,
  reducers: {
    setActiveMissionId: (state, action) => {
      state.activeMissionId = action.payload;
    },
    setActiveMissionVersionId: (state, action) => {
      state.activeMissionVersionId = action.payload;
    },
    setLatestJobId: (state, action) => {
      state.latestJobId = action.payload;
    },
  },
});

export default missionExplorerSlice.reducer;
export const { setActiveMissionId, setActiveMissionVersionId, setLatestJobId } =
  missionExplorerSlice.actions;
