import { useMemo } from 'react';
import _, { isString, isPlainObject } from 'lodash';

const resolveRef = (ref, schema) => {
  const segments = ref.split('/').slice(1);
  let cursor = schema;
  for (const seg of segments) {
    cursor = cursor[seg];
  }
  return cursor;
};

const groupToBlocks = (ref, schema) => {
  const l = resolveRef(ref, schema).properties.data.additionalProperties;
  let result = [];
  if (l.anyOf) {
    result = result.concat(l.anyOf.map(({ $ref: ref }) => ref));
  }
  if (l.allOf) {
    result = result.concat(l.allOf.map(({ $ref: ref }) => ref));
  }
  if (l.$ref) {
    result.push(l.$ref);
  }
  return result;
};

const blockToProperties = (ref, schema) => {
  const _ref = resolveRef(ref, schema);
  if (_ref.properties) return _ref.properties;
  return _ref.schema.properties;
};
const propertiesToRels = (properties) => {
  if (!properties) {
    return [];
  }
  return Object.entries(properties)
    .filter(([_, v]) => v.description && v.description.includes('Relationship'))
    .map(([k, _]) => k);
};
const getGroupsRelFields = (groupKey, schema) => {
  const relFields = new Set();
  try {
    for (const { $ref: ref } of schema.properties[groupKey].allOf) {
      for (const blockRef of groupToBlocks(ref, schema)) {
        for (const field of propertiesToRels(blockToProperties(blockRef, schema))) {
          relFields.add(field);
        }
      }
    }
  } catch (e) {
    // Do nothing for now
  }
  return relFields;
};

const isGroupKey = (str) => str.charAt(0) === str.charAt(0).toUpperCase();

export const makeModel = (def, schema, options = {}) => {
  const blocksById = {};
  const idsByGroup = {};
  const root = {};
  const { ignoreRels = new Set() } = options;

  if (schema) {
    for (const k in def) {
      if (isString(k) && isGroupKey(k)) {
        const relFields = new Set(
          [...getGroupsRelFields(k, schema)].filter((relName) => !ignoreRels.has(relName))
        );
        if (!idsByGroup[k]) idsByGroup[k] = [];

        for (const id in def[k]) {
          if (blocksById[id]) {
            throw Error(`A block with that ID (${id}) already exists.`);
          }

          idsByGroup[k].push(id);

          const _block = def[k][id];
          blocksById[id] = { ..._block, rels: relFields };
        }
      } else {
        root[k] = def[k];
      }
    }
    root.rels = propertiesToRels(schema.properties).filter((relName) => !ignoreRels.has(relName));
  }
  return {
    _blocksById: blocksById,
    _idsByGroup: idsByGroup,
    _root: root,
    _schema: schema,
    ready: Boolean(schema),
  };
};

export const useModel = (_model) => {
  return useMemo(() => {
    const model = _.cloneDeep(_model);
    const _cache = {
      // Don't need to index by `group` or `rel` because all ids are unique
      _byids: {},
      _get: {},
    };
    const handler = {
      get(target, prop, receiver) {
        if (target[prop]) return target[prop];
        if (isString(prop) && isGroupKey(prop)) {
          if (!_cache[prop]) _cache[prop] = { _all: [], _byIds: [] };
          return {
            all: () => _cache[prop]._all,
            byId: () => undefined,
            byIds: () => _cache[prop]._byIds,
            ids: () => [],
          };
        }
        return undefined;
      },
    };

    if (!model?._schema) {
      // Return proxy object where any key is valid for easier use in components
      return new Proxy({}, handler);
    }

    const { _blocksById: blocksById, _idsByGroup: idsByGroup, _root: root } = model;
    const compiledRoot = { ...root };
    const compiledBlocksById = {};

    const resolver = (ids) => {
      return {
        get: () => {
          let isManySide = true;
          let isManySideData = false;
          let _ids = ids; // Break pointer
          if (!Array.isArray(ids)) {
            isManySide = false;
            if (isPlainObject(ids)) {
              isManySideData = true;
              _ids = Object.keys(ids);
            } else {
              _ids = [ids]; // Cast to list for consistent handling
            }
          }
          if (isManySide && _cache._get[ids]) return _cache._get[ids];
          if (isManySideData && _cache._get[JSON.stringify(ids)])
            return _cache._get[JSON.stringify(ids)];
          const entities = _ids.map((id) => compiledBlocksById[id]);
          if (isManySide) {
            _cache._get[ids] = entities;
            return _cache._get[ids];
          } else if (isManySideData) {
            _cache._get[JSON.stringify(ids)] = entities.map((entity) => ({
              name: entity.name,
              id: entity.id,
              manySideData: ids[entity.id],
            }));
            return _cache._get[JSON.stringify(ids)];
          } else {
            return entities[0];
          }
        },
      };
    };

    for (const id in blocksById) {
      compiledBlocksById[id] = { ...blocksById[id] };
      const block = compiledBlocksById[id];
      if (block.rels) {
        for (const rel of block.rels) {
          Object.defineProperty(block, rel, resolver(block[rel])); // Consider updating these to just refs
        }
      }
    }
    if (root.rels) {
      for (const rel of root.rels) {
        Object.defineProperty(compiledRoot, rel, resolver(root[rel])); // Consider updating these to just refs
      }
    }
    for (const group in idsByGroup) {
      const _all = idsByGroup[group].map((id) => compiledBlocksById[id]);
      compiledRoot[group] = {
        all: () => _all,
        byId: (id) => compiledBlocksById[id],
        byIds: (ids) => {
          if (_cache._byids[ids]) return _cache._byids[ids];
          _cache._byids[ids] = ids.map((id) => compiledBlocksById[id]);
          return _cache._byids[ids];
        },
        ids: () => idsByGroup[group],
      };
    }
    const compiledModel = new Proxy(compiledRoot, handler);
    const exclude = new Set(['describe', 'rels', 'nextId']);
    compiledRoot.describe = () => {
      const bg = {};
      const obj = { '🧱 Block Groups -->': bg };
      Object.entries(compiledModel).forEach(([k, v]) => {
        if (!exclude.has(k) && v) {
          if (v.all) bg[`${k}.all()`] = v.all();
          else obj[k] = v;
        }
      });
      console.dir({ '`🛰️ Compiled Model -->`': obj });
      // ^^^ console.dir is intentionally here
    };

    return compiledModel;
  }, [_model]);
};
