import { TDPSchemaList } from 'components/general/types';

const tsSurfaceTempsDPSchema: TDPSchemaList = [
  {
    name: '"{name}"',
    subtitle: '',
    each: 'Surface',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Temperature',
          unit: 'C',
          keys: ['temperature.celsius'],
          legend: ['"{name}" Temperature'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Hot Margin',
          unit: 'C',
          keys: ['hotMargin'],
          legend: ['"{name}" Hot Margin'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Cold Margin',
          unit: 'C',
          keys: ['coldMargin'],
          legend: ['"{name}" Cold Margin'],
        },
      },
    ],
  },
];

export default tsSurfaceTempsDPSchema;
