import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    width: '100%',
    overflow: 'auto',
    ...theme.mixins.noScroll,
  },

  widget: {
    display: 'block',
    margin: 'auto',
    position: 'static',
    left: 0,
    right: 0,
    zIndex: 1,

    width: '450px',
    paddingBottom: 75,
    [theme.breakpoints.down('xs')]: { paddingBottom: 0 },

    [theme.breakpoints.up('xs')]: { width: '320px' },
    [theme.breakpoints.up('sm')]: { width: '400px' },
    [theme.breakpoints.up('md')]: { width: '450px' },
    [theme.breakpoints.up('lg')]: { width: '500px' },

    '& form': {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      [theme.breakpoints.down('sm')]: { width: '90%' },
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      margin: 'auto',
    },

    '& div.split-inputs': {
      display: 'flex',

      '& #left': {
        marginRight: 8,
      },
      '& #right': {
        marginLeft: 8,
      },
    },
  },

  brandWrapper: {
    textAlign: 'center',
    paddingTop: 5,
  },

  brand: {
    margin: 'auto',
    marginBottom: 30,
    marginLeft: 74,
    width: '84%',
    [theme.breakpoints.down('sm')]: { width: '90%', marginLeft: 'auto', marginBottom: 15 },
    height: 'auto',
  },

  legal: {
    display: 'flex',
    textAlign: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 5,
  },

  recaptchaLegal: {
    '@media (min-width:451px)': { display: 'none' },
  },
}));

export default useStyles;
