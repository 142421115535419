import { useMemo } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useEntityDialogControl } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import validation from './validation';
import useGuidance from './guidance';
import { SurfaceVables } from 'utils/vable';
import { IBodyFrameVector, ISurface, ISurfaceMaterial } from 'components/general/types/spacecraft';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { ISelectOption } from 'components/general/types';
import SurfaceMaterialDialog from './SurfaceMaterialDialog';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { MdAccent } from 'components/general/Accent/variants';
import { InputAdornment } from '@material-ui/core';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import AttitudeDisplay from '../../general/AttitudeDisplay';

interface IForm {
  name: string;
  area: string;
  motionType: ISelectOption | '';
  bodyFrameVector: ISelectOption | '';
  trackedVector: ISelectOption | '';
  antiTrack: ISelectOption | '';
  surfaceCentroid: [string, string, string];
  surfaceMaterial: ISelectOption | '';
}

const defaultValues: IForm = {
  name: '',
  area: '',
  motionType: '',
  bodyFrameVector: '',
  trackedVector: '',
  antiTrack: '',
  surfaceCentroid: ['', '', ''],
  surfaceMaterial: '',
};

const materialColumns = [
  {
    title: 'Part Number',
    field: 'partNumber',
  },
];

const ExternalSurfacesDialog = (props: { control: TEntityDialogControl<ISurface> }) => {
  const { control } = props;

  const classes = useStyles();
  const { satellite, bodyFrameVectors, surfaceMaterials, model, referenceVectors } =
    useActiveEntities();
  const bfVectorsList = useMemo(
    () => bodyFrameVectors.map((bfv) => ({ value: bfv.id, label: bfv.name })),
    [bodyFrameVectors]
  );
  const refVectorsList = useMemo(
    () => referenceVectors.map((rv) => ({ value: rv.id, label: rv.name })),
    [referenceVectors]
  );

  const surfaceMaterialList = useMemo(
    () => surfaceMaterials.map((e) => ({ value: e.id, label: e.partNumber })),
    [surfaceMaterials]
  );

  const materialEntityDialogControl = useEntityDialogControl<ISurfaceMaterial>();

  const options = useMemo(() => {
    return {
      motionType: SurfaceVables.MotionTypes.options,
      bodyFrameVector: bfVectorsList,
      surfaceMaterial: surfaceMaterialList,
      trackedVector: refVectorsList,
    };
  }, [bfVectorsList, surfaceMaterialList, refVectorsList]);

  const entityForm = useEntityForm<ISurface, IForm>({
    entityTypeText: 'Surface',
    entityDialogControl: control,
    additionalCreateValues: { satellite: satellite.id },
    defaultValues,
    validationSchema: validation,
    formikOptionalParams: { useGuidance, options },
  });

  const { formik } = entityForm;
  const { values, getFieldProps } = formik;

  const bfvLabel =
    typeof values.motionType === 'object' &&
    values.motionType.value === SurfaceVables.MotionTypes.FIXED.value
      ? 'Select Body Frame Vector normal to surface'
      : 'Select Body Frame Vector for axis of rotation';

  return (
    <>
      <EntityDialog
        entityForm={entityForm}
        xlarge={true}
        childrenRight={
          <AttitudeDisplay
            bodyFrameVectors={
              values.bodyFrameVector
                ? [
                    model.BodyFrameVector.byId(
                      String(values.bodyFrameVector.value)
                    ) as IBodyFrameVector,
                  ]
                : []
            }
            file={{
              fileUrl: satellite.cadSignedUrl,
              fileName: satellite.cadFileName,
            }}
            rightSide={true}
          />
        }
      >
        <div className={classes.inputs}>
          <div className={classes.inputGroup}>
            <LabeledInput
              label="Surface Name"
              placeholder="Surface Name"
              {...getFieldProps('name')}
              autoFocus
            />
            <LabeledInput
              label="Area"
              placeholder="Area"
              type="number"
              {...getFieldProps('area')}
              endAdornment={<InputAdornment position="end">m²</InputAdornment>}
            />
          </div>
          <div className={classes.inputGroup}>
            <LabeledSelect
              label="Motion Type"
              options={SurfaceVables.MotionTypes.options}
              {...getFieldProps('motionType')}
            />
          </div>
          {values?.motionType && values.motionType.value === 'VECTOR_TRACKING' && (
            <div className={classes.inputGroup}>
              <LabeledSelect
                label={'Tracked Reference Vector'}
                options={refVectorsList}
                {...getFieldProps('trackedVector')}
                noOptionsMessage={() => 'Create a Reference Vector'}
              />
              <LabeledCheckbox
                {...getFieldProps('antiTrack')}
                label="Anti-track?"
              ></LabeledCheckbox>
            </div>
          )}
          {typeof values.motionType === 'object' && values.motionType.value && (
            <>
              <div className={classes.inputGroup}>
                <LabeledSelect
                  label={bfvLabel}
                  options={bfVectorsList}
                  {...getFieldProps('bodyFrameVector')}
                  noOptionsMessage={() => 'Create a Body Frame Vector'}
                />
              </div>
              <div className={classes.inputGroup}>
                <h6>Centroid</h6>
                <LabeledInput
                  label="x-Component"
                  type="number"
                  {...getFieldProps('surfaceCentroid.0')}
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                />
                <LabeledInput
                  label="y-Component"
                  type="number"
                  {...getFieldProps('surfaceCentroid.1')}
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                />
                <LabeledInput
                  label="z-Component"
                  type="number"
                  {...getFieldProps('surfaceCentroid.2')}
                  endAdornment={<InputAdornment position="end">m</InputAdornment>}
                />
              </div>
            </>
          )}
        </div>
        <div className={classes.inputGroup}>
          <MdAccent header="Surface Materials">
            <WidgetTable
              className={classes.table}
              columns={materialColumns}
              data={surfaceMaterials}
              modelName="surface material"
              onFabClick={materialEntityDialogControl.openDialogForNew}
              onActionClick={materialEntityDialogControl.openDialogForExisting}
            />
          </MdAccent>
          <LabeledSelect
            label="Surface Material"
            options={surfaceMaterialList}
            {...getFieldProps('surfaceMaterial')}
            noOptionsMessage={() => 'Create a Surface Material'}
          />
        </div>
      </EntityDialog>
      <SurfaceMaterialDialog control={materialEntityDialogControl} />
    </>
  );
};

export default ExternalSurfacesDialog;
