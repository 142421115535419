import { faSatellite } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@material-ui/core';
import { RepoVables, TRepoDataTypesKeys } from 'utils/vable';
import theme from 'theme';
import mdLogo from 'multimedia/icons/md.png';

interface IProps {
  dataType: TRepoDataTypesKeys;
  height?: number;
}

const dataTypes = RepoVables.DataTypes;

const RepoDataTypeIcon = ({ dataType, height }: IProps) => {
  return (
    <Tooltip title={dataTypes[dataType].label} arrow>
      <span style={{ color: theme.palette.text.primary, marginLeft: '0.3rem' }}>
        {dataType === dataTypes.AGENT_TEMPLATE.value ? (
          <FontAwesomeIcon icon={faSatellite} />
        ) : (
          <img src={mdLogo} style={{ height: height || '1rem' }} alt="scenario-logo" />
        )}
      </span>
    </Tooltip>
  );
};

export default RepoDataTypeIcon;
