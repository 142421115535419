import { useMemo } from 'react';
import { Node, Edge } from 'react-flow-renderer';
import { MarkerType } from 'react-flow-renderer';
import theme from 'theme';
import SensorNode from './SensorNode';
import AlgoNode from './AlgoNode';
import SlidingModeNode from './SlidingModeNode';
import ActuatorNode from './ActuatorNode';
import {
  ISensorNode,
  IAlgoNode,
  ISlidingModeNode,
  IActuatorNode,
  ACTUATOR_REACTION_WHEEL,
  ACTUATOR_MAGNETORQUER,
  IAllNodes,
} from './general/types';

// =============================================== Positioning Variables ===============================================
// General
const distanceBetweenColumsX = 150;

// SensorNode
const SENSOR_NODE_TYPE = 'sensor-node';
const sensorNodeX = 0;
const sensorNodeHeight = 44;
const constraintHeight = 12;
const constraintOverhead = 14;

// AlgoNode
const ALGO_NODE_TYPE = 'algo-node';
const algoNodeX = distanceBetweenColumsX;
const algoNodeHeight = 60;

// SlidingModeNode
const SLIDING_MODE_NODE_TYPE = 'sliding-mode-node';
const slidingModeNodeX = distanceBetweenColumsX * 2;

// ActuatorNode
const ACTUATOR_NODE_TYPE = 'actuator-node';
const actuatorNodeX = distanceBetweenColumsX * 3;
const actuatorHeightMap = {
  [ACTUATOR_REACTION_WHEEL]: 80,
  [ACTUATOR_MAGNETORQUER]: 60,
};

// ============================================= Individual Prep Functions =============================================
const prepSensorNodes = (sensorNodes: ISensorNode[]) => {
  let numKeepOuts = 0;
  let numKeepIns = 0;
  let additional = 0;

  return sensorNodes.map((sN, i) => {
    const res: Node = {
      ...sN,
      id: String(sN.id),
      type: SENSOR_NODE_TYPE,
      position: {
        x: sensorNodeX,
        y:
          i * sensorNodeHeight +
          numKeepOuts * constraintHeight +
          numKeepIns * constraintHeight +
          additional,
      },
    };
    if (sN.data.keepOuts.length) additional += constraintOverhead;
    if (sN.data.keepIns.length) additional += constraintOverhead;
    numKeepOuts += sN.data.keepOuts.length;
    numKeepIns += sN.data.keepIns.length;
    return res;
  }) as Node[];
};

const prepAlgoNodes = (algoNodes: IAlgoNode[]) => {
  return algoNodes.map((aN, i) => ({
    ...aN,
    id: String(aN.id),
    type: ALGO_NODE_TYPE,
    position: { x: algoNodeX, y: i * algoNodeHeight },
  })) as Node[];
};

const prepSlidingModeNodes = (slidingModeNodes: ISlidingModeNode[]) => {
  return slidingModeNodes.map((sMN) => ({
    ...sMN,
    id: String(sMN.id),
    type: SLIDING_MODE_NODE_TYPE,
    position: { x: slidingModeNodeX, y: 0 },
  })) as Node[];
};

const prepActuatorNodes = (actuatorNodes: IActuatorNode[]) => {
  let curHeight = 0;

  return actuatorNodes.map((aN) => {
    const res: Node = {
      ...aN,
      id: String(aN.id),
      type: ACTUATOR_NODE_TYPE,
      position: { x: actuatorNodeX, y: curHeight },
    };

    curHeight += actuatorHeightMap[aN.data.type as keyof typeof actuatorHeightMap];

    return res;
  }) as Node[];
};

const prepEdgesFromNodes = (nodes: IAllNodes[]) => {
  //@ts-ignore // because we're adding a lot more than is normally just on Edge
  return nodes.flatMap((n) => {
    return n.targets.map((targetId) => {
      const sending = typeof n.sending === 'function' ? n.sending(targetId) : n.sending;
      return {
        id: `${n.id}-${targetId}`,
        // animated: sending, // comment in for animated dashed lines when `sending` is `true`
        zIndex: sending ? 2 : 1,
        source: n.id,
        target: String(targetId),
        markerEnd: {
          type: MarkerType.ArrowClosed,
          color: sending ? theme.palette.success.pastel : theme.palette.background.lightest,
        },
        type: 'smoothstep',
        style: {
          stroke: sending ? theme.palette.success.pastel : theme.palette.background.lightest,
        },
      };
    });
  }) as Edge[];
};

// ========================================== NodeTypes, and Nodes/Edges Hook ==========================================
export const nodeTypes = {
  [SENSOR_NODE_TYPE]: SensorNode,
  [ALGO_NODE_TYPE]: AlgoNode,
  [SLIDING_MODE_NODE_TYPE]: SlidingModeNode,
  [ACTUATOR_NODE_TYPE]: ActuatorNode,
};

const usePrepNodesAndEdges = (
  sensorNodes: ISensorNode[],
  algoNodes: IAlgoNode[],
  slidingModeNodes: ISlidingModeNode[],
  actuatorNodes: IActuatorNode[]
) => {
  const senNs = useMemo(() => prepSensorNodes(sensorNodes), [sensorNodes]);
  const algNs = useMemo(() => prepAlgoNodes(algoNodes), [algoNodes]);
  const slMoNs = useMemo(() => prepSlidingModeNodes(slidingModeNodes), [slidingModeNodes]);
  const actNs = useMemo(() => prepActuatorNodes(actuatorNodes), [actuatorNodes]);

  const nodes = useMemo(
    () => [...senNs, ...algNs, ...slMoNs, ...actNs],
    [senNs, algNs, slMoNs, actNs]
  );
  // @ts-ignore // because `nodes` type are typed as Node[], but they have a lot more than is normally on just `Node`:
  // the things that are needed in the function for IAllNodes[].
  const edges = useMemo(() => prepEdgesFromNodes(nodes), [nodes]);

  return [nodes, edges] as [Node[], Edge[]];
};

export default usePrepNodesAndEdges;
