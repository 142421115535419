import { useMemo } from 'react';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { IColumn } from 'components/general/types';
import guidance from './guidance';
import { ISolarPanel, ISubsystem } from 'components/general/types/power';
import { useActiveEntities } from 'hooks';
import COTSDialogButton from 'components/general/COTSDialogButton';
import Dialog from './Dialog';
import { SurfaceVables, TMotionTypesKeys } from 'utils/vable';

interface IProps {
  index: string;
}

const SolarPanelsSegment = (props: IProps) => {
  const { index } = props;
  const { solarPanels, subsystems } = useActiveEntities();
  const powerSubsystem = subsystems.find((s) => s.category === 'POWER');

  // Columns must be rendered inside the component to render the surface type of each solar panel
  const solarPanelsColumns: IColumn[] = useMemo(() => {
    return [
      { title: 'Name', field: 'name', width: '35%' },
      {
        title: 'Configuration',
        render: (solarPanel: ISolarPanel) => `${solarPanel.numSeries}S${solarPanel.numParallel}P`,
        width: '25%',
      },
      {
        title: 'Surface Type',
        render: (solarPanel: ISolarPanel) =>
          SurfaceVables.MotionTypes[solarPanel.surface.motionType as TMotionTypesKeys].label,
        width: '30%',
      },
    ];
  }, []);

  return (
    <EntityTableSegment<ISolarPanel>
      title="Solar Panels"
      index={index}
      guidance={guidance}
      titleButton={
        <COTSDialogButton entityKey="solarPanels" subsystem={powerSubsystem as ISubsystem} />
      }
      entityColumns={solarPanelsColumns}
      entityData={solarPanels}
      DialogComponent={Dialog}
      modelName="solar panel"
      customColumns={true}
    />
  );
};

export default SolarPanelsSegment;
