import {
  cesiumScaleAltitude,
  cesiumModelScaleDown,
  cesiumScaleLinearSlope,
  cesiumScaleLinearIntercept,
  perigeeAltScaleCap,
} from '../general/constants';

export const cadScale = (perigeeAlt, cadScaleFactor) => {
  if (!perigeeAlt) throw Error('perigeeAlt is undefined.');
  const cappedPerigeeAlt = Math.min(perigeeAlt, perigeeAltScaleCap); // To cap scale in HEO and deep space
  return cappedPerigeeAlt <= cesiumScaleAltitude
    ? 1000 * cesiumModelScaleDown * cadScaleFactor * cappedPerigeeAlt
    : (cesiumScaleLinearSlope * cappedPerigeeAlt + cesiumScaleLinearIntercept) * cadScaleFactor;
};
