import { SatelliteApi } from './api';
import { createAction } from '@reduxjs/toolkit';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  setActiveMissionId,
  setActiveMissionVersionId,
} from 'components/RootView/missionExplorerSlice';
import { sagaWrapper } from './api';
import { getSearchParams } from 'routes';

const { MissionVersion, OperationalMode } = SatelliteApi;

function* getMissionVersionAndMakeActive(action) {
  const { share } = getSearchParams();

  return yield sagaWrapper(action, function* (action) {
    const { id, successCallback, missionVersion } = action.payload;

    const successAction = createAction(action.type + '_SUCCESS');

    // Fetch MV if not already active
    let activeMissionVersion = missionVersion;
    if (!activeMissionVersion) {
      activeMissionVersion = yield call(
        MissionVersion.sagas.getMissionVersion,
        MissionVersion.actions.getMissionVersion({
          id,
          queryParams: { expand: { repository: {} }, share },
          disableWrap: true,
        })
      );
    }

    yield all([
      put(setActiveMissionId(activeMissionVersion.mission)),
      put(setActiveMissionVersionId(activeMissionVersion.id)),
    ]);

    const result = {
      missionVersion: activeMissionVersion,
    };

    yield put(successAction());
    successCallback(result);
  });
}

function* updateOperationalModePriorities(action) {
  return yield sagaWrapper(action, function* (action) {
    const { updates, branchId, successCallback } = action.payload;
    const successAction = createAction(OperationalMode.actions.updatePriorities + '_SUCCESS');
    yield all(
      updates.map((update) =>
        call(
          OperationalMode.sagas.updateOperationalMode,
          OperationalMode.actions.updateOperationalMode({
            branchId,
            ...update,
            successCallback: (res) => res,
          })
        )
      )
    );
    yield put(successAction());
    return successCallback();
  });
}

function* CustomSagas() {
  yield takeEvery(MissionVersion.actions.getAndMakeActive, getMissionVersionAndMakeActive);
  yield takeEvery(OperationalMode.actions.updatePriorities, updateOperationalModePriorities);
}

export default CustomSagas;
