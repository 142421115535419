import { useState, useEffect } from 'react';
import useStyles from './PriorityQueue.styles';
import clsx from 'clsx';
import Muuri from 'muuri';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from 'components/general/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip';
import PriorityDecoration from 'components/general/PriorityDecoration';
import InlineBadge from 'components/general/InlineBadge';
import EntityMenu from 'components/general/EntityMenu';

const PriorityQueue = (props) => {
  const { data, onActionClick, onAddClick, onChange, className } = props;

  const [grid, setGrid] = useState();
  const [loading, setLoading] = useState(false);
  const [[anchorEl, selectedRow], setAnchorEl] = useState([null, undefined]);

  const _onChange = (_grid, _setLoading) => {
    onChange(
      _grid.getItems().map((d, i) => ({
        id: parseInt(d._element.attributes.getNamedItem('data-entity-id').value),
        priority: _grid.getItems().length - 1 - i,
        oldPriority: parseInt(d._element.attributes.getNamedItem('data-entity-priority').value),
      })),
      _setLoading
    );
  };

  useEffect(() => {
    if (!loading) {
      let g = new Muuri('.priority-queue-grid', {
        dragEnabled: true,
        layoutDuration: 0,
        showDuration: 0,
        hideDuration: 0,
        // stops event propagation of IconButton by setting minimum distance (px) user must drag before movement starts
        dragStartPredicate: { distance: 10 },
      });
      setGrid(g);

      // On drag release event binding to update entity priorities
      g.on('dragReleaseEnd', function (item) {
        _onChange(g, setLoading);
      });

      // Check for priority discontinuity from delete and automatically update as if it were a drag release
      for (let i = 0; i < data.length; i++) {
        if (data[i].priority !== data.length - 1 - i) {
          _onChange(g, setLoading);
          break;
        }
      }

      return () => g.destroy();
    }
  }, [data, loading]); //eslint-disable-line

  useEffect(() => {
    if (grid) {
      grid.refreshItems().layout(true);
    }
  }, [data]); //eslint-disable-line

  const classes = useStyles();

  const handleClick = (event, rowData) => {
    setAnchorEl([event.currentTarget, rowData]);
  };

  const handleClose = () => {
    setAnchorEl([null, undefined]);
  };

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress loading={true} />
      </div>
    );
  }

  return (
    <div className={clsx(className, classes.root)}>
      <PriorityDecoration className={classes.decoration} />
      <div className={classes.queue}>
        <button className={classes.newSlot} onClick={onAddClick} type="button">
          + Create a new Operational Mode
        </button>
        <div className={clsx('priority-queue-grid', classes.grid)}>
          {data.map((d, i) => {
            return (
              <div
                className={classes.slot}
                data-entity-id={d.id}
                data-entity-priority={d.priority}
                key={'pq-' + d.id}
              >
                <div className={classes.slotContent}>
                  <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={5} className={classes.name}>
                      {d.name}
                      {d.priority === 0 && (
                        <InlineBadge
                          badgeContent={'default'}
                          color="primary"
                          className={classes.badge}
                        />
                      )}
                    </Grid>
                    <Grid item xs={5}>
                      {d.priority !== 0 &&
                        (d.conditions.length === 0 ? 'No' : d.conditions.length) +
                          ' Condition' +
                          (d.conditions.length !== 1 ? 's' : '')}
                    </Grid>
                  </Grid>
                  <div className={classes.action}>
                    <Tooltip title={'Edit or delete operational mode'} aria-label="add">
                      <IconButton
                        onClick={(event) => handleClick(event, d)}
                        edge="end"
                        size="small"
                      >
                        <MoreHorizIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <EntityMenu
          anchorEl={anchorEl}
          selectedData={selectedRow}
          onActionClick={onActionClick}
          handleClose={handleClose}
        />
      </div>
    </div>
  );
};

export default PriorityQueue;
