import { makeGuidance } from 'hooks';

export const targetGroupsAndPointing =
  'When pointing is driven by a target group direction, the Mission Module will select a single "active target" from the group at each time step. The direction to this "active target" will be used to compute spacecraft attitude. \nIf target group parameters are associated with any of the active operational mode\'s conditions, then only the targets that meet those conditions will be "active target" candidates. \nThe Mission Module will select the highest-priorty target from this set of candidates as the "active target".';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Target Group Inputs',
    body: [
      {
        chunk:
          'Input a name for the target group. You will be able to add targets once the target group has been saved. ',
      },
    ],
  },

  name: {
    alias: '_default',
  },

  targets: {
    heading: 'Select and Prioritize Targets',
    body: [
      {
        chunk:
          'Select targets to add to the group. The order of targets in this interface indicates their respective priority levels. Drag and drop targets to re-order them by priority.',
      },
      {
        subHeading: 'Connection to Pointing',
        chunk: targetGroupsAndPointing,
      },
    ],
  },
});
export default useGuidance;
