import React, { useCallback } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IGenericObject, ISelectOption, INestedSelectOption } from 'components/general/types';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { OrbitVables, TInitialRefOrbitTypes } from 'utils/vable';
import { InputAdornment } from '@material-ui/core';
import LabeledTimePicker from 'components/general/inputs/LabeledPickers/LabeledTimePicker';

interface IForm {
  name: string;
  initialStateDefType: ISelectOption | '';
  initialStateDefParams: IGenericObject & { initialRefOrbit: ISelectOption | '' };
}

const defaultValues: IForm = {
  name: '',
  initialStateDefType: '',
  initialStateDefParams: {
    a: '',
    e: '',
    inc: '',
    nu: '',
    om: '',
    raan: '',
    alt: '',
    lon: '',
    altPerigee: '',
    mltAscNode: null,
    initialRefOrbit: '',
    stateEci: ['', '', '', '', '', ''],
  },
};

// --------------------------------------------------------------------------
// Define input fields
// --------------------------------------------------------------------------
interface ISpec {
  name: string;
  label: string;
  units?: string;
}

const altitudeField: ISpec = {
  name: 'alt',
  label: 'Altitude',
  units: 'km',
};
const raanField: ISpec = {
  name: 'raan',
  label: 'RAAN',
  units: 'deg',
};
const argOfLatField: ISpec = {
  name: 'nu',
  label: 'Argument of Latitude',
  units: 'deg',
};
const trueLonField: ISpec = {
  ...argOfLatField,
  label: 'True Longitude',
};
const trueAnomalyField: ISpec = {
  ...argOfLatField,
  label: 'True Anomaly',
};
const longitudeField: ISpec = {
  name: 'lon',
  label: 'Longitude (East)',
  units: 'deg',
};
const altPerigeeField: ISpec = {
  name: 'altPerigee',
  label: 'Perigee Altitude',
  units: 'km',
};
const trueLonOfPerigeeField: ISpec = {
  name: 'om',
  label: 'True Longitude of Perigee',
  units: 'deg',
};
const argOfPerigeeField: ISpec = {
  ...trueLonOfPerigeeField,
  label: 'Argument of Perigee',
};
const semimajorAxisField: ISpec = {
  name: 'a',
  label: 'Semimajor Axis',
  units: 'km',
};
const inclinationField: ISpec = {
  name: 'inc',
  label: 'Inclination',
  units: 'deg',
};
const eccentricityField: ISpec = {
  name: 'e',
  label: 'Eccentricity',
};

interface IProps {
  // Use 'any' type because it depends on where OrbitForm is called from
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entityForm: ReturnType<typeof useEntityForm<any, any>>;
  options: { [key: string]: (ISelectOption | INestedSelectOption)[] };
}
const OrbitForm = ({ entityForm, options }: IProps) => {
  // Set up styles
  const classes = useStyles();

  const { formik } = entityForm;
  const { getFieldProps, values, setFieldValue } = formik;
  const { initialStateDefType, initialStateDefParams } = values;

  const renderInputField = useCallback(
    (spec: ISpec, value?: number) => {
      // Conditionally use setValue to manually update the value and rerender the input
      if (value !== undefined && initialStateDefParams[spec.name] !== value) {
        setFieldValue(`initialStateDefParams.${spec.name}`, value);
      }

      return (
        <LabeledInput
          {...getFieldProps(`initialStateDefParams.${spec.name}`)}
          key={spec.name}
          type="number"
          endAdornment={spec.units && <InputAdornment position="end">{spec.units}</InputAdornment>}
          label={spec.label}
          readOnly={value !== undefined}
        />
      );
    },
    [initialStateDefParams, setFieldValue, getFieldProps]
  );

  const renderInitialStateDefParamFields = useCallback(
    (initialRefOrbit: TInitialRefOrbitTypes) => {
      const fieldSpecs: { [key in TInitialRefOrbitTypes]: ISpec[] } = {
        POLAR_CIRC: [altitudeField, raanField, argOfLatField],
        EQUATORIAL_CIRC: [altitudeField, trueLonField],
        SUN_SYNC_CIRC: [altitudeField, trueAnomalyField],
        GEOSTAT: [longitudeField],
        GEOSTAT_TRANSFER: [altPerigeeField, trueLonOfPerigeeField, trueAnomalyField],
        ISS: [raanField, trueAnomalyField],
      };
      const fields = fieldSpecs[initialRefOrbit].map((spec) => renderInputField(spec));
      if (initialRefOrbit === 'SUN_SYNC_CIRC') {
        fields.push(
          <LabeledTimePicker
            label="Mean Local Time at the Ascending Node"
            {...getFieldProps('initialStateDefParams.mltAscNode')}
            key="initialStateDefParams.mltAscNode"
          />
        );
      }
      return fields;
    },
    [getFieldProps, renderInputField]
  );

  return (
    <>
      <div className={classes.inputGroup}>
        <LabeledSelect
          label="Define Initial Orbit Using:"
          options={options.initialStateDefType}
          {...getFieldProps('initialStateDefType')}
          formikOnChange={(val: ISelectOption) => {
            if (
              typeof initialStateDefType === 'object' &&
              val.value !== initialStateDefType.value
            ) {
              setFieldValue('initialStateDefParams', defaultValues.initialStateDefParams);
            }
          }}
        />
        {initialStateDefType === OrbitVables.InitialStateDefType.ECI_STATE && // --------- POSITION & VELOCITY
          [
            {
              name: 'initialStateDefParams.stateEci.0',
              label: 'x-Position',
              units: 'km',
            },
            {
              name: 'initialStateDefParams.stateEci.1',
              label: 'y-Position',
              units: 'km',
            },
            {
              name: 'initialStateDefParams.stateEci.2',
              label: 'z-Position',
              units: 'km',
            },
            {
              name: 'initialStateDefParams.stateEci.3',
              label: 'x-Velocity',
              units: 'km/s',
            },
            {
              name: 'initialStateDefParams.stateEci.4',
              label: 'y-Velocity',
              units: 'km/s',
            },
            {
              name: 'initialStateDefParams.stateEci.5',
              label: 'z-Velocity',
              units: 'km/s',
            },
          ].map((spec: ISpec) => (
            <LabeledInput
              {...getFieldProps(spec.name)}
              key={spec.name}
              type="number"
              endAdornment={
                spec.units && <InputAdornment position="end">{spec.units}</InputAdornment>
              }
              label={spec.label}
            />
          ))}
        {initialStateDefType === OrbitVables.InitialStateDefType.ORBITAL_ELEMENTS && (
          <>
            {renderInputField(semimajorAxisField)}
            {renderInputField(inclinationField)}
            {renderInputField(eccentricityField)}
            {initialStateDefParams &&
              initialStateDefParams.e !== '' &&
              renderInputField(
                raanField,
                parseFloat(initialStateDefParams.inc) === 0 ? 0 : undefined
              )}
            {initialStateDefParams &&
              initialStateDefParams.inc !== '' &&
              parseFloat(initialStateDefParams.inc) !== 0 &&
              renderInputField(
                argOfPerigeeField,
                parseFloat(initialStateDefParams.e) === 0 ? 0 : undefined
              )}
            {initialStateDefParams &&
              parseFloat(initialStateDefParams.inc) === 0 &&
              renderInputField(
                trueLonOfPerigeeField,
                parseFloat(initialStateDefParams.e) === 0 ? 0 : undefined
              )}
            {initialStateDefParams &&
              parseFloat(initialStateDefParams.inc) === 0 &&
              parseFloat(initialStateDefParams.e) === 0 &&
              renderInputField(trueLonField)}
            {initialStateDefParams &&
              initialStateDefParams.inc !== '' &&
              parseFloat(initialStateDefParams.inc) !== 0 &&
              parseFloat(initialStateDefParams.e) === 0 &&
              renderInputField(argOfLatField)}
            {initialStateDefParams &&
              parseFloat(initialStateDefParams.e) > 0 &&
              renderInputField(trueAnomalyField)}
          </>
        )}
        {initialStateDefType === OrbitVables.InitialStateDefType.REF_ORBIT && ( // --------- REFERENCE ORBIT
          <>
            <LabeledSelect
              label="Reference Orbit Type"
              options={options['initialStateDefParams.initialRefOrbit']}
              {...getFieldProps('initialStateDefParams.initialRefOrbit')}
              formikOnChange={(val: ISelectOption) => {
                if (
                  initialStateDefParams.initialRefOrbit &&
                  val.value !== initialStateDefParams.initialRefOrbit.value
                ) {
                  setFieldValue('initialStateDefParams', {
                    ...defaultValues.initialStateDefParams,
                  });
                }
              }}
            />
            <div className={classes.indent}>
              {initialStateDefParams &&
                initialStateDefParams.initialRefOrbit &&
                renderInitialStateDefParamFields(
                  initialStateDefParams.initialRefOrbit.value as TInitialRefOrbitTypes
                )}
            </div>
          </>
        )}
        {initialStateDefType === OrbitVables.InitialStateDefType.TLE && ( // --------- TWO-LINE ELEMENT
          <LabeledInput
            {...getFieldProps('initialStateDefParams.tle')}
            label={'Two-Line Element (TLE)'}
            multiline
            rows={4}
            className={classes.tleInput}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
              setFieldValue(
                'initialStateDefParams.tle',
                e.target.value
                  .replace(/,+/g, '\n') // Replace commas with newlines (to support csv TLE entry)
                  .replace(/\r+/g, '') // Remove CRs
                  .replace(/^[ \t]+/gm, '') // Removing leading whitespace
                  // .replace(/[ \t]+$/gm, '') // Remove trailing whitespace #Disabled for now (MVP)
                  .replace(/\n+/g, '\n') // Replace line-separating whitespace with a single \n
                // .replace(/[ \t]+(?=\n)/g, '') // Replace whitespace before line-separating newlines  #Disabled for now (MVP)
              );
            }}
          />
        )}
      </div>
    </>
  );
};

export default OrbitForm;
