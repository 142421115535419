import { useEffect } from 'react';
import LoadingView from '../LoadingView';
import { useHistory } from 'react-router-dom';
import Routes from 'routes';
import { timing } from 'config';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useDispatch } from 'react-redux';
import { useUser, useSnackbar } from 'hooks';

let startTime = Date.now();

function SubscriptionLoadingView() {
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const {
    User: {
      actions: { getUser },
    },
  } = SatelliteApi;
  const user = useUser();
  const dispatch = useDispatch();

  useEffect(() => {
    let interval = setInterval(() => {
      if (Date.now() - startTime > timing.maxStripeLoadDuration) {
        clearInterval(interval);
        enqueueSnackbar(
          'Something went wrong. Please refresh the page or check back later. If this problem persists or your subscription does not appear, please contact our support team.'
        );
        history.replace(Routes.ACCOUNT());
        return;
      }

      dispatch(
        getUser({
          successCallback: () => {
            for (const subscription of user.subscriptions) {
              if (subscription.status === 'ACTIVE' && subscription.term !== 'TRIAL') {
                history.push({
                  pathname: Routes.ROOT(),
                });
              }
            }
          },
        })
      );
    }, 500);
    return () => clearInterval(interval);
  }, [user]); //eslint-disable-line

  return <LoadingView />;
}

export default SubscriptionLoadingView;
