import LabeledInput from 'components/general/inputs/LabeledInput';
import { InputAdornment } from '@material-ui/core';
import * as Yup from 'yup';
import { IGenericObject } from 'components/general/types';

/**
 * Used to add hotTempRating, coldTempRating, [initial] temperature, and thermalCapacitance to a component. –
 * Returns input form, default values, validation schema, and guidance definition.
 * @returns thermalPropsInput (React Input elements), thermalDefaultValues (object), thermalValidation (Yup validation schema), thermalGuidance (guidance info)
 */
const getThermalProps = () => {
  interface IThermalForm {
    hotTempRating: number | '';
    coldTempRating: number | '';
    thermalCapacitance: number | '';
    temperature: number | '';
  }

  // Typed default values for these thermal props.
  // Can be destructured into the rest of the default values in a form
  const thermalDefaultValues: IThermalForm = {
    hotTempRating: 100,
    coldTempRating: -40,
    thermalCapacitance: 1000,
    temperature: 20,
  };

  // Validation def, to be destructured into the rest of the validation
  const thermalValidation = {
    hotTempRating: Yup.number()
      .required('Hot temperature rating is required. Default is 100°C.')
      .min(-273.15, 'Temperatures must be no less than absolute zero.'),
    coldTempRating: Yup.number()
      .required('Cold temperature rating is required. Default is -40°C.')
      .min(-273.15, 'Temperatures must be no less than absolute zero.'),
    thermalCapacitance: Yup.number()
      .required('Thermal capacitance is required.')
      .min(0, 'Thermal capacitance must be non-negative.'),
    temperature: Yup.number()
      .required('Initial temperature is required. Default is 20°C.')
      .min(-273.15, 'Temperatures must be no less than absolute zero.'),
  };

  // TODO: Guidance to pass into makeGuidance
  const thermalGuidance = {};

  // Function that returns Input components, requires formik getFieldProps function as input
  const thermalPropsInput = (getFieldProps: (name: string) => IGenericObject) => {
    return (
      <>
        <LabeledInput
          label="Initial Temperature"
          type="number"
          {...getFieldProps('temperature')}
          endAdornment={<InputAdornment position="end">°C</InputAdornment>}
        />
        <LabeledInput
          label="Hot Temperature Rating"
          type="number"
          {...getFieldProps('hotTempRating')}
          endAdornment={<InputAdornment position="end">°C</InputAdornment>}
        />
        <LabeledInput
          label="Cold Temperature Rating"
          type="number"
          {...getFieldProps('coldTempRating')}
          endAdornment={<InputAdornment position="end">°C</InputAdornment>}
        />
        <LabeledInput
          label="Thermal Capacitance"
          type="number"
          {...getFieldProps('thermalCapacitance')}
          endAdornment={<InputAdornment position="end">J/K</InputAdornment>}
        />
      </>
    );
  };

  return { thermalPropsInput, thermalDefaultValues, thermalValidation, thermalGuidance };
};

export default getThermalProps;
