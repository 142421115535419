import { makeGuidance } from 'hooks';
import componentThermalGuidance from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/componentGuidance';

const actuatorsHeading = 'Create and Edit Actuator';

const actuatorsMainParagraph =
  'Actuators are used to orient the spacecraft based on the attitude control algorithm. Each algorithm is assigned specific actuators by the user.';

const rwChunk =
  'Specify the reaction wheel’s inertia, rated angular momentum (maximum angular momentum storage), rated torque (maximum instantaneous torque), and efficiency (a measure of how much power is lost to heat).';
const mtChunk =
  'Specify the device’s maximum rated magnetic moment and the power draw at the maximum. The control algorithm uses Pulse-Width Modulation (PWM) to drive a variable torque.';

export const actuatorsGuidance = {
  _default: {
    heading: actuatorsHeading,
    body: [
      {
        chunk: actuatorsMainParagraph,
      },
    ],
  },
  bodyFrameVector: {
    heading: 'Torque Axis',
    body: [
      {
        subHeading: 'For Reaction Wheels:',
        chunk:
          'Input the torque body frame vector: the direction around which the reaction wheel spins and produces torque on the spacecraft.',
      },
      {
        subHeading: 'For Magnetorquers:',
        chunk:
          ' Input the vector along the magnetorquer: this is the direction of the magnetic moment. The produced torque will always be orthogonal to this vector.',
      },
    ],
  },
  ratedMagneticMoment: {
    heading: 'Magnetorquer',
    body: [
      {
        chunk: mtChunk,
      },
    ],
  },
  powerAtRatedMagneticMoment: {
    heading: 'Magnetorquer',
    body: [
      {
        chunk: mtChunk,
      },
    ],

    //TODO add Drawing
  },
  inertia: {
    heading: 'Reaction Wheel',
    body: [
      {
        chunk: rwChunk,
      },
    ],
    //TODO add Drawing
  },
  ratedTorque: {
    heading: 'Reaction Wheel',
    body: [
      {
        chunk: rwChunk,
      },
    ],
  },
  ratedMomentum: {
    heading: 'Reaction Wheel',
    body: [
      {
        chunk: rwChunk,
      },
    ],
  },
  efficiency: {
    heading: 'Reaction Wheel',
    body: [
      {
        chunk: rwChunk,
      },
    ],
  },
  ...componentThermalGuidance,
};

export const useGuidance = makeGuidance(actuatorsGuidance);

const actuatorsSegmentGuidance = {
  heading: 'Actuators',
  body: [
    {
      chunk: actuatorsMainParagraph,
    },
  ],
};

export default actuatorsSegmentGuidance;
