import InputAdornment from '@material-ui/core/InputAdornment';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { IErrorResponse, IGuidanceCard, ISelectOption } from 'components/general/types';
import { IPowerProcessor } from 'components/general/types/power';
import EntitySegment from 'components/general/wizards/EntitySegment';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useFormikForm, useSnackbar } from 'hooks';
import getThermalProps from 'hooks/getThermalProps';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PowerProcessorVables } from 'utils/vable';
import useGuidance from './guidance';
import powerProcessorSchema from './validation';

interface IProps {
  index: string;
}

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  topologyType: ISelectOption | '';
  topologyParams: {
    bcrEfficiency?: number | '';
    outputPowerRating?: number | '';
    dischargeDiodeDrop?: number | '';
    chargeDiodeDrop?: number | '';
    chargeControllerBusVoltage?: number | '';
    drivenControllerBusVoltage?: number | '';
    pptEfficiency?: number | '';
    bdrEfficiency?: number | '';
    bypassBlockingDiodeDrop?: number | '';
  };
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  topologyType: '',
  topologyParams: {
    bcrEfficiency: '',
    outputPowerRating: '',
    dischargeDiodeDrop: '',
    chargeDiodeDrop: '',
    chargeControllerBusVoltage: '',
    drivenControllerBusVoltage: '',
    pptEfficiency: '',
    bdrEfficiency: '',
    bypassBlockingDiodeDrop: '',
  },
};

interface ISpec {
  name: string;
  label: string;
  units: string;
}

// --------------------------------------------------------------------------
// Define input fields
// --------------------------------------------------------------------------
const bcrEfficiency: ISpec = {
  name: 'bcrEfficiency',
  label: 'BCR Efficiency',
  units: '%',
};

const bdrEfficiency: ISpec = {
  name: 'bdrEfficiency',
  label: 'BDR Efficiency',
  units: '%',
};

const pptEfficiency: ISpec = {
  name: 'pptEfficiency',
  label: 'PPT Efficiency',
  units: '%',
};

const outputPowerRating: ISpec = {
  name: 'outputPowerRating',
  label: 'Output Power Rating',
  units: 'W',
};

const dischargeDiodeDrop: ISpec = {
  name: 'dischargeDiodeDrop',
  label: 'Battery Discharge Diode Drop (D1)',
  units: 'V',
};

const chargeDiodeDrop: ISpec = {
  name: 'chargeDiodeDrop',
  label: 'Solar Array Diode Drop (D2)',
  units: 'V',
};

const chargeControllerBusVoltage: ISpec = {
  name: 'chargeControllerBusVoltage',
  label: 'Controller Bus Voltage',
  units: 'V',
};

const drivenControllerBusVoltage: ISpec = {
  ...chargeControllerBusVoltage,
  name: 'drivenControllerBusVoltage',
};

const bypassBlockingDiodeDrop: ISpec = {
  name: 'bypassBlockingDiodeDrop',
  label: 'Battery Discharge Diode Drop (D1)',
  units: 'V',
};

// Note fieldSpecs are not correct for DUAL_CONV_MPPT, SINGLE_CONV_HYBRID, and DUAL_CONV_HYBRID. Added now to keep from erroring out if selected
const fieldSpecs = {
  SINGLE_CONV_MPPT: [outputPowerRating, bcrEfficiency],
  TWO_CONV_MPPT: [
    outputPowerRating,
    chargeControllerBusVoltage,
    bcrEfficiency,
    pptEfficiency,
    dischargeDiodeDrop,
  ],
  QUASI_REG_DET: [
    outputPowerRating,
    chargeControllerBusVoltage,
    bcrEfficiency,
    chargeDiodeDrop,
    dischargeDiodeDrop,
  ],
  FULLY_REG_DET: [
    outputPowerRating,
    drivenControllerBusVoltage,
    bcrEfficiency,
    bdrEfficiency,
    chargeDiodeDrop,
  ],
  SINGLE_CONV_HYBRID: [outputPowerRating, bcrEfficiency, bypassBlockingDiodeDrop],
};

const PowerProcessorSegment = (props: IProps) => {
  // Handle props and set up state
  const { index } = props;
  const [loading, setLoading] = useState(false);

  // Get current battery and get update battery action
  const {
    Component: {
      actions: { updateComponent },
    },
  } = SatelliteApi;
  const { powerProcessor, branch } = useActiveEntities();
  const dispatch = useDispatch();

  const classes = useStyles();

  // Set up options
  const options = useMemo(() => {
    return {
      topologyType: PowerProcessorVables.TopologyTypes.options,
    };
  }, []);

  // Set up dispatch action (Battery will only be edited)
  const { enqueueSnackbar } = useSnackbar();
  const editPowerProcessor = useCallback(
    (values) => {
      setLoading(true);
      dispatch(
        updateComponent({
          branchId: branch.id,
          id: powerProcessor?.id,
          ...values,
          entity: powerProcessor,
          successCallback: (response: { simulationRequired: boolean }) => {
            enqueueSnackbar('Power Processor updated successfully.', {
              variant: 'success',
            });
            setLoading(false);
          },
          failureCallback: (response: IErrorResponse) => {
            const errorMessage = response.error.message;
            enqueueSnackbar(errorMessage);
            setLoading(false);
          },
        })
      );
    },
    [powerProcessor, dispatch, enqueueSnackbar, updateComponent, branch]
  );

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  // Hook up form
  const { formik, guidance } = useFormikForm<IPowerProcessor, IForm & typeof thermalDefaultValues>(
    { ...defaultValues, ...thermalDefaultValues },
    editPowerProcessor,
    powerProcessorSchema,
    powerProcessor,
    {
      options,
      useGuidance,
      percentages: [
        'topologyParams.bcrEfficiency',
        'topologyParams.bdrEfficiency',
        'topologyParams.pptEfficiency',
      ],
      allowedEmptyFields: ['manufacturer', 'partNumber'],
    }
  );

  const { handleSubmit, getFieldProps, resetForm, dirty, setValues } = formik;
  const { values } = formik;

  const renderField = (spec: ISpec) => {
    return (
      <LabeledInput
        {...getFieldProps(`topologyParams.${spec.name}`)}
        key={spec.name}
        type="number"
        endAdornment={spec.units && <InputAdornment position="end">{spec.units}</InputAdornment>}
        label={spec.label}
      />
    );
  };

  return (
    <EntitySegment
      title="Power Controller"
      index={index}
      // Guidance is optional for the formik hook, so we typecast it since it will be returned whenever we pass the hook useGuidance
      guidance={guidance as IGuidanceCard}
      onSubmit={handleSubmit}
      onReset={resetForm}
      disableSubmit={!dirty}
      loading={loading}
      gridConfig={{ left: { xs: 12, md: 5 }, right: { xs: 12, md: 7 } }}
      xray={{ ...powerProcessor, ...values }}
    >
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            type="text"
            placeholder="Manufacturer"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            label="Topology Type"
            options={options.topologyType}
            {...getFieldProps('topologyType')}
            formikOnChange={(val: ISelectOption) => {
              if (values.topologyType !== '' && val.value !== values.topologyType.value) {
                setValues({
                  ...values,
                  topologyParams: defaultValues.topologyParams,
                });
              }
            }}
          />
          <div className={classes.indent}>
            {values.topologyType !== '' &&
              fieldSpecs[values.topologyType.value as keyof typeof fieldSpecs].map((spec: ISpec) =>
                renderField(spec)
              )}
          </div>
        </div>
        <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
      </div>
    </EntitySegment>
  );
};

export default PowerProcessorSegment;
