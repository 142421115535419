import { makeStyles } from '@material-ui/core/styles';
import theme from 'theme';

export const rowStyles = {
  buttonContainer: {
    '& button': {
      '&:hover': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
    },
    '& span': {
      margin: '0px 5px',
      '& div': {
        color: `${theme.palette.text.primary} !important`,
      },
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  iconButton: {
    // Override minWidth to scale for icons
    '& button': {
      minWidth: '42px !important',
    },
    '& .Mui-disabled': {
      backgroundColor: `${theme.palette.action.disabled} !important`,
      border: 'transparent 1px solid !important',
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderBottom: '0.5px solid #dddddd',
    marginLeft: 20,
    padding: 10,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.background.mainNested,
    },
  },
  infoContainer: {
    paddingTop: '2px',
    width: '100%',
    minWidth: '250px',
    color: theme.palette.text.primary,
    textDecoration: 'none',
  },
  deleteButton: () => ({
    '& button': {
      '&.MuiButtonBase-root': {
        border: 'transparent 1px solid',
        backgroundColor: theme.palette.tertiary.main,
      },
    },
  }),
  dateText: {
    color: theme.palette.text.secondary,
  },
};

const useStyles = makeStyles((theme) => ({
  ...rowStyles,
}));

export default useStyles;
