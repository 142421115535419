import { useMemo, useState, useCallback, useEffect } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import {
  IBranchMergeErrorResponse,
  IGenericObject,
  IMissionVersion,
  ISelectOption,
} from 'components/general/types';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useDispatch } from 'react-redux';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { useFormikForm, useSelectById, useSelectByIds } from 'hooks';
import * as Yup from 'yup';
import { exclude } from 'utils/forms';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import GuidanceCard from 'components/general/GuidanceCard';
import { useEntityDialogControl, useSnackbar } from 'hooks';
import ConflictResolutionDialog from './ConflictResolutionDialog';

const mergeSchema = Yup.object().required('A source branch is required.');

export const mergeSuccessMessage = (
  incomingBranchName: string,
  sourceBranchName: string
): [string, { variant: 'success' }] => {
  return [
    `Successfully merged branch "${incomingBranchName}" into branch "${sourceBranchName}".`,
    {
      variant: 'success',
    },
  ];
};
export interface IConflict {
  current: string;
  incoming: string;
  block: string;
}
export interface IConflictsObj {
  currentBranch: number;
  incomingBranch: number;
  conflicts: IConflict[];
}

interface IProps {
  dialogControl: TEntityDialogControl<IMissionVersion>;
}

interface IMergeForm {
  incomingBranch: ISelectOption | '';
}

const defaultValues: IMergeForm = {
  incomingBranch: '',
};

const MergeDialog = ({ dialogControl }: IProps) => {
  const {
    dialogConfig: { open, entity: currentBranch },
    closeDialog,
  } = dialogControl;

  const conflictResolutionDialogControl = useEntityDialogControl<IConflictsObj>();

  const [loading, setLoading] = useState(false);

  const { MissionVersion } = SatelliteApi;

  const { id: repoId } = useParams<{ id: string }>();
  const repo = useSelectById('Mission', parseInt(repoId));
  const branches = useSelectByIds('MissionVersion', repo?.versions || []);

  const branchOptions = useMemo(
    () => branches.map((branch) => ({ value: branch.id, label: branch.name })),
    [branches]
  );

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const merge = useCallback(
    ({ incomingBranch }: IMergeForm) => {
      setLoading(true);
      if (currentBranch && typeof incomingBranch === 'object') {
        dispatch(
          MissionVersion.actions.gitMerge({
            id: currentBranch.id,
            modelId: incomingBranch.value,
            successCallback: (response: IGenericObject) => {
              setLoading(false);
              enqueueSnackbar(
                ...mergeSuccessMessage(
                  branches.find((b) => b.id === incomingBranch.value)?.name || '',
                  currentBranch.name
                )
              );
              closeDialog();
            },
            failureCallback: (response: IBranchMergeErrorResponse) => {
              setLoading(false);
              if (response.conflicts) {
                enqueueSnackbar(response.error.message, { variant: 'success' });
                // Open conflict resolution dialog
                conflictResolutionDialogControl.openDialogForExisting(
                  {
                    currentBranch: currentBranch.id,
                    incomingBranch: incomingBranch.value,
                    conflicts: response.conflicts,
                  },
                  'edit'
                );
              } else {
                enqueueSnackbar(response.error.message);
              }
              closeDialog();
            },
          })
        );
      }
    },
    [
      currentBranch,
      dispatch,
      MissionVersion,
      enqueueSnackbar,
      closeDialog,
      conflictResolutionDialogControl,
      branches,
    ]
  );

  const { formik } = useFormikForm<IMergeForm, IMergeForm>(
    defaultValues,
    merge,
    mergeSchema,
    defaultValues
  );

  const { getFieldProps, handleSubmit, resetForm } = formik;

  // use Effect to reset form whenever dialog is closed
  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open, resetForm]);

  return (
    <>
      <Dialog
        onClose={closeDialog}
        open={open}
        prompt="Select branch to merge in"
        submitActionText="Merge"
        loading={loading}
        onSubmit={handleSubmit}
        large
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <LabeledSelect
              {...getFieldProps('incomingBranch')}
              options={exclude(branchOptions, currentBranch)}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <GuidanceCard
              guidance={{
                heading: 'Version Control: Merging',
                body: [
                  {
                    chunk:
                      'Merging in a branch will bring in all committed changes from the selected branch that occured since the branches diverged.',
                  },
                  {
                    chunk:
                      'If corresponding properties were modified on both branches, there will be merge conflicts. In that case, you will be prompted to select which of the changes you would like to keep.',
                  },
                  {
                    chunk: 'If there are no conflicts, the merge will execute immediately.',
                  },
                ],
              }}
            />
          </Grid>
        </Grid>
      </Dialog>
      <ConflictResolutionDialog dialogControl={conflictResolutionDialogControl} />
    </>
  );
};

export default MergeDialog;
