import { Fragment, useCallback, useMemo, useContext } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import WizardSegment from 'components/general/wizards/WizardSegment';
import WidgetTable from 'components/general/widgets/WidgetTable';
import InputAdornment from '@material-ui/core/InputAdornment';
import { BfVectorAccent, SpacecraftAccent } from 'components/general/Accent/variants';
import { translateIn, exclude, translateOut } from 'utils/forms';
import useGuidance from './guidance';
import GuidanceCard from 'components/general/GuidanceCard';
import Grid from '@material-ui/core/Grid';
import { array2String } from 'utils/strings';
import { SpacecraftContext } from 'providers';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import pointingModeSchema from './validation';
import { useActiveEntities } from 'hooks';
import { useEntityForm, useEntityDialogControl } from 'hooks';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { PointingModeVables, AlgorithmVables } from 'utils/vable';
import GncAccent from 'components/general/Accent/variants/GncAccent';

const defaultValues = {
  name: '',
  pointingModeType: '',
  odAlgorithm: '',
  adAlgorithm: '',
  acAlgorithm: '',
  lockVector: '',
  lockBodyFrameVector: '',
  maxAlignVector: '',
  maxAlignBodyFrameVector: '',
  spinRateBool: false,
  spinRate: '',
};

const pointingModeTypeOptions = PointingModeVables.PointingModeTypes.options;

const PointingModeDialog = ({ control, options }) => {
  const { conOps, model } = useActiveEntities();

  // Handle context
  const { setSpacecraftDialogConfig, SpacecraftTabs } = useContext(SpacecraftContext);
  const classes = useStyles();

  /* Need to fetch body frame vectors from ORM instead of attaching them to the dropdown option
   * because if a BFV is selected in the PointingModeDialog and it is then edited in the S/C Dialog,
   * the selected value in RHF won't reflect the change and form validation may be falsely pos/neg.*/
  const validateForm = useCallback(
    (values) => {
      let result = false;
      const a = model.BodyFrameVector.byId(values.lockBodyFrameVector)?.unitVector;
      const b = model.BodyFrameVector.byId(values.maxAlignBodyFrameVector)?.unitVector;
      if (a && b && array2String(a) === array2String(b)) {
        result = 'The locked and max-aligned body frame unit vectors cannot be equivalent';
      }
      return result;
    },
    [model]
  );

  const customTranslateIn = useCallback((pointingMode, defaultValues, options) => {
    if (pointingMode.spinRate) {
      pointingMode.spinRateBool = true;
    } else if (pointingMode.spinRate === 0) {
      pointingMode.spinRateBool = false;
    }
    return translateIn(pointingMode, defaultValues, options);
  }, []);

  const customTranslateOut = useCallback((pointingMode, allowedEmptyFields, options) => {
    /** If you toggle between pointing mode types, sometimes `lockBodyFrameVector` property will end up having been set
     * even when `pointingModeType` is passive & the property is no longer needed -- due to our custom `formikOnChange`
     * for "Pointing Mode Type" to save it after it's set. Thus, always reset it when `pointingModeType` is passive. */
    if (
      pointingMode.pointingModeType.value === PointingModeVables.PointingModeTypes.PASSIVE.value
    ) {
      pointingMode.lockBodyFrameVector = '';
    }
    return translateOut(pointingMode, allowedEmptyFields, options);
  }, []);

  const entityForm = useEntityForm({
    entityTypeText: 'Pointing Mode',
    entityDialogControl: control,
    validateForm,
    additionalCreateValues: { conOps: conOps.id },
    valuesToRemove: ['spinRateBool'],
    defaultValues,
    validationSchema: pointingModeSchema,
    formikOptionalParams: {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      allowedEmptyFields: ['odAlgorithm', 'adAlgorithm', 'acAlgorithm'],
    },
  });

  const { formik } = entityForm;

  const { getFieldProps, setFieldValue, values, setValues } = formik;
  const { pointingModeType, lockBodyFrameVector, maxAlignBodyFrameVector, spinRateBool } = values;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Pointing Mode Name"
            type="text"
            placeholder="Pointing Mode Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            label="Pointing Mode Type"
            options={options.pointingModeType}
            {...getFieldProps('pointingModeType')}
            formikOnChange={(val) => {
              if (val.value !== pointingModeType?.value)
                setValues({
                  ...defaultValues,
                  name: values.name,
                  lockBodyFrameVector, // makes it so users don't have to set BFV again if change between types
                });
            }}
          />
          {pointingModeType?.value && (
            <div className={classes.inputGroup}>
              <GncAccent header="Algorithms">
                <LabeledSelect
                  label="Orbit Determination Algorithm"
                  options={options.odAlgorithm}
                  optional
                  {...getFieldProps('odAlgorithm')}
                  noOptionsMessage={() => 'Create an Algorithm'}
                  formikOnChange={(val) => {
                    if (!val) setFieldValue('odAlgorithm', 'bas');
                  }}
                />
                <LabeledSelect
                  label="Attitude Determination Algorithm"
                  options={options.adAlgorithm}
                  optional
                  {...getFieldProps('adAlgorithm')}
                  noOptionsMessage={() => 'Create an Algorithm'}
                />
                {pointingModeType?.value !== 'PASSIVE' && (
                  <LabeledSelect
                    label="Attitude Control Algorithm"
                    options={options.acAlgorithm}
                    {...getFieldProps('acAlgorithm')}
                    noOptionsMessage={() => 'Create an Algorithm'}
                  />
                )}
              </GncAccent>
            </div>
          )}
          {pointingModeType?.value && pointingModeType?.value !== 'PASSIVE' && (
            <div className={classes.inputGroup}>
              <BfVectorAccent
                onAddAction={() =>
                  setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
                }
              >
                <LabeledSelect
                  label="Locked BF Vector"
                  options={exclude(options.lockBodyFrameVector, maxAlignBodyFrameVector)}
                  isClearable
                  {...getFieldProps('lockBodyFrameVector')}
                  noOptionsMessage={() => 'Create a Body Frame Vector'}
                />
              </BfVectorAccent>
              <SpacecraftAccent
                header="Reference Vector"
                onAddAction={() =>
                  setSpacecraftDialogConfig({
                    open: true,
                    tabNumber: SpacecraftTabs.REFERENCE_VECTORS,
                  })
                }
              >
                <LabeledSelect
                  label="Pointing Direction"
                  options={options.lockVector}
                  {...getFieldProps('lockVector')}
                />
              </SpacecraftAccent>
            </div>
          )}
          {pointingModeType?.value === 'MAX_SECONDARY_ALIGN' && (
            <div className={classes.inputGroup}>
              <BfVectorAccent
                onAddAction={() =>
                  setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
                }
              >
                <LabeledSelect
                  label="Max Aligned BF Vector"
                  options={exclude(options.maxAlignBodyFrameVector, lockBodyFrameVector)}
                  isClearable
                  {...getFieldProps('maxAlignBodyFrameVector')}
                  noOptionsMessage={() => 'Create a Body Frame Vector'}
                />
              </BfVectorAccent>
              <SpacecraftAccent
                header="Reference Vector"
                onAddAction={() =>
                  setSpacecraftDialogConfig({
                    open: true,
                    tabNumber: SpacecraftTabs.REFERENCE_VECTORS,
                  })
                }
              >
                <LabeledSelect
                  label="Pointing Direction"
                  options={options.maxAlignVector}
                  {...getFieldProps('maxAlignVector')}
                />
              </SpacecraftAccent>
            </div>
          )}
          {pointingModeType?.value === 'LOCK' && (
            <>
              <LabeledCheckbox
                {...getFieldProps('spinRateBool')}
                label="Spin about locked direction"
                formikOnChange={() => {
                  setFieldValue('spinRate', defaultValues.spinRate);
                }}
              />
              {spinRateBool && (
                <LabeledInput
                  {...getFieldProps('spinRate')}
                  type="number"
                  endAdornment={<InputAdornment position="end">RPM</InputAdornment>}
                  label="Spin Rate"
                />
              )}
            </>
          )}
        </div>
      </div>
    </EntityDialog>
  );
};

const columns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (val) => pointingModeTypeOptions.find((o) => o.value === val.pointingModeType)?.label,
  },
];

const PointingModesSegment = (props) => {
  const { index, ...remainingProps } = props;

  const pmEntityDialogControl = useEntityDialogControl();
  const { openDialogForExisting, openDialogForNew } = pmEntityDialogControl;

  const classes = useStyles();
  const { pointingModes, bodyFrameVectors, algorithms, referenceVectors } = useActiveEntities();

  const referenceVectorsList = useMemo(
    () =>
      referenceVectors?.map((vector) => ({
        value: vector.id,
        label: vector.name,
      })),
    [referenceVectors]
  );

  const bfVectorsList = useMemo(
    () =>
      bodyFrameVectors
        ?.filter((bfVector) => bfVector != null)
        .map((bfVector) => {
          return { value: bfVector.id, label: bfVector.name };
        }),
    [bodyFrameVectors]
  );

  const odAlgoList = useMemo(
    () =>
      algorithms
        .filter((a) => a.algorithmType === AlgorithmVables.AlgorithmType.ORBIT_DETERMINATION.value)
        .map((a) => ({ value: a.id, label: a.name })),
    [algorithms]
  );

  const adAlgoList = useMemo(
    () =>
      algorithms
        .filter(
          (a) => a.algorithmType === AlgorithmVables.AlgorithmType.ATTITUDE_DETERMINATION.value
        )
        .map((a) => ({ value: a.id, label: a.name })),
    [algorithms]
  );

  const acAlgoList = useMemo(
    () =>
      algorithms
        .filter((a) => a.algorithmType === AlgorithmVables.AlgorithmType.ATTITUDE_CONTROL.value)
        .map((a) => ({ value: a.id, label: a.name })),
    [algorithms]
  );

  const options = useMemo(() => {
    return {
      pointingModeType: pointingModeTypeOptions,
      lockVector: referenceVectorsList,
      lockBodyFrameVector: bfVectorsList,
      maxAlignVector: referenceVectorsList,
      maxAlignBodyFrameVector: bfVectorsList,
      odAlgorithm: odAlgoList,
      adAlgorithm: adAlgoList,
      acAlgorithm: acAlgoList,
    };
  }, [bfVectorsList, odAlgoList, adAlgoList, acAlgoList, referenceVectorsList]);

  return (
    <Fragment>
      <WizardSegment title="Pointing Modes" index={index} {...remainingProps}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.swapRight}>
            <WidgetTable
              className={classes.table}
              columns={columns}
              data={pointingModes}
              onFabClick={openDialogForNew}
              onActionClick={openDialogForExisting}
              modelName="pointing mode"
            />
          </Grid>
          <Grid item xs={12} md={6} className={classes.swapLeft}>
            <GuidanceCard
              guidance={{
                heading: 'Create and Edit Pointing Modes',
                body: [
                  {
                    chunk:
                      'Pointing Modes determine the attitude (i.e. orientation) of your Satellite at each time step in your simulation. Use the Pointing Modes table to edit or add Pointing Modes.',
                  },
                  {
                    subHeading: 'Link to Operational Modes',
                    chunk:
                      'In the Operational Modes dialog under Command & Control, you can connect Pointing Modes to Operational Modes. When an Operational Mode is active, as defined by your ConOps logic, its associated Pointing Mode will dictate the commanded attitude of the vehicle. A Pointing Mode can be associated to multiple Operational Modes.',
                  },
                  {
                    subHeading: 'Relation to Algorithms',
                    chunk:
                      'In order to point at a target, the spacecraft must have Attitude Control, Attitude Determination, and Orbit Determination algorithms defined and assigned to an Active Pointing Mode.',
                  },
                ],
              }}
            />
          </Grid>
        </Grid>
      </WizardSegment>
      <PointingModeDialog control={pmEntityDialogControl} options={options} />
    </Fragment>
  );
};

export default PointingModesSegment;
