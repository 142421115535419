import { Fragment } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import Grid from '@material-ui/core/Grid';
import PhoneIcon from '@material-ui/icons/Phone';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import BugReportIcon from '@material-ui/icons/BugReport';
import A from 'components/general/A';
import {
  SUPPORT_EMAIL,
  SUPPORT_PHONE,
  SALES_EMAIL,
  DOCUMENTATION_URL,
  COMMUNITY_SLACK_URL,
  FEEDBACK_FORM_URL,
} from 'config';
import useStyles from './styles';

const SupportDialog = (props) => {
  const {
    config: { open, sales },
    onClose,
  } = props;
  const classes = useStyles();

  const contactEmail = sales ? SALES_EMAIL : SUPPORT_EMAIL;

  return (
    <Fragment>
      <Dialog title={sales ? 'Sales' : 'Support'} open={open} onClose={onClose}>
        <p>
          Please call or email us using the links below. Our team will get back to you within 24
          hours.
        </p>
        {!sales && (
          <p style={{ marginTop: 5 }}>
            View our public{' '}
            <A href={DOCUMENTATION_URL} target="_blank">
              documentation
            </A>{' '}
            for general site assistance or help with common questions or join the Sedaro Community{' '}
            <A href={COMMUNITY_SLACK_URL} target="_blank">
              Slack
            </A>
            .
          </p>
        )}
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <div className={classes.supportChannel}>
              <PhoneIcon />
              <A href={`tel:${SUPPORT_PHONE}`}>{SUPPORT_PHONE}</A>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.supportChannel}>
              <MailOutlineIcon />
              <A href={`mailto:${contactEmail}`}>{contactEmail}</A>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className={classes.supportChannel}>
              <BugReportIcon />
              <A href={`${FEEDBACK_FORM_URL}`} target="_blank">
                Submit Ticket
              </A>
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </Fragment>
  );
};

export default SupportDialog;
