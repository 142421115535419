import { useCallback, useEffect, useRef, useState } from 'react';
import { IControlState, ITempController } from 'components/general/types/thermal';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import { useGuidance } from './guidance';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { CdhAccent } from 'components/general/Accent/variants';
import { InputAdornment } from '@material-ui/core';
import { useActiveEntities } from 'hooks';
import validation from './validation';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { IOperationalMode } from 'components/general/types/cdh';
import { translateOut } from 'utils/forms';
import { TBlockId } from 'components/general/types';

interface IForm {
  name: string;
  constantTemperature: number | '';
  operationalModes?: IOperationalMode[];
}

const defaultValues: IForm = {
  name: '',
  constantTemperature: '',
};

const opModeTableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

interface IProps {
  control: TEntityDialogControl<IControlState>;
  tempController?: ITempController;
}

interface IParsedOpMode {
  id: TBlockId;
  name: string;
  tableData: {
    checked: boolean;
  };
}

const ControlStatesDialog = ({ control, tempController }: IProps) => {
  // Get entity
  const {
    dialogConfig: { entity: controlState },
  } = control;

  // Set up styles
  const classes = useStyles();

  // Set up table data
  const tableRef = useRef(null);
  const { operationalModes } = useActiveEntities();
  const [parsedOpModes, setParsedOpModes] = useState<IParsedOpMode[]>([]);

  // Set up op modes for select table
  const initOpModes = useCallback(() => {
    let _parsedOpModes: IParsedOpMode[] = [];
    if (operationalModes) {
      _parsedOpModes = operationalModes.map((opMode: IOperationalMode) => ({
        id: opMode.id,
        name: opMode.name,
        tableData: {
          checked: !!controlState?.operationalModes?.map(({ id }) => id).includes(opMode.id),
        },
      }));
    }
    setParsedOpModes(_parsedOpModes);
  }, [operationalModes, setParsedOpModes, controlState]);

  useEffect(() => {
    initOpModes();
  }, [initOpModes]);

  const customTranslateOut = useCallback(
    (values) => {
      values.operationalModes = parsedOpModes
        .filter((opMode: IParsedOpMode) => opMode.tableData?.checked)
        .map((panel) => panel.id);
      return translateOut(values);
    },
    [parsedOpModes]
  );

  // Use entity form hook to hook up form
  const entityForm = useEntityForm<IControlState, IForm>({
    entityTypeText: 'Control State',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    extendReset: initOpModes,
    additionalCreateValues: { tempController: tempController?.id },
    formikOptionalParams: {
      useGuidance,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, setFieldValue } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Control State Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('constantTemperature')}
            type="number"
            endAdornment={<InputAdornment position="end">°C</InputAdornment>}
            label="Constant Temperature"
          />
        </div>
      </div>
      <div className={classes.inputGroup}>
        <CdhAccent header="Operational Modes">
          <WidgetTable
            tableRef={tableRef}
            className={classes.table}
            columns={opModeTableColumns}
            data={parsedOpModes}
            setData={setParsedOpModes}
            // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
            // It is used through a level of indirection here to set the value on the form data and therefore mark
            // the form as dirty. This will enable the save button for the form.
            onSelectionChange={(newTableData) => setFieldValue('operationalModes', newTableData)}
            selection={true}
            title="Select Operational Modes"
          />
        </CdhAccent>
      </div>
    </EntityDialog>
  );
};

export default ControlStatesDialog;
