import { useEffect } from 'react';
import ViewContainer from 'components/general/ViewContainer';
import Nav from 'components/general/Nav';
import ViewPort from 'components/general/ViewPort';
import BranchExplorer from './BranchExplorer';
import {
  setActiveMissionVersionId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';
import { useDispatch } from 'react-redux';

const BranchesView = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActiveMissionVersionId(undefined));
    dispatch(setLatestJobId(undefined));
  }, [dispatch]);

  return (
    <ViewContainer>
      <Nav>
        <p>Select a branch to open its modules or interact with it via the options on the right.</p>
      </Nav>
      <ViewPort>
        <BranchExplorer />
      </ViewPort>
    </ViewContainer>
  );
};

export default BranchesView;
