import EqualizerIcon from '@material-ui/icons/Equalizer';
import StyledButton from 'components/general/StyledButton';
import StyledSlider, { ThumbComponent } from 'components/general/StyledSlider';
import { IGenericObject } from 'components/general/types';
import type { EChartOption, ECharts, SetOptionOpts } from 'echarts';
import { dispose, getInstanceByDom, init } from 'echarts';
import { useDataContext } from 'providers';
import type { CSSProperties } from 'react';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useStyles from './styles';

const zoomFull = (chartEl: HTMLDivElement | null, zoomed: boolean) => {
  if (chartEl && zoomed) {
    const chart = getInstanceByDom(chartEl);
    chart.dispatchAction({
      type: 'restore',
    });
  }
};

export interface ChartProps {
  option: EChartOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  children?: ReactNode;
  titled?: boolean;
  withRightAxis?: boolean;
  withZoom?: boolean;
}

export const Chart = ({ option, style, settings, titled, withRightAxis, withZoom }: ChartProps) => {
  const { startTime, stopTime } = useDataContext();
  const chartRef = useRef<HTMLDivElement>(null);
  const wrapper = useRef<HTMLDivElement>(null);
  const [adjustedHeight, setAdjustedHeight] = useState(1);
  const [zoomed, setZoomed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [config, setConfig] = useState({
    startValue: startTime,
    endValue: stopTime,
    sliderPosition: (startTime + stopTime) / 2,
  });
  const styles = useStyles();

  const _setGridHeight = useCallback(
    (chart: ECharts) => {
      // Set top position and left/right margins of grid using private echarts data
      // Hopefully temporary until echarts provides more dynamic auto-positioning
      // https://github.com/apache/echarts/issues/15654

      // Get underlying elements on the chart
      // TS Ignore because echarts doesn't display these in their types, they're under the surface
      // @ts-ignore:next-line
      const legend = chart._componentsViews.find(
        (entry: { type: string }) => entry.type === 'legend.plain'
      );
      if (!legend) return;
      const legendHeight = legend._backgroundEl.shape.height;
      // @ts-ignore:next-line
      const grid = chart._componentsViews.find((entry: { type: string }) => entry.type === 'grid');
      const gridWidth = grid.__model.coordinateSystem._rect.width;

      // Echarts dynamically positions the grid very well, but it seems to neglect
      // the axis labels by pushing them off the sides, allowing them no margin.
      // This function does custom dynamic positioning using a sigmoid function,
      // based on trial and error with the possible size of our chart widgets.
      const _dynamicMargin = (minMargin: number, maxMargin: number) => {
        const range = maxMargin - minMargin;
        return minMargin + (-range / (1 + Math.pow(1.02, -(gridWidth - 560))) + range);
      };

      if (legendHeight) {
        chart.setOption({
          animation: false, // To prevent animation on dynamic resize
          grid: {
            // Set top of grid based on 1) size of legend and 2) presence of title
            top: legendHeight + 15 + (titled ? 40 : 0),

            // Set dynamic left and right margins based on chart width
            // These are literally magic numbers – they happened to work well, change them if you need to
            left: `${_dynamicMargin(10, 15)}%`,
            right: withRightAxis ? `${_dynamicMargin(10, 15)}%` : `${_dynamicMargin(3.75, 6.5)}%`,
          },
        });

        // Resize height based on legend and title, since chart doesn't automatically do it
        if (style && style.height)
          setAdjustedHeight(style.height + legendHeight + (titled ? 40 : 0));

        // Turn animation back on after resize
        chart.setOption({
          animation: true,
        });
      }
    },
    [titled, style, withRightAxis]
  );

  useEffect(() => {
    if (chartRef.current !== null && visible) {
      if (!getInstanceByDom(chartRef.current)) init(chartRef.current); // Returns chart instance if needed – chart: ECharts | undefined

      // Set main options
      const chart = getInstanceByDom(chartRef.current);
      chart.setOption(option, settings);

      // Toggle zoom abilities
      if (withZoom) {
        // Select dataZoom tool that echarts provides in toolbox
        // Done programmatically instead of user manually clicking the tool
        chart.dispatchAction({
          type: 'takeGlobalCursor',
          key: 'dataZoomSelect',
          dataZoomSelectActive: true,
        });
        // Event listener for 'restore', i.e. Zoom Full
        // On restore, re-select dataZoom tool
        chart.on('restore', () => {
          chart.dispatchAction({
            type: 'takeGlobalCursor',
            key: 'dataZoomSelect',
            dataZoomSelectActive: true,
          });
          // Needs a resize after restore, probably due to the
          // weird way we're setting grid height
          _setGridHeight(chart);
          setZoomed(false);
        });
        // Event listener for 'datazoom'
        // Whenever user zooms, set config to update slider
        chart.on('datazoom', (event: IGenericObject) => {
          event = event.batch ? event.batch[event.batch.length - 1] : event;
          setConfig({
            startValue: event.startValue,
            endValue: event.endValue,
            sliderPosition: (event.endValue + event.startValue) / 2,
          });
          setZoomed(true);
        });
      }

      const observer = new ResizeObserver((entries) => {
        const chart = getInstanceByDom(entries[0].target as HTMLElement);
        if (chart) {
          chart.resize();
          _setGridHeight(chart);
        }
      });
      observer.observe(chartRef.current);

      // Cleanup
      return () => {
        dispose(chart);
        observer.disconnect();
      };
    }
  }, [visible, option]); // eslint-disable-line react-hooks/exhaustive-deps

  // Setup observer to detect when chart is visible and set visible state to true.
  // Often rendering all the charts at once takes a noticeable amount of time but by
  // spreading the rendering out as charts scroll into view, the render time is much
  // less noticeable.
  useEffect(() => {
    if (wrapper.current) {
      const el = document.getElementById('viewport');
      const handle: IntersectionObserverCallback = (entries) => {
        if (entries[0].isIntersecting && !visible) {
          setVisible(true);
        }
      };
      const observer = new IntersectionObserver(handle, {
        root: el,
        rootMargin: '100px', // Expands the root to trigger before visible
        threshold: 0,
      });
      observer.observe(wrapper.current);
      return () => observer.disconnect();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const zoomStyles = zoomed ? { height: 40, marginBottom: 15 } : {};

  return (
    <div ref={wrapper} className={styles.wrapper}>
      {!visible && (
        <div className={styles.lazyPlaceholder}>
          <EqualizerIcon />
        </div>
      )}
      <div ref={chartRef} style={{ ...style, height: adjustedHeight }} />
      {withZoom && (
        <div style={zoomStyles} className={styles.zoomControls}>
          <StyledSlider
            className={styles.slider}
            ThumbComponent={ThumbComponent}
            value={[config.sliderPosition]}
            onChange={(e, v) => {
              const chartWidth = config.endValue - config.startValue;
              if (chartRef.current && chartWidth) {
                const newPosition = Array.isArray(v) ? v[0] : v;
                setConfig({
                  sliderPosition: newPosition,
                  startValue: newPosition - chartWidth / 2,
                  endValue: newPosition + chartWidth / 2,
                });
                const chart = getInstanceByDom(chartRef.current);

                // Panning with echarts is actually doing many small datazooms
                chart.dispatchAction({
                  type: 'dataZoom',
                  startValue: newPosition - chartWidth / 2,
                  endValue: newPosition + chartWidth / 2,
                });
              }
            }}
            // Setting max and min like this makes slider stop when data[0] reaches left end of chart, not center of chart
            // Same with data[data.length-1] at right end of chart
            min={startTime + (config.endValue - config.startValue) / 2} // dataMin + chartWidth/2
            max={stopTime - (config.endValue - config.startValue) / 2} // dataMax - chartWidth/2
            step={10 ** -11}
          />
          <StyledButton
            type="button"
            onClick={() => {
              zoomFull(chartRef.current, zoomed);
            }}
            tooltip="Reset zoom to the full window width"
            framed
            dontDisableInReadOnly
          >
            Zoom Full
          </StyledButton>
        </div>
      )}
    </div>
  );
};

export default Chart;
