import { useContext } from 'react';
import useStyles from './styles';
import { useEntityDialogControl } from 'hooks';
import RepoRow from './RepoRow';
import CollaborationDialog from './CollaborationDialog/Index';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { IMission } from 'components/general/types';
import ViewPortInlay from 'components/general/ViewPortInlay';
import { RepositoriesContext } from 'providers/RepositoriesProvider';

interface IProps {
  repoDialogControl: TEntityDialogControl<IMission>;
}

const RepoExplorer = (props: IProps) => {
  const { repoDialogControl } = props;
  const collaborationDialogControl = useEntityDialogControl<number>();
  const { repos, digestedRepos } = useContext(RepositoriesContext);

  const classes = useStyles();

  return (
    <>
      <div className={classes.RepoExplorerRoot}>
        <h1 className={classes.title}>Repositories</h1>
        {!digestedRepos ? (
          <h2 className={classes.loading}>Your repositories are loading</h2>
        ) : repos.length === 0 ? (
          <ViewPortInlay>
            No Repositories. Create a Repository from the navigation on the left or contact your
            team's Repository owner to obtain an invitation.
          </ViewPortInlay>
        ) : (
          <div>
            {repos.map((repo) => (
              <RepoRow
                key={repo.id}
                repo={repo}
                repoDialogControl={repoDialogControl}
                collaborationControl={collaborationDialogControl}
              />
            ))}
          </div>
        )}
      </div>
      <CollaborationDialog control={collaborationDialogControl} />
    </>
  );
};

export default RepoExplorer;
