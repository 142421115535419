import { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import StyledButton from 'components/general/StyledButton';
import { Dialog as MaterialDialog, Tooltip } from '@material-ui/core';
import useStyles from './styles';
import Visibility from '@material-ui/icons/Visibility';
import Xray from 'components/general/Xray';

// Necessary to enable safe form nesting in children due to react-dom upgrade from 16->17
const FormContents = (props) => {
  const {
    onClose,
    onSubmit,
    onSecondaryAction,
    submitActionText,
    submitButtonTooltip,
    secondaryActionText,
    loading,
    children,
    noButtons,
    disableSubmit,
    large,
    small,
    xlarge,
    dontDisableInReadOnly,
  } = props;

  const classes = useStyles({ large, small, xlarge });

  return (
    <>
      {children}
      {!noButtons && (
        <div className={classes.footer}>
          {(onClose || onSecondaryAction) && (
            <StyledButton
              type="button"
              framed
              onClick={onSecondaryAction || onClose}
              dontDisableInReadOnly={dontDisableInReadOnly}
            >
              {secondaryActionText || 'Cancel'}
            </StyledButton>
          )}
          {onSubmit && (
            <StyledButton
              disabled={loading || disableSubmit}
              loading={loading}
              replaceSpinner
              type="submit"
              tooltip={submitButtonTooltip}
              dontDisableInReadOnly={dontDisableInReadOnly}
            >
              {submitActionText ? submitActionText : 'Confirm'}
            </StyledButton>
          )}
        </div>
      )}
    </>
  );
};

const Dialog = (props) => {
  const { onClose, onSubmit, open, title, prompt, large, small, xlarge, xxlarge, xray } = props;

  const classes = useStyles({ large, small, xlarge, xxlarge });
  const [openXray, setOpenXray] = useState(false);

  return (
    <>
      <MaterialDialog onClose={onClose} open={open} className={classes.root} scroll="body">
        <div className={classes.content}>
          <div className={classes.header}>
            {title ? <h3>{title}</h3> : <h4>{prompt}</h4>}
            <div>
              {xray && (
                <Tooltip title="View as SedaroML">
                  <IconButton
                    onClick={() => setOpenXray(true)}
                    style={{ marginRight: 10 }}
                    className={classes.iconButton}
                  >
                    <Visibility />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton onClick={onClose} className={classes.iconButton}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          {onSubmit ? (
            <form
              className={classes.form}
              onSubmit={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if (onSubmit) {
                  onSubmit();
                }
              }}
            >
              <FormContents {...props} />
            </form>
          ) : (
            <div className={classes.form}>
              <FormContents {...props} />
            </div>
          )}
        </div>
      </MaterialDialog>
      {xray && <Xray data={xray} open={openXray} onClose={() => setOpenXray(false)} />}
    </>
  );
};

export default Dialog;
