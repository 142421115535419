/* eslint-disable react/no-unknown-property */
// TODO: Update babylon across the board so we can remove
// the above eslint disable, which suppresses errors from react-babylonjs

import { Suspense, useState } from 'react';
import { Engine, Scene, StandardMaterial } from 'react-babylonjs';
import { Vector3, Color3, Color4, Mesh } from '@babylonjs/core';
import theme from 'theme';
import CADModel from './CADModel';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import CircularProgress from 'components/general/CircularProgress';
import { useActiveEntities, useSnackbar } from 'hooks';
import { normalize, sphericalToCartesian } from 'utils/math';
import { BodyFrameVectorVables } from 'utils/vable';

const barColors = Object.values(theme.palette.charts.primary);

const Label = (props) => {
  const { positionVector, text, fontSize } = props;
  return (
    <plane
      name="arrowLabelPlane"
      billboardMode={Mesh.BILLBOARDMODE_ALL}
      size={2}
      position={positionVector}
    >
      <advancedDynamicTexture
        name="arrowLabel"
        height={1024}
        width={1024}
        createForParentMesh
        hasAlpha
      >
        <textBlock
          text={text}
          fontSize={fontSize || 70}
          fontFamily={theme.typography.subh2.fontFamily}
          color="white"
          outlineColor="black"
          outlineWidth={2}
        />
      </advancedDynamicTexture>
    </plane>
  );
};

const Arrow = (props) => {
  const { direction, color, label } = props;

  // Input Geometry Paramaters
  const fontSize = 52;
  const arrowLength = 1.1;
  const arrowOrigin = new Vector3(0, 0, 0);
  let arrowDirection = new Vector3(...direction).normalize();
  const arrowRadiusFactor = 0.01; // Ratio of Arrow radius / Arrow length
  const arrowHeadLengthFactor = 0.1; // Ratio of Arrow head length / Arrow total length
  const arrowHeadBaseFactor = 2; // Ratio of Arrow head base radius / Arrow body radius
  const labelOffsetFactor = 1.2; // Offest factor by which Label floats off tip of Arrow
  const nFaces = 10; // Number of faces for Arrow body

  // Derived Geometry Parameters
  const arrowBodyRadius = arrowLength * arrowRadiusFactor;
  const arrowHeadLength = arrowLength * arrowHeadLengthFactor;
  const arrowBodyLength = arrowLength - arrowHeadLength;
  const arrowBodyEnd = arrowOrigin.add(arrowDirection.scale(arrowBodyLength));
  const arrowHeadEnd = arrowBodyEnd.add(arrowDirection.scale(arrowHeadLength));

  // ExtrudeShapeCustom: Specify shape to be extruded (a circle made of many segments)
  let extrusionShape = [];
  for (let i = 0; i <= nFaces; i++) {
    extrusionShape.push(
      new Vector3(
        arrowBodyRadius * Math.cos((2 * Math.PI * i) / nFaces),
        arrowBodyRadius * Math.sin((2 * Math.PI * i) / nFaces),
        0
      )
    );
  }
  extrusionShape.push(extrusionShape[0]);

  // ExtrudeShapeCustom: Path that the extrusion will follow
  let extrusionPath = [arrowOrigin, arrowBodyEnd, arrowBodyEnd, arrowHeadEnd];

  // ExtrudeShapeCustom: Scaling of extruded shape at each point in the extrusion path
  // This is used to form the Arrow head
  const extrusionScaling = (index) => {
    switch (index) {
      case 0:
        return 1;
      case 1:
        return 1;
      case 2:
        return arrowHeadBaseFactor;
      case 3:
        return 0;
      default:
        return;
    }
  };

  // Label Position
  let labelPosition = new Vector3(
    arrowBodyEnd.x * labelOffsetFactor,
    arrowBodyEnd.y * labelOffsetFactor,
    arrowBodyEnd.z * labelOffsetFactor
  );

  return (
    <extrudeShapeCustom
      shape={extrusionShape}
      path={extrusionPath}
      scaleFunction={extrusionScaling}
      key={`extrudeShape-arrow-${label}-${new Date().getTime()}`} // Necessary to force re-render
      name="extrudeShape-arrow"
    >
      <StandardMaterial
        name="arrowMaterial"
        diffuseColor={color}
        emissiveColor={color}
        specularColor={color}
      />
      <Label positionVector={labelPosition} text={label} fontSize={fontSize} />
    </extrudeShapeCustom>
  );
};

const Satellite = (props) => {
  const {
    file,
    setCadScaleFactor,
    modelLoadError,
    setModelLoadError,
    enqueueSnackbar,
    bodyFrameVectors,
    satellite,
  } = props;
  const length = 1.25; // Change this to adjust the size of the axes
  const fontSize = 70;

  return (
    <transformNode name="transform-node">
      <lines
        name="red-line"
        points={[new Vector3.Zero(), new Vector3(length, 0, 0)]}
        color={new Color3(1, 0, 0)}
      />
      <Label positionVector={new Vector3(length * 1.1, 0, 0)} text={'X'} fontSize={fontSize} />
      <lines
        name="green-line"
        points={[new Vector3.Zero(), new Vector3(0, length, 0)]}
        color={new Color3(0, 1, 0)}
      />
      <Label positionVector={new Vector3(0, length * 1.1, 0)} text={'Y'} fontSize={fontSize} />
      <lines
        name="blue-line"
        points={[new Vector3.Zero(), new Vector3(0, 0, length)]}
        color={new Color3(0, 0, 1)}
      />
      <Label positionVector={new Vector3(0, 0, length * 1.1)} text={'Z'} fontSize={fontSize} />
      {bodyFrameVectors &&
        bodyFrameVectors.map((vector, index) => {
          let direction = vector.unitVector;
          if (!direction) {
            try {
              if (
                vector.definitionType.value ===
                BodyFrameVectorVables.BodyFrameVectorTypes.SPHERICAL_ANGLES.value
              ) {
                direction = sphericalToCartesian(
                  vector.definitionParams.theta * (Math.PI / 180),
                  vector.definitionParams.phi * (Math.PI / 180)
                );
              } else if (
                vector.definitionType.value ===
                BodyFrameVectorVables.BodyFrameVectorTypes.VECTOR.value
              ) {
                direction = vector.definitionParams.vector;
              }
              direction = normalize(direction);
            } catch (e) {
              return null;
            }
          }
          return (
            <Arrow
              // Babylon components require a unique new key each time it is changed
              // to force the component to unmount and remount a new component instead
              key={`bfVector: ${vector.name} ${vector.unitVector}`}
              name="bfVector"
              label={vector.name}
              color={Color3.FromHexString(barColors[index % barColors.length])}
              direction={direction}
            />
          );
        })}
      <CADModel
        setCadScaleFactor={setCadScaleFactor}
        file={file}
        center={Vector3.Zero()}
        modelLoadError={modelLoadError}
        setModelLoadError={setModelLoadError}
        enqueueSnackbar={enqueueSnackbar}
        satellite={satellite}
      />
    </transformNode>
  );
};

const AttitudeDisplay = (props) => {
  const {
    file,
    setCadScaleFactor,
    setModelLoadError,
    modelLoadError,
    setLoading,
    bodyFrameVectors,
  } = props;

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { satellite } = useActiveEntities();

  const [babylonLoading, setBabylonLoading] = useState(true);

  const onReady = () => {
    setBabylonLoading(false);
    setLoading && setLoading(false);
  };

  const onBeforeCameraRender = (camera) => {
    camera.upVector = new Vector3(0, 0, 1);
  };

  return (
    // need this flag to rerender the babylon component model if there is a model load error
    !modelLoadError ? (
      <div className={classes.attitudeDisplay}>
        <div className={classes.babylonLoader}>
          {babylonLoading && (
            <Box display="flex">
              <Typography
                noWrap
                gutterBottom
                variant="body2"
                color="textSecondary"
                style={{ marginRight: 10 }}
              >
                Loading Spacecraft Model....
              </Typography>
              <CircularProgress size={20} loading={true} />
            </Box>
          )}
        </div>
        <Engine width={400} height={400} antialias canvasId="babylonJS">
          <Scene
            onDataLoadedObservable={onReady}
            onBeforeCameraRenderObservable={onBeforeCameraRender}
            useRightHandedSystem
            clearColor={new Color4(0, 0, 0, 0)}
            key={`${file.lastModified} ${file.fileName} ${file.fileUrl}`}
          >
            <Suspense fallback={null}>
              <arcRotateCamera
                name="camera1"
                alpha={-Math.PI / 4}
                beta={Math.PI / 4}
                radius={4}
                target={Vector3.Zero()}
                wheelPrecision={150}
                lowerRadiusLimit={0.5}
                minZ={0.1}
              />
              {/* Light from 8 different angles. */}
              <hemisphericLight name="light1" intensity={0.5} direction={new Vector3(1, 1, 1)} />
              <hemisphericLight name="light2" intensity={0.5} direction={new Vector3(1, 1, -1)} />
              <hemisphericLight name="light3" intensity={0.5} direction={new Vector3(1, -1, -1)} />
              <hemisphericLight name="light4" intensity={0.5} direction={new Vector3(-1, -1, -1)} />
              <hemisphericLight name="light5" intensity={0.5} direction={new Vector3(-1, -1, 1)} />
              <hemisphericLight name="light6" intensity={0.5} direction={new Vector3(-1, 1, 1)} />
              <hemisphericLight name="light7" intensity={0.5} direction={new Vector3(1, -1, 1)} />
              <hemisphericLight name="light8" intensity={0.5} direction={new Vector3(-1, 1, -1)} />
              <Satellite
                file={file}
                setCadScaleFactor={setCadScaleFactor}
                setModelLoadError={setModelLoadError}
                modelLoadError={modelLoadError}
                enqueueSnackbar={enqueueSnackbar}
                bodyFrameVectors={bodyFrameVectors}
                satellite={satellite}
              />
            </Suspense>
          </Scene>
        </Engine>
      </div>
    ) : null
  );
};

export default AttitudeDisplay;
