import { useEffect, useState } from 'react';
import { useSelectByIds, useSelectById } from 'hooks';
import { useDispatch } from 'react-redux';
import useStyles from './styles';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { IMissionVersion } from 'components/general/types';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import BranchRow from './BranchRow';
import StyledButton from 'components/general/StyledButton';
import Routes from 'routes';
import DiffDialog from './DiffDialog';
import { useEntityDialogControl, useSnackbar } from 'hooks';
import CommitDialog from './CommitDialog';
import HistoryDialog from './HistoryDialog';
import MergeDialog from './MergeDialog';
import BranchDialog from 'components/BranchesView/BranchExplorer/BranchDialog';
import useGitHistory from './useGitHistory';
import { IErrorResponse } from 'components/general/types';
import RepoDataTypeIcon from 'components/general/RepoDataTypeIcon';

export interface IDiffDialogControl {
  open: boolean;
  currentBranch?: IMissionVersion;
  action?: 'compare' | 'merge';
}

const BranchExplorer = () => {
  const { Mission } = SatelliteApi;
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const repo = useSelectById('Mission', parseInt(id));
  const branches = useSelectByIds('MissionVersion', repo?.versions || []);
  const [diffConfig, setDiffConfig] = useState<IDiffDialogControl>({ open: false });
  const [openBranchId, setOpenBranchId] = useState(0);

  const mergeDialogControl = useEntityDialogControl<IMissionVersion>();
  const branchDialogControl = useEntityDialogControl<IMissionVersion>();
  // this hook is designed typically for entities, but is intentially used with other data types here
  const commitDialogControl = useEntityDialogControl<number>();

  const { historyDialogControl, fetchGitHist, commitHist, loadingGitHistBrId } = useGitHistory();

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (repo && repo?.versions.length === branches.length) return;
    // if repo or any of its branches not in store (condition above not met), fetch repo from backend
    dispatch(
      Mission.actions.getMission({
        id,
        failureCallback: (response: IErrorResponse) => {
          const message =
            response.error.code === 'RESOURCE_NOT_FOUND'
              ? 'The repository does not exist or is not accessible from this account. Please select a different repository.'
              : response.error.message;
          enqueueSnackbar(message);
          history.replace(Routes.ROOT());
        },
      })
    );
  }, [dispatch, branches, repo, Mission, enqueueSnackbar, history, id]);

  const classes = useStyles();

  if (!repo) return <></>;

  return (
    <div className={classes.branchExplorerRoot}>
      <div className={classes.headerContainer}>
        <div className={classes.repoInfo}>
          <h1>
            {repo.name} <RepoDataTypeIcon dataType={repo.dataType} height={28} />
          </h1>
          <p>{repo.description}</p>
        </div>
        <StyledButton onClick={() => history.push(Routes.ROOT())}>
          Back to Repositories
        </StyledButton>
      </div>
      <div className={classes.title}>
        <h1>Branches</h1>
        <StyledButton onClick={() => setDiffConfig({ open: true, action: 'compare' })} framed>
          Compare Branches
        </StyledButton>
      </div>

      {branches.map((branch) => (
        <BranchRow
          key={branch.id}
          branch={branch}
          openBranchDialog={branchDialogControl.openDialogForExisting}
          openMergeDialog={mergeDialogControl.openDialogForExisting}
          openCommitDialog={commitDialogControl.openDialogForExisting}
          fetchGitHistAndOpenDialog={fetchGitHist}
          loadingGitHistBrId={loadingGitHistBrId}
          openBranchId={openBranchId}
          setOpenBranchId={setOpenBranchId}
        />
      ))}

      <DiffDialog branches={branches} diffConfig={diffConfig} setDiffConfig={setDiffConfig} />
      <MergeDialog dialogControl={mergeDialogControl} />
      <CommitDialog dialogControl={commitDialogControl} />
      <BranchDialog dialogControl={branchDialogControl} />
      <HistoryDialog dialogControl={historyDialogControl} commitHist={commitHist} />
    </div>
  );
};

export default BranchExplorer;
