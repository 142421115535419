import { useContext, useMemo } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm, useActiveEntities } from 'hooks';
import {
  ReferenceVectorVables,
  SensorVables,
  TLocalPointingDirections,
  TReferenceVectorTypes,
} from 'utils/vable';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { ISelectOption } from 'components/general/types';
import validation from './validation';
import { useGuidance } from './guidance';
import getThermalProps from 'hooks/getThermalProps';
import { ISensor } from 'components/general/types/gnc';
import { InputAdornment } from '@material-ui/core';
import { SpacecraftAccent } from 'components/general/Accent/variants';
import { SpacecraftContext } from 'providers';
import { SubsystemVables } from 'utils/vable';
import { ILocalVector } from 'components/general/types/spacecraft';

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  componentType: ISelectOption | '';
  oneSigmaAngleError: number | '';
  oneSigmaCrossAxisError: number | '';
  oneSigmaBoresightAxisError: number | '';
  oneSigmaPerAxisError: number | '';
  oneSigmaDistanceError: number | '';
  fieldOfView: ISelectOption | '';
  referenceVector:
    | (ISelectOption & {
        type: TReferenceVectorTypes;
        localPointingDirection: TLocalPointingDirections;
      })
    | '';
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  componentType: '',
  oneSigmaAngleError: '',
  oneSigmaCrossAxisError: '',
  oneSigmaBoresightAxisError: '',
  oneSigmaPerAxisError: '',
  oneSigmaDistanceError: '',
  fieldOfView: '',
  referenceVector: '',
};

interface IProps {
  control: TEntityDialogControl<ISensor>;
}

const SensorDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action },
  } = control;
  const { subsystems, fieldsOfView, referenceVectors } = useActiveEntities();
  const { setSpacecraftDialogConfig, SpacecraftTabs } = useContext(SpacecraftContext);

  // Set up styles
  const classes = useStyles();

  const options = useMemo(() => {
    return {
      componentType: SensorVables.ComponentType.options,
      fieldOfView: fieldsOfView.map((f) => {
        return { value: f.id, label: f.name };
      }),
      referenceVector: referenceVectors.map((v) => {
        return {
          value: v.id,
          label: v.name,
          // Below 2 keys are for filtering out some vectors for vector sensors
          type: v.vectorType,
          // localPointingDirection is `undefined` if type isn't "LOCAL"
          localPointingDirection: (v as ILocalVector).localPointingDirection,
        };
      }),
    };
  }, [fieldsOfView, referenceVectors]);

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const entityForm = useEntityForm<ISensor, IForm & typeof thermalDefaultValues>({
    entityTypeText: 'Sensor',
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: {
      subsystem: subsystems.find((s) => s.category === SubsystemVables.Categories.GNC.value)?.id,
    },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
      allowedEmptyFields: ['manufacturer', 'partNumber', 'fieldOfView'],
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setValues } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Sensor Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('componentType')}
            label="Sensor Type"
            options={options.componentType}
            isDisabled={action !== 'create'}
            formikOnChange={(val: ISelectOption) => {
              if (val !== values.componentType) {
                // Reset values that are based on componentType
                setValues((values) => ({
                  ...values,
                  referenceVector: defaultValues.referenceVector,
                  oneSigmaAngleError: defaultValues.oneSigmaAngleError,
                  oneSigmaCrossAxisError: defaultValues.oneSigmaCrossAxisError,
                  oneSigmaBoresightAxisError: defaultValues.oneSigmaBoresightAxisError,
                  oneSigmaPerAxisError: defaultValues.oneSigmaPerAxisError,
                  oneSigmaDistanceError: defaultValues.oneSigmaDistanceError,
                  fieldOfView: defaultValues.fieldOfView,
                }));
              }
            }}
          />
          <div className={classes.indent}>
            {values.componentType === SensorVables.ComponentType.DIRECTION_SENSOR && (
              <>
                <LabeledSelect
                  {...getFieldProps('referenceVector')}
                  label="Direction Vector"
                  options={options.referenceVector}
                />
                <LabeledInput
                  {...getFieldProps('oneSigmaAngleError')}
                  label="One Sigma Angle Error"
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                />
              </>
            )}
            {values.componentType === SensorVables.ComponentType.OPTICAL_ATTITUDE_SENSOR && (
              <>
                <LabeledInput
                  {...getFieldProps('oneSigmaCrossAxisError')}
                  label="One Sigma Cross-Axis Error"
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                />
                <LabeledInput
                  {...getFieldProps('oneSigmaBoresightAxisError')}
                  label="One Sigma Boresight-Axis Error"
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                />
              </>
            )}
            {values.componentType === SensorVables.ComponentType.VECTOR_SENSOR && (
              // WARN: Only reference vectors with magnitude are allowed for vector sensors.
              // On 11/15/22, as a possibly temporary fix, it was decided that magnetic field should
              // be the only local reference vector allowed, since it's the only one with inherent
              // magnitude. Its unit is Teslas, and every other option (at present) has units in km.
              // This may no longer be valid if more types of local vectors are added.
              <>
                <LabeledSelect
                  {...getFieldProps('referenceVector')}
                  label="Reference Vector"
                  options={options.referenceVector.filter(
                    (rv) =>
                      rv.type !== ReferenceVectorVables.VectorTypes.LOCAL.value ||
                      rv.localPointingDirection ===
                        ReferenceVectorVables.LocalPointingDirections.MAGNETIC_FIELD.value
                  )}
                />
                <LabeledInput
                  {...getFieldProps('oneSigmaPerAxisError')}
                  label="One Sigma Per-Axis Error"
                  type="number"
                  endAdornment={
                    // Dynamic units based on referenceVector selection.
                    // WARN: If more types of local vectors are added, this may no longer be valid.
                    <InputAdornment position="end">
                      {values.referenceVector === ''
                        ? ''
                        : values.referenceVector.type ===
                          ReferenceVectorVables.VectorTypes.LOCAL.value
                        ? 'T'
                        : 'km'}
                    </InputAdornment>
                  }
                />
              </>
            )}
            {values.componentType === SensorVables.ComponentType.ANGULAR_VELOCITY_SENSOR && (
              <LabeledInput
                {...getFieldProps('oneSigmaPerAxisError')}
                label="One Sigma Per-Axis Error"
                type="number"
                endAdornment={<InputAdornment position="end">rad/s</InputAdornment>}
              />
            )}
            {values.componentType === SensorVables.ComponentType.POSITION_SENSOR && (
              <LabeledInput
                {...getFieldProps('oneSigmaDistanceError')}
                label="One Sigma Error"
                type="number"
                endAdornment={<InputAdornment position="end">km</InputAdornment>}
              />
            )}
          </div>
        </div>
        {values.componentType &&
          values.componentType !== SensorVables.ComponentType.POSITION_SENSOR && (
            <div className={classes.inputGroup}>
              <SpacecraftAccent
                header="Field of View"
                onAddAction={() =>
                  setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
                }
              >
                <LabeledSelect
                  {...getFieldProps('fieldOfView')}
                  label="Sensor Field of View"
                  options={options.fieldOfView}
                  optional={
                    values.componentType !== SensorVables.ComponentType.OPTICAL_ATTITUDE_SENSOR
                  }
                />
              </SpacecraftAccent>
            </div>
          )}
        <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
      </div>
    </EntityDialog>
  );
};

export default SensorDialog;
