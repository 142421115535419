import { useEffect, useCallback, useMemo, useState, useRef } from 'react';
import ReactFlow, { Controls, useNodesState, useEdgesState } from 'react-flow-renderer';
import ELK from 'elkjs';
import Slider from '@material-ui/core/Slider';

import * as d3 from 'd3-force';
import * as _ from 'lodash';
import theme, { borderRadius } from 'theme';
import { useStyles } from './styles';
import { useActiveEntities, useEntityDialogControl, useSnackbar } from 'hooks';
import ThermalInterfaceDialog from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/ThermalInterfacesSegment/ThermalInterfacesDialog';

import { TempNode, ConnectableTempNode, ChildNode, ConnectableChildNode } from './nodes';
import { FloatingEdge, FloatingEdgeWithDialog, FloatingConnectionLine } from './edges';

// Using Elk in place of this for now
// const getLayoutedDagre = (nodes, edges, direction = 'TB') => {
//   const isHorizontal = direction === 'LR';

//   const dagreGraph = new dagre.graphlib.Graph();
//   dagreGraph.setDefaultEdgeLabel(() => ({}));
//   dagreGraph.setGraph({ rankdir: direction });

//   const ignore = {};
//   nodes.forEach((node) => {
//     if (node.position) {
//       ignore[node.id] = node;
//       return;
//     }
//     dagreGraph.setNode(node.id, { width: node.style.width, height: node.style.height });
//   });

//   edges.forEach((edge) => {
//     if (ignore[edge.source] || ignore[edge.target]) {
//       return;
//     }
//     dagreGraph.setEdge(edge.source, edge.target);
//   });

//   dagre.layout(dagreGraph);

//   nodes.forEach((node) => {
//     const nodeWithPosition = dagreGraph.node(node.id);
//     node.targetPosition = isHorizontal ? 'left' : 'top';
//     node.sourcePosition = isHorizontal ? 'right' : 'bottom';

//     // We are shifting the dagre node position (anchor=center center) to the top left
//     // so it matches the React Flow node anchor point (top left).
//     node.position = {
//       x: nodeWithPosition.x - node.style.width / 2,
//       y: nodeWithPosition.y - node.style.height / 2,
//     };

//     return node;
//   });

//   return { nodes, edges };
// };

const getLayoutedElk = async (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR';

  const elk = new ELK({
    algorithms: ['layered', 'stress', 'mrtree', 'radial', 'force', 'disco', 'fixed'],
  });

  const graph = {
    id: 'root',
    layoutOptions: { 'elk.algorithm': 'org.eclipse.elk.force' },
    children: [],
    edges: [],
  };

  const ignore = {};

  nodes.forEach((node) => {
    if (node.position) {
      node.targetPosition = isHorizontal ? 'left' : 'top';
      node.sourcePosition = isHorizontal ? 'right' : 'bottom';
      ignore[node.id] = node;
      return;
    }
    graph.children.push({
      id: node.id,
      width: node.style.width * 1.1,
      height: node.style.height * 1.1,
    });
  });

  edges.forEach((edge) => {
    if (ignore[edge.source] || ignore[edge.target]) {
      return;
    }
    graph.edges.push({ id: edge.id, sources: [edge.source], targets: [edge.target] });
  });

  const nodeLookup = {};
  for (const node of nodes) {
    nodeLookup[node.id] = node;
  }

  const { children } = await elk.layout(graph);

  children.forEach((child) => {
    // TODO: Don't mutate `nodes`
    const node = nodeLookup[child.id];
    node.targetPosition = isHorizontal ? 'left' : 'top';
    node.sourcePosition = isHorizontal ? 'right' : 'bottom';

    // We are shifting the dagre node position (anchor=center center) to the top left
    // so it matches the React Flow node anchor point (top left).
    node.position = {
      x: child.x - node.style.width / 2,
      y: child.y - node.style.height / 2,
    };
  });

  return { nodes, edges };
};

// Left for sake of example
// const onInit = (reactFlowInstance) => console.log('flow loaded:', reactFlowInstance);

function tempToColor(temp, maxTemp, minTemp, maxHue = 260, minHue = 0) {
  const hue =
    Math.max(0, Math.min(1 - (temp - minTemp) / (maxTemp - minTemp), 1)) * (maxHue - minHue) +
    minHue;
  return `hsl(${hue}, 100%, 50%)`;
}

const updateNode = (node, temps, max, min) => {
  let newStyle = {
    ...node.style,
    border: `1px solid ${theme.palette.background.darkest}`,
    borderRadius,
  };
  // Child nodes get a more default-looking style
  node.style = node.parentNode
    ? newStyle
    : {
        ...newStyle,
        paddingLeft: '2px',
        paddingRight: '2px',
        backgroundColor: tempToColor(temps[node.id] || temps[node.id.split('-')[0]], max, min),
      };
  node.data = {
    ...node.data,
    temp: temps[node.id] || temps[node.id.split('-')[0]],
  };
  return node;
};

const updateEdge = (edge, temps, max) => {
  const a = temps[edge.source] || temps[edge.source.split('-')[0]];
  const b = temps[edge.target] || temps[edge.target.split('-')[0]];
  return {
    ...edge,
    source: a > b ? edge.source : edge.target,
    target: a > b ? edge.target : edge.source,
  };
};

// ----------------- Slider Vars -----------------
const ZERO_KELVIN = -273.15;
const H2O_FREEZING_POINT = 0;
const H2O_BOILING_POINT = 100;
const HOT = 200;
const min = -55;
const max = 125;
const tempRangeMarks = [ZERO_KELVIN, H2O_FREEZING_POINT, H2O_BOILING_POINT, HOT].map((t) => ({
  value: t,
  label: `${t}°C`,
}));
// const edgeRadiusMarks = [...Array(11).keys()]
//   .map((t) => t * 100)
//   .map((t) => ({ value: t, label: t }));
// const collisionRadiusMarks = [...Array(5).keys()]
//   .map((t) => t * 50)
//   .map((t) => ({ value: t, label: t }));
// const collisionForceMarks = [...Array(11).keys()]
//   .map((t) => t / 10)
//   .map((t) => ({ value: t, label: t }));
// -----------------------------------------------

const ThermalMap = (props) => {
  const { nodes: _nodes, edges: _edges, temps, editable } = props;
  const classes = useStyles();
  const rootRef = useRef(null);
  const sliderContainerRef = useRef(null);
  const nodeTypes = useMemo(
    () => ({
      temp: editable ? ConnectableTempNode : TempNode,
      'temp-group': TempNode, // Group node not editable
      child: editable ? ConnectableChildNode : ChildNode,
    }),
    [editable]
  );
  const edgeTypes = useMemo(
    () => ({
      floating: editable ? FloatingEdgeWithDialog : FloatingEdge,
    }),
    [editable]
  );

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [go, setGo] = useState(false);
  const [fSim, setFSim] = useState(null);
  const [tempRange, setTempRange] = useState([min, max]);

  // When nodes or edges change, remove the tick handler
  // because its internal nodes are now out of date.
  // NOTE: This must happen BEFORE the next useEffect.
  //       It resets the previous render's effect and allows
  //       a new handler to be defined.
  // REF: tickHandlerEvents
  useEffect(() => {
    if (
      fSim &&
      (_nodes.length !== nodes.length || _edges.filter((e) => !e.hidden).length !== edges.length)
    )
      fSim.on('tick', null);
  }, [_nodes, _edges]); //eslint-disable-line

  // REF: tickHandlerEvents
  useEffect(() => {
    const prevNodes = nodes || [];
    if (_nodes.length !== nodes.length || _edges.filter((e) => !e.hidden).length !== edges.length)
      getLayoutedElk(_nodes, _edges).then(({ nodes, edges }) => {
        setNodes(nodes.map((node) => updateNode(node, temps, tempRange[1], tempRange[0])));
        setEdges(
          edges
            .filter(
              // Filter out all edges pointing at "groups" as they were only used for the layout engine
              ({ source, target }) => !source.includes('-group') && !target.includes('-group')
            )
            .map((edge) => updateEdge(edge, temps))
        );

        const xMean = nodes.reduce((acc, curr) => acc + curr.position.x, 0) / nodes.length;
        const yMean = nodes.reduce((acc, curr) => acc + curr.position.y, 0) / nodes.length;
        const links = edges.map((edge) => ({ source: edge.source, target: edge.target }));

        // retain previous node position when possible
        const prevPositions = _.fromPairs(prevNodes.map((n) => [n.id, n.position]));
        for (const node of nodes)
          if (prevPositions[node.id]) node.position = prevPositions[node.id];

        // TODO: find better force-based layout system or write one, d3-force has a limited set of forces to choose from
        const fSim = d3
          .forceSimulation(nodes.map((node) => ({ id: node.id, ...node.position })))
          // .alphaMin(0)
          // .alphaDecay(0)
          // .velocityDecay(0.5)
          .alpha(0.1)
          .force('collide', d3.forceCollide(100).strength(0.1))
          .force(
            'link',
            d3
              .forceLink()
              .id((d) => d.id)
              .links(links)
              .distance(400)
          )
          .force(
            'center',
            d3.forceCenter(xMean, yMean)
            // .force("charge", d3.forceManyBody().strength(-100))
          );
        setFSim(fSim);
        fSim.on('tick', () => {
          setNodes((nodes) =>
            _.zip(nodes, fSim.nodes()).map(([node, fNode]) => {
              return node.ignoreLayout
                ? node
                : {
                    ...node,
                    position: {
                      x: fNode.fx ?? fNode.x,
                      y: fNode.fy ?? fNode.y,
                    },
                  };
            })
          );
        });
        setGo(true);
      });
    else {
      // If nodes.length and edges.length hasn't changed but this useEffect is still called,
      // it must be that an interface (i.e. edge) has been updated by the user.
      // Run through the list and reassign an interface's data to its corresponding edge.
      // This way the ThermalInterfaceDialog will have fresh data when it's reopened.
      // Assumes edges[i] maps to _filteredEdges[i].
      const _filteredEdges = _edges.filter(
        // Filter out all edges pointing at "groups" as they were only used for the layout engine
        ({ source, target }) => !source.includes('-group') && !target.includes('-group')
      );
      setEdges((prev) =>
        prev.map((edge, i) =>
          updateEdge(
            { ...edge, data: _filteredEdges[i].data, label: _filteredEdges[i].data.name },
            temps
          )
        )
      );
    }
    // Don't put fSim in dependency array, will cause an infinite loop
  }, [_nodes, _edges]); //eslint-disable-line

  useEffect(() => {
    if (go) {
      setNodes((nds) => nds.map((node) => updateNode(node, temps, tempRange[1], tempRange[0])));
      setEdges((eds) => eds.map((edge) => updateEdge(edge, temps)));
    }
  }, [go, temps, setNodes, setEdges, tempRange]);

  const stopSim = useCallback(() => {
    return fSim.stop();
  }, [fSim]);

  const onNodeDrag = useCallback(
    (event, node, nodes) => {
      for (let fNode of fSim.nodes()) {
        if (fNode.id === node.id) {
          fNode.x = node.position.x;
          fNode.y = node.position.y;
        }
      }
    },
    [fSim]
  );

  const startSim = useCallback(() => {
    return fSim.alpha(0.01).tick(0);
  }, [fSim]);

  const { components, surfaces } = useActiveEntities();
  const dialogControl = useEntityDialogControl();
  const { openDialogForNew } = dialogControl;
  const { enqueueSnackbar } = useSnackbar();

  // If opening an edit dialog, it needs to know which components the
  // interface is between. This state defines those components.
  const [interfaceEndpoints, setInterfaceEndpoints] = useState({
    source: null,
    target: null,
    sourceCooler: null,
    targetCooler: null,
  });

  // Opens dialog to create new interface, using interfaceEndpoints
  const newInterfaceDialog = useCallback(
    ({ source, target }) => {
      // source & target are ids as strings
      const allThermalEndpoints = components.concat(surfaces);
      // get components based on ids
      // some ids have suffixes (e.g. if cooler is id '257', source might be '257-cool' or '257-group')
      const sourceNode = allThermalEndpoints.find((el) => el.id === source.split('-')[0]);
      const targetNode = allThermalEndpoints.find((el) => el.id === target.split('-')[0]);
      // get number of interfaces for components so far
      const numSourceInterfaces =
        sourceNode.thermal_interface_A.length + sourceNode.thermal_interface_B.length;
      const numTargetInterfaces =
        targetNode.thermal_interface_A.length + targetNode.thermal_interface_B.length;
      // get types of source and target
      const sourceSurface = sourceNode.surfaceMaterial;
      const sourceCooler = source.includes('cool');
      const targetSurface = targetNode.surfaceMaterial;
      const targetCooler = target.includes('cool');

      // Can't connect two surfaces
      if (sourceSurface && targetSurface)
        enqueueSnackbar('Thermal interfaces between two external surfaces are not allowed.', {
          variant: 'warning',
        });
      // Can't connect a surface and a cooler
      else if ((sourceSurface && targetCooler) || (sourceCooler && targetSurface))
        enqueueSnackbar('An external surface cannot be regulated by a cooler.', {
          variant: 'warning',
        });
      // Surfaces can't have more than one thermal interface
      else if (
        (sourceSurface && numSourceInterfaces > 0) ||
        (targetSurface && numTargetInterfaces > 0)
      )
        enqueueSnackbar('An external surface can only have one thermal interface.', {
          variant: 'warning',
        });
      else {
        setInterfaceEndpoints({
          source: sourceNode,
          target: targetNode,
          sourceCooler,
          targetCooler,
        });
        openDialogForNew();
      }
    },
    [components, surfaces, setInterfaceEndpoints, openDialogForNew, enqueueSnackbar]
  );

  // TODO: This method of dynamic resizing tends to grow the height way too far.
  // const activeKey = useContext(ContextNavContext)?.state?.activeKey;
  // const rootElTop = rootRef.current?.getBoundingClientRect().top;
  // const sliderContainerElHeight = sliderContainerRef.current?.getBoundingClientRect().height;
  // const reactFlowHeight =
  //   activeKey === wGroupIndicesAgentCustom.PLAYBACK
  //     ? 600
  //     : Math.max(600, window.innerHeight - rootElTop - sliderContainerElHeight - 41);
  const reactFlowHeight = 700;

  return (
    <div ref={rootRef}>
      <ReactFlow
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={newInterfaceDialog}
        onNodeDragStart={stopSim}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={startSim}
        // onInit={onInit}
        fitView
        attributionPosition="bottom-right"
        connectionLineComponent={FloatingConnectionLine}
        style={{ height: reactFlowHeight }}
        deleteKeyCode={[]}
      >
        <Controls />
      </ReactFlow>
      <div className={classes.sliderContainer} ref={sliderContainerRef}>
        <p className={classes.sliderTitleFirst}>Temperature Range:</p>
        <Slider
          className={classes.slider}
          name="Temperature Range"
          defaultValue={[min, max]}
          min={ZERO_KELVIN}
          max={HOT}
          track={false}
          value={tempRange}
          marks={tempRangeMarks}
          onChange={(c, v) => setTempRange(v)}
        />
        {/* TODO: should collisions radius always be <= edge radius so we can use a single slider? */}
        {/* <p className={classes.sliderTitle}>Edge Radius:</p>
        <Slider
          className={classes.slider}
          name="Edge Radius"
          defaultValue={400}
          min={0}
          max={1000}
          track={false}
          marks={edgeRadiusMarks}
          onChange={(c, v) => startSim().force('link').distance(v)}
        />
        <p className={classes.sliderTitle}>Collision Radius:</p>
        <Slider
          className={classes.slider}
          name="Collision Radius"
          defaultValue={100}
          min={0}
          max={200}
          track={false}
          marks={collisionRadiusMarks}
          onChange={(c, v) => startSim().force('collide').radius(v)}
        />
        <p className={classes.sliderTitle}>Collision Force:</p>
        <Slider
          className={classes.slider}
          name="Collision Force"
          defaultValue={0.1}
          min={0}
          max={1}
          step={null}
          track={false}
          marks={collisionForceMarks}
          onChange={(c, v) => startSim().force('collide').strength(v)}
        /> */}
      </div>
      {dialogControl.dialogConfig.open && editable && (
        <ThermalInterfaceDialog
          control={dialogControl}
          source={interfaceEndpoints.source}
          target={interfaceEndpoints.target}
          sourceCooler={interfaceEndpoints.sourceCooler}
          targetCooler={interfaceEndpoints.targetCooler}
        />
      )}
    </div>
  );
};

export default ThermalMap;
