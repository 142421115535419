import { Fragment, useMemo } from 'react';
import StyledLabel from 'components/general/StyledLabel';
import Select from 'react-select';
import styles from './styles';
import _ from 'lodash';
import { useInReadOnlyBranch } from 'hooks';

const LabeledSelect = (props) => {
  const {
    guide,
    label,
    errors,
    name,
    setFieldValue,
    isClearable,
    value,
    optional,
    formikOnChange,
    onChange,
    ...remainingProps
  } = props;
  const { options } = remainingProps;
  const inReadOnlyBranch = useInReadOnlyBranch();

  // This is necessary to fix instances where a select is used to choose an entity/model
  // Without this, when the entity/model is updated, the option label (shown in the select) will
  // not update to the new value (typically entity.name)
  // https://github.com/react-hook-form/react-hook-form/issues/1620
  const foundOption = useMemo(() => {
    let _foundOption = '';
    // Must check sub options for any fields with nested options
    if (value) {
      if (options[0]?.options) {
        for (let subOptions of options) {
          _foundOption = subOptions.options.find((option) => option.value === value.value);
          if (_foundOption) break;
        }
      } else {
        // If no sub options then just find the value normally
        _foundOption = options.find((option) => option.value === value.value);
      }
    }
    return _foundOption;
  }, [options, value]);

  return (
    <Fragment>
      <StyledLabel>{optional ? `${label} (optional)` : label}</StyledLabel>
      <Select
        {...remainingProps}
        value={foundOption}
        onFocus={guide && ((e) => guide(e, name, label))}
        //onBlur={guide && ((e) => guide(e))}
        onChange={(value) => {
          // Calling the default onChange for React Select causes an error and the value must be changed with setFieldValue
          if (setFieldValue) {
            if (formikOnChange) {
              formikOnChange(value);
            }
            setFieldValue(name, value);
          } else {
            // If the React Select is not in Formik (for example please see SimEx Widget) then call the normal onChange
            onChange(value);
          }
        }}
        isClearable={optional || isClearable}
        disableUnderline
        error={errors && _.get(errors, name) ? true : false}
        styles={styles}
        isDisabled={props.disabled || props.isDisabled || inReadOnlyBranch}
      />
    </Fragment>
  );
};

export default LabeledSelect;
