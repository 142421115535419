import { TDPSchemaList } from 'components/general/types';

const tsSurfaceRadiativeHeatTransferDPSchema: TDPSchemaList = [
  // {
  //   name: 'Surface Radiative Heat Transfer Overview',
  //   subtitle: '',
  //   //TODO output a total
  //   plots: [
  //     {
  //       type: 'time-series',
  //       left: {
  //         label: 'Thermal Dissipation Rate',
  //         unit: 'W',
  //         keys: [
  //           'heatFlowRate',
  //           'spaceHeatFlowRate',
  //           'solarHeatFlowRate',
  //           'earthIrHeatFlowRate',
  //           'earthAlbedoHeatFlowRate',
  //         ],
  //         legend: ['Total', 'Space', 'Solar', 'Earth IR', 'Earth Albedo'],
  //       },
  //     },
  //   ],
  // },
  {
    name: '"{name}" Radiative Heat Transfer',
    subtitle: '',
    each: 'Surface',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Thermal Dissipation Rate',
          unit: 'W',
          keys: [
            'heatFlowRate',
            'spaceHeatFlowRate',
            'solarHeatFlowRate',
            'earthIrHeatFlowRate',
            'earthAlbedoHeatFlowRate',
          ],
          legend: ['Total', 'Space', 'Solar', 'Earth IR', 'Earth Albedo'],
        },
      },
    ],
  },
];

export default tsSurfaceRadiativeHeatTransferDPSchema;
