import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { IMission } from 'components/general/types';
import StyledButton from 'components/general/StyledButton';
import useStyles from './styles';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { useSelectById, useUser } from 'hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import Routes from 'routes';
import moment from 'moment-timezone';
import { toTitleCase } from 'utils/strings';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import RepoDataTypeIcon from 'components/general/RepoDataTypeIcon';
import { TClickEvent } from 'components/general/types';

interface IProps {
  repo: IMission;
  collaborationControl: TEntityDialogControl<number>;
  repoDialogControl: TEntityDialogControl<IMission>;
}

const RepoRow = (props: IProps) => {
  const { repo, collaborationControl, repoDialogControl } = props;
  const { openDialogForExisting: openCollaborationDialog } = collaborationControl;
  const { openDialogForExisting: openRepoDialog } = repoDialogControl;

  const user = useUser();
  const history = useHistory();
  const owner = useSelectById('Collaborator', repo.user);

  const isUsersRepo = useMemo(() => {
    return repo.user === user?.id;
  }, [user, repo]);

  const tooltip = useCallback(
    (action) => {
      return !isUsersRepo
        ? `Only the repository owner can ${action} the repository`
        : toTitleCase(action);
    },
    [isUsersRepo]
  );

  const classes = useStyles({ enabledDelete: isUsersRepo });

  // collaborators is one more than length, because repo.collaborators it doesn't include the owner
  const collaboratorsToolTip = `This repository has ${repo.collaborators.length + 1} collaborators`;

  const openCollabDialog = (e: TClickEvent) => {
    e.stopPropagation();
    openCollaborationDialog(repo.id, undefined);
  };

  const openRepoDialogCB = (e: TClickEvent, type: 'edit' | 'delete') => {
    e.stopPropagation();
    openRepoDialog(repo, type);
  };

  return (
    <div className={classes.row} onClick={() => history.push(Routes.BRANCH(repo.id))}>
      <div className={classes.infoContainer}>
        <h3>
          {repo.name} <RepoDataTypeIcon dataType={repo.dataType} />
        </h3>
        <div className={classes.dateText}>
          ID: {repo.id} | Updated {moment(repo.dateModified).fromNow()} by {owner?.firstName}{' '}
          {owner?.lastName}
        </div>
      </div>
      <div className={classes.buttonContainer}>
        {repo.collaborators.length > 0 && (
          <Tooltip arrow title={collaboratorsToolTip}>
            <span className={classes.userIconContainer}>
              <FontAwesomeIcon size="lg" icon={faUsers} />
            </span>
          </Tooltip>
        )}
        <StyledButton framed onClick={openCollabDialog}>
          Collaborators
        </StyledButton>
        <StyledButton
          framed
          disabled={!isUsersRepo}
          tooltip={tooltip('update')}
          className={classes.iconButton}
          onClick={(e: TClickEvent) => openRepoDialogCB(e, 'edit')}
        >
          <FontAwesomeIcon icon={faPencilAlt} />
        </StyledButton>
        <StyledButton
          disabled={!isUsersRepo}
          tooltip={tooltip('delete')}
          className={clsx(classes.iconButton, classes.deleteButton)}
          onClick={(e: TClickEvent) => openRepoDialogCB(e, 'delete')}
        >
          <FontAwesomeIcon icon={faTrash} />
        </StyledButton>
      </div>
    </div>
  );
};

export default RepoRow;
