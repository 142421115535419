export const slice = (data, i, seriesLength, keyOverride) => {
  return Object.keys(data).reduce((obj, k) => {
    if (data[k].length !== seriesLength) {
      obj[keyOverride || k] = data[k].map((data) => data[i]);
    } else {
      obj[keyOverride || k] = data[k][i];
    }
    return obj;
  }, {});
};

// Returns true if arrays hold all the same values regardless of order, false otherwise
// compareUnorderedArrays([2, 4, 6], [4, 2, 6]) === true
// compareUnorderedArrays([], []) === true
// compareUnorderedArrays([1,2], [1]) === false
// compareUnorderedArrays([1,1,0], [1,0,0]) === false
export const compareUnorderedArrays = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  let hashMap1 = {};
  let hashMap2 = {};
  arr1.forEach((item) => {
    hashMap1[item] = Object.prototype.hasOwnProperty.call(hashMap1, item) ? hashMap1[item] + 1 : 1;
  });
  arr2.forEach((item) => {
    hashMap2[item] = Object.prototype.hasOwnProperty.call(hashMap2, item) ? hashMap2[item] + 1 : 1;
  });
  return Object.keys(hashMap1).every((item) => hashMap1[item] === hashMap2[item]);
};

export const interpolator = (data, timeFactor, correctTimeSeries) => {
  let interpolatedData = [];

  for (let i = 0; i < data.length; i++) {
    let fillFactor = timeFactor;
    if (i === data.length - 1) {
      fillFactor = correctTimeSeries.data.length - i * timeFactor;
    }
    for (let j = 0; j < fillFactor; j++) {
      interpolatedData.push({
        ...data[i],
        times: correctTimeSeries.data[i * timeFactor + j].times,
      });
    }
  }

  return interpolatedData;
};
