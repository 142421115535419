import * as Yup from 'yup';

const operationalModeSchema = Yup.object({
  name: Yup.string()
    .required('An operational mode name is required.')
    .max(32, 'Operation mode name must be no more than 32 characters.'),

  pointingMode: Yup.object().required('Select a pointing mode.'),

  limitDurationAndFrequency: Yup.boolean(),

  maxOccurrenceDuration: Yup.mixed().when('limitDurationAndFrequency.', {
    is: true,
    then: Yup.number().min(0, 'Maximum occurrence duration must be non-negative.'),
  }),

  minTimeBetweenOccurrences: Yup.mixed().when('limitDurationAndFrequency.', {
    is: true,
    then: Yup.number()
      .required('A minimum time between occurrences is required.')
      .min(0, 'Minimum time between occurrences must be non-negative.'),
  }),

  requireMinDuration: Yup.boolean(),

  minOccurrenceDuration: Yup.mixed().when('requireMinDuration', {
    is: true,
    then: Yup.number()
      .required('A minimum occurrence duration is required.')
      .min(0, 'Minimum occurrence duration must be non-negative.')
      .test(
        'compareMaxOccurrence',
        'The minimum occurrence must be less than the maximum occurrence duration.',
        (minOccurrenceDuration, schema) => {
          const { parent } = schema;
          if (
            parent.limitDurationAndFrequency &&
            typeof parent.maxOccurrenceDuration === 'number'
          ) {
            return minOccurrenceDuration < parent.maxOccurrenceDuration;
          }
          return true;
        }
      ),
  }),
});

export default operationalModeSchema;
