import { ISelectOption } from 'components/general/types';
import { ReferenceVectorVables } from 'utils/vable';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  vectorType: Yup.object().required('Vector type is required.'),
  celestialPointingDirection: Yup.object().when('vectorType', {
    is: (vectorType: ISelectOption) =>
      vectorType?.value === ReferenceVectorVables.VectorTypes.CELESTIAL.value,
    then: Yup.object().required('Celestial pointing direction is required.'),
  }),
  localPointingDirection: Yup.object().when('vectorType', {
    is: (vectorType: ISelectOption) =>
      vectorType?.value === ReferenceVectorVables.VectorTypes.LOCAL.value,
    then: Yup.object().required('Local pointing direction is required.'),
  }),
  referenceTarget: Yup.object().when(['vectorType'], {
    is: (vectorType: ISelectOption) =>
      vectorType?.value === ReferenceVectorVables.VectorTypes.TARGET.value,
    then: Yup.object().required('Target is required.'),
  }),
  targetGroup: Yup.object().when(['vectorType'], {
    is: (vectorType: ISelectOption) =>
      vectorType?.value === ReferenceVectorVables.VectorTypes.TARGET_GROUP.value,
    then: Yup.object().required('Target group is required.'),
  }),
});

export default validation;
