import * as Yup from 'yup';

const targetSchema = Yup.object().shape({
  name: Yup.string()
    .required('A name is required')
    .max(32, 'Target name must be no more than 32 characters'),

  targetType: Yup.object().required('Select a target type'),

  polynomialEphemerisBody: Yup.object().when('targetType', {
    is: (targetType) => targetType?.value === 'CELESTIAL_TARGET',
    then: Yup.object().required('Select a celestial body'),
  }),
});

export default targetSchema;
