import { useMemo } from 'react';
import useActiveEntities from './useActiveEntities';
import useUser from './useUser';

/**
 * @returns boolean - whether the user is a collaborator on the active repo (activeMission)
 */
const useInCollaboratedRepo = (): boolean => {
  const { repo } = useActiveEntities();
  const user = useUser();

  return useMemo(() => !!user && repo?.collaborators.includes(user.id), [user, repo]);
};

export default useInCollaboratedRepo;
