import clsx from 'clsx';
import useStyles from './styles';

export default function ViewPortInlay(props) {
  const { text, children, className } = props;
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.inlayWrapper)}>
      <div className={classes.inlayText}>{text}</div>
      {children}
    </div>
  );
}
