import { isValidElement, Fragment } from 'react';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

const parseParagraphs = (chunk) => {
  return (
    <Fragment>
      {chunk.split('\n').map((paragraph, key) => {
        return <p key={key}>{paragraph}</p>;
      })}
    </Fragment>
  );
};

const GuidanceCard = (props) => {
  const { guidance, noIcon } = props;

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <h5 className={classes.heading}>{guidance.heading}</h5>
      <div className={classes.body}>
        {typeof guidance.body !== 'string'
          ? isValidElement(guidance.body)
            ? guidance.body
            : guidance.body.map((item, key) => {
                const fullGraphic = item.graphicConfig === 'full';
                return (
                  <Fragment key={key}>
                    {item.subHeading && <h6 className={classes.subHeading}>{item.subHeading}</h6>}
                    <div className={fullGraphic ? classes.fullChunk : classes.chunk}>
                      <div className={classes.text}>{parseParagraphs(item.chunk)}</div>
                      {item.graphic && (
                        <div className={fullGraphic ? classes.fullGraphic : classes.graphic}>
                          <img
                            src={item.graphic}
                            style={{ maxHeight: item.maxHeight }}
                            alt={`guidance-${key}-graphic`}
                          />
                        </div>
                      )}
                    </div>
                  </Fragment>
                );
              })
          : parseParagraphs(guidance.body)}
      </div>
      {!noIcon && (
        <div className={classes.icon}>
          <FontAwesomeIcon icon={faInfo} />
        </div>
      )}
    </div>
  );
};

export default GuidanceCard;
