import moment from 'moment';
import { round } from 'mathjs';

export const mjd2Moment = (mjd) => {
  return moment.unix(round((mjd - 40587) * 86400)).utc();
};

export const moment2Mjd = (mnt) => {
  return mnt.utc().unix() / 86400 + 40587;
};

export const mjd2Jd = (mjd) => {
  return mjd + 2400000.5;
};

export const jd2Mjd = (jd) => {
  return jd - 2400000.5;
};

export const msToDuration = (ms, plural = true) => {
  const units = ['ms', 's', 'mins', 'hrs', 'days', 'yrs'];
  const unitsSingular = ['ms', 'sec', 'min', 'hr', 'day', 'yr'];
  const conversions = [1000, 60, 60, 24, 365.2422];
  let i = 0;
  while (ms > conversions[i] && i < conversions.length - 1) {
    ms /= conversions[i];
    i++;
  }
  if (!plural) return `${round(ms, 1)}${unitsSingular[i]}`;
  return `${round(ms, 1)} ${units[i]}`;
};

export const diff2Duration = (start, end) => {
  return msToDuration(end - start);
};

export const dateFormatLong = 'HH:mm:ss MM-DD-YYYY';

const DELTA_AT = 37; // Current value for international atomic time to UTC offset (as of Dec 2016) [seconds]
// Modified julian date to terrestrial time (TT) julian date
const jd2JdTt = (jd) => {
  const JdTai = jd + DELTA_AT / 86400;
  return JdTai + 32.184 / 86400;
};

//  Julian centuries TT (terrestrial time) to julian centuries of tT
export const jd2JcJ2k = (jdTt) => {
  return (jdTt - 2451545) / 36525;
};

// Modified Julian day to Julian day TDB (see GLOSS)
export const mjd2JdTdb = (mjd) => {
  const jdTt = jd2JdTt(mjd2Jd(mjd)); // NOTE: call to jd2JdUt1 removed from python port since we are considering it to be an identity function
  const jcTt = jd2JcJ2k(jdTt);
  const mAnomEarth = (Math.PI / 180) * ((357.5277233 + 35999.05034 * jcTt) % 360);

  return (
    jdTt + (0.001658 * Math.sin(mAnomEarth)) / 86400 + (0.00001385 * Math.sin(2 * mAnomEarth)) / 24
  );
};

// Modified Julian day to Julian centuries GLOSS TDB (Barycentric Dynamical Time)
export const mjd2JcTdb = (mjd) => {
  return (mjd2JdTdb(mjd) - 2451545) / 36525;
};
