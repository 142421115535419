import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  powerTableNestedCell: {
    padding: 0,
    border: 0,
    '& .MuiCollapse-wrapper': {
      padding: 0,
    },
  },
  sectionHeader: {
    cursor: 'pointer',
    '&:active': {
      backgroundColor: theme.palette.action.selected + '!important',
    },
  },
}));

export default useStyles;
