import { useCallback } from 'react';
import { Model } from 'react-babylonjs';
import { FilesInput } from '@babylonjs/core';
import '@babylonjs/loaders';
import { deg2Rad } from 'utils/math';

const CADModel = (props) => {
  let {
    file,
    setCadScaleFactor,
    satellite,
    // unsure why useSnackbar() is not working here or in parent component. Had to pass down enqueueSnackbar as a prop from AttitudeDisplay.
    enqueueSnackbar,
    setModelLoadError,
    modelLoadError,
  } = props;

  // using snackbar from provider seems to break onModelError
  // const { enqueueSnackbar } = useSnackbar();

  const vectorDistance = (mesh) => {
    return Math.sqrt(mesh.x ** 2 + mesh.y ** 2 + mesh.z ** 2);
  };

  const onModelLoaded = (model) => {
    const maxVectorDistance = vectorDistance(model.boundingInfo.boundingSphere.maximum);
    setCadScaleFactor && setCadScaleFactor(1 / maxVectorDistance);
    model.rootMesh.scaling.scaleInPlace(1 / maxVectorDistance);
    model.rootMesh.rotation.x = deg2Rad(90);
  };

  const onModelError = useCallback(() => {
    enqueueSnackbar(
      'There was an error loading your file. Please check that your file is not corrupted.'
    );

    // flag will reset the cad model url and filename back to the previous model in CADModelPanel if there is an error loading the model
    setModelLoadError && setModelLoadError(true);
  }, [enqueueSnackbar, setModelLoadError]);

  // file name must be lowercase otherwise the babylon preview will break
  const fileName = file.fileName.toLowerCase();
  const fileIsBlob = typeof file.fileUrl !== 'string';
  FilesInput.FilesToLoad[fileName] = file.fileUrl;
  return (
    <Model
      reportProgress
      onModelLoaded={onModelLoaded}
      onModelError={onModelError}
      rootUrl={modelLoadError ? satellite.cadSignedUrl : fileIsBlob ? 'file:' : file.fileUrl}
      sceneFilename={fileIsBlob && !modelLoadError ? fileName : ''}
    />
  );
};

export default CADModel;
