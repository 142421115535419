import { TDPSchemaList } from 'components/general/types';

const tsAdodDPSchema: TDPSchemaList = [
  {
    name: 'Attitude and Attitude Solution',
    subtitle: 'Estimate minus truth from attitude determination (AD) algorithms',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'ECI Error Angles',
          unit: 'rad',
          keys: [
            'conOps.attitudeSolutionError.0',
            'conOps.attitudeSolutionError.1',
            'conOps.attitudeSolutionError.2',
          ],
          legend: [
            'AD Solution Error: Yaw',
            'AD Solution Error: Pitch',
            'AD Solution Error: Roll',
          ],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Body Frame Angular Velocity Error',
          unit: 'rpm',
          keys: [
            'conOps.angularVelocitySolutionError.rpm.0',
            'conOps.angularVelocitySolutionError.rpm.1',
            'conOps.angularVelocitySolutionError.rpm.2',
          ],
          legend: [
            'AV Solution Error: Axis-x',
            'AV Solution Error: Axis-y',
            'AV Solution Error: Axis-z',
          ],
        },
      },
    ],
  },
  {
    name: 'Orbit and Orbit Solution',
    subtitle: 'Estimate minus truth from orbit determination (OD) algorithms',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'ECI Position Error',
          unit: 'km',
          keys: [
            'positionSolutionError.0',
            'positionSolutionError.1',
            'positionSolutionError.2',
          ],
          legend: [
            'OD Solution Error: x-Position',
            'OD Solution Error: y-Position',
            'OD Solution Error: z-Position',
          ],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'ECI Velocity Error',
          unit: 'km/s',
          keys: [
            'velocitySolutionError.0',
            'velocitySolutionError.1',
            'velocitySolutionError.2',
          ],
          legend: [
            'OD Solution Error: x-Velocity',
            'OD Solution Error: y-Velocity',
            'OD Solution Error: z-Velocity',
          ],
        },
      },
    ],
  },
  {
    name: 'Geodetic Position',
    subtitle: 'True position, WGS84 ellipsoid',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Angle',
          unit: 'deg',
          keys: ['position.llaDeg.0', 'position.llaDeg.1'],
          legend: ['Latitude', 'Longitude'],
        },
        right: {
          label: 'Altitude',
          unit: 'km',
          keys: ['position.llaDeg.2'],
          legend: ['Altitude'],
        },
      },
    ],
  },
  {
    name: '"{name}" Direction Sensor',
    each: 'satellite.directionSensors',
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Unit Vector Components, S/C Body-Frame',
          unit: '',
          keys: [
            'truth.0',
            'truth.1',
            'truth.2',
            'measurement.0',
            'measurement.1',
            'measurement.2',
          ],
          legend: [
            'Truth: x-Component',
            'Truth: y-Component',
            'Truth: z-Component',
            'Measurement: x-Component',
            'Measurement: y-Component',
            'Measurement: z-Component',
          ],
        },
      },
    ],
  },
  {
    name: '"{name}" Vector Sensor',
    each: 'satellite.vectorSensors',
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Vector Components, S/C Body Frame',
          unit: '{unit}',
          keys: [
            'referenceVector.truth.eci.0',
            'referenceVector.truth.eci.1',
            'referenceVector.truth.eci.2',
            'measurement.0',
            'measurement.1',
            'measurement.2',
          ],
          legend: [
            'Truth: x-Component',
            'Truth: y-Component',
            'Truth: z-Component',
            'Measurement: x-Component',
            'Measurement: y-Component',
            'Measurement: z-Component',
          ],
        },
      },
    ],
  },
  {
    name: '"{name}" Angular Velocity Sensor',
    each: 'satellite.angularVelocitySensors',
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Rates, S/C Body Frame',
          unit: 'rpm',
          keys: [
            'root.conOps.angularVelocity.rpm.0',
            'root.conOps.angularVelocity.rpm.1',
            'root.conOps.angularVelocity.rpm.2',
            'measurement.rpm.0',
            'measurement.rpm.1',
            'measurement.rpm.2',
          ],
          legend: [
            'Truth: x-Component',
            'Truth: y-Component',
            'Truth: z-Component',
            'Measurement: x-Component',
            'Measurement: y-Component',
            'Measurement: z-Component',
          ],
        },
      },
    ],
  },
  {
    name: '"{name}" Position Sensor',
    each: 'satellite.positionSensors',
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Position, ECI',
          unit: 'km',
          keys: [
            'root.position.eci.0',
            'root.position.eci.1',
            'root.position.eci.2',
            'measurement.0',
            'measurement.1',
            'measurement.2',
          ],
          legend: [
            'Truth: x-Position',
            'Truth: y-Position',
            'Truth: z-Position',
            'Measurement: x-Position',
            'Measurement: y-Position',
            'Measurement: z-Position',
          ],
        },
      },
    ],
  },
  {
    name: '"{name}" Optical Attitude Sensor',
    each: 'satellite.opticalAttitudeSensors',
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Quaternion Components, ECI-to-S/C Body Frame',
          unit: '',
          keys: [
            'root.conOps.attitude.eci.0',
            'root.conOps.attitude.eci.1',
            'root.conOps.attitude.eci.2',
            'root.conOps.attitude.eci.3',
            'measurement.0',
            'measurement.1',
            'measurement.2',
            'measurement.3',
          ],
          legend: [
            'Truth: Axis-x',
            'Truth: Axis-y',
            'Truth: Axis-z',
            'Truth: Scalar',
            'Measurement: Axis-x',
            'Measurement: Axis-y',
            'Measurement: Axis-z',
            'Measurement: Scalar',
          ],
        },
      },
    ],
  },
];

export default tsAdodDPSchema;
