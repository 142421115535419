import { makeGuidance } from 'hooks';

const groundTargDescription =
  'Ground targets are fixed to the surface of the Earth, specifically the WGS84 ellipsoid. Their location is specified in each specific Scenario.';
const spaceTargetDescription =
  'Space targets are other Earth-orbiting objects. These could be other satellites targeted by your payload or used for communications crosslink or relay. Their orbit is specified in each specific Scenario.';
const celestialTargetDescription =
  "Celestial targets are natural objects including the Earth and those which are located outside of Earth's atmosphere, such as the Moon and Sun.";

const useGuidance = makeGuidance({
  _default: {
    heading: 'Target Inputs',
    body: [
      {
        chunk:
          'Input a descriptive name for the target and select the appropriate target type from the options below.',
      },
      {
        subHeading: 'Ground Target',
        chunk: groundTargDescription,
      },
      {
        subHeading: 'Space Target',
        chunk: spaceTargetDescription,
      },
      {
        subHeading: 'Celestial Target',
        chunk: celestialTargetDescription,
      },
    ],
  },

  name: {
    alias: '_default',
  },

  targetType: {
    alias: '_default',
  },

  polynomialEphemerisBody: {
    heading: 'Celestial Body Targets',
    body:
      celestialTargetDescription +
      ' Select from the dropdown of available bodies to define this target.',
  },
});
export default useGuidance;
