import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import App from 'app';
import { Provider } from 'react-redux';
import store from 'store';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import GlobalStyle from 'theme/GlobalStyle';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import theme from 'theme';
import { HashRouter } from 'react-router-dom';
import { SnackbarProvider, MasonryProvider } from 'providers';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <HashRouter>
              <SnackbarProvider>
                <MasonryProvider>
                  <App />
                </MasonryProvider>
              </SnackbarProvider>
            </HashRouter>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// ========================================
