import { useContext } from 'react';
import CdhAnalyzeBoards from './CdhAnalyzeBoards';
import CommsAnalyzeBoard from './CommsAnalyzeBoard';
import PlaybackWGroups from 'components/general/PlaybackWGroups';
import { wGroupIndicesAgentCdh } from '../menu/cdh';
import { wGroupIndicesAgentThermal } from '../menu/thermal';
import { wGroupIndicesAgentGnc } from '../menu/gnc';
import { wGroupIndicesAgentPower } from '../menu/power';
import ModePlaybackWidget from 'components/AgentAnalyzeView/AnalyzeBoards/general/ModePlaybackWidget';
import ConOpsLogicPlaybackWidget from 'components/AgentAnalyzeView/AnalyzeBoards/CdhAnalyzeBoards/playback/ConOpsLogicPlaybackWidget';
import {
  EPSStateWidget,
  ElectricalPowerFlow,
} from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/playback';
import ThermalStateWidget from './ThermalAnalyzeBoards/playback/ThermalStateWidget';
import WGroup from 'components/general/WGroup';
import { ContextNavContext, MomentProvider } from 'providers';
import ThermalFlowWidget from './ThermalAnalyzeBoards/playback/ThermalFlowWidget';
import { wGroupIndicesAgentCustom } from '../menu/custom';
import ACSStateWidget from './GncAnalyzeBoards/playback/ACSStateWidget';

const AnalyzeBoards = () => {
  // Consider changing this to use moduleTypes
  const activeKey = useContext(ContextNavContext)?.state?.activeKey;
  const group = (index: string) => activeKey === index;
  // const masonryConfig = !group(wGroupIndicesAgentCdh.PLAYBACK)
  //   ? { mdLg: [50, 50] }
  //   : { lg: [60, 40], mdLg: [50, 30, 20] };
  const masonryConfig =
    activeKey === wGroupIndicesAgentCustom.PLAYBACK ? { mdLg: [65, 35] } : { mdLg: [55, 45] };
  return (
    <MomentProvider>
      <PlaybackWGroups
        index={[
          wGroupIndicesAgentCdh.PLAYBACK,
          wGroupIndicesAgentThermal.PLAYBACK,
          wGroupIndicesAgentGnc.PLAYBACK,
          wGroupIndicesAgentPower.PLAYBACK,
          wGroupIndicesAgentCustom.PLAYBACK,
        ]}
        masonryConfig={masonryConfig}
      >
        {group(wGroupIndicesAgentCustom.PLAYBACK) && [
          // Keep as a list for masonry layout
          // custom
          <ElectricalPowerFlow key="power-flow" />,
          <WGroup
            key="acs-thermal-states"
            index={wGroupIndicesAgentCustom.PLAYBACK}
            masonryConfig={{ xs: [55, 45] }}
          >
            <ACSStateWidget />
            <ThermalStateWidget />
          </WGroup>,
          <ThermalFlowWidget key="thermal-flow" />,
        ]}
        {group(wGroupIndicesAgentCdh.PLAYBACK) && (
          // Command & Control
          <WGroup index={wGroupIndicesAgentCdh.PLAYBACK} masonryConfig={{ xs: [70, 30] }}>
            <ModePlaybackWidget />
            <ConOpsLogicPlaybackWidget />
          </WGroup>
        )}
        {group(wGroupIndicesAgentPower.PLAYBACK) && [
          // power
          // Keep as a list for masonry layout
          <EPSStateWidget key="eps-state" />,
          <ModePlaybackWidget key="modes" />,
          <ElectricalPowerFlow key="power-flow" />,
        ]}
        {group(wGroupIndicesAgentThermal.PLAYBACK) && ( // thermal
          <>
            <ThermalStateWidget />
            <ThermalFlowWidget />
          </>
        )}
        {group(wGroupIndicesAgentGnc.PLAYBACK) && ( // thermal
          <>
            <ACSStateWidget />
          </>
        )}
      </PlaybackWGroups>
      <CdhAnalyzeBoards />
      <CommsAnalyzeBoard />
    </MomentProvider>
  );
};

export default AnalyzeBoards;
