import logo from 'multimedia/brand/logo_secondary_white.png';
import useStyles from './styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';

function LoadingView(props) {
  const classes = useStyles();
  const { message, inlay } = props;

  return (
    <div className={classes.root}>
      <div className={inlay ? classes.contentInlay : classes.content}>
        <Box position="relative" display="inline-flex" className={classes.spinnerWrapper}>
          <CircularProgress size={250} thickness={2} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <img src={logo} alt="logo" className={classes.brand} />
          </Box>
        </Box>
        <h2 className={classes.message}>{message || 'Sedaro Satellite is loading...'}</h2>
      </div>
    </div>
  );
}

export default LoadingView;
