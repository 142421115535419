import { createContext, useReducer, useMemo, useCallback, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import Routes from 'routes';
import { useActiveEntities, useMenu } from 'hooks';
import { ITEMS } from 'config';

export const ContextNavContext = createContext();

const ContextNavProvider = (props) => {
  const { children, reducer } = props;
  const initialMenuState = useMenu();

  const history = useHistory();
  const location = history.location;
  const activeEntities = useActiveEntities();

  const { id, moduleAction, subKey, subSubKey } = useParams();

  const baseReducer = useCallback(
    (state, action) => {
      if (reducer) {
        state = reducer(state, action); // Allow for reducer extending
      }

      switch (action.type) {
        case 'UPDATE_DYNAMIC_ITEMS': {
          if (action.reset) state = initialMenuState;

          const { dynamicItems } = state;
          // if there are no dynamicMenuItems (it's an empty array), the forEach will do nothing, and original menu is returned
          dynamicItems.forEach((dynItm) => {
            // grab all intended entities (and filter out empty indices of array -- only needed in rare edge cases)
            const itemsToAdd = action.activeEntities[dynItm.entityKey].filter((i) => i);

            // grab list of previously added dynamic menu items from the nav context state
            const prevMenuItems = _.get(state, dynItm.keysToLoc);

            // add in updated dynamic items
            // this will also remove any deleted entities from the nav context
            const updatedItems = {};
            itemsToAdd.forEach((item, i) => {
              // spread old state here to maintain expanded state of segments so they remain open if a user clicked on them
              updatedItems[item.id] = { ...prevMenuItems[item.id], title: item.name, order: i };
            });

            // set dynamic menu state to the new updatedItems object
            _.set(state, dynItm.keysToLoc, updatedItems);
          });
          return { ...state };
        }
        case 'CONTEXT_NAV_ITEM_HANDLE_CLICK': {
          let key = action.key;
          let items = _.cloneDeep(state.items);
          let k = key.replaceAll('.', '.subItems.');
          let i = _.get(items, k);

          // Fail fast if key(s) invalid
          if (!i) {
            // if we're not on a dynamic loacation redirect to 404 before returning state
            const _k = `${ITEMS}.` + k.slice(0, k.lastIndexOf('.'));
            if (state.dynamicItems.every((dynItm) => dynItm.keysToLoc !== _k)) {
              history.replace(Routes.NOT_FOUND());
            }
            return state;
          }

          // Control context nav menu visibility
          let leaf = i;
          let _open = true;
          if (i.open && i.subItems && !action.freshLoad) {
            // Collapse menu parent if its open
            _open = false;
          } else {
            let ii = items;
            if (!i.open) {
              for (let parentKey of key.split('.')) {
                // Open parents
                ii[parentKey].open = _open;
                ii = ii[parentKey].subItems;
              }
            }
          }

          while (leaf.subItems) {
            // Update children
            leaf.open = _open;
            if (Object.keys(leaf.subItems).length > 0) {
              let k = Object.keys(leaf.subItems)[0];
              key += `.${k}`;
              leaf = leaf.subItems[k];
            }
          }

          // Toggle accordion if leaf.  This is for controlling accordion style edit wizards (i.e. MDW)
          if (_open) {
            leaf.shouldFocus = !action.allowCollapse && !action.freshLoad; // Determine if focus should be drawn on segment
            if (!leaf.expanded) {
              leaf.expanded = true;
            } else if (action.allowCollapse) {
              leaf.expanded = false;
            }
          } else {
            leaf.shouldFocus = false;
            key = state.activeKey; // Keep same key on collapse
          }

          _.set(items, k, i);

          // Update URL encoding of nav state
          // Use callback to avoid race condition with primary useEffect
          let updateRoute = undefined;
          if (i.open || !i.subItems) {
            const keyList = key.split('.');
            const route = state.routeFn(id, ...keyList) + location.search;
            if (route.includes(location.pathname)) {
              // If the current pathname is partial of intended route, don't push to history stack as
              // this causes undesirable behavior. Instead, replace current pathname.
              updateRoute = (h) => h.replace(route);
            } else if (location.pathname.includes('edit')) {
              // If on edit and this isn't first nav to edit, don't push to history stack as this
              // causes undesirable behavior. Instead, replace current pathname.
              if (leaf.expanded) {
                // Don't update route on WizardSegment collapse
                updateRoute = (h) => h.replace(route);
              }
            } else if (location.pathname !== route) {
              updateRoute = (h) => h.push(route);
            }
          }

          return { ...state, items, activeKey: key, updateRoute };
        }
        default:
          return state;
      }
    },
    [reducer, location, history, id, initialMenuState]
  );

  const [state, dispatch] = useReducer(baseReducer, initialMenuState || {});

  const contextValue = useMemo(() => {
    return { state, dispatch };
  }, [state, dispatch]);

  // get the dynamic active entities for the current menu and store them in an array to use as
  // dependency for both useEffect dispatches below
  const dynActiveEntities = useMemo(
    () => initialMenuState.dynamicItems.map((item) => activeEntities[item.entityKey]),
    [initialMenuState, activeEntities]
  );

  // triggered when switching directly between modules, so the menu state can reset and reflect the new module
  useEffect(() => {
    dispatch({
      type: 'UPDATE_DYNAMIC_ITEMS',
      activeEntities: activeEntities,
      reset: true,
    });
  }, [initialMenuState]); // eslint-disable-line

  // trigger when item (such as custom subsystem) is added/removed/edited so corresponding dynamic part of menu updates also
  useEffect(() => {
    dispatch({
      type: 'UPDATE_DYNAMIC_ITEMS',
      activeEntities: activeEntities,
    });
  }, [...dynActiveEntities]); // eslint-disable-line

  // Initialize/update state on page load/change via call to reducer with key generated from URL params
  useEffect(() => {
    let key = moduleAction;
    if (subKey) {
      key += `.${subKey}`;
      if (subSubKey) {
        key += `.${subSubKey}`;
      }
    }
    if (key && state.activeKey !== key) {
      dispatch({ type: 'CONTEXT_NAV_ITEM_HANDLE_CLICK', key, freshLoad: true });
    }
  }, [location.pathname, ...dynActiveEntities]); // eslint-disable-line

  // Update URL encoding after state update
  useEffect(() => {
    if (state.updateRoute) state.updateRoute(history);
  }, [state.updateRoute]); // eslint-disable-line

  return <ContextNavContext.Provider value={contextValue}>{children}</ContextNavContext.Provider>;
};

export default ContextNavProvider;
