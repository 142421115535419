import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    '& div.MuiDialog-container.MuiDialog-scrollBody': {
      ...theme.mixins.noScroll,
    },
    '& div.MuiPaper-root.MuiDialog-paper': {
      width: '80%',
      maxWidth: props.large
        ? 900
        : props.small
        ? 350
        : props.xlarge
        ? 1100
        : props.xxlarge
        ? 1400
        : 600,
      backgroundColor: theme.palette.background.main,
      borderRadius: 3,
      boxShadow: theme.shadows[5],
      overflow: 'visible',
    },
  }),

  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
    border: 0,
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  iconButton: {
    padding: 0,

    '& svg': {
      fill: theme.palette.background.contrastText,
    },
  },

  form: (props) => ({
    paddingTop: props.large ? 20 : props.small ? 10 : 0,
    display: 'flex',
    flexDirection: 'column',

    '& div.MuiInputBase-root': {
      maxWidth: props.small ? '100%' : 300,
    },

    '& div.MuiInputBase-root.MuiInputBase-multiline': {
      maxWidth: 'none',
    },
  }),

  footer: {
    alignSelf: 'flex-end',
    display: 'flex',
    marginTop: 20,

    '& button': {
      marginLeft: '10px !important',
    },
  },
}));

export default useStyles;
