import { useContext } from 'react';
import Panel from '../general/Panel';
import { SpacecraftContext } from 'providers';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import WidgetTable from 'components/general/widgets/WidgetTable';
import ssGuidanceObj from './guidance';
import COTSDialogButton from 'components/general/COTSDialogButton';
import { IComponent, ISubsystem } from 'components/general/types/power';
import { useEntityDialogControl } from 'hooks';
import ComponentDialog from './ComponentDialog';
import SubsystemTitle from './SubsystemTitle';
import { ComponentVables } from 'utils/vable';

const columns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Part #',
    field: 'partNumber',
  },
  {
    title: 'Manufacturer',
    field: 'manufacturer',
  },
];

interface IProps {
  subsystem: ISubsystem;
  preventTabOnAddCustomButton: () => void;
}

const SubsystemPanel = ({ subsystem, preventTabOnAddCustomButton }: IProps) => {
  const { closeSpacecraftDialog } = useContext(SpacecraftContext);

  const classes = useStyles();

  const components = subsystem.components;

  const control = useEntityDialogControl<IComponent>();
  const { openDialogForExisting, openDialogForNew } = control;

  const guidance = ssGuidanceObj[subsystem.category];

  return (
    <Panel
      title={
        <SubsystemTitle
          subsystem={subsystem}
          preventTabOnAddCustomButton={preventTabOnAddCustomButton}
        />
      }
      guidance={guidance}
      secondaryActionText="Close"
      onClose={closeSpacecraftDialog}
    >
      <div className={classes.preTableRow}>
        <h6>Components</h6>
        <COTSDialogButton entityKey="components" subsystem={subsystem} />
      </div>
      <WidgetTable
        className={classes.table}
        columns={columns}
        data={components}
        modelName="component"
        onFabClick={openDialogForNew}
        onActionClick={openDialogForExisting}
        disableActionMenuOptions={{
          actions: ['delete'],
          conditionalCB: (data: IComponent) =>
            data?.componentType === ComponentVables.ComponentType.POWER_PROCESSOR.value,
        }}
      />
      <ComponentDialog control={control} subsystemId={subsystem.id} />
    </Panel>
  );
};

export default SubsystemPanel;
