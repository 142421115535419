import { useMemo, useRef, useState } from 'react';
import PlaybackViewer from './PlaybackViewer';
import PlaybackControls from './PlaybackControls';
import WGroup from 'components/general/WGroup';
import ViewPortInlay from 'components/general/ViewPortInlay';

export default function PlaybackWGroups(props) {
  const { index, masonryConfig, children, viewerProps } = props;

  const dataAliasing = useMemo(() => {
    // TODO: Logic for downsampling
    return false;
  }, []);

  const viewerRef = useRef(null);
  const cameraRef = useRef(null);
  const clockRef = useRef(null);
  const satelliteEntityRef = useRef(null);
  const sceneRef = useRef(null);
  const [animate, setAnimate] = useState(false);

  const [positiveClockMultiplier, setPositiveClockMultiplier] = useState(true);

  return (
    <>
      {!dataAliasing ? (
        <>
          <WGroup index={index}>
            <PlaybackControls
              viewerRef={viewerRef}
              clockRef={clockRef}
              positiveClockMultiplier={positiveClockMultiplier}
              setPositiveClockMultiplier={setPositiveClockMultiplier}
              animate={animate}
              setAnimate={setAnimate}
              {...viewerProps}
            />
          </WGroup>
          <WGroup index={index} masonryConfig={masonryConfig}>
            <PlaybackViewer
              viewerRef={viewerRef}
              cameraRef={cameraRef}
              clockRef={clockRef}
              satelliteEntityRef={satelliteEntityRef}
              sceneRef={sceneRef}
              animate={animate}
            />
            {children}
          </WGroup>
        </>
      ) : (
        <WGroup index={index}>
          <ViewPortInlay
            text="Simulation playback cannot be displayed for downsampling rates greater than 50%. Please fetch
                a smaller window."
          />
        </WGroup>
      )}
    </>
  );
}

export { default as MissionDesignerPlaybackControls } from './PlaybackControls';
