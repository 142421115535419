import { useContext, useEffect, useCallback, useState, useRef } from 'react';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import IconButton from '@material-ui/core/IconButton';
import Slider from '@material-ui/core/Slider';
import useStyles from './styles';
import { TimeContext, ContextNavContext, DataContext } from 'providers';
import { mjd2Moment } from 'utils/time';
import * as Cesium from 'cesium';
import { cesiumClockMultiplierScaleDefault } from '../general/constants';
import { useActiveEntities } from 'hooks';
import { isLiveDemoFf, isLiveDemo } from 'utils/debt';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';

export default function PlaybackControls(props) {
  const {
    viewerRef,
    clockRef,
    positiveClockMultiplier,
    setPositiveClockMultiplier,
    animate,
    setAnimate,
  } = props;

  const classes = useStyles();
  const { startTime, stopTime, injestConfig } = useContext(DataContext);
  const {
    activeAgentData: { cesiumData, calculateMultiplier },
  } = useAnalyticsContext();
  const { time, setTime, timeFormatted } = useContext(TimeContext);
  const navContext = useContext(ContextNavContext);
  const { branch } = useActiveEntities();

  const [visible, setVisible] = useState(true);
  const timeoutRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // This useEffect is triggered whenever we navigate to this page and when new series data is fetched. It makes sure
    // the cesium playback is paused when navigate to/from playback
    setAnimate(false);
    // eslint-disable-next-line
  }, [navContext.state.activeKey]); // (adding setAnimate causes infinite loop in useEffect)

  const [shouldAnimateListenerInitialized, setShouldAnimateListenerInitialized] = useState(false);
  useEffect(() => {
    // create listener to shouldAnimate and use to make `animate` state always align with it
    if (!shouldAnimateListenerInitialized && viewerRef.current) {
      Cesium.knockout
        .getObservable(viewerRef.current.cesiumElement.clockViewModel, 'shouldAnimate')
        .subscribe(setAnimate);
      setShouldAnimateListenerInitialized(true);
    }
  }, [
    viewerRef,
    setAnimate,
    shouldAnimateListenerInitialized,
    setShouldAnimateListenerInitialized,
  ]);

  const progressSliderOnChange = useCallback(
    (e, t) => {
      const newJulianDate = Cesium.JulianDate.fromIso8601(mjd2Moment(t).format());
      // There were edge cases where the playback progress bar forced the currentTime
      // out of the boundaries of startTime or stopTime, which caused all entities to
      // disappear. The below logic prevents the currentTime from being set to
      // something exceeding those boundaries.
      clockRef.current.cesiumElement.currentTime =
        Cesium.JulianDate.compare(newJulianDate, cesiumData.stopTime) > 0
          ? cesiumData.stopTime
          : Cesium.JulianDate.compare(newJulianDate, cesiumData.startTime) < 0
          ? cesiumData.startTime
          : newJulianDate;
      if (viewerRef.current?.cesiumElement.clockViewModel.shouldAnimate) {
        viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
      }
    },
    [clockRef, viewerRef, cesiumData]
  );

  useEffect(() => {
    if (clockRef.current && injestConfig.rate && isLiveDemo(branch)) {
      const W = 0.01;
      const K = 1;
      const NAME_ME = 10;

      const now = Date.now() / 1000 / 86400 + 40587;

      let x = time - now;
      if (x > 0.0099999999) x = 0.0099999999;
      else if (x < -0.0099999999) x = -0.0099999999;
      let inner = (2 * W) / (x + W) - 1;
      let adjustment = K * Math.log(inner) * 40;
      if (adjustment > NAME_ME) adjustment = NAME_ME;
      else if (adjustment < -NAME_ME) adjustment = -NAME_ME;
      const fn = positiveClockMultiplier ? Math.max : Math.min;
      const result = fn(0, 1 + adjustment);
      clockRef.current.cesiumElement.multiplier = result;
    }
  }, [injestConfig, time]); //eslint-disable-line

  useEffect(() => {
    const el = document.getElementById('viewport');
    el.addEventListener('mousemove', (event) => {
      setVisible(true);
      clearTimeout(timeoutRef.current);
      // Don't set timeout if mouse is inside container
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        if (
          event.x <= rect.right &&
          event.x >= rect.left &&
          event.y <= rect.bottom &&
          event.y >= rect.top
        ) {
          return;
        }
      }
      timeoutRef.current = setTimeout(() => {
        setVisible(false);
      }, 3000);
    });
  }, []);

  return (
    <div ref={containerRef} className={classes.root} style={{ display: visible || 'none' }}>
      <div className={classes.playbackControlsWrapper}>
        <div className={classes.playbackControls}>
          <div className={classes.playPauseSkipWrapper}>
            <IconButton
              edge="start"
              onClick={() => {
                viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
                viewerRef.current.cesiumElement.clockViewModel.currentTime =
                  viewerRef.current.cesiumElement.clockViewModel.startTime;
                // manually set time to make sure the cesium currentTime observer never gets out of sync
                setTime(startTime);
              }}
            >
              <SkipPreviousIcon />
            </IconButton>
            <IconButton
              disabled={
                (time === startTime && !positiveClockMultiplier) ||
                (time === stopTime && positiveClockMultiplier)
              }
              onClick={() => {
                viewerRef.current.cesiumElement.clockViewModel.shouldAnimate =
                  !viewerRef.current.cesiumElement.clockViewModel.shouldAnimate;
              }}
            >
              {animate ? <PauseIcon /> : <PlayArrowIcon />}
            </IconButton>
            <IconButton
              onClick={() => {
                viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
                viewerRef.current.cesiumElement.clockViewModel.currentTime =
                  viewerRef.current.cesiumElement.clockViewModel.stopTime;
                // manually set time to make sure the cesium currentTime observer never gets out of sync
                setTime(stopTime);
              }}
            >
              <SkipNextIcon />
            </IconButton>
          </div>
          <Slider
            className={classes.multiplierControl}
            defaultValue={cesiumClockMultiplierScaleDefault}
            step={1}
            min={-100}
            max={100}
            track={false}
            marks={[{ value: -100 }, { value: 0 }, { value: 100 }]}
            onChange={(c, v) => {
              const multiplier = calculateMultiplier(v);
              clockRef.current.cesiumElement.multiplier = multiplier;
              setPositiveClockMultiplier(multiplier > 0);
            }}
          />
        </div>
        <div className={classes.progressWrapper}>
          <Slider
            className={classes.progress}
            min={startTime}
            max={stopTime}
            step={10 ** -11}
            value={time}
            onChange={progressSliderOnChange}
          />
        </div>
        {!isLiveDemoFf(branch) && (
          <div className={classes.currentDate}>
            <p>{timeFormatted} UTC</p>
          </div>
        )}
      </div>
    </div>
  );
}
