/**
 *
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 *
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 *
 * This file uses RECHARTS (ewwww) and is therefore OLD AND OBSOLETE
 *
 * DO NOT USE THIS COMPONENT UNTIL IT IS UPDATED TO USE ECHARTS
 *
 * If you are updating it yourself, please remove this notice when finished.
 *
 *
 */

/* eslint-disable */

import { Fragment, useMemo } from 'react';
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  Line,
  Label,
  ReferenceLine,
  Text,
} from 'recharts';
import theme from 'theme';
import GregorianTickFormatter from 'components/general/charts/GregorianTickFormatter';
import withZoom from 'components/general/charts/withZoom';
import { mjd2Moment, dateFormatLong } from 'utils/time';
import { timing } from 'config';

const ConOpsTickFormatter = (props) => {
  const { x, y, payload, labelBodies, labelHeadings, labelPriorities, opModeFlags } = props;

  const textElement = (
    <Fragment>
      <Text
        x={0}
        y={0}
        dy={0}
        dx={-6}
        width={140}
        textAnchor="end"
        fontSize=".75em"
        verticalAnchor="middle"
        fill={theme.palette.background.contrastText}
      >
        {labelBodies[payload.value]}
      </Text>
      <Text
        x={0}
        y={0}
        dy={labelPriorities[payload.value] === 0 ? 0 : -18 - 2 * opModeFlags[payload.value]}
        dx={-6}
        textAnchor="end"
        fontWeight="bold"
        fontSize={0.8 + 0.25 * opModeFlags[payload.value] + 'em'}
        width={140}
        verticalAnchor="middle"
        fill={theme.palette.background.contrastText}
      >
        {labelHeadings[payload.value]}
      </Text>
    </Fragment>
  );

  return <g transform={`translate(${x},${y})`}>{textElement}</g>;
};

const conOpsModeStrokeWidth = 15;
const conOpsConditionStrokeWidth = 10;
const conOpsReferenceLineColor = theme.palette.background.light;
const conOpsReferenceLineWidthMultiplier = 0.5;
const opModeElementGap = 1;

const modeColors = Object.values(theme.palette.charts.booleanBars.primary);
const conditionColors = Object.values(theme.palette.charts.booleanBars.secondary);

const ConOpsTimeSeriesChart = (props) => {
  const {
    children,
    model,
    data,
    zoomProps: { domain, mouseEvents },
  } = props;

  let conOpsLabelBodies = [];
  let conOpsLabelHeadings = [];
  let conOpsLabelPriorities = [];
  let conOpsLabelCircleColors = [];
  let conOpsLabelOpModeFlags = [];
  let conOpsTicks = [];

  const conditionList = model.Condition.all();
  const operationalModeList = model.OperationalMode.all();

  const totalElements = useMemo(() => {
    return operationalModeList.reduce((accumulator, opModeReduce) => {
      return (
        accumulator +
        2 +
        (opModeReduce.priority !== 0 && opModeElementGap + opModeReduce.conditions.length + 1)
      );
    }, 0);
  }, [operationalModeList]);

  const renderOperationalModeLines = (
    operationalMode,
    operationalModeList,
    operationalModeIndex,
    totalElements
  ) => {
    const baseIndex =
      operationalMode.priority === 0
        ? 1
        : operationalModeList.reduce((accumulator, opModeReduce) => {
            return (
              accumulator +
              (opModeReduce.priority === 0
                ? 2
                : opModeReduce.priority < operationalMode.priority
                ? 2 + opModeElementGap + opModeReduce.conditions.length + 1
                : 0)
            );
          }, 0) +
          operationalMode.conditions.length +
          2;
    conOpsLabelHeadings[baseIndex] = operationalMode.name;
    conOpsLabelPriorities[baseIndex] = operationalMode.priority;
    conOpsLabelBodies[baseIndex] = operationalMode.priority === 0 ? '' : 'Mode Compliance';
    conOpsLabelOpModeFlags[baseIndex] = 1;
    conOpsTicks.push(baseIndex);

    return [
      <YAxis yAxisId={baseIndex} domain={[-baseIndex + 1, totalElements - baseIndex + 1]} hide />,
      <ReferenceLine
        yAxisId={baseIndex}
        y={1}
        stroke={
          operationalMode.priority !== 0
            ? theme.palette.background.light
            : modeColors[operationalModeIndex % modeColors.length].dark
        }
        strokeWidth={
          conOpsModeStrokeWidth *
          (operationalMode.priority !== 0 ? conOpsReferenceLineWidthMultiplier : 1)
        }
      />,
      <Line // Dull op mode compliance lines
        yAxisId={baseIndex}
        type="monotone"
        dataKey={'operationalModeCompliances[' + operationalModeIndex + ']'}
        stroke={modeColors[operationalModeIndex % modeColors.length].dark}
        strokeWidth={conOpsModeStrokeWidth}
        dot={false}
        animationDuration={timing.lineAnimationDuration}
      />,
      <Line // Dull op mode compliance lines for extra steps
        yAxisId={baseIndex}
        type="monotone"
        dataKey={'operationalModeCompliancesExtra[' + operationalModeIndex + ']'}
        stroke={modeColors[operationalModeIndex % modeColors.length].dark}
        strokeWidth={conOpsModeStrokeWidth}
        dot={false}
        animationDuration={timing.lineAnimationDuration}
      />,
      <Line // Bright active op mode lines
        yAxisId={baseIndex}
        type="monotone"
        dataKey={'operationalModeStatuses[' + operationalModeIndex + ']'}
        stroke={modeColors[operationalModeIndex % modeColors.length].light}
        strokeWidth={conOpsModeStrokeWidth}
        dot={false}
        animationDuration={timing.lineAnimationDuration}
      />,
      <Line // Bright active op mode lines for extra steps
        yAxisId={baseIndex}
        type="monotone"
        dataKey={'operationalModeStatusesExtra[' + operationalModeIndex + ']'}
        stroke={modeColors[operationalModeIndex % modeColors.length].light}
        strokeWidth={conOpsModeStrokeWidth}
        dot={false}
        animationDuration={timing.lineAnimationDuration}
      />,
      operationalMode.priority !== 0 &&
        operationalMode.conditions.map((condition, conditionIndex) => {
          const conditionDataIndex = conditionList.reduce(
            (arrayInd, filterCondition, filterCondIndex) =>
              arrayInd + (filterCondition.id === condition.id) * filterCondIndex,
            0
          );
          conOpsLabelHeadings[baseIndex - conditionIndex - 2] =
            conditionIndex === 0 ? 'Condition Compliance' : '';
          conOpsLabelBodies[baseIndex - conditionIndex - 2] = condition.name;
          conOpsTicks.push(baseIndex - conditionIndex - 2);
          conOpsLabelOpModeFlags[baseIndex - conditionIndex - 2] = 0;
          return [
            <YAxis
              yAxisId={baseIndex - conditionIndex - 1}
              domain={[
                -(baseIndex - conditionIndex - 3),
                totalElements - (baseIndex - conditionIndex - 2) + 1,
              ]}
              hide
            />,
            <ReferenceLine
              yAxisId={baseIndex - conditionIndex - 1}
              y={1}
              stroke={conOpsReferenceLineColor}
              strokeWidth={conOpsConditionStrokeWidth * conOpsReferenceLineWidthMultiplier}
            />,
            <Line // Dull condition compliance lines
              yAxisId={baseIndex - conditionIndex - 1}
              type="monotone"
              dataKey={'conditionCompliances[' + conditionDataIndex + ']'}
              stroke={conditionColors[operationalModeIndex % conditionColors.length].dark}
              strokeWidth={conOpsConditionStrokeWidth}
              dot={false}
              animationDuration={timing.lineAnimationDuration}
            />,
            <Line // Dull condition compliance lines for extra steps
              yAxisId={baseIndex - conditionIndex - 1}
              type="monotone"
              dataKey={'conditionCompliancesExtra[' + conditionDataIndex + ']'}
              stroke={conditionColors[operationalModeIndex % conditionColors.length].dark}
              strokeWidth={conOpsConditionStrokeWidth}
              dot={false}
              animationDuration={timing.lineAnimationDuration}
            />,
            <Line // Bright active condition lines
              yAxisId={baseIndex - conditionIndex - 1}
              type="monotone"
              dataKey={'operationalModeStatuses[' + operationalModeIndex + ']'}
              stroke={conditionColors[operationalModeIndex % conditionColors.length].light}
              strokeWidth={conOpsConditionStrokeWidth}
              dot={false}
              animationDuration={timing.lineAnimationDuration}
            />,
            <Line // Bright active condition lines for extra steps
              yAxisId={baseIndex - conditionIndex - 1}
              type="monotone"
              dataKey={'operationalModeStatusesExtra[' + operationalModeIndex + ']'}
              stroke={conditionColors[operationalModeIndex % conditionColors.length].light}
              strokeWidth={conOpsConditionStrokeWidth}
              dot={false}
              animationDuration={timing.lineAnimationDuration}
            />,
          ];
        }),
    ];
  };

  // Animation doesn't work without this
  // Memoized for slightly better performance
  const _data = useMemo(
    () => [...data],
    //eslint-disable-next-line
    [data, domain]
  );

  return (
    <ResponsiveContainer width="100%" height={totalElements * 23 + 100}>
      <LineChart
        data={_data}
        margin={{ top: 30, right: 10, left: 100, bottom: 0 }}
        {...mouseEvents}
      >
        <CartesianGrid stroke={theme.palette.background.light} horizontal={false} vertical={true} />
        <XAxis
          dataKey="times.mjd"
          stroke={theme.palette.background.contrastText}
          height={70}
          tickFormatter={() => 'MM-DD-YYYY'} // Necessary to fix misaligned lables on X-axis
          tick={<GregorianTickFormatter julian />}
          tickCount={8}
          interval="preserveStartEnd"
          type="number"
          scale="auto"
          domain={domain}
          allowDataOverflow
        >
          <Label
            value="Time (UTC)"
            position="insideBottom"
            fill={theme.palette.background.contrastText}
            style={{ textAnchor: 'middle' }}
          />
        </XAxis>
        {operationalModeList.map((operationalMode, operationalModeIndex) =>
          renderOperationalModeLines(
            operationalMode,
            operationalModeList,
            operationalModeIndex,
            totalElements
          )
        )}
        <YAxis
          stroke={theme.palette.background.contrastText}
          yAxisId="left" //labelsAxis
          type="number"
          interval="preserveStartEnd"
          minTickGap={0}
          domain={[0, totalElements]}
          allowDataOverflow
          width={70}
          ticks={conOpsTicks.sort((a, b) => a - b)}
          tick={
            <ConOpsTickFormatter
              labelBodies={conOpsLabelBodies}
              labelHeadings={conOpsLabelHeadings}
              labelPriorities={conOpsLabelPriorities}
              labelCircleColors={conOpsLabelCircleColors}
              opModeFlags={conOpsLabelOpModeFlags}
            />
          }
        />
        <YAxis stroke="none" yAxisId="opModeAxis" domain={[-1, 0]} allowDataOverflow hide />
        <Line
          yAxisId="opModeAxis"
          type="monotone"
          dataKey="activeOpMode"
          stroke="none"
          dot={false}
          animationDuration={timing.lineAnimationDuration}
        />
        ,
        <Tooltip
          contentStyle={{
            backgroundColor: 'none',
            borderStyle: 'none',
          }}
          labelFormatter={(mjd) => mjd2Moment(mjd).format(dateFormatLong)}
          formatter={(value, name) => [
            name === 'activeOpMode'
              ? operationalModeList?.filter((opMode) => opMode.id === value)[0]?.name
              : undefined,
            name === 'activeOpMode' ? 'Active Op Mode' : undefined,
          ]}
          itemSorter={(item) => item.name}
          separator={undefined}
          isAnimationActive={false}
          position={{ y: 0 }}
          offset={0}
          itemStyle={{ color: theme.palette.background.contrastText, fontSize: '0.85em' }}
          labelStyle={{ fontSize: '0.85em' }}
          cursor={{ stroke: theme.palette.background.contrastText }}
          allowEscapeBoxView={{ x: true, y: true }}
        />
        {children}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default withZoom(ConOpsTimeSeriesChart, { defaultHeight: 290 });
