import { TBlockId } from 'components/general/types';

interface INode {
  id: TBlockId;
  targets: TBlockId[];
  sending: boolean | ((targetId: TBlockId) => boolean);
}

interface ITitleAndSubTitle {
  title: string;
  subtitle: string;
}

// ============================================ Sensor Node ============================================
export interface IConstraint {
  name: string;
  compliant: boolean;
}

export interface ISensorNodeData {
  data: {
    keepIns: IConstraint[];
    keepOuts: IConstraint[];
  } & ITitleAndSubTitle;
}

export interface ISensorNode extends INode, ISensorNodeData {}

// ============================================= ALgo Node =============================================
export interface IAlgoNodeData {
  data: ITitleAndSubTitle;
}

export interface IAlgoNode extends INode, IAlgoNodeData {}

// ========================================= Sliding Mode Node =========================================
export interface ISlidingModeNodeData {
  data: ITitleAndSubTitle;
}

export interface ISlidingModeNode extends INode, ISlidingModeNodeData {}

// =========================================== Actuator Node ===========================================

export const ACTUATOR_REACTION_WHEEL = 'Reaction Wheel';
export const ACTUATOR_MAGNETORQUER = 'Magnetorquer';
export const MOMENTUM = 'Momentum';
export const TORQUE = 'Torque';
export const MAGNETIC_MOMENT = 'Magnetic Moment';
export const DUTY_CYCLE = 'Duty Cycle';

interface IReactionWheelNodeData {
  data: {
    title: string;
    subtitle: string;
    type: typeof ACTUATOR_REACTION_WHEEL;
    percentages: {
      [key in typeof MOMENTUM | typeof TORQUE]: number;
    };
  };
}

interface IReactionWheelNode extends INode, IReactionWheelNodeData {}
interface IMagnetorquerNodeData {
  data: {
    title: string;
    subtitle: string;
    type: typeof ACTUATOR_MAGNETORQUER;
    percentages: {
      [key in typeof MAGNETIC_MOMENT]: number;
    };
  };
}
interface IMagnetorquerNode extends INode, IMagnetorquerNodeData {}

export type IActuatorNodeData = IReactionWheelNodeData | IMagnetorquerNodeData;
export type IActuatorNode = IReactionWheelNode | IMagnetorquerNode;

// =============================================== Edges ===============================================
export type IAllNodes = ISensorNode | IAlgoNode | ISlidingModeNode | IActuatorNode;
