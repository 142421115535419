const thermalChunk = 'Specify the maximum and minimum temperatures.';
const thermalHeading = 'Thermal Properties';
const genericThermalGuidance = {

  hotTempRating: {
    heading: thermalHeading,
    body: [
      {
        chunk:
        thermalChunk,
      },
    ],
  },
  coldTempRating: {
    heading: thermalHeading,
    body: [
      {
        chunk:
        thermalChunk,
      },
    ],
  },
  };
export default genericThermalGuidance;