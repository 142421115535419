import { useEffect, useMemo, useCallback } from 'react';
import { IControlState, ITempController } from 'components/general/types/thermal';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm, useEntityDialogControl, useActiveEntities } from 'hooks';
import { TempControllerVables } from 'utils/vable';
import InputAdornment from '@material-ui/core/InputAdornment';
import { PowerAccent, ThermalAccent } from 'components/general/Accent/variants';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { ISelectOption, IColumn, TDialogConfig } from 'components/general/types';
import WidgetTable from 'components/general/widgets/WidgetTable';
import validation from './validation';
import { useGuidance } from './guidance';
import ControlStateDialog from './ControlStatesDialog';
import getThermalProps from 'hooks/getThermalProps';
import { translateIn, translateOut } from 'utils/forms';

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  powerSource: ISelectOption | '';
  controlledComponent: ISelectOption | '';
  componentType: ISelectOption | '';
  onRegHeatFlowRate: number | '';
  efficiency: number | '';
  tempControllerStates?: IControlState[];
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  powerSource: '',
  controlledComponent: '',
  componentType: '',
  onRegHeatFlowRate: '',
  efficiency: '',
};

const controlStateColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Temperature',
    render: (row) => `${row.constantTemperature}°C`,
  },
];

interface IProps {
  control: TEntityDialogControl<ITempController>;
}
const TempControllerDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { entity: tempController, action },
    setDialogConfig,
  } = control;

  const { components, subsystems, model, busRegulators, powerProcessor } = useActiveEntities();
  const tempControllerStates = tempController?.tempControllerStates;

  // This useEffect re-opens the dialog for the same load state after edit/create/delete of a load => rerenders dialog,
  // otherwise the new control state does not show up in the list of control states
  useEffect(() => {
    setDialogConfig((prev: TDialogConfig<ITempController>) => {
      if (prev.open && prev.entity && prev.action === 'edit')
        return {
          open: true,
          action: 'edit',
          entity: model.Component.byId(prev.entity.id) as ITempController,
        };
      return prev;
    });
  }, [tempControllerStates, model, setDialogConfig]);

  // Set up styles
  const classes = useStyles();

  const options = useMemo(() => {
    return {
      componentType: TempControllerVables.ComponentType.options,
      controlledComponent: components
        ?.filter((c) => c != null && c.componentType !== 'COOLER' && c.componentType !== 'HEATER')
        .map((c) => {
          return { value: c.id, label: c.name };
        }),
      powerSource: [{ value: powerProcessor.id, label: powerProcessor.name }].concat(
        busRegulators.map((br) => {
          return { value: br.id, label: br.name };
        })
      ),
    };
  }, [components, busRegulators, powerProcessor]);

  const customTranslateIn = useCallback(
    (tempController, defaultValues, options, datetimes, percentages) => {
      // Power source can be powerProcessor or busRegulator
      tempController.powerSource = tempController.busRegulator || tempController.powerProcessor;
      return translateIn(tempController, defaultValues, options, datetimes, percentages);
    },
    []
  );

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options, datetimes, percentages) => {
      const result = translateOut(values, allowedEmptyFields, options, datetimes, percentages);

      // Power source can be powerProcessor or busRegulator
      if (result.powerSource === powerProcessor.id) {
        result.powerProcessor = result.powerSource;
        result.busRegulator = null;
      } else {
        result.busRegulator = result.powerSource;
        result.powerProcessor = null;
      }
      delete result.powerSource;

      return result;
    },
    [powerProcessor]
  );

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const entityForm = useEntityForm<ITempController, IForm & typeof thermalDefaultValues>({
    entityTypeText: 'Temperature Controller',
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: { subsystem: subsystems.find((s) => s.category === 'THERMAL')?.id },
    validationSchema: validation,
    editAfterCreate: true,
    formikOptionalParams: {
      useGuidance,
      options,
      percentages: ['efficiency'],
      allowedEmptyFields: ['manufacturer', 'partNumber'],
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });

  // Set up config for control states dialog
  const associatedStates = useMemo(() => tempControllerStates || [], [tempControllerStates]);
  const controlStateDialogControl = useEntityDialogControl<IControlState>();
  const { openDialogForExisting, openDialogForNew } = controlStateDialogControl;

  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Temperature Controller Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <PowerAccent header="Power Source">
            <LabeledSelect {...getFieldProps('powerSource')} options={options.powerSource} />
          </PowerAccent>
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('componentType')}
            label="Temperature Controller Type"
            options={options.componentType}
          />
          {typeof values.componentType === 'object' &&
            values.componentType === TempControllerVables.ComponentType.COOLER && (
              <div className={classes.indent}>
                <LabeledInput
                  {...getFieldProps('onRegHeatFlowRate')}
                  type="number"
                  endAdornment={<InputAdornment position="end">W</InputAdornment>}
                  label="Cooling Rate"
                />
                <LabeledInput
                  {...getFieldProps('efficiency')}
                  label="Efficiency"
                  type="number"
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </div>
            )}
          {typeof values.componentType === 'object' &&
            values.componentType === TempControllerVables.ComponentType.HEATER && (
              <div className={classes.indent}>
                <LabeledInput
                  {...getFieldProps('onRegHeatFlowRate')}
                  type="number"
                  endAdornment={<InputAdornment position="end">W</InputAdornment>}
                  label="Heating Rate"
                />
              </div>
            )}
          <LabeledSelect
            {...getFieldProps('controlledComponent')}
            label="Controlled Component"
            options={options.controlledComponent}
          />
        </div>
        <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
      </div>
      {action === 'create' ? (
        <p>Create a temperature controller to associate controllers to control states.</p>
      ) : (
        <div className={classes.inputGroup}>
          <ThermalAccent header="Control States">
            <WidgetTable
              columns={controlStateColumns}
              data={associatedStates}
              modelName="control state"
              onActionClick={openDialogForExisting}
              onFabClick={openDialogForNew}
            />
          </ThermalAccent>
        </div>
      )}
      {controlStateDialogControl.dialogConfig.open && (
        <ControlStateDialog tempController={tempController} control={controlStateDialogControl} />
      )}
    </EntityDialog>
  );
};

export default TempControllerDialog;
