import { Fragment, useState, useContext } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import { Alert, AlertTitle } from '@material-ui/lab';
import useStyles from './styles.ts';
import { useSnackbar } from 'hooks';
import { ActiveBranchContext } from 'providers';
import { useDispatch } from 'react-redux';
import { SatelliteApi } from 'middleware/SatelliteApi/api';

const DeleteEntityDialog = (props) => {
  const { entity, action, entityTypeText, setup, onClose, open, childEntities, onSuccess } = props;

  const {
    MissionVersion: {
      actions: { invalidateSimulation },
    },
  } = SatelliteApi;

  const dispatch = useDispatch();

  const { branch } = useContext(ActiveBranchContext);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const handleDelete = () => {
    setLoading(true);
    if (setup) {
      setup();
    }
    dispatch(
      action({
        branchId: branch.id,
        id: entity.id,
        entity,
        successCallback: () => {
          branch && dispatch(invalidateSimulation(branch.id));
          setErrorMessage(null);
          setLoading(false);
          onClose();
          enqueueSnackbar(`${entityTypeText} deleted successfully`, {
            variant: 'success',
          });
          if (onSuccess) {
            onSuccess();
          }
        },
        failureCallback: (response) => {
          setErrorMessage(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  return (
    <Fragment>
      <Dialog
        prompt={`Delete ${entityTypeText.toLowerCase()}`}
        submitActionText="Delete"
        open={open}
        onSubmit={handleDelete}
        onClose={() => {
          setErrorMessage(null);
          onClose();
        }}
        loading={loading}
      >
        {entity && (
          <p>
            Are you sure you'd like to delete {entityTypeText}: '{entity.name}'? This cannot be
            undone.
          </p>
        )}
        <p className={classes.note}>
          <strong>Note:</strong> Deleting this entity will simultaneously delete
          {childEntities && ` all related child entities including: ${childEntities}, and`} all
          related simulation data.
        </p>
        {errorMessage && (
          <Alert severity="error" icon={false} variant="outlined" className={classes.error}>
            <AlertTitle className={classes.errorHeading}>Deletion Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </Dialog>
    </Fragment>
  );
};

export default DeleteEntityDialog;
