import { useCallback } from 'react';
import { InputAdornment } from '@material-ui/core';
import { useActiveEntities, useEntityForm } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { translateIn, translateOut } from 'utils/forms';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { IBatteryCell } from 'components/general/types/power';
import validation from './validation';
import useGuidance from './guidance';
import CurvePointInputs from './CurvePointInputs';
import { round } from 'lodash';

export interface IForm {
  manufacturer: string;
  partNumber: string;
  capacity: number | '';
  esr: number | '';
  maxChargeCurrent: number | '';
  maxDischargeCurrent: number | '';
  minSoc: number | '';
  curve: { pointsSoc: (number | '')[]; pointsVoc: (number | '')[] };
}

const defaultValues: IForm = {
  manufacturer: '',
  partNumber: '',
  capacity: '',
  esr: '',
  maxChargeCurrent: '',
  maxDischargeCurrent: '',
  minSoc: '',
  curve: { pointsSoc: ['', ''], pointsVoc: ['', ''] },
};

interface IProps {
  control: TEntityDialogControl<IBatteryCell>;
}

const Dialog = (props: IProps) => {
  const { control } = props;
  const classes = useStyles();
  const { powerProcessor } = useActiveEntities();

  const customTranslateIn = useCallback(
    (batteryCell, defaultValues, options, datetimes, percentages) => {
      if (!Object.prototype.hasOwnProperty.call(batteryCell.curve, 'pointsSoc')) {
        // converting curve from 2d array in database to object to simplify validation and to ensure that correct inputs are highlighted when they're invalid
        batteryCell.curve = {
          pointsSoc: batteryCell.curve[0].map((point: number) => round(point * 100, 3)),
          pointsVoc: batteryCell.curve[1].map((point: number) => point),
        };
      }
      return translateIn(batteryCell, defaultValues, options, datetimes, percentages);
    },
    []
  );

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options, datetimes, percentages) => {
      values.curve = [
        values.curve.pointsSoc.map((point: number) => point / 100),
        values.curve.pointsVoc.map((point: number) => point),
      ];
      return translateOut(values, allowedEmptyFields, options, datetimes, percentages);
    },
    []
  );

  const entityForm = useEntityForm<IBatteryCell, IForm>({
    entityTypeText: 'Battery Cell',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    additionalCreateValues: { powerProcessor: powerProcessor?.id },
    formikOptionalParams: {
      useGuidance,
      percentages: ['minSoc'],
      allowedEmptyFields: ['manufacturer', 'partNumber'],
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput label="Part Number" {...getFieldProps('partNumber')} autoFocus />
          <LabeledInput label="Manufacturer" optional {...getFieldProps('manufacturer')} />
          <LabeledInput
            label="Capacity"
            type="number"
            endAdornment={<InputAdornment position="end">Ah</InputAdornment>}
            {...getFieldProps('capacity')}
          />
          <LabeledInput
            label="Equivalent Series Resistance"
            type="number"
            endAdornment={<InputAdornment position="end">Ω</InputAdornment>}
            {...getFieldProps('esr')}
          />
          <LabeledInput
            label="Maximum Charge Current"
            type="number"
            endAdornment={<InputAdornment position="end">A</InputAdornment>}
            {...getFieldProps('maxChargeCurrent')}
          />
          <LabeledInput
            label="Maximum Discharge Current"
            type="number"
            endAdornment={<InputAdornment position="end">A</InputAdornment>}
            {...getFieldProps('maxDischargeCurrent')}
          />
          <LabeledInput
            label="Minimum State of Charge"
            type="number"
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            {...getFieldProps('minSoc')}
          />
        </div>
        <div className={classes.inputGroup}>
          <h5>Points on SoC vs Voc curve</h5>
          <CurvePointInputs formik={formik} />
        </div>
      </div>
    </EntityDialog>
  );
};

export default Dialog;
