import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IUser } from 'components/general/types/general';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { IErrorResponse, IGenericObject } from 'components/general/types';
import { useFormikForm, useSnackbar } from 'hooks';
import * as Yup from 'yup';
import Dialog from 'components/general/dialogs/Dialog';
import LabeledInput from 'components/general/inputs/LabeledInput';
import { Grid } from '@material-ui/core';
import GuidanceCard from 'components/general/GuidanceCard';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import CollaborationDeleteDialog from './CollaborationDeleteDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { EMAIL_REGEX } from 'config';

interface IProps {
  repoId: number;
  control: TEntityDialogControl<IUser>;
}

const collaboratorsSchema = Yup.object().shape({
  collaborators: Yup.string()
    .required('One or more emails are required.')
    .test(
      'Test email validity',
      'All email addresses must be in a valid format.',
      (collaborators) => {
        const emails = collaborators?.replace(/\s/g, '').split(',');
        if (emails) {
          for (const email of emails) {
            if (!email.match(EMAIL_REGEX)) {
              return false;
            }
          }
        }
        return true;
      }
    )
    .test('Uniqueness test', 'Emails must be unique.', (collaborators) => {
      const emails = collaborators?.replace(/\s/g, '').split(',') || [];
      const emailsSet = new Set(emails.map((e) => e.toLowerCase()));
      return emails?.length === emailsSet.size;
    }),
});

interface ICollaboratorForm {
  collaborators: string[];
}

const defaultValues: ICollaboratorForm = {
  collaborators: [''],
};

const CollaboratorDialog = (props: IProps) => {
  const { control, repoId } = props;
  const [loading, setLoading] = useState(false);
  const { dialogConfig, closeDialog } = control;

  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const {
    Mission: {
      actions: { inviteCollaborator },
    },
  } = SatelliteApi;

  const addCollaborators = (values: { collaborators: string[] }) => {
    setLoading(true);
    dispatch(
      inviteCollaborator({
        ...values,
        repository: repoId,
        successCallback: (response: IGenericObject) => {
          closeDialog();
          enqueueSnackbar('Invitation emails were successfully sent to collaborators', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  const customTranslateOut = useCallback((values) => {
    // convert emails from string into an array of emails, which is what the backend expects
    const emails = values.collaborators?.replace(/\s/g, '').split(',');
    return { collaborators: emails };
  }, []);

  const { formik } = useFormikForm<ICollaboratorForm, ICollaboratorForm>(
    defaultValues,
    addCollaborators,
    collaboratorsSchema,
    defaultValues,
    { translateOut: customTranslateOut }
  );

  const { handleSubmit, getFieldProps, resetForm } = formik;

  // use Effect to reset form whenever dialog is closed
  useEffect(() => {
    if (!dialogConfig.open) {
      resetForm();
    }
  }, [dialogConfig.open, resetForm]);

  if (dialogConfig.action === 'delete') {
    return (
      <CollaborationDeleteDialog onClose={closeDialog} config={dialogConfig} repoId={repoId} />
    );
  }

  return (
    <Dialog
      open={dialogConfig.open}
      prompt="Add collaborators to the repository"
      onClose={closeDialog}
      submitActionText="Invite"
      onSubmit={handleSubmit}
      large
      loading={loading}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={5} className={classes.swapRight}>
          <div className={classes.inputs}>
            <div className={classes.inputGroup}>
              <LabeledInput
                {...getFieldProps('collaborators')}
                label="Collaborators"
                multiline
                rows={4}
                type="text"
                placeholder="Collaborators"
                autoFocus
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={7} className={classes.swapLeft}>
          <GuidanceCard
            guidance={{
              heading: 'Collaborators',
              body: `Enter email addresses of desired collaborators on this repository (separated by a comma).
              Collaborators will be required to login or create an account to accept the invitation.
              After accepting, collaborators will be displayed in the table and will be able to create new branches under this repository.`,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CollaboratorDialog;
