import ViewContainer from 'components/general/ViewContainer';
import Nav from 'components/general/Nav';
import LoadingView from 'components/LoadingView';
import ViewPort from 'components/general/ViewPort';
import mdLogo from 'multimedia/icons/md.png';
import EditBoard from './EditBoard';
import AnalyzeBoards from './AnalyzeBoards';
import { useActiveEntities, useLatestJob } from 'hooks';
import SimulationControls from 'components/general/SimulationControls';
import { DataProvider } from 'providers';
import WGroup from 'components/general/WGroup';
import { wGroupIndicesScenario } from './menu';
import ViewPortInlay from 'components/general/ViewPortInlay';
import AnalyticsProvider from 'providers/AnalyticsProvider';

const ScenarioView = () => {
  const { branch } = useActiveEntities();
  const latestJob = useLatestJob();

  if (!branch.id || !latestJob) return <LoadingView message="Sedaro is loading..." />;

  return (
    <DataProvider>
      <ViewContainer>
        <Nav contextName="Scenario" contextImg={mdLogo} control={<SimulationControls />}></Nav>
        <ViewPort minWidth={650}>
          <EditBoard />
          <AnalyticsProvider>
            {
              // If data is here, show analyze boards
              // Don't unmount when moving to edit board to minimize cesium latency
              branch.analyzeState?.dataState ? (
                <AnalyzeBoards />
              ) : (
                // Otherwise, show placeholders
                <WGroup index={wGroupIndicesScenario.ANALYZE}>
                  <ViewPortInlay text="Fetch a window of data to view analytics" />
                </WGroup>
              )
            }
          </AnalyticsProvider>
        </ViewPort>
      </ViewContainer>
    </DataProvider>
  );
};

export default ScenarioView;
