import Routes from 'routes';
import { ITEMS } from 'config';
import { IMenu } from 'components/general/types';
// import agentTargetsMenu from './targets';
import agentCdhMenu from './cdh';
import agentGncMenu from './gnc';
import agentPowerMenu from './power';
import agentThermalMenu from './thermal';
import agentCommsMenu from './comms';
import agentCustomMenu from './custom';

const AgentAnalyzeMenu: IMenu = {
  routeFn: Routes.AGENT_ANALYZE,
  dynamicItems: [],
  [ITEMS]: {
    // ...agentTargetsMenu,
    ...agentCdhMenu,
    ...agentGncMenu,
    ...agentPowerMenu,
    ...agentThermalMenu,
    ...agentCommsMenu,
    ...agentCustomMenu,
  },
};

export const wGroupIndicesAgentAnalyze = {};

export default AgentAnalyzeMenu;
