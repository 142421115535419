import { useMemo, useContext } from 'react';
import Widget from 'components/general/widgets/Widget';
import ThermalMap from './ThermalMap';
import { MomentContext } from 'providers';
import { isCooler } from '../../thermaldata';
import { IComponent } from 'components/general/types/power';
import { IThermalInterface } from 'components/general/types/thermal';
import { TCompiledModel } from 'middleware/SatelliteApi/template';
import { IGenericObject } from 'components/general/types';
import { ISurface } from 'components/general/types/spacecraft';

interface INode {
  id: string;
  type?: string;
  data?: { label: string };
  style: IGenericObject;
  parentNode?: string;
  ignoreLayout?: boolean;
  extent?: string;
  position?: { x: number; y: number };
}

interface IEdge {
  id: string;
  source?: string;
  target?: string;
  label?: string;
  sourceHandle?: string;
  targetHandle?: string;
  type?: string;
  zIndex?: number;
  hidden?: boolean;
  animated?: boolean;
  data?: IThermalInterface;
}

const defaultNodeStyles = {
  backgroundColor: 'white',
  width: 80,
  height: 50,
  lineHeight: '10px',
  textAlign: 'center',
};

const toNodes = (e: IComponent | ISurface): INode[] => {
  const nodes: INode[] = [
    {
      id: e.id.toString(),
      data: { label: e.name },
      type: 'temp',
      style: {
        ...defaultNodeStyles,
      },
    },
  ];
  if (isCooler(e)) {
    nodes[0].id = nodes[0].id + '-group';
    nodes[0].type = 'temp-group';
    nodes[0].style.width = defaultNodeStyles.width * 2 + 30;
    nodes[0].style.paddingTop = 40;
    const defaultCoolerStyles = {
      parentNode: nodes[0].id,
      extent: 'parent',
      draggable: false,
      style: {
        ...defaultNodeStyles,
        height: 30,
      },
    };
    nodes[0].style.height = defaultCoolerStyles.style.height + 20 + nodes[0].style.paddingTop;
    nodes.push({
      id: e.id.toString(),
      data: { label: 'Sink' },
      position: { x: 10, y: 10 },
      ignoreLayout: true,
      type: 'child',
      ...defaultCoolerStyles,
    });
    nodes.push({
      id: e.id.toString() + '-cool',
      data: { label: 'Regulated' },
      position: { x: 20 + defaultNodeStyles.width, y: 10 },
      ignoreLayout: true,
      type: 'child',
      ...defaultCoolerStyles,
    });
  }
  return nodes;
};

const toEdges = (e: IThermalInterface, map: { [id: number]: IComponent | ISurface }): IEdge[] => {
  const sideA =
    e.componentA?.id.toString() || e.surfaceA?.id.toString() || e.coolerA?.id.toString();
  const sideB =
    e.componentB?.id.toString() || e.surfaceB?.id.toString() || e.coolerB?.id.toString();
  const sideACooler = sideA && isCooler(map[parseInt(sideA)]);
  const sideBCooler = sideB && isCooler(map[parseInt(sideB)]);

  const edges: IEdge[] = [
    {
      id: e.id.toString(),
      source: e.coolerA
        ? e.coolerA.id + '-cool'
        : e.componentA?.id.toString() || e.surfaceA?.id.toString(),
      target: e.coolerB
        ? e.coolerB.id + '-cool'
        : e.componentB?.id.toString() || e.surfaceB?.id.toString(),
      label: e.name,
      animated: true,
      type: 'floating',
      zIndex: 10000,
      data: e,
    },
  ];
  if (sideACooler || sideBCooler) {
    edges.push({
      id: e.id.toString(),
      source: sideACooler ? sideA + '-group' : sideA,
      target: sideBCooler ? sideB + '-group' : sideB,
      hidden: true,
    });
  }
  return edges;
};

const max = 400;

interface IProps {
  model?: TCompiledModel;
  noWidget?: boolean;
  editable?: boolean;
}
const ThermalStateWidget = (props: IProps) => {
  let { model } = useContext(MomentContext);
  model = props.model || model;

  const components = model.Component.all() as IComponent[];
  const interfaces = model.ThermalInterface.all() as unknown as IThermalInterface[];
  const surfaces = model.Surface.all() as ISurface[];

  const t: { [id: string]: number } = useMemo(() => {
    const x: { [id: string]: number } = {};
    components.forEach((e) => {
      const temp = typeof e.temperature === 'number' ? e.temperature : e.temperature.celsius;
      x[e.id] = temp;
    });
    surfaces.forEach((e) => {
      const temp = typeof e.temperature === 'number' ? e.temperature : e.temperature.celsius;
      x[e.id] = temp;
    });
    return x;
  }, [components, surfaces]);

  const [n, map] = useMemo(() => {
    const map = [...components, ...surfaces].reduce(
      (prev: { [id: string]: IComponent | ISurface }, curr) => {
        prev[curr.id] = curr;
        return prev;
      },
      {}
    );
    return [[...components.flatMap(toNodes), ...surfaces.flatMap(toNodes)], map];
  }, [components, surfaces]);

  const e = useMemo(() => {
    return interfaces.flatMap((i) => toEdges(i, map));
  }, [interfaces, map]);

  return (
    <>
      {props.noWidget ? (
        <ThermalMap nodes={n} edges={e} temps={t} max={max} editable={props.editable} />
      ) : (
        <Widget
          title="Thermal State"
          subtitle="State of Components, Temp Controllers, and Interfaces"
          collapsibleConfig
        >
          <ThermalMap nodes={n} edges={e} temps={t} max={max} editable={props.editable} />
        </Widget>
      )}
    </>
  );
};

export default ThermalStateWidget;
