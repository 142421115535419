import { useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ILocationState, IGenericObject, IErrorResponse } from 'components/general/types';
import AuthWidget from 'components/general/widgets/AuthWidget';
import WaveContainer from 'components/general/WaveContainer';
import LabeledPasswordInput from 'components/general/inputs/LabeledInput/LabeledPasswordInput.jsx';
import StyledButton from 'components/general/StyledButton';

import { useFormikForm, useSnackbar } from 'hooks';
import * as Yup from 'yup';
import Routes, { routePathsCommon } from 'routes';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useDispatch } from 'react-redux';
import { SUPPORT_EMAIL } from 'config';

const errMsg = `An error occured in getting this branch's data. Please try again and contact Sedaro at ${SUPPORT_EMAIL} if needed.`;

const { AGENT_ANALYZE, AGENT_TEMPLATE_EDIT, SCENARIO } = routePathsCommon;

const defaultValues = {
  password: '',
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required('A password input is required.'),
});

const ShareAuthView = () => {
  const history = useHistory();
  const location = useLocation<ILocationState>();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { MissionVersion } = SatelliteApi;

  const [loading, setLoading] = useState(false);

  const { pathname, search } = location?.state?.from || { pathname: '', search: '' };

  const cameFromABranch = [AGENT_ANALYZE, AGENT_TEMPLATE_EDIT, SCENARIO].some((path) =>
    pathname.includes(path)
  );

  if (!cameFromABranch || !search) history.replace(Routes.ROOT());

  const branchId = Number(pathname.split('/')[2]);

  const onSubmit = ({ password }: { password: string }) => {
    setLoading(true);
    dispatch(
      MissionVersion.actions.checkSharePw({
        id: branchId,
        password,
        successCallback: (response: IGenericObject) => {
          setLoading(false);
          enqueueSnackbar(response.message, { variant: 'success' });
          history.replace(pathname + search);
        },
        failureCallback: (response: IErrorResponse) => {
          setLoading(false);
          setFieldValue('password', '');
          enqueueSnackbar(response.error?.message || errMsg);
        },
      })
    );
  };

  const { formik } = useFormikForm(defaultValues, onSubmit, validationSchema, defaultValues);
  const { getFieldProps, handleSubmit, setFieldValue } = formik;

  return (
    <WaveContainer>
      <AuthWidget>
        <form onSubmit={handleSubmit}>
          <p>
            Enter URL password. After access is granted, you will periodically be prompted to
            re-enter the password.
          </p>
          <LabeledPasswordInput autoFocus label="URL Password" {...getFieldProps('password')} />
          <StyledButton type="submit" disabled={loading} loading={loading} tallMargin fullWidth>
            Submit
          </StyledButton>
        </form>
      </AuthWidget>
    </WaveContainer>
  );
};

export default ShareAuthView;
