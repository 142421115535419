import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ViewContainer from 'components/general/ViewContainer';
import Nav from 'components/general/Nav';
import ViewPort from 'components/general/ViewPort';
import StyledButton from 'components/general/StyledButton';
import AddIcon from '@material-ui/icons/Add';
import RepoDialog from 'components/RootView/RepoDialog';
import { useEntityDialogControl, useSelectTotalNum } from 'hooks';
import RepoExplorer from './RepoExplorer';
import { IMission } from 'components/general/types';
import {
  setActiveMissionVersionId,
  setLatestJobId,
} from 'components/RootView/missionExplorerSlice';

const RootView = () => {
  const numRepos = useSelectTotalNum('Mission');
  const dispatch = useDispatch();

  const repoDialogControl = useEntityDialogControl<IMission>();
  const { openDialogForNew } = repoDialogControl;

  // Unset active mission version ID whenever user navigates to repos. This unsets the nav bar.
  // Unset latest job ID so ActiveBranch provider knows to fetch a new one when switching between repos.
  useEffect(() => {
    dispatch(setActiveMissionVersionId(undefined));
    dispatch(setLatestJobId(undefined));
  }, [dispatch]);

  return (
    <ViewContainer>
      <Nav>
        <p>Create {numRepos > 0 && 'or select'} a repository in the Repository Explorer</p>
        <StyledButton type="button" onClick={openDialogForNew} fullWidth midMargin>
          <AddIcon /> New Repository
        </StyledButton>
      </Nav>
      <ViewPort>
        <RepoExplorer repoDialogControl={repoDialogControl} />
      </ViewPort>
      <RepoDialog control={repoDialogControl} />
    </ViewContainer>
  );
};

export default RootView;
