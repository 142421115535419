import * as Yup from 'yup';
import getThermalProps from 'hooks/getThermalProps';
import { SensorVables } from 'utils/vable';
import { ISelectOption } from 'components/general/types';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  name: Yup.string().required('A sensor name is required.'),
  manufacturer: Yup.string(),
  partNumber: Yup.string(),
  componentType: Yup.object().required('Select a sensor type.'),
  referenceVector: Yup.object().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === SensorVables.ComponentType.DIRECTION_SENSOR.value ||
      componentType?.value === SensorVables.ComponentType.VECTOR_SENSOR.value,
    then: Yup.object().required('Reference vector is required.'),
  }),
  oneSigmaAngleError: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === SensorVables.ComponentType.DIRECTION_SENSOR.value,
    then: Yup.number()
      .required('One Sigma Angle Error is required.')
      .min(0, 'One Sigma Angle Error must be non-negative.'),
  }),
  oneSigmaCrossAxisError: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === SensorVables.ComponentType.OPTICAL_ATTITUDE_SENSOR.value,
    then: Yup.number().required('One Sigma Cross-Axis Error is required.'),
  }),
  oneSigmaBoresightAxisError: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === SensorVables.ComponentType.OPTICAL_ATTITUDE_SENSOR.value,
    then: Yup.number().required('One Sigma Boresight-Axis Error is required.'),
  }),
  oneSigmaPerAxisError: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === SensorVables.ComponentType.VECTOR_SENSOR.value ||
      componentType?.value === SensorVables.ComponentType.ANGULAR_VELOCITY_SENSOR.value,
    then: Yup.number().required('One Sigma Per-Axis Error is required.'),
  }),
  oneSigmaDistanceError: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === SensorVables.ComponentType.POSITION_SENSOR.value,
    then: Yup.number()
      .required('One Sigma Distance Error is required.')
      .min(0, 'One Sigma Distance Error must be non-negative.'),
  }),
  fieldOfView: Yup.object()
    .nullable()
    .when('componentType', {
      is: (componentType: ISelectOption) =>
        componentType?.value === SensorVables.ComponentType.OPTICAL_ATTITUDE_SENSOR.value,
      then: Yup.object().required('A field of view is required for optical attitude sensors.'),
    }),
  ...thermalValidation,
});

export default validation;
