import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from 'theme';
import { ellipsesOverflow } from '../../general/sharedStyles';

interface IProps {
  truncateTitleOverflow: boolean;
  truncateSubtitleOverflow: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.dark,
    border: `1px solid ${theme.palette.background.darkest}`,
    padding: '.25rem',
    borderRadius,
    width: '5rem',
    minHeight: '2rem',
    '& p': {
      fontSize: '0.5rem',
      margin: 0,
    },
    boxShadow: 'rgba(0, 0, 0, 0.3) 3px 3px 3px',
  },
  title: ({ truncateTitleOverflow }: IProps) => ({
    color: theme.palette.text.primary,
    ...(truncateTitleOverflow ? ellipsesOverflow : {}),
  }),
  subtitle: ({ truncateSubtitleOverflow }: IProps) => ({
    color: theme.palette.text.secondary,
    ...(truncateSubtitleOverflow ? ellipsesOverflow : {}),
  }),
}));

export default useStyles;
