import { useMemo } from 'react';
import { TPowerTableDatum } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import { StatsWidget } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general';
import { useActiveEntities } from 'hooks';
import { LoadVables } from 'utils/vable';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { wizardRootStyles } from 'components/general/wizards/Wizard/styles';
import { IComponent, ILoad, ILoadState } from 'components/general/types/power';

const formatValue = (total: { power: number; resistance: number }) => {
  let str = '';
  if (total.power) {
    str += `${total.power.toFixed(2)} W`;
    if (total.resistance !== Infinity) {
      str += ' & ';
    }
  }
  if (total.resistance !== Infinity) {
    str += `${total.resistance.toFixed(2)} Ω`;
  }
  if (str === '') {
    return '-';
  }
  return str;
};

const parallelResistances = (a: number, b: number) => {
  const denom = 1 / a + 1 / b;
  if (denom === 0) return Infinity;
  return 1 / denom;
};

// TODO: In the future, update to show unallocated loads

const SubsystemLoadingSummary = () => {
  const { operationalModes, subsystems } = useActiveEntities();

  const tableData = useMemo(() => {
    const result: TPowerTableDatum[] = [];
    let i = 0;
    for (const opMode of operationalModes) {
      result.push({
        name: opMode.name,
        hierarchy: 1,
      });
      const opModeTotal = { power: 0, resistance: Infinity };
      for (const subsystem of subsystems) {
        // Subsystem aggregation
        const subsystemTotal = subsystem.components.reduce(
          (acc, component: IComponent) => {
            // Component aggregation
            const componentTotal = component?.loadStates.reduce(
              (acc, loadState: ILoadState) => {
                // Load state aggregation
                let loadStateTotal;
                if (loadState && loadState.operationalModes.map((o) => o.id).includes(opMode.id)) {
                  loadStateTotal = loadState.loads.reduce(
                    (acc, load: ILoad) => {
                      // Load aggregation
                      if (load?.loadDefParams) {
                        let dutyCyclePercentage = 1;
                        if (load?.dutyCyclePeriod && load?.dutyCyclePercentage) {
                          dutyCyclePercentage = load.dutyCyclePercentage;
                        }
                        if ('power' in load.loadDefParams) {
                          acc.power += load.loadDefParams.power * dutyCyclePercentage;
                        } else if ('resistance' in load.loadDefParams) {
                          if (load.epsOutputType === LoadVables.EpsOutputType.CORE_OUTPUT.value) {
                            acc.resistance = parallelResistances(
                              acc.resistance,
                              load.loadDefParams.resistance / dutyCyclePercentage
                            );
                          } else {
                            const busRegulator = load.busRegulator;
                            if (busRegulator)
                              acc.power +=
                                (busRegulator.voltage ** 2 / load.loadDefParams.resistance) *
                                dutyCyclePercentage;
                          }
                        }
                      }
                      return acc;
                    },
                    { resistance: Infinity, power: 0 }
                  );
                }
                if (loadStateTotal) {
                  acc.power += loadStateTotal.power;
                  acc.resistance = parallelResistances(acc.resistance, loadStateTotal.resistance);
                }
                return acc;
              },
              { resistance: Infinity, power: 0 }
            );
            if (componentTotal) {
              acc.power += componentTotal.power;
              acc.resistance = parallelResistances(acc.resistance, componentTotal.resistance);
            }
            return acc;
          },
          { resistance: Infinity, power: 0 }
        );
        result.push({
          name: subsystem.name,
          value: formatValue(subsystemTotal),
          hierarchy: 2,
        });
        if (subsystemTotal) {
          opModeTotal.power += subsystemTotal.power;
          opModeTotal.resistance = parallelResistances(
            opModeTotal.resistance,
            subsystemTotal.resistance
          );
        }
      }
      result[i * (subsystems.length + 1)].value = formatValue(opModeTotal);
      i++;
    }
    return result;
  }, [operationalModes, subsystems]);

  const wrapped = useMediaQuery('(max-width: 1863px)', { noSsr: true }); // Update this to use edit board summary component once created

  return (
    <div style={wrapped ? wizardRootStyles : {}}>
      <StatsWidget
        tableData={tableData}
        maxHeight={null}
        widgetTitle="Average Loading by Operational Mode and Subsystem"
        subtitle="Average power and effective resistance (for resistive loading on power controller bus)"
        defaultExpanded={false}
      />
    </div>
  );
};

export default SubsystemLoadingSummary;
