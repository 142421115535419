import { ISelectOption } from 'components/general/types';

type TVable<T extends string> = {
  _keys: string[];
  readonly options: ISelectOption[];
} & Record<T, { label: string; value: string }>;

function createVable<T extends string>(obj: { [key: string]: string }): TVable<T> {
  const vable: TVable<T> = {
    _keys: [] as string[],
    get options() {
      return this._keys.map((o: string) => this[o as T]);
    },
  } as TVable<T>;

  for (const k in obj) {
    //@ts-ignore // wonkiness with Record
    vable[k as T] = {
      label: obj[k],
      value: k,
    };
    vable._keys.push(k);
  }

  return vable;
}

/*============================================================================*/
// VABLE DOCUMENTATION
/*============================================================================*/
/*
**What is it?**
- "value" + "label" = "vable"

**Purpose**
- Render user friendly strings based on CAPITAL_CASE keys:

  `HardwareVables.SubsystemCategories['CDH'].label` --> 'Command & Data Handling'

  Note: typically a variable would be passed in where 'CDH' is. This just shows one case where
  the variable would resolve to 'CDH'.

- Auto create value/label objects for use in forms

  `HardwareVables.SubsystemCategories.options` -->

    [
      { value: 'POWER', label: 'Power' },
      { value: 'CDH', label: 'Command & Data Handling' },
      { value: 'COMMS', label: 'Communications' },
      { value: 'GNC', label: 'Guidance, Navigation, and Control' },
      { value: 'THERMAL', label: 'Thermal' },
      { value: 'CUSTOM', label: 'Custom Subsystem' },
    ]

- Avoid errors in comparing strings:

    if (paramACategory.value === 'POWER') {...}
    vs.
    if (paramACategory.value === HardwareVables.SubsystemCategories.POWER.value) {...}

**Adding new vables**
- Add them below in alphabetical order with commented headings

*/

/*============================================================================*/
// Actuator
/*============================================================================*/
export type TActuatorTypes = 'MAGNETORQUER' | 'REACTION_WHEEL';

const ActuatorType = createVable<TActuatorTypes>({
  MAGNETORQUER: 'Magnetorquer',
  REACTION_WHEEL: 'Reaction Wheel',
});

export const ActuatorVables = {
  ComponentType: ActuatorType,
};

/*============================================================================*/
// Agent
/*============================================================================*/
export type TAgentTypes = 'TEMPLATED' | 'PERIPHERAL';

const AgentType = createVable<TAgentTypes>({
  TEMPLATED: 'Templated',
  PERIPHERAL: 'Peripheral',
});

export const AgentVables = {
  AgentType,
};

/*============================================================================*/
// Algorithm
/*============================================================================*/
export type TAlgorithmTypes =
  | 'ATTITUDE_CONTROL'
  | 'ATTITUDE_DETERMINATION'
  | 'ORBIT_DETERMINATION'
  | 'THRUST_CONTROL';
export type TAttitudeControlTypes = 'PID' | 'SLIDING_MODE';
export type TAttitudeDeterminationTypes = 'AVERAGING' | 'MEKF' | 'TRIAD';
export type TOrbitDeterminationTypes = 'EKF' | 'GPS';
export type TThrustControlTypes = 'STATIC';

const AlgorithmType = createVable<TAlgorithmTypes>({
  ATTITUDE_CONTROL: 'Attitude Control',
  ATTITUDE_DETERMINATION: 'Attitude Determination',
  ORBIT_DETERMINATION: 'Orbit Determination',
  THRUST_CONTROL: 'Thrust Control',
});

const AttitudeControlType = createVable<TAttitudeControlTypes>({
  PID: 'PID',
  SLIDING_MODE: 'Sliding Mode',
});

const AttitudeDeterminationType = createVable<TAttitudeDeterminationTypes>({
  AVERAGING: 'Averaging',
  MEKF: 'MEKF',
  TRIAD: 'Triad',
});

const OrbitDeterminationType = createVable<TOrbitDeterminationTypes>({
  EKF: 'EKF',
  GPS: 'GPS Direct',
});

const ThrustControlType = createVable<TAttitudeControlTypes>({
  STATIC: 'Static',
});

export const AlgorithmVables = {
  AlgorithmType,
  AttitudeControlType,
  AttitudeDeterminationType,
  OrbitDeterminationType,
  ThrustControlType,
};

/*============================================================================*/
// Battery
/*============================================================================*/
export type TConfigurationTypesKeys = 'SERIES' | 'PARALLEL';

const ConfigurationTypes = createVable<TConfigurationTypesKeys>({
  SERIES: 'Series',
  PARALLEL: 'Parallel',
});

export const BatteryVables = {
  ConfigurationTypes,
};

/*============================================================================*/
// Body Frame Vector
/*============================================================================*/
export type TBodyFrameVectorTypesKeys = 'SPHERICAL_ANGLES' | 'VECTOR';

const BodyFrameVectorTypes = createVable<TBodyFrameVectorTypesKeys>({
  SPHERICAL_ANGLES: 'Spherical Angles',
  VECTOR: 'Vector',
});

export const BodyFrameVectorVables = {
  BodyFrameVectorTypes,
};

/*============================================================================*/
// Bus Regulator
/*============================================================================*/
export type TInputTypesKeys = 'EPS_ROOT_NODE' | 'BUS_REGULATOR';

const InputTypes = createVable<TInputTypesKeys>({
  EPS_ROOT_NODE: 'EPS Root Node Output',
  BUS_REGULATOR: 'Bus Regulator Output',
});

export const BusRegulatorVables = {
  InputTypes,
};

/*============================================================================*/
// Component
/*============================================================================*/
export type TComponentTypes =
  | 'GENERIC'
  | 'BATTERY_PACK'
  | 'SOLAR_PANEL'
  | 'POWER_PROCESSOR'
  | 'COOLER'
  | 'REACTION_WHEEL'
  | 'MAGNETORQUER'
  | 'DIRECTION_SENSOR'
  | 'POSITION_SENSOR'
  | 'OPTICAL_ATTITUDE_SENSOR'
  | 'ANGULAR_VELOCITY_SENSOR'
  | 'HEATER'
  | 'COOLER'
  | 'VECTOR_SENSOR';

const ComponentType = createVable<TComponentTypes>({
  GENERIC: 'Component',
  BATTERY_PACK: 'Battery Back',
  SOLAR_PANEL: 'Solar Panel',
  POWER_PROCESSOR: 'Power Processor',
  REACTION_WHEEL: 'Reaction Wheel',
  MAGNETORQUER: 'Magnetorquer',
  DIRECTION_SENSOR: 'Direction Sensor',
  POSITION_SENSOR: 'Position Sensor',
  OPTICAL_ATTITUDE_SENSOR: 'Optical Attitude Sensor',
  ANGULAR_VELOCITY_SENSOR: 'Angular Velocity Sensor',
  HEATER: 'Heater',
  COOLER: 'Cooler',
  VECTOR_SENSOR: 'Vector Sensor',
});

export const ComponentVables = {
  ComponentType,
};

/*============================================================================*/
// Condition
/*============================================================================*/
export type TConditionRelationship =
  | 'GREATER'
  | 'LESS'
  | 'GREATER_EQUAL'
  | 'LESS_EQUAL'
  | 'EQUAL'
  | 'NOT_EQUAL';

const ConditionRelationshipTypes = createVable<TConditionRelationship>({
  GREATER: 'Greater Than ( > )',
  LESS: 'Less Than ( < )',
  GREATER_EQUAL: 'Greater Than or Equal to ( >= )',
  LESS_EQUAL: 'Less Than or Equal to ( <= )',
  EQUAL: 'Equal to ( == )',
  NOT_EQUAL: 'Not Equal to ( != )',
});

export const ConditionVables = {
  ConditionRelationshipTypes,
};

/*============================================================================*/
// Field of View
/*============================================================================*/
export type TFieldOfViewTypes = 'CIRC_FIELD_OF_VIEW' | 'RECT_FIELD_OF_VIEW';

const FieldOfViewTypes = createVable<TFieldOfViewTypes>({
  CIRC_FIELD_OF_VIEW: 'Circular',
  RECT_FIELD_OF_VIEW: 'Rectangular',
});

export const FieldOfViewVables = {
  FieldOfViewTypes,
};

/*============================================================================*/
// Load
/*============================================================================*/
export type TBusDefinitionTypeKeys = 'VOLTAGE' | 'EPS_OUTPUT';

const BusDefinitionType = createVable<TBusDefinitionTypeKeys>({
  VOLTAGE: 'Fixed Voltage',
  EPS_OUTPUT: 'EPS Output',
});

export type TEpsOutputTypeKeys = 'CORE_OUTPUT' | 'BUS_REGULATOR';

const EpsOutputType = createVable<TEpsOutputTypeKeys>({
  CORE_OUTPUT: 'Power Controller Bus',
  BUS_REGULATOR: 'Bus Regulator Output',
});

export type TLoadDefinitionTypeKeys = 'CONSTANT_POWER' | 'CONSTANT_RESISTANCE';

const LoadDefinitionType = createVable<TLoadDefinitionTypeKeys>({
  CONSTANT_POWER: 'Constant Power',
  CONSTANT_RESISTANCE: 'Constant Resistance',
});

export const LoadVables = {
  BusDefinitionType,
  EpsOutputType,
  LoadDefinitionType,
};

/*============================================================================*/
// Modules
/*============================================================================*/
export type TModuleType =
  | 'PLATFORM'
  | 'ENERGY_BALANCE_MODULE'
  | 'TARGETS'
  | 'GNC'
  | 'CDH'
  | 'POWER'
  | 'THERMAL'
  | 'COMMS'
  | 'PAYLOAD';

export type TSimulationStatus =
  | 'READY'
  | 'INCOMPLETE'
  | 'SUCCEEDED'
  | 'RUNNING'
  | 'FAILED'
  | 'ERROR'
  | 'MD_FAILURE'
  | 'PARTIAL_RESULTS'
  | 'OUTDATED'
  | 'PENDING'
  | 'TERMINATED';

export type TCompletedStatus = 'SUCCEEDED' | 'FAILED' | 'ERROR' | 'TERMINATED';

const ModuleTypes = createVable<TModuleType>({
  PLATFORM: 'Sedaro Satellite', // TODO ZACH: remove these two, adn from TModuleType Above
  ENERGY_BALANCE_MODULE: 'Power Module', // TODO ZACH: remove these two TModuleType Above
  TARGETS: 'Targets',
  GNC: 'GNC',
  CDH: 'Command & Control',
  POWER: 'Power',
  THERMAL: 'Thermal',
  COMMS: 'Data Handling',
  PAYLOAD: 'Payload',
});

const SimulationStatuses = createVable<TSimulationStatus>({
  READY: 'Ready',
  INCOMPLETE: 'Incomplete design',
  SUCCEEDED: 'Success',
  RUNNING: 'Running...',
  FAILED: 'Failed',
  ERROR: 'Error',
  MD_FAILURE: 'MD Failed',
  PARTIAL_RESULTS: 'Incomplete simulation',
  OUTDATED: 'Outdated',
  PENDING: 'Waiting...',
  TERMINATED: 'Aborted',
});

const CompletedStatuses = createVable<TCompletedStatus>({
  SUCCEEDED: 'Success',
  FAILED: 'Failed',
  ERROR: 'Error',
  TERMINATED: 'Aborted',
});

export const ModuleVables = {
  ModuleTypes,
  SimulationStatuses,
  CompletedStatuses,
};

/*============================================================================*/
// Orbit
/*============================================================================*/
export type TInitialStateDefTypes = 'ECI_STATE' | 'ORBITAL_ELEMENTS' | 'REF_ORBIT' | 'TLE';
export type TInitialRefOrbitTypes =
  | 'POLAR_CIRC'
  | 'EQUATORIAL_CIRC'
  | 'SUN_SYNC_CIRC'
  | 'GEOSTAT'
  | 'GEOSTAT_TRANSFER'
  | 'ISS';

const InitialStateDefType = createVable<TInitialStateDefTypes>({
  ECI_STATE: 'Position & Velocity',
  ORBITAL_ELEMENTS: 'Orbital Elements',
  REF_ORBIT: 'Reference Orbit',
  TLE: 'Two-Line Element',
});

const InitialRefOrbit = createVable<TInitialRefOrbitTypes>({
  POLAR_CIRC: 'Polar Circular',
  EQUATORIAL_CIRC: 'Equatorial Circular',
  SUN_SYNC_CIRC: 'Sun-Synchronous Circular',
  GEOSTAT: 'Geostationary',
  GEOSTAT_TRANSFER: 'Geostationary Transfer',
  ISS: 'International Space Station',
});

export const OrbitVables = {
  InitialStateDefType,
  InitialRefOrbit,
};

// Pointing Modes
/*============================================================================*/

export type TPointingModeType = 'PASSIVE' | 'MAX_SECONDARY_ALIGN' | 'LOCK';

const PointingModeTypes = createVable<TPointingModeType>({
  PASSIVE: 'Passive',
  MAX_SECONDARY_ALIGN: 'Max Secondary Alignment',
  LOCK: 'Direction Lock',
});

export const PointingModeVables = {
  PointingModeTypes,
};

/*============================================================================*/
// Power Processor
/*============================================================================*/
export type TTopologyTypes =
  | 'SINGLE_CONV_MPPT'
  | 'TWO_CONV_MPPT'
  | 'QUASI_REG_DET'
  | 'SINGLE_CONV_HYBRID'
  | 'FULLY_REG_DET';

const TopologyTypes = createVable<TTopologyTypes>({
  SINGLE_CONV_MPPT: 'Single Converter Maximum Peak Power Point Tracking',
  TWO_CONV_MPPT: 'Two Converter Maximum Peak Power Point Tracking',
  QUASI_REG_DET: 'Quasi-Regulated Direct Energy Transfer',
  FULLY_REG_DET: 'Fully-Regulated Direct Energy Transfer',
  SINGLE_CONV_HYBRID: 'Single Converter Hybrid',
});

export const PowerProcessorVables = {
  TopologyTypes,
};

/*============================================================================*/
// Reference Vectors
/*============================================================================*/

export type TReferenceVectorTypes = 'CELESTIAL' | 'LOCAL' | 'TARGET' | 'TARGET_GROUP';
export type TCelestialPointingDirections =
  | 'SUN'
  | 'MOON'
  | 'EARTH'
  | 'MERCURY'
  | 'VENUS'
  | 'MARS'
  | 'JUPITER'
  | 'SATURN'
  | 'URANUS'
  | 'NEPTUNE'
  | 'PLUTO';
export type TLocalPointingDirections =
  | 'NADIR'
  | 'ZENITH'
  | 'CROSS_TRACK_POS'
  | 'CROSS_TRACK_NEG'
  | 'ALONG_TRACK_POS'
  | 'ALONG_TRACK_NEG'
  | 'RAM'
  | 'ANI_RAM'
  | 'MAGNETIC_FIELD';
export type TLocalReferenceFrames = 'LVLH' | 'ENU' | 'ECI';

const VectorTypes = createVable<TReferenceVectorTypes>({
  CELESTIAL: 'Celestial',
  LOCAL: 'Local',
  TARGET: 'Target',
  TARGET_GROUP: 'Target Group',
});

const CelestialPointingDirections = createVable<TCelestialPointingDirections>({
  SUN: 'Sun',
  MOON: 'Moon',
  EARTH: 'Earth',
});

const LocalPointingDirections = createVable<TLocalPointingDirections>({
  NADIR: 'Nadir',
  ZENITH: 'Zenith',
  CROSS_TRACK_POS: 'Positive Cross Track',
  CROSS_TRACK_NEG: 'Negative Cross Track',
  ALONG_TRACK_POS: 'Positive Along Track',
  ALONG_TRACK_NEG: 'Negative Along Track',
  RAM: 'Ram',
  ANTI_RAM: 'Anti-Ram',
  MAGNETIC_FIELD: 'Magnetic Field',
});

const LocalReferenceFrames = createVable<TLocalReferenceFrames>({
  LVLH_COORDINATES: 'LVLH',
  ENU_COORDINATES: 'ENU',
  ECI_COORDINATES: 'ECI',
});

export const ReferenceVectorVables = {
  CelestialPointingDirections,
  LocalPointingDirections,
  LocalReferenceFrames,
  VectorTypes,
};

/*============================================================================*/
// Repository
/*============================================================================*/

export type TRepoDataTypesKeys = 'SCENARIO' | 'AGENT_TEMPLATE';

const DataTypes = createVable<TRepoDataTypesKeys>({
  AGENT_TEMPLATE: 'Agent Template',
  SCENARIO: 'Scenario',
});

export type TRepoAccessTypesKeys = 'PRIVATE' | 'PUBLIC' | 'OFFICIAL';

const AccessTypes = createVable<TRepoAccessTypesKeys>({
  PRIVATE: 'Private',
  PUBLIC: 'Public',
  OFFICIAL: 'Official',
});

export const RepoVables = {
  DataTypes,
  AccessTypes,
};

/*============================================================================*/
// Sensor
/*============================================================================*/
export type TSensorTypes =
  | 'ANGULAR_VELOCITY_SENSOR'
  | 'DIRECTION_SENSOR'
  | 'OPTICAL_ATTITUDE_SENSOR'
  | 'POSITION_SENSOR'
  | 'VECTOR_SENSOR';

const SensorType = createVable<TSensorTypes>({
  ANGULAR_VELOCITY_SENSOR: 'Angular Velocity Sensor',
  DIRECTION_SENSOR: 'Direction Sensor',
  OPTICAL_ATTITUDE_SENSOR: 'Optical Attitude Sensor',
  POSITION_SENSOR: 'Position Sensor',
  VECTOR_SENSOR: 'Vector Sensor',
});

export const SensorVables = {
  ComponentType: SensorType,
};

/*============================================================================*/
// Subsystem
/*============================================================================*/
export type TSubsystemCategoriesKeys =
  | 'POWER'
  | 'CDH'
  | 'COMMS'
  | 'GNC'
  | 'THERMAL'
  | 'STRUCTURE'
  | 'CUSTOM';

const Categories = createVable<TSubsystemCategoriesKeys>({
  POWER: 'Power',
  CDH: 'Command & Control',
  COMMS: 'Data Handling',
  GNC: 'Guidance, Navigation, and Control',
  THERMAL: 'Thermal',
  STRUCTURE: 'Structure',
  CUSTOM: 'Custom Subsystem',
});

export const SubsystemVables = {
  Categories,
};

/*============================================================================*/
// Surface
/*============================================================================*/
export type TMotionTypesKeys = 'FIXED' | 'SUN_TRACKING' | 'ANTI_SUN_TRACKING' | 'VECTOR_TRACKING';

const MotionTypes = createVable<TMotionTypesKeys>({
  FIXED: 'Fixed',
  SUN_TRACKING: 'Sun Tracking',
  ANTI_SUN_TRACKING: 'Anti-Sun Tracking',
  VECTOR_TRACKING: 'Vector Tracking',
});

export const SurfaceVables = {
  MotionTypes,
};

/*============================================================================*/
// Target
/*============================================================================*/
export type TTargetTypes = 'SPACE_TARGET' | 'CELESTIAL_TARGET' | 'GROUND_TARGET';
export type TPolynomialEphemerisBodyTypes =
  | 'SUN'
  | 'MOON'
  | 'EARTH'
  | 'MERCURY'
  | 'VENUS'
  | 'MARS'
  | 'JUPITER'
  | 'SATURN'
  | 'URANUS'
  | 'NEPTUNE'
  | 'PLUTO';

const TargetType = createVable<TTargetTypes>({
  SPACE_TARGET: 'Space Target',
  CELESTIAL_TARGET: 'Celestial Target',
  GROUND_TARGET: 'Ground Target',
});

const PolynomialEphemerisBody = createVable<TPolynomialEphemerisBodyTypes>({
  SUN: 'Sun',
  MOON: 'Moon',
  EARTH: 'Earth',
  MERCURY: 'Mercury',
  VENUS: 'Venus',
  MARS: 'Mars',
  JUPITER: 'Jupiter',
  SATURN: 'Saturn',
  URANUS: 'Uranus',
  NEPTUNE: 'Neptune',
  PLUTO: 'Pluto',
});

export const TargetVables = {
  TargetType,
  PolynomialEphemerisBody,
};

/*============================================================================*/
// Temperature Controller
/*============================================================================*/
export type TTempControllerTypes = 'HEATER' | 'COOLER';

const TempControllerType = createVable<TTempControllerTypes>({
  HEATER: 'Heater',
  COOLER: 'Cooler',
});

export const TempControllerVables = {
  ComponentType: TempControllerType,
};
