/* eslint no-template-curly-in-string: 0 */
import * as Yup from 'yup';
import getThermalProps from 'hooks/getThermalProps';

const { thermalValidation } = getThermalProps();

const requiredMaxString = (name: string, nameField = false) => {
  return Yup.string()
    .required(`A ${name} ${nameField ? 'name ' : ''}is required.`)
    .max(32, `${name[0].toUpperCase() + name.slice(1)} must be no more than 32 characters.`);
};

const validation = Yup.object().shape({
  name: requiredMaxString('battery pack', true),

  manufacturer: Yup.string().max(32, 'Manufacturer must be no more than ${max} characters.'),

  partNumber: Yup.string().max(32, 'Part Number must be no more than ${max} characters.'),

  cell: Yup.object().required('Select a solar cell.'),

  numSeries: Yup.number()
    .required('Number of cells in series is required.')
    .integer('Number of cells in series should be a positive integer.')
    .min(1, 'Number of cells in series must be at least ${min}.'),

  numParallel: Yup.number()
    .required('Number of cells in parallel is required.')
    .integer('Number of cells in parallel should be a positive integer.')
    .min(1, 'Number of cells in parallel must be at least ${min}.'),

  ...thermalValidation,
});

export default validation;
