import {
  CSSProperties,
  MouseEventHandler,
  useCallback,
  useMemo,
  useState,
  MouseEvent,
} from 'react';
import {
  Node,
  EdgeProps,
  useStore,
  Position,
  getBezierPath,
  EdgeText,
  ConnectionLineComponentProps,
} from 'react-flow-renderer';
import EntityMenu from 'components/general/EntityMenu';
import { useActiveEntities, useEntityDialogControl } from 'hooks';
import { IComponent } from 'components/general/types/power';
import ThermalInterfaceDialog from 'components/AgentTemplateEditView/EditBoards/ThermalEditBoard/ThermalInterfacesSegment/ThermalInterfacesDialog';
import { IThermalInterface } from 'components/general/types/thermal';
import { ISurface } from 'components/general/types/spacecraft';

/**
 * Converts a child node's position from relative to absolute.
 *
 * @param node child node with position defined relative to parent
 * @param parentNode parent of node
 * @returns child node with absolute position defined
 */
const adjustPositionToParent = (node?: Node, parentNode?: Node) => {
  if (parentNode && node) {
    const { x, y } = parentNode.position;
    return {
      ...node,
      position: { x: x + node.position.x, y: y + node.position.y },
    };
  }
  return node;
};

// this helper function returns the intersection point
// of the line between the center of the intersectionNode and the target node
function getNodeIntersection(intersectionNode: Node, targetNode: Node) {
  // https://math.stackexchange.com/questions/1724792/an-algorithm-for-finding-the-intersection-point-between-a-center-of-vision-and-a
  const {
    width: intersectionNodeWidth,
    height: intersectionNodeHeight,
    position: intersectionNodePosition,
  } = intersectionNode;
  const { position: targetPosition } = targetNode;

  if (!intersectionNodeWidth || !intersectionNodeHeight) return { x: 0, y: 0 };

  const w = intersectionNodeWidth / 2;
  const h = intersectionNodeHeight / 2;

  const x2 = intersectionNodePosition.x + w;
  const y2 = intersectionNodePosition.y + h;
  const x1 = targetPosition.x + w;
  const y1 = targetPosition.y + h;

  const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
  const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
  const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
  const xx3 = a * xx1;
  const yy3 = a * yy1;
  const x = w * (xx3 + yy3) + x2;
  const y = h * (-xx3 + yy3) + y2;

  return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(node: Node, intersectionPoint: { x: number; y: number }) {
  const n = { ...node.position, ...node };
  const nx = Math.round(n.x);
  const ny = Math.round(n.y);
  const px = Math.round(intersectionPoint.x);
  const py = Math.round(intersectionPoint.y);

  if (!n.width || !n.height) return Position.Top;

  if (px <= nx + 1) {
    return Position.Left;
  }
  if (px >= nx + n.width - 1) {
    return Position.Right;
  }
  if (py <= ny + 1) {
    return Position.Top;
  }
  if (py >= n.y + n.height - 1) {
    return Position.Bottom;
  }

  return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
function getEdgeParams(source: Node, target: Node) {
  const sourceIntersectionPoint = getNodeIntersection(source, target);
  const targetIntersectionPoint = getNodeIntersection(target, source);

  const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
  const targetPos = getEdgePosition(target, targetIntersectionPoint);

  return {
    sx: sourceIntersectionPoint.x,
    sy: sourceIntersectionPoint.y,
    tx: targetIntersectionPoint.x,
    ty: targetIntersectionPoint.y,
    sourcePos,
    targetPos,
  };
}

export function FloatingEdge(
  props: EdgeProps & { onClick: MouseEventHandler; additionalStyles: CSSProperties }
) {
  const {
    id,
    source,
    target,
    markerEnd,
    style,
    label,
    labelStyle,
    labelShowBg,
    labelBgStyle,
    labelBgPadding,
    labelBgBorderRadius,
    onClick,
    additionalStyles,
  } = props;

  const sourceNode = useStore(useCallback((store) => store.nodeInternals.get(source), [source]));
  const sourceNodeParent = useStore(
    useCallback((store) => store.nodeInternals.get(sourceNode?.parentNode || ''), [sourceNode])
  );
  const targetNode = useStore(useCallback((store) => store.nodeInternals.get(target), [target]));
  const targetNodeParent = useStore(
    useCallback((store) => store.nodeInternals.get(targetNode?.parentNode || ''), [targetNode])
  );

  const adjustedSourceNode = useMemo(
    () => adjustPositionToParent(sourceNode, sourceNodeParent),
    [sourceNode, sourceNodeParent]
  );
  const adjustedTargetNode = useMemo(
    () => adjustPositionToParent(targetNode, targetNodeParent),
    [targetNode, targetNodeParent]
  );

  if (!adjustedSourceNode || !adjustedTargetNode) {
    return null;
  }

  const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
    adjustedSourceNode,
    adjustedTargetNode
  );

  const d = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition: sourcePos,
    targetPosition: targetPos,
    targetX: tx,
    targetY: ty,
  });

  return (
    <>
      <g className="react-flow__connection">
        <path id={id} className="react-flow__edge-path" d={d} markerEnd={markerEnd} style={style} />
      </g>
      <EdgeText
        x={(sx + tx) / 2}
        y={(sy + ty) / 2}
        label={label}
        labelStyle={labelStyle}
        labelShowBg={labelShowBg}
        labelBgStyle={labelBgStyle}
        labelBgPadding={labelBgPadding}
        labelBgBorderRadius={labelBgBorderRadius}
        onClick={onClick}
        style={additionalStyles}
      />
    </>
  );
}

/**
 * An editable version of FloatingEdge.
 * Clicking the text box opens a dialog to edit the existing interface
 * represented by this edge.
 */
export function FloatingEdgeWithDialog(props: EdgeProps) {
  const { id, data, source, target } = props;

  const dialogControl = useEntityDialogControl<IThermalInterface>();
  const { openDialogForExisting } = dialogControl;
  const { components, surfaces } = useActiveEntities();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const selectedData = { id, ...data };

  const allThermalEndpoints = (components as (IComponent | ISurface)[]).concat(surfaces);
  // remove suffixes to get root block id, e.g. "345-cool" -> "345"
  const sourceNode = allThermalEndpoints.find(
    (el) => Number(el.id) === Number(source.split('-')[0])
  );
  const targetNode = allThermalEndpoints.find(
    (el) => Number(el.id) === Number(target.split('-')[0])
  );

  return (
    <>
      <FloatingEdge
        onClick={(event: MouseEvent<HTMLElement>) => {
          // Open up EntityMenu, includes "Edit" and "Delete" buttons
          setAnchorEl(event.currentTarget);
        }}
        additionalStyles={{ cursor: 'pointer' }}
        {...props}
      />
      {anchorEl && (
        <EntityMenu
          anchorEl={anchorEl}
          selectedData={selectedData}
          onActionClick={openDialogForExisting}
          handleClose={() => setAnchorEl(null)}
        />
      )}
      {dialogControl.dialogConfig.open && sourceNode && targetNode && (
        // Open dialog once source and target nodes are found
        <ThermalInterfaceDialog control={dialogControl} source={sourceNode} target={targetNode} />
      )}
    </>
  );
}

export function FloatingConnectionLine({
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  sourceNode,
}: ConnectionLineComponentProps) {
  const sourceNodeParent = useStore(
    useCallback((store) => store.nodeInternals.get(sourceNode?.parentNode || ''), [sourceNode])
  );
  sourceNode = adjustPositionToParent(sourceNode, sourceNodeParent);

  if (!sourceNode) {
    return null;
  }

  const targetNode = {
    id: 'connection-target',
    width: 1,
    height: 1,
    position: { x: targetX, y: targetY },
    data: {},
  };

  const { sx, sy } = getEdgeParams(sourceNode, targetNode);
  const d = getBezierPath({
    sourceX: sx,
    sourceY: sy,
    sourcePosition,
    targetPosition,
    targetX,
    targetY,
  });

  return (
    <g>
      <path fill="none" stroke="#b1b1b7" strokeWidth={1.5} className="animated" d={d} />
      <circle cx={targetX} cy={targetY} fill="#fff" r={3} stroke="#222" strokeWidth={1.5} />
    </g>
  );
}
