import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { wGroupIndicesATThermal } from 'components/AgentTemplateEditView/menu/thermal';
import { IColumn } from 'components/general/types';
import { ITempController } from 'components/general/types/thermal';
import { useActiveEntities } from 'hooks';
import guidance from './guidance';
import TempControllerDialog from './TempControllerDialog';
import { TempControllerVables, TTempControllerTypes } from 'utils/vable';

const tempControllerColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (row) =>
      TempControllerVables.ComponentType[row.componentType as TTempControllerTypes].label,
  },
  {
    title: 'Heat Flow Rating',
    render: (row) => `${row.onRegHeatFlowRate} W`,
  },
];

const TempControllersSegment = () => {
  const { tempControllers } = useActiveEntities();

  return (
    <EntityTableSegment<ITempController>
      title="Temperature Controllers"
      index={wGroupIndicesATThermal.TEMP_CONT}
      guidance={guidance}
      entityColumns={tempControllerColumns}
      entityData={tempControllers}
      DialogComponent={TempControllerDialog}
      modelName="temperature controller"
    ></EntityTableSegment>
  );
};

export default TempControllersSegment;
