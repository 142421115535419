/**
 *
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 *
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 *
 * This file uses RECHARTS (ewwww) and is therefore OLD AND OBSOLETE
 *
 * DO NOT USE THIS COMPONENT UNTIL IT IS UPDATED TO USE ECHARTS
 *
 * If you are updating it yourself, please remove this notice when finished.
 *
 *
 */

import Widget from 'components/general/widgets/Widget';
import TwoLevelActivePieChart from 'components/general/charts/TwoLevelActivePieChart';
import { PowerTable } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general';
import { IStatsPieData, TPowerTableDatum } from './types';

interface IProps {
  widgetTitle: string;
  tableData?: TPowerTableDatum[];
  tableUnit?: string;
  pieData?: IStatsPieData;
  pieTitle?: string;
  subtitle?: string;
  maxHeight?: number | null;
  defaultExpanded?: boolean;
}

const StatsWidget = (props: IProps) => {
  const {
    pieData,
    tableData,
    tableUnit,
    maxHeight = 600,
    widgetTitle,
    pieTitle,
    subtitle,
    defaultExpanded,
  } = props;

  return (
    <Widget title={widgetTitle} subtitle={subtitle} collapsibleConfig>
      {tableData && (
        <PowerTable
          data={tableData}
          unit={tableUnit}
          maxHeight={maxHeight}
          defaultExpanded={defaultExpanded}
        />
      )}
      {pieData && pieData.data.length > 0 && (
        <TwoLevelActivePieChart title={pieTitle} data={pieData.data} units={pieData.pieUnits} />
      )}
    </Widget>
  );
};

export default StatsWidget;
