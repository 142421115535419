import { useMemo, useCallback } from 'react';
import { IBusRegulator, ILoad, ILoadState } from 'components/general/types/power';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm } from 'hooks';
import { useGuidance } from './guidance';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { LoadVables } from 'utils/vable';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { PowerAccent } from 'components/general/Accent/variants';
import { useActiveEntities } from 'hooks';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import { InputAdornment } from '@material-ui/core';
import { translateIn, translateOut } from 'utils/forms';
import validation from './validation';
import { ISelectOption } from 'components/general/types';

interface IForm {
  name: string;
  epsOutputType: ISelectOption | '';
  busRegulator: ISelectOption | '';
  dutyCycled: boolean;
  dutyCyclePeriod: number | '';
  dutyCyclePercentage: number | '';
  loadDefType: ISelectOption | '';
  loadDefParams: {
    resistance: number | '';
    power: number | '';
  };
  busDefType: string;
}

const defaultValues: IForm = {
  name: '',
  epsOutputType: '',
  busRegulator: '',
  dutyCycled: false,
  dutyCyclePeriod: '',
  dutyCyclePercentage: '',
  loadDefType: '',
  loadDefParams: {
    resistance: '',
    power: '',
  },
  busDefType: LoadVables.BusDefinitionType.EPS_OUTPUT.value,
};

interface IProps {
  control: TEntityDialogControl<ILoad>;
  loadState?: ILoadState;
}

const LoadDialog = (props: IProps) => {
  // Handle props
  const { control, loadState } = props;

  // Grab entities
  const { busRegulators, powerProcessor } = useActiveEntities();

  // Set up styles
  const classes = useStyles();

  // Set up options
  const busRegulatorsList = useMemo(
    () =>
      busRegulators.map((regulator: IBusRegulator) => {
        return { value: regulator.id, label: regulator.name };
      }),
    [busRegulators]
  );

  const options = useMemo(() => {
    return {
      epsOutputType: LoadVables.EpsOutputType.options,
      busRegulator: busRegulatorsList,
      loadDefType: LoadVables.LoadDefinitionType.options,
    };
  }, [busRegulatorsList]);

  // Set up custom translateIn and translateOut since there is a checkbox field that should not be sent to the backend
  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options, datetimes, percentageFields) => {
      // Delete checkbox field and set corresponding values to null if not checked
      if (!values.dutyCycled) {
        values.dutyCyclePeriod = null;
        values.dutyCyclePercentage = null;
      }
      delete values.dutyCycled;
      // PowerProcessor must be added to values if the epsOutputType is Core
      if (values.epsOutputType.value === LoadVables.EpsOutputType.CORE_OUTPUT.value) {
        values.powerProcessor = powerProcessor?.id;
      }
      return translateOut(values, allowedEmptyFields, options, datetimes, percentageFields);
    },
    [powerProcessor]
  );

  const customTranslateIn = useCallback(
    (values, defaultValues, options, datetimes, percentageFields) => {
      // If load has a dutyCyclePeriod then set the checkbox to true since it is a form only field, else keep as false
      values.dutyCycled = typeof values.dutyCyclePeriod === 'number';
      return translateIn(values, defaultValues, options, datetimes, percentageFields);
    },
    []
  );

  // Use entity form hook to hook up form
  const entityForm = useEntityForm<ILoad, IForm>({
    entityTypeText: 'Load',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    additionalCreateValues: { loadState: loadState?.id },
    formikOptionalParams: {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      percentages: ['dutyCyclePercentage'],
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setValues } = formik;
  const { epsOutputType, dutyCycled, loadDefType } = values;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Load Name"
            type="text"
            placeholder="Load Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('epsOutputType')}
            label="Source Type"
            options={options.epsOutputType}
          />
          {typeof epsOutputType === 'object' &&
            epsOutputType.value === LoadVables.EpsOutputType.BUS_REGULATOR.value && (
              <PowerAccent header="Bus Regulator">
                <LabeledSelect
                  {...getFieldProps('busRegulator')}
                  options={options.busRegulator}
                  noOptionsMessage={() => 'Create a Bus Regulator'}
                />
              </PowerAccent>
            )}
        </div>
        <div className={classes.inputGroup}>
          <LabeledCheckbox {...getFieldProps('dutyCycled')} label="Duty Cycled" />
          {dutyCycled && (
            <div className={classes.indent}>
              <LabeledInput
                {...getFieldProps('dutyCyclePeriod')}
                endAdornment={<InputAdornment position="end">min</InputAdornment>}
                label="Duty Cycle Period"
                type="number"
              />
              <LabeledInput
                {...getFieldProps('dutyCyclePercentage')}
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                label="Duty Cycle Percentage"
                type="number"
              />
            </div>
          )}
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('loadDefType')}
            label="Load Type"
            options={options.loadDefType}
            formikOnChange={(val: ISelectOption) => {
              if (typeof loadDefType === 'object' && val.value !== loadDefType.value) {
                setValues({
                  ...values,
                  loadDefParams: defaultValues.loadDefParams,
                });
              }
            }}
          />
          {typeof loadDefType === 'object' &&
            loadDefType.value === LoadVables.LoadDefinitionType.CONSTANT_POWER.value && (
              <div className={classes.indent}>
                <LabeledInput
                  {...getFieldProps('loadDefParams.power')}
                  type="number"
                  endAdornment={<InputAdornment position="end">W</InputAdornment>}
                  label="Power"
                />
              </div>
            )}
          {typeof loadDefType === 'object' &&
            loadDefType.value === LoadVables.LoadDefinitionType.CONSTANT_RESISTANCE.value && (
              <div className={classes.indent}>
                <LabeledInput
                  {...getFieldProps('loadDefParams.resistance')}
                  type="number"
                  endAdornment={<InputAdornment position="end">Ω</InputAdornment>}
                  label="Resistance"
                />
              </div>
            )}
        </div>
      </div>
    </EntityDialog>
  );
};

export default LoadDialog;
