import { TDPSchemaList } from 'components/general/types';

const tsAttitudeControlDPSchema: TDPSchemaList = [
  {
    name: 'Attitude Error',
    subtitle: 'Commanded minus truth attitude and angular velocity',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'ECI Error Angles',
          unit: 'rad',
          keys: [
            'conOps.attitudeError.0',
            'conOps.attitudeError.1',
            'conOps.attitudeError.2',
          ],
          legend: [
            'Error: Yaw',
            'Error: Pitch',
            'Error: Roll',
          ],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Body Frame Angular Velocity Error',
          unit: 'rpm',
          keys: [
            'conOps.angularVelocityError.rpm.0',
            'conOps.angularVelocityError.rpm.1',
            'conOps.angularVelocityError.rpm.2',
          ],
          legend: [
            'Error: x',
            'Error: y',
            'Error: z',
          ],
        },
      },
    ],
  },
  {
    name: 'Reaction Wheels',
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Commanded Torque',
          unit: 'N-m',
          keys: ['satellite.reactionWheels.$each.commandedTorqueMagnitude'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Speed',
          unit: 'rpm',
          keys: ['satellite.reactionWheels.$each.speed.rpm'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Angular Momentum',
          unit: 'kg m^2 / s',
          keys: ['satellite.reactionWheels.$each.momentum'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Torque Margin',
          unit: '',
          keys: ['satellite.reactionWheels.$each.torqueMargin'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Momentum Margin',
          unit: '',
          keys: ['satellite.reactionWheels.$each.momentumMargin'],
          legend: ['{name}'],
        },
      },
    ],
  },
  {
    name: 'Magnetorquers',
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Commanded Torque',
          unit: 'N-m',
          keys: ['satellite.magnetorquers.$each.commandedTorqueMagnitude'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Magnetic Moment',
          unit: 'N-m/T',
          keys: ['satellite.magnetorquers.$each.magneticMoment'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Magnetic Moment Margin',
          unit: '',
          keys: ['satellite.magnetorquers.$each.magneticMomentMargin'],
          legend: ['{name}'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Duty Cycle',
          unit: '',
          keys: ['satellite.magnetorquers.$each.pwmDutyCycle'],
          legend: ['{name}'],
        },
      },
    ],
  },
];

export default tsAttitudeControlDPSchema;
