import TargetDialog from './TargetDialog';
import { useActiveEntities } from 'hooks';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { wGroupIndicesATTargets } from 'components/AgentTemplateEditView/menu/targets';

const options = {
  targetType: [
    { value: 'GROUND_TARGET', label: 'Ground Target' },
    { value: 'SPACE_TARGET', label: 'Space Target' },
    { value: 'CELESTIAL_TARGET', label: 'Celestial Target' },
  ],
  polynomialEphemerisBody: [
    { value: 'SUN', label: 'Sun' },
    { value: 'MOON', label: 'Moon' },
    { value: 'EARTH', label: 'Earth' },
    { value: 'MERCURY', label: 'Mercury' },
    { value: 'VENUS', label: 'Venus' },
    { value: 'MARS', label: 'Mars' },
    { value: 'JUPITER', label: 'Jupiter' },
    { value: 'SATURN', label: 'Saturn' },
    { value: 'URANUS', label: 'Uranus' },
    { value: 'NEPTUNE', label: 'Neptune' },
    { value: 'PLUTO', label: 'Pluto' },
  ],
};

// Change to field being targetType and have the actual field look up the value from options
const columns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (val) => options.targetType.find((o) => o.value === val.targetType)?.label,
  },
];

const TargetsSegment = () => {
  const { targets } = useActiveEntities();

  return (
    <EntityTableSegment
      title="Targets"
      index={wGroupIndicesATTargets.TARGETS_INDEX}
      guidance={{
        heading: 'Create and Edit Targets',
        body: [
          {
            chunk:
              'Targets and their motion relative to your satellite are used to drive ConOps logic and satellite pointing. Use the targets table to edit or add targets. Common targets include the Sun, ground stations, imaging targets, and crosslink or relay satellites.',
          },
          {
            subHeading: 'Link to ConOps Conditions',
            chunk:
              "If your mission's ConOps logic will be driven by the lighting, local time, position, or relative position of a target, define the target here. ConOps conditions can be defined based on line-of-sight status to a target, elevation or range of your satellite from the target, local time or lighting conditions at the target, and other target parameters.",
          },
          {
            subHeading: 'Link to Pointing Modes',
            chunk:
              'Pointing modes can be defined to point (or maximize pointing) of a satellite-fixed vector to a target.',
          },
        ],
      }}
      entityColumns={columns}
      entityData={targets}
      DialogComponent={TargetDialog}
      modelName="target"
    ></EntityTableSegment>
  );
};

export default TargetsSegment;
