import { useState, useMemo } from 'react';
import * as React from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import {
  IErrorResponse,
  IGenericObject,
  IMissionVersion,
  ISelectOption,
} from 'components/general/types';
import { IDiffDialogControl } from '..';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useDispatch } from 'react-redux';
import { useFormikForm, useSnackbar } from 'hooks';
import { exclude } from 'utils/forms';
import * as Yup from 'yup';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import useStyles from './styles';
import _ from 'lodash';
import ViewPortInlay from 'components/general/ViewPortInlay';
import StyledReactDiffViewer from '../general/StyledReactDiffViewer';

interface IProps {
  diffConfig: IDiffDialogControl;
  setDiffConfig: React.Dispatch<React.SetStateAction<IDiffDialogControl>>;
  branches: IMissionVersion[];
}

interface ICompareVals {
  sourceBranch: ISelectOption;
  targetBranch: ISelectOption;
  compareType: { value: 'getCommitted' | 'getSaved'; label: string };
}

interface ICompareForm {
  sourceBranch: ISelectOption | '';
  targetBranch: ISelectOption | '';
  compareType: ISelectOption;
}

const defaultValues: ICompareForm = {
  sourceBranch: '',
  targetBranch: '',
  compareType: { label: 'Committed changes only', value: 'getCommitted' },
};

const compareTypeOptions = [
  { label: 'Committed changes only', value: 'getCommitted' },
  { label: 'All saved changes', value: 'getSaved' },
];

const compareSchema = Yup.object().shape({
  sourceBranch: Yup.object().required('A source branch is required.'),
  targetBranch: Yup.object().required('A target branch is required.'),
});

const DiffDialog = ({ diffConfig, setDiffConfig, branches }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [targetBranch, setTargetBranch] = useState<IGenericObject>();
  const [sourceBranch, setSourceBranch] = useState<IGenericObject>();

  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    MissionVersion: {
      actions: { getCommittedBranchForGitDiff: getCommitted, getSavedBranchForGitDiff: getSaved },
    },
  } = SatelliteApi;

  const branchOptions = useMemo(() => {
    return branches.map((branch) => {
      return { value: branch.id, label: branch.name };
    });
  }, [branches]);

  const getBranchComparison = (values: ICompareForm) => {
    setTargetBranch(undefined);
    setSourceBranch(undefined);

    const { sourceBranch, targetBranch, compareType } = values as ICompareVals;
    const action = { getCommitted, getSaved }[compareType.value];

    setLoading(true);
    dispatch(
      action({
        id: sourceBranch.value,
        successCallback: (response: IGenericObject) => {
          // Responses come back with the branch '_id', which should not be used in the diff
          delete response._id;
          setSourceBranch(response);
          dispatch(
            action({
              id: targetBranch.value,
              successCallback: (response: IGenericObject) => {
                delete response._id;
                setTargetBranch(response);
                setLoading(false);
              },
            })
          );
        },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  const { formik } = useFormikForm<ICompareForm, ICompareForm>(
    defaultValues,
    getBranchComparison,
    compareSchema,
    defaultValues
  );
  const { handleSubmit, getFieldProps, values, resetForm } = formik;

  const noDifferences = useMemo(() => {
    return _.isEqual(sourceBranch, targetBranch);
  }, [sourceBranch, targetBranch]);

  const closeAndResetFormAndState = () => {
    resetForm();
    setTargetBranch(undefined);
    setSourceBranch(undefined);
    setDiffConfig({ open: false });
  };

  return (
    <Dialog
      prompt="Select branches to compare"
      submitActionText="Compare"
      onSubmit={handleSubmit}
      loading={loading}
      xxlarge
      open={diffConfig.open}
      onClose={closeAndResetFormAndState}
    >
      <div>
        Compare committed changes only or all saved changes. Note that only committed changes will
        be included when branches are merged.
      </div>
      <div className={classes.selectContainer}>
        <div className={classes.select}>
          <LabeledSelect
            label="Comparison type"
            {...getFieldProps('compareType')}
            options={compareTypeOptions}
          />
        </div>
      </div>
      <div className={classes.compareContainer}>
        <div className={classes.selectContainer}>
          <div className={classes.select}>
            <LabeledSelect
              label="First Branch (Red)"
              {...getFieldProps('sourceBranch')}
              options={exclude(branchOptions, values.targetBranch)}
              isClearable
            />
          </div>
        </div>
        <div className={classes.selectContainer}>
          <div className={classes.select}>
            <LabeledSelect
              label="Second Branch (Blue)"
              {...getFieldProps('targetBranch')}
              options={exclude(branchOptions, values.sourceBranch)}
              isClearable
            />
          </div>
        </div>
      </div>
      {sourceBranch &&
        targetBranch &&
        (noDifferences ? (
          <ViewPortInlay>No differences detected between these two branches.</ViewPortInlay>
        ) : (
          <div className={classes.diffContainer}>
            <StyledReactDiffViewer
              oldValue={JSON.stringify(sourceBranch, undefined, 4)}
              newValue={JSON.stringify(targetBranch, undefined, 4)}
            />
          </div>
        ))}
    </Dialog>
  );
};

export default DiffDialog;
