import Dialog from 'components/general/dialogs/Dialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import CollaboratorsTable from './CollaboratorsTable';
import { useEntityDialogControl, useSelectById } from 'hooks';
import { IUser } from 'components/general/types/general';
import StyledDivider from 'components/general/StyledDivider';
import useStyles from './styles';
import CollaboratorDialog from 'components/RootView/RepoExplorer/CollaborationDialog/CollaboratorDialog';
import PendingInvitesTable from './PendingInvitesTable';
import { SUPPORT_EMAIL } from 'config';

interface IProps {
  control: TEntityDialogControl<number>;
}

const CollaborationDialog = (props: IProps) => {
  const { control } = props;
  const { closeDialog, dialogConfig } = control;
  const { entity: missionId, open } = dialogConfig;

  const classes = useStyles();

  const collaboratorsDialogControl = useEntityDialogControl<IUser>();

  const repo = useSelectById('Mission', missionId || 0);

  return (
    <div>
      {repo && (
        <>
          <Dialog title={`${repo.name} - Collaboration`} large open={open} onClose={closeDialog}>
            <StyledDivider className={classes.hr} />
            <CollaboratorsTable
              repo={repo}
              collaboratorsDialogControl={collaboratorsDialogControl}
            />
            <PendingInvitesTable
              repo={repo}
              collaboratorsDialogControl={collaboratorsDialogControl}
            />
            <div className={classes.contact}>
              Contact Sedaro at {SUPPORT_EMAIL} to change the owner of this repository.
            </div>
          </Dialog>
          <CollaboratorDialog repoId={repo.id} control={collaboratorsDialogControl} />
        </>
      )}
    </div>
  );
};

export default CollaborationDialog;
