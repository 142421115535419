import { makeGuidance } from 'hooks';
import { IGenericObject } from 'components/general/types';
const targetChunk =
  'Select a user-created Target/Target Group to create a Reference Vector from the satellite to the Target or Target Group.';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Reference Vector Inputs',
    body: 'Select either a Celestial, Target/Target Group, or Local vector to create.',
  },
  celestialPointingDirection: {
    heading: 'Celestial Reference',
    body: [
      {
        chunk:
          'Select a celestial body to create a Reference Vector from the satellite to the celestial body.',
      },
    ],
  },
  localPointingDirection: {
    heading: 'Local Reference',
    body: [
      {
        chunk:
          'Define a Local Reference Vector using one of the available reference frames or by choosing a pointing direction.',
      },
      {
        subHeading: 'Reference Frames',
        chunk:
          'LVLH (Local-Vertical-Local-Horizontal) - based on the spacecraft position and velocity',
      },
      {
        chunk: 'ENU (East-North-Up) - based on spacecraft position in an Earth-fixed frame',
      },
      {
        chunk:
          'ECI (Earth-Centered-Inertial) - constant relative position vector in the J2000 Earth centered reference frame',
      },
      {
        subHeading: 'Pointing Directions',
        chunk: 'Nadir - spacecraft nadir',
      },
      {
        chunk: 'Zenith - vector in the direction opposite nadir',
      },
      {
        chunk:
          'Positive Cross Track - out of plane at right angle to position and velocity vectors (cross product of position and velocity)',
      },
      {
        chunk:
          'Negative Cross Track - out of plane at right angle to position and velocity vectors (negative direction of the cross product of position and velocity)',
      },
      {
        chunk: 'Positive Along Track - "forward", at right angle to position vector',
      },
      {
        chunk: 'Negative Along Track - "aft", at right angle to position vector',
      },
      {
        chunk: 'Ram - velocity direction',
      },
      {
        chunk: 'Anti-Ram - vector in the direction opposite ram',
      },
      {
        chunk: 'Magnetic Field - Local magnetic field vector',
      },
    ],
  },
  //TODO add Drawing
  targetPointingDirection: {
    heading: 'Target Reference',
    body: [
      {
        chunk: targetChunk,
      },
    ],
  },
  target: {
    heading: 'Target Reference',
    body: [
      {
        chunk: targetChunk,
      },
    ],
  },
  targetGroup: {
    heading: 'Target Reference',
    body: [
      {
        chunk: targetChunk,
      },
    ],
  },
  vectorCoordinates: (values: IGenericObject) => {
    const referenceFrame = values.localPointingDirection.value;
    if (referenceFrame === 'LVLH_COORDINATES') {
      return {
        heading: 'LVLH',
        body: [
          {
            chunk: 'x: Local vertical, aligned with spacecraft position vector.',
          },
          {
            chunk: 'y: In-track, component of velocity vector perpendicular to x.',
          },
          {
            chunk: 'z: Completes the right-handed system, parallel to orbit angular momentum.',
          },
        ],
      };
    } else if (referenceFrame === 'ENU_COORDINATES') {
      return {
        heading: 'ENU',
        body: [
          {
            chunk: 'x: Completes the right-handed system, points along local East.',
          },
          {
            chunk: 'y: Component of geographic north vector perpendicular to z.',
          },
          {
            chunk: 'z: Up, aligned with spacecraft position vector.',
          },
        ],
      };
    } else {
      return {
        heading: 'J2000 ECI',
        body: [
          {
            chunk: 'x: Vernal equinox at 12:00 January 1, 2000',
          },
          {
            chunk: "y: Completes the right handed system, lies in plane of Earth's mean equator",
          },
          {
            chunk: 'z: Celstial North, aligned with Geographic North at 12:00 January 1, 2000',
          },
        ],
      };
    }
  },
  name: {
    heading: 'Reference Vector Name',
    alias: 'vectorCoordinates',
  },
});
export default useGuidance;
