import BaseNode from './general/BaseNode';
import ACSNodeHandle, { Position } from './general/ACSNodeHandle';

import { ISlidingModeNodeData } from './general/types';

const customContainerStyle = {
  height: '10rem',
};

const SlidingModeNode = ({ data }: ISlidingModeNodeData) => {
  return (
    <BaseNode
      title={data.title}
      subtitle={data.subtitle}
      containerStyle={customContainerStyle}
      truncateTitleOverflow={false}
      truncateSubtitleOverflow={false}
    >
      <ACSNodeHandle type="target" position={Position.Left} />
      <ACSNodeHandle type="source" position={Position.Right} />
    </BaseNode>
  );
};

export default SlidingModeNode;
