import Widget from 'components/general/widgets/Widget';
import ConOpsTimeSeriesChart from './ConOpsTimeSeriesChart';
import PriorityDecoration from 'components/general/PriorityDecoration';
import useStyles from './styles';
// import { mjd2Moment, dateFormatLong } from 'utils/time';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';

const ConOpsTimeSeriesWidget = (props) => {
  const { loading } = props;

  const {
    activeAgentData: { conOpsData, staticModel: model },
  } = useAnalyticsContext();

  const operationalModes = model.OperationalMode.all();

  const classes = useStyles();

  // PARSE DATA, FORMAT FOR CSV
  // const conditions = model.Condition.all()
  // This construction relies on the fact that operationalModeList is ordered by priority
  // and lines up with the order in conOpsData.timeSeries.operationalModeCompliances
  // const csvData = useCallback(() => {
  //   let labels = ['Time (UTC)', 'Time (MJD)', 'Active Operational Mode'];
  //   labels = labels.concat(
  //     operationalModes.map((opMode) => `Op. Mode: ${opMode.name} Compliance`), // Op Mode names
  //     conditions.map((cond) => `Condition: ${cond.name} Compliance`) // Condition names
  //   );
  //   let csvData = [labels];

  //   return csvData.concat(
  //     conOpsData.timeSeries.map((item) => {
  //       let res = [
  //         mjd2Moment(item.times.mjd).format(dateFormatLong), // Time String
  //         item.times.mjd, // Time in MJD
  //         operationalModes[item.operationalModeStatuses.indexOf(1)].name, // activeOpMode
  //         ...item.operationalModeCompliances.map((mode) => (!!mode).toString()), // compliance of each op mode
  //       ];
  //       if (item.conditionCompliances)
  //         // compliance of each condition
  //         return res.concat(item.conditionCompliances.map((mode) => (!!mode).toString()));
  //       else return res;
  //     })
  //   );
  // }, [conOpsData, operationalModes, conditions]);

  return (
    <Widget
      title="ConOps Logic and Active Operational Mode"
      loading={loading}
      // csvData={csvData} // FIXME: Re-enable once migrated and tested
      minWidth={400}
      maxWidth="100%"
      collapsibleConfig
    >
      <div className={classes.root}>
        {operationalModes.length > 1 && <PriorityDecoration className={classes.decoration} />}
        <div className={classes.plot}>
          <ConOpsTimeSeriesChart model={model} data={conOpsData.timeSeries} />
        </div>
      </div>
    </Widget>
  );
};

export default ConOpsTimeSeriesWidget;
