import { makeGuidance } from 'hooks';
import { targetGroupsAndPointing } from 'components/AgentTemplateEditView/general/TargetGroupGuidance';

const latAltNote = ' Latitude and altitude are geodetic and based on the WGS84 ellipsoid. ';
const rangeNote = ' Range is from your satellite to the target. ';
const losNote =
  ' Line of sight is true/false and indicates whether a line from your satellite to the target is blocked by the solid Earth. Line of sight is computed based on WGS84 ellipsoid polar Earth radius. ';
const oeNote =
  ' True anomaly is actually argument of latitude if your satellite orbit is circular-inclined and true longitude if your orbit is circular-equatorial. ';

const useGuidance = makeGuidance({
  _default: {
    heading: 'Condition Inputs',
    body: 'For this condition to be satisfied at a given time step, two parameters (A and B) must satisfy the relationship defined here. Parameter A must be a time series parameter from the mission simulation. Parameter B can be either another time series parameter or a constant value.',
  },

  name: {
    alias: '_default',
  },

  relationship: {
    heading: 'Define A/B Relationship',
    body: 'Select a relationship to enforce between your selected parameters. \nThe compliance state of your condition will be determined as: \nA *Relationship* B',
  },

  paramACategory: {
    alias: '_default',
  },

  paramA: (values, paramCatKey, tgtKey) => {
    let paramACat = values[paramCatKey ? paramCatKey : 'paramACategory'].value;
    if (paramACat === 'SATELLITE') {
      return {
        heading: 'Satellite Parameters',
        body:
          "Beta angle is the angle between the Sun-direction and its projection onto your satellite's orbital plane.\n" +
          oeNote +
          '\n' +
          losNote +
          '\n' +
          latAltNote,
      };
    }
    if (paramACat === 'TARGET_GROUP') {
      return {
        heading: 'Target Group Conditions and Pointing',
        body: targetGroupsAndPointing,
      };
    }
    let tgtType = values[tgtKey ? tgtKey : 'targetA'].value.targetType;
    if (tgtType === 'GROUND_TARGET') {
      return {
        heading: 'Ground Target Parameters',
        body:
          'Elevation angles are geodetic and based on the WGS84 ellipsoid.\n' +
          losNote +
          '\n' +
          rangeNote,
      };
    } else if (tgtType === 'CELESTIAL_TARGET') {
      return {
        heading: 'Celestial Target Parameters',
        body: losNote + '\n' + rangeNote + '\n' + latAltNote,
      };
    } else {
      return {
        heading: 'Space Target Parameters',
        body: oeNote + '\n' + losNote + '\n' + latAltNote + '\n' + rangeNote,
      };
    }
  },

  targetA: {
    heading: 'Target Parameter',
    body: 'Select one of your targets from the dropdown.',
  },

  paramBCategory: {
    alias: '_default',
  },

  paramB: {
    alias: 'paramA',
    props: ['paramBCategory', 'targetB'],
  },

  targetB: {
    alias: 'targetA',
  },
});
export default useGuidance;
