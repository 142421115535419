import { useState, ReactNode } from 'react';
import { Table, TableBody, TableRow, TableCell, Collapse } from '@material-ui/core';
import PowerTableRow from './PowerTableRow';
import { TPowerTableDatum } from 'components/AgentAnalyzeView/AnalyzeBoards/PowerAnalyzeBoards/general/types';
import useStyles from './styles';

interface IPowerTableSectionProps {
  headerDatum: TPowerTableDatum;
  collapsibleContent: ReactNode;
  unit?: string;
  defaultExpanded?: boolean;
}

const PowerTableSection = (props: IPowerTableSectionProps) => {
  const { headerDatum, collapsibleContent, unit, defaultExpanded = true } = props;
  const [expanded, setExpanded] = useState<boolean>(defaultExpanded);
  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };
  const classes = useStyles();
  return (
    <>
      <PowerTableRow
        datum={headerDatum}
        unit={headerDatum?.unit || unit}
        expanded={expanded}
        onExpandClick={toggleExpanded}
        className={classes.sectionHeader}
      />
      <TableRow>
        <TableCell className={classes.powerTableNestedCell} colSpan={3}>
          <Collapse in={expanded} unmountOnExit>
            <Table>
              <TableBody>{collapsibleContent}</TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default PowerTableSection;
