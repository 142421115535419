import { useEffect, useMemo, useState } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { IComponent } from 'components/general/types/power';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import validation from './validation';
import { useGuidance } from './guidance';
import { ThermalAccent } from 'components/general/Accent/variants';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { InputAdornment } from '@material-ui/core';
import { IThermalInterface, IThermalInterfaceMaterial } from 'components/general/types/thermal';
import { translateIn, translateOut } from 'utils/forms';
import { ISelectOption } from 'components/general/types';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import { ISurface } from 'components/general/types/spacecraft';

interface IProps {
  control: TEntityDialogControl<IThermalInterface>;
  source: IComponent | ISurface;
  target: IComponent | ISurface;
  // coolers can be treated as coolers or as generic components
  // below two props determine which is the case
  sourceCooler?: boolean; // tells if source should be treated as a cooler – special status
  targetCooler?: boolean; // tells if target should be treated as a cooler – special status
}

interface IForm {
  name: string;
  area: number | '';
  useInterfaceMaterial: boolean;
  material?: IThermalInterfaceMaterial | '';
  coolerA?: ISelectOption | '';
  coolerB?: ISelectOption | '';
  componentA?: ISelectOption | '';
  componentB?: ISelectOption | '';
  surfaceA?: ISelectOption | '';
  surfaceB?: ISelectOption | '';
}

type TEndpointKey =
  | 'coolerA'
  | 'componentA'
  | 'surfaceA'
  | 'coolerB'
  | 'componentB'
  | 'surfaceB'
  | null;

const ThermalInterfaceDialog = ({
  control,
  source,
  target,
  sourceCooler,
  targetCooler,
}: IProps) => {
  const classes = useStyles();
  const {
    dialogConfig: { entity: thermalInterface },
  } = control;

  const { thermalInterfaceMaterials, surfaces, components, thermalInterfaces } =
    useActiveEntities();

  const defaultValues: IForm = useMemo(() => {
    const name = `${source.name} <> ${target.name}`;
    const numDuplicates = thermalInterfaces.filter((ti) => ti.name.includes(name)).length;
    return {
      name: `${name}${numDuplicates > 0 ? ` (${numDuplicates})` : ''}`,
      area: '',
      material: '',
      useInterfaceMaterial: true,
    };
  }, [source, target, thermalInterfaces]);

  // Keys for ThermalInterfaces depend on the types of the sides themselves.
  // This state can be referenced across the file after it is first defined
  // REF: endpointKeysDefined
  const [endpointKeys, setEndpointKeys] = useState<{
    source: TEndpointKey;
    target: TEndpointKey;
  }>({
    source: null,
    target: null,
  });

  const options = useMemo(() => {
    const res: { [key: string]: ISelectOption[] } = {
      material: thermalInterfaceMaterials
        ?.filter((m) => m != null)
        .map((m) => {
          return { value: m.id, label: m.name };
        }),
    };

    // Logic to distinguish between source/target types
    // so we can send the right keys to the API
    // and prepopulate the form.
    // REF: endpointKeysDefined
    let [sourceKey, targetKey]: TEndpointKey[] = [null, null];
    if (source) {
      if (!('componentType' in source)) {
        // source is a surface
        res.surfaceA = surfaces
          ?.filter((s) => s != null && s.id === source.id)
          .map((s) => {
            return { value: s.id, label: s.name };
          });
        sourceKey = 'surfaceA';
      } else if (source.componentType === 'COOLER' && sourceCooler) {
        // source is a cooler AND should be treated as such
        res.coolerA = components
          ?.filter((c) => c != null && c.id === source.id)
          .map((c) => {
            return { value: c.id, label: c.name };
          });
        sourceKey = 'coolerA';
      } else {
        // source is a generic component
        res.componentA = components
          ?.filter((c) => c != null && c.id === source.id)
          .map((c) => {
            return { value: c.id, label: c.name };
          });
        sourceKey = 'componentA';
      }
    }
    if (target) {
      // target is a surface
      if (!('componentType' in target)) {
        res.surfaceB = surfaces
          ?.filter((s) => s != null && s.id === target.id)
          .map((s) => {
            return { value: s.id, label: s.name };
          });
        targetKey = 'surfaceB';
      } else if (target.componentType === 'COOLER' && targetCooler) {
        // target is a cooler AND should be treated as such
        res.coolerB = components
          ?.filter((c) => c != null && c.id === target.id)
          .map((c) => {
            return { value: c.id, label: c.name };
          });
        targetKey = 'coolerB';
      } else {
        // target is a generic component
        res.componentB = components
          ?.filter((c) => c != null && c.id === target.id)
          .map((c) => {
            return { value: c.id, label: c.name };
          });
        targetKey = 'componentB';
      }
    }
    setEndpointKeys({ source: sourceKey, target: targetKey });
    return res;
  }, [
    source,
    target,
    sourceCooler,
    targetCooler,
    thermalInterfaceMaterials,
    components,
    surfaces,
    setEndpointKeys,
  ]);

  const entityForm = useEntityForm<IThermalInterface, IForm>({
    entityTypeText: 'Thermal Interface',
    entityDialogControl: control,
    additionalCreateValues: {
      // determine types of sides
      sideA:
        source && 'componentType' in source
          ? source.componentType === 'COOLER' && sourceCooler
            ? 'COOLER'
            : 'COMPONENT'
          : 'SURFACE',
      sideB:
        target && 'componentType' in target
          ? target.componentType === 'COOLER' && targetCooler
            ? 'COOLER'
            : 'COMPONENT'
          : 'SURFACE',
    },
    defaultValues: defaultValues,
    validationSchema: validation,
    valuesToRemove: ['useInterfaceMaterial'],
    formikOptionalParams: {
      useGuidance,
      options,
      allowedEmptyFields: ['area', 'material'],
      translateIn: (thermalInterface, defaultValues, options) => {
        return {
          ...translateIn(thermalInterface, defaultValues, options),
          useInterfaceMaterial: Boolean(thermalInterface?.material),
        };
      },
      translateOut: (values, allowedEmptyFields) => {
        if (endpointKeys.source && endpointKeys.target) {
          values[endpointKeys.source] = options[endpointKeys.source][0];
          values[endpointKeys.target] = options[endpointKeys.target][0];
        }
        return translateOut(values, allowedEmptyFields, options);
      },
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setValues, setFieldValue } = formik;
  useEffect(() => {
    // Mark form as dirty when making new interface
    // Otherwise, all default values can't be used
    if (!thermalInterface)
      setFieldValue(
        'useInterfaceMaterial',
        endpointKeys.source !== 'surfaceA' && endpointKeys.target !== 'surfaceB'
      );
  }, [setFieldValue, endpointKeys, thermalInterface]);

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            label="Thermal Interface Name"
            placeholder="Name"
            {...getFieldProps('name')}
            autoFocus
          />
        </div>
        {
          // Prepopulate form for interface, since select
          // isn't actually the mechanism used to select
          // source and target components
          endpointKeys.source &&
            options[endpointKeys.source] &&
            endpointKeys.target &&
            options[endpointKeys.target] && (
              <div className={classes.inputGroup}>
                <LabeledSelect
                  {...getFieldProps(endpointKeys.source)}
                  label="Side A"
                  options={options[endpointKeys.source]}
                  value={options[endpointKeys.source][0]}
                  isDisabled
                />
                <LabeledSelect
                  {...getFieldProps(endpointKeys.target)}
                  label="Side B"
                  options={options[endpointKeys.target]}
                  value={options[endpointKeys.target][0]}
                  isDisabled
                />
              </div>
            )
        }
        <div className={classes.inputGroup}>
          <LabeledCheckbox
            {...getFieldProps('useInterfaceMaterial')}
            label="Assign Thermal Interface Material"
            formikOnChange={() => {
              setValues({ ...values, material: '', area: '' });
            }}
            disabled={endpointKeys.source === 'surfaceA' || endpointKeys.target === 'surfaceB'}
          />
          {values.useInterfaceMaterial && (
            <>
              <ThermalAccent header="Thermal Interface Material">
                <LabeledSelect options={options.material} {...getFieldProps('material')} />
              </ThermalAccent>
              <LabeledInput
                {...getFieldProps('area')}
                label="Area"
                type="number"
                endAdornment={<InputAdornment position="end">m²</InputAdornment>}
              />
            </>
          )}
        </div>
      </div>
    </EntityDialog>
  );
};

export default ThermalInterfaceDialog;
