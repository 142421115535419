import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  playbackViewer: ({ playbackViewerHeight }) => ({
    width: '100%',
    // height: '50%',
    height: playbackViewerHeight, // TODO: Temporary fix for small sat, consider fixing or rewriting old conditional sizing
    // [theme.breakpoints.up('lg')]: {
    //   height: '100%',
    // },
    // [theme.breakpoints.up('mdLg')]: {
    //   '& > div': {
    //     height: 'auto',
    //   },
    // },
  }),
  cesiumViewer: (props) => ({
    // aspectRatio: props.fitHeight,
    height: '100%',
    // minimum height for very small screens
    // [theme.breakpoints.up('lg')]: {
    //   // increase minimum height for larger screens that aren't very tall, otherwise cesium viewer becomes smaller than the other components with the aspect ratio property
    //   minHeight: 610,
    // },
    // }),
    // expandedViewer: (props) => ({
    //   height: props.fitHeight ? `calc(100vh - ${props.viewerPosition}px - 3em)` : 800, // last em is to ensure there is space at the bottom of the viewer
    //   [theme.breakpoints.up('mdLg')]: {
    //     height: !props.fitHeight && 1000,
    //   },
  }),
  buttonContainer: {
    display: 'flex',
    position: 'absolute',
    top: 2,
    left: 7,
    flexDirection: 'column',
    alignItems: 'flex-start',
    // spacing for the widgets and WGroups changes and the button container is absolutely positioned
    // so we shift the positioning slightly at this breakpoint to align with the default cesium buttons
    [theme.breakpoints.up('xl')]: {
      flexDirection: 'row',
    },
  },
  viewerButton: {
    paddingRight: '5px',
    '& .MuiButton-label > div': {
      '& div': {
        fontSize: '.75rem',
      },
    },
  },
  followSpacecraftButton: {
    [theme.breakpoints.up('xl')]: {
      padding: '0 5px',
    },
  },
  playbackViewerContent: {
    height: '100%',
  },
}));

export default useStyles;
