import { useState, useEffect } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import { useFormikForm, useSnackbar } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import * as Yup from 'yup';
import { setActiveMissionVersionId } from 'components/RootView/missionExplorerSlice';
import DeleteEntityDialog from 'components/general/dialogs/DeleteEntityDialog';
import { useDispatch } from 'react-redux';
import { SatelliteApi } from 'middleware/SatelliteApi/api';

const defaultValues = {
  name: '',
  description: '',
};

const branchSchema = Yup.object().shape({
  name: Yup.string()
    .required('A branch name is required')
    .max(64, 'Branch name must be no more than 64 characters'),
  description: Yup.string().max(1000, 'Branch description must be no more than 1000 characters'),
});

const BranchDialog = ({ dialogControl }) => {
  const { dialogConfig: config, closeDialog: onClose } = dialogControl;
  const { open, action, entity: branch } = config;

  const dispatch = useDispatch();
  const {
    MissionVersion: {
      actions: { updateMissionVersion, deleteMissionVersion, branchOffOfBranch },
    },
  } = SatelliteApi;

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const alreadyExistsMessage = 'A branch with that name already exists';
  const notFoundMessage =
    'Desired branch either does not exist or is not accessible from this account. Refresh to see current branches.';

  const editBranch = (values) => {
    setLoading(true);
    dispatch(
      updateMissionVersion({
        id: branch.id,
        ...values,
        successCallback: () => {
          dispatch(setActiveMissionVersionId(branch.id));
          onClose();
          enqueueSnackbar('Branch updated successfully.', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          let errorMessage;
          if (response.error.message.includes('already exists')) {
            errorMessage = alreadyExistsMessage;
          } else {
            errorMessage = response.error.message;
          }
          enqueueSnackbar(errorMessage);
          setLoading(false);
        },
      })
    );
  };

  const branchBranch = (values) => {
    setLoading(true);
    dispatch(
      branchOffOfBranch({
        id: branch.id,
        ...values,
        successCallback: (response) => {
          dispatch(setActiveMissionVersionId(response.id));
          onClose();
          enqueueSnackbar('Created new branch from branch successfully.', {
            variant: 'success',
          });
          setLoading(false);
        },
        failureCallback: (response) => {
          let errorMessage;
          if (response.error.message.includes('Uniqueness violation')) {
            errorMessage = alreadyExistsMessage;
          } else if (response.error.code === 'RESOURCE_NOT_FOUND') {
            errorMessage = notFoundMessage;
          } else {
            errorMessage = response.error.message;
          }
          enqueueSnackbar(errorMessage);
          setLoading(false);
        },
      })
    );
  };

  // this config is only used for actions 'edit' and 'branch', but the formik hook can't be called conditionally, so obj
  // also needs to be able to be keyed into on 'delete' although it doesn't use anything.
  const dialogConfig = {
    branch: {
      prompt: 'Create branch from branch',
      submit: branchBranch,
      subheader: `Edit the name and optional description below.\n\nNote: The new branch will be a copy of "${branch?.name}", including all committed work and the commit history. Note: non-committed changes will not be copied.`,
    },
    edit: { prompt: 'Edit branch', submit: editBranch, subheader: '' },
    delete: { prompt: '', submit: () => undefined, subheader: '' },
  };
  const { prompt, submit, subheader } = dialogConfig[action || 'edit']; // default to edit for when action is undefined (dialog won't ever be showing when action is undefined so doesn't matter)

  const { formik } = useFormikForm(defaultValues, submit, branchSchema, branch, {
    allowedEmptyFields: ['description'],
  });
  const { getFieldProps, handleSubmit, setFieldValue } = formik;

  // Set the name in the new branch dialog if branching a new branch
  useEffect(() => {
    if (branch && action === 'branch') {
      setFieldValue('name', branch.name + ' (Copy)');
    }
  }, [action, branch, setFieldValue]);

  if (action === 'delete') {
    return (
      <DeleteEntityDialog
        action={deleteMissionVersion}
        entity={branch}
        entityTypeText={'Branch'}
        childEntities="targets, pointing modes, conditions, operational modes"
        setup={() => {
          dispatch(setActiveMissionVersionId(undefined));
        }}
        onClose={onClose}
        open={open}
        skipHandleRunningSimulation
      />
    );
  }

  return (
    <Dialog prompt={prompt} open={open} onSubmit={handleSubmit} onClose={onClose} loading={loading}>
      {subheader && <p style={{ whiteSpace: 'pre-line' }}>{subheader}</p>}
      <LabeledInput
        {...getFieldProps('name')}
        label="Branch Name"
        type="text"
        placeholder="Branch Name"
        autoFocus
      />
      <LabeledInput
        {...getFieldProps('description')}
        label="Branch Description"
        type="text"
        placeholder="Branch Description"
        multiline
        rows={4}
        optional
      />
    </Dialog>
  );
};

export default BranchDialog;
