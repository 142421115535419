import { useState, useCallback } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { IErrorResponse, IGenericObject } from 'components/general/types';
import { useDispatch } from 'react-redux';
import { IConflictsObj } from '../index';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import useStyles from './styles';
import { useSelectById, useSnackbar } from 'hooks';
import ConflictSection, { CurrentOrIncoming } from './ConflictSection';
import { mergeSuccessMessage } from '../index';
interface IProps {
  dialogControl: TEntityDialogControl<IConflictsObj>;
}

const ConflictResolutionDialog = ({ dialogControl }: IProps) => {
  const {
    dialogConfig: { open, entity: conflictsObj },
    closeDialog,
  } = dialogControl;

  const resolutionsInitState = new Array(conflictsObj?.conflicts?.length).fill('');
  const [resolutions, setResolutions] = useState<string[]>(resolutionsInitState);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { MissionVersion } = SatelliteApi;
  const { enqueueSnackbar } = useSnackbar();

  const currentBranch = useSelectById('MissionVersion', conflictsObj?.currentBranch || 0);
  const incomingBranch = useSelectById('MissionVersion', conflictsObj?.incomingBranch || 0);

  const classes = useStyles();

  const extendCloseDialog = useCallback(() => {
    setResolutions(resolutionsInitState);
    closeDialog();
  }, [resolutionsInitState, closeDialog]);

  const merge = () => {
    if (resolutions.includes('')) {
      enqueueSnackbar('Not all conflicts have been resolved. Please resolve and retry.');
      return;
    }
    setLoading(true);
    dispatch(
      MissionVersion.actions.gitMerge({
        id: conflictsObj?.currentBranch,
        modelId: conflictsObj?.incomingBranch,
        resolutions,
        successCallback: (response: IGenericObject) => {
          setLoading(false);
          enqueueSnackbar(...mergeSuccessMessage(incomingBranch.name, currentBranch.name));
          extendCloseDialog();
        },
        failureCallback: (response: IErrorResponse) => {
          setLoading(false);
          enqueueSnackbar(response.error.message);
        },
      })
    );
  };

  const setCurrentOrIncoming = useCallback(
    (curOrInc: 'current' | 'incoming', index: number) => {
      setResolutions((prevArr) => {
        const newArr = [...prevArr];
        newArr[index] = newArr[index] === curOrInc ? '' : curOrInc;
        return newArr;
      });
    },
    [setResolutions]
  );

  if (!currentBranch) return null;

  const instructions = `The sections below display the conflicting fields on corresponding blocks in your branch. In order to proceed with your merge of "${incomingBranch.name}" into "${currentBranch.name}", please select from each section which side you would like to keep. You can expand the hidden (unchanged) lines if desired.`;

  return (
    <Dialog
      open={open}
      onClose={extendCloseDialog}
      prompt="Resolve Branch Conflicts"
      submitActionText="Merge"
      onSubmit={merge}
      loading={loading}
      xxlarge
      disableSubmit={resolutions.includes('')}
    >
      <div>{instructions}</div>
      <div className={classes.titleContainer}>
        <h3 className={classes.title}>{currentBranch.name}</h3>
        <h3 className={classes.title}>{incomingBranch.name}</h3>
      </div>
      <div className={classes.resolutionRoot}>
        {conflictsObj?.conflicts.map((conflict, i) => (
          <ConflictSection
            i={i}
            conflict={conflict}
            resolutions={resolutions as CurrentOrIncoming[]}
            setCurrentOrIncoming={setCurrentOrIncoming}
            key={JSON.stringify(conflict)}
          />
        ))}
      </div>
    </Dialog>
  );
};

export default ConflictResolutionDialog;
