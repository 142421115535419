import { makeGuidance } from 'hooks';

const fovConstraintPermanentGuidance = 'Select a Reference Vector to the Target of the constraint. Constraints for sensor FoVs can be either a keep in (ex: Earth Sensors need to have the Earth in view to operate properly, Sun Sensors need to keep in the sun) or a keep out (Star Trackers are sensitive to light and need to keep large/close/bright sources out of view). Keep out constraints can be destructive, meaning that if the sensor accidentally operates in view of the reference, then the sensor will be damaged. Damaged sensors raise a simulation Termination Event.';

export const useGuidance = makeGuidance({
  _default: {
    heading: 'Field of View Constraint',
    body: fovConstraintPermanentGuidance,
  },
  
});
