import { DP_SCHEMA, SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';
import { THERMAL } from 'components/AgentTemplateEditView/menu/thermal';
import {
  tsComponentDissipationDPSchema,
  tsSurfaceRadiativeHeatTransferDPSchema,
  tsThermalInterfaceDPSchema,
  tsTempControllerDPSchema,
  tsComponentTempsDPSchema,
  tsSurfaceTempsDPSchema,
} from '../AnalyzeBoards/ThermalAnalyzeBoards/timeSeries';

import { routePathsCommon } from 'routes';
const { PLAYBACK, TS } = routePathsCommon;

const COMPONENT_DISSIPATION = 'component-dissipation';
const SURFACE_RADIATION = 'surface-radiation';
const THERMAL_INTERFACE = 'thermal-interface';
const TEMP_CONTROLLER = 'temp-controller';
const COMPONENT_TEMPS = 'component-temps';
const SURFACE_TEMPS = 'surface-temps';
const agentThermalMenu: IMenu['items'] = {
  [THERMAL]: {
    [TITLE]: 'Thermal',
    [SUB_ITEMS]: {
      [PLAYBACK]: {
        [TITLE]: 'Playback',
      },
      [TS]: {
        [TITLE]: 'Time Series',
        [SUB_ITEMS]: {
          [COMPONENT_DISSIPATION]: {
            [TITLE]: 'Component Dissipation',
            [DP_SCHEMA]: tsComponentDissipationDPSchema,
          },
          [SURFACE_RADIATION]: {
            [TITLE]: 'Surface Radiative Heat Transfer',
            [DP_SCHEMA]: tsSurfaceRadiativeHeatTransferDPSchema,
          },
          [TEMP_CONTROLLER]: {
            [TITLE]: 'Temperature Controllers',
            [DP_SCHEMA]: tsTempControllerDPSchema,
          },
          [THERMAL_INTERFACE]: {
            [TITLE]: 'Thermal Interfaces',
            [DP_SCHEMA]: tsThermalInterfaceDPSchema,
          },
          [COMPONENT_TEMPS]: {
            [TITLE]: 'Component Temperatures',
            [DP_SCHEMA]: tsComponentTempsDPSchema,
          },
          [SURFACE_TEMPS]: {
            [TITLE]: 'Surface Temperatures',
            [DP_SCHEMA]: tsSurfaceTempsDPSchema,
          },
        },
      },
    },
  },
};
export const wGroupIndicesAgentThermal = {
  THERMAL: `${THERMAL}*`,
  PLAYBACK: `${THERMAL}.${PLAYBACK}`,
};

export default agentThermalMenu;
