import Constraints from './Constraints';
import BaseNode from '../general/BaseNode';
import ACSNodeHandle, { Position } from '../general/ACSNodeHandle';
import { ISensorNodeData } from '../general/types';

const SensorNode = ({ data }: ISensorNodeData) => {
  return (
    <BaseNode title={data.title} subtitle={data.subtitle}>
      <Constraints legend="Keep In" constraints={data.keepIns} />
      <Constraints legend="Keep Out" constraints={data.keepOuts} />

      <ACSNodeHandle type="source" position={Position.Right} />
    </BaseNode>
  );
};

export default SensorNode;
