import { useContext } from 'react';
import ViewContainer from 'components/general/ViewContainer';
import Nav from 'components/general/Nav';
import ViewPort from 'components/general/ViewPort';
import AnalyzeBoards from './AnalyzeBoards';
import DataPlotting from 'components/general/DataPlotting';
import AgentAnalyzeMenu from './menu';
import { useActiveEntities } from 'hooks';
import { RangeProvider, TimeProvider } from 'providers';
import { DataContext } from 'providers/DataProvider';
import { getSearchParams } from 'routes';
import SimulationControls from 'components/general/SimulationControls';
import ViewPortInlay from 'components/general/ViewPortInlay';
import AnalyticsProvider from 'providers/AnalyticsProvider';

const AgentAnalyzeView = () => {
  const { staticModels } = useContext(DataContext);
  const { repo, branch } = useActiveEntities();
  const { agentId } = getSearchParams();

  return (
    <ViewContainer>
      <Nav
        // TODO: Get scenarioModel from ActiveBranchProvider to use here
        contextName={
          staticModels.scenario && 'Analyze - ' + staticModels.scenario._blocksById[agentId].name
        }
        contextHeader={repo.name}
        control={<SimulationControls />}
      ></Nav>
      <ViewPort minWidth={650}>
        <AnalyticsProvider>
          {
            // If data is here, show analyze boards
            branch.analyzeState?.dataState && staticModels?.agents[agentId] ? (
              <TimeProvider>
                <AnalyzeBoards />
                <RangeProvider>
                  <DataPlotting menu={AgentAnalyzeMenu} />
                </RangeProvider>
              </TimeProvider>
            ) : (
              // Otherwise, show placeholders
              <ViewPortInlay text="Fetch a window of data to view analytics" />
            )
          }
        </AnalyticsProvider>
      </ViewPort>
    </ViewContainer>
  );
};

export default AgentAnalyzeView;
