import { DP_SCHEMA, SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';
import { GNC } from 'components/AgentTemplateEditView/menu/gnc';
import { routePathsCommon } from 'routes';
import {
  tsAdodDPSchema,
  tsAttitudeControlDPSchema,
  tsPerturbationsDPSchema,
} from '../AnalyzeBoards/GncAnalyzeBoards/timeSeries';

const { PLAYBACK, TS } = routePathsCommon;

const AD_OD = 'ad-od';
const PERTURBATIONS = 'perturbations';
const ATTITUDE_CONTROL = 'attitude-control';

const agentGncMenu: IMenu['items'] = {
  [GNC]: {
    [TITLE]: 'GNC',
    [SUB_ITEMS]: {
      [PLAYBACK]: {
        [TITLE]: 'Playback',
      },
      [TS]: {
        [TITLE]: 'Time Series',
        [SUB_ITEMS]: {
          [AD_OD]: {
            [TITLE]: 'AD & OD',
            [DP_SCHEMA]: tsAdodDPSchema,
          },
          [PERTURBATIONS]: {
            [TITLE]: 'Perturbations',
            [DP_SCHEMA]: tsPerturbationsDPSchema,
          },
          [ATTITUDE_CONTROL]: {
            [TITLE]: 'Attitude Control',
            [DP_SCHEMA]: tsAttitudeControlDPSchema,
          },
        },
      },
    },
  },
};

export const wGroupIndicesAgentGnc = {
  GNC: `${GNC}*`,
  PLAYBACK: `${GNC}.${PLAYBACK}`,
  TS_ORBIT: `${GNC}.${TS}.${AD_OD}`,
  TS_ATTITUDE: `${GNC}.${TS}.${PERTURBATIONS}`,
  TS_BFV: `${GNC}.${TS}.${ATTITUDE_CONTROL}`,
};

export default agentGncMenu;
