import CircularProgress from 'components/general/CircularProgress';
import StyledButton from 'components/general/StyledButton';
import { setLatestJobId } from 'components/RootView/missionExplorerSlice';
import { useLatestJob, useSnackbar } from 'hooks';
import useMountStatus from 'hooks/useMountStatus';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { ActiveBranchContext } from 'providers';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import theme from 'theme';
import { ModuleVables, TSimulationStatus } from 'utils/vable';
import StyledDivider from '../StyledDivider';
import { IErrorResponse, IJob } from '../types';
import DataWindow from './DataWindow';
import useStyles from './styles';

export const isJobRunning = (job: IJob) =>
  job?.status === ModuleVables.SimulationStatuses.RUNNING.value ||
  job?.status === ModuleVables.SimulationStatuses.PENDING.value;

const SimulationControls = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const {
    Job: {
      actions: { createJob, abortJob, getJob },
    },
    MissionVersion: {
      actions: { updateAnalyzeState },
    },
  } = SatelliteApi;

  // Job data
  const latestJob = useLatestJob();
  const {
    branch: { id },
  } = useContext(ActiveBranchContext);
  const progress = useMemo(() => latestJob?.progress?.percentComplete, [latestJob]);
  const running = useMemo(() => isJobRunning(latestJob), [latestJob]);

  // Component data dictating when polling can run
  const isMounted = useMountStatus();
  const [loading, setLoading] = useState(true);
  const timeout = useRef<NodeJS.Timeout>();

  const run = useCallback(() => {
    const _getStatus = () => {
      dispatch(
        getJob({
          branchId: id,
          queryParams: { latest: undefined },
          successCallback: (response: IJob) => {
            if (isMounted()) {
              if (
                response.status === ModuleVables.SimulationStatuses.RUNNING.value ||
                response.status === ModuleVables.SimulationStatuses.PENDING.value
              ) {
                timeout.current = setTimeout(run, 1000);
              } else if (
                response.status !== ModuleVables.SimulationStatuses.SUCCEEDED.value &&
                response.message
              ) {
                enqueueSnackbar('Simulation halted early: ' + response.message.split('\n')[0]);
                setLoading(false);
                dispatch(updateAnalyzeState({ id, fetchWhenTrue: true })); // Trigger fetch if no data is cached
              } else {
                setLoading(false);
                dispatch(updateAnalyzeState({ id, fetchWhenTrue: true })); // Trigger fetch if no data is cached
              }
            }
          },
          failureCallback: (response: IErrorResponse) => {
            if (isMounted()) {
              enqueueSnackbar(response?.error?.message || 'Error fetching job.');
              setLoading(false);
            }
          },
        })
      );
    };

    if (isMounted()) _getStatus();
  }, [dispatch, getJob, enqueueSnackbar, id, isMounted, updateAnalyzeState]);

  // Clear timeout on unmount
  useEffect(() => {
    return () => clearTimeout(timeout.current);
  }, []);

  // When latest job is first fetched,
  // set loading to false, and start polling if appropriate
  const [gotFirstJob, setGotFirstJob] = useState(false);
  useEffect(() => {
    if (latestJob && !gotFirstJob && isMounted()) {
      setGotFirstJob(true);
      setLoading(false);
      if (running) run();
    }
  }, [latestJob, gotFirstJob, run, isMounted, running]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.toolStatus}>
          <CircularProgress
            size={30}
            value={progress ?? 0}
            loading={loading}
            status={latestJob?.status || ModuleVables.SimulationStatuses.READY.value}
          />
        </div>
        {
          <h5>
            {
              latestJob?.status
                ? loading
                  ? running
                    ? 'Aborting...' // Latest job + loading + running sim
                    : 'Deploying...' // Latest job + loading + not yet running
                  : ModuleVables.SimulationStatuses[latestJob?.status as TSimulationStatus]
                      ?.label || 'Loading...' // Latest job + no loading, plus fallback for race condition error
                : loading
                ? gotFirstJob
                  ? 'Deploying...' // No job + loading + first fetch completed
                  : 'Loading...' // No job + loading + first fetch incomplete
                : 'Ready' // No job + not loading
            }
          </h5>
        }
        <StyledButton
          className={classes.toolBtn}
          type="button"
          min
          onClick={() => {
            setLoading(true);
            // Launch job
            if (!running) {
              dispatch(
                createJob({
                  branchId: id,
                  successCallback: async (response: IJob) => {
                    setLoading(false);
                    dispatch(setLatestJobId(response.id));
                    run();
                  },
                  failureCallback: (response: IErrorResponse) => {
                    enqueueSnackbar(
                      response?.error?.message ||
                        `Error creating job. Please check that your scenario and associated agent templates are 
                        fully populated, or contact us at support@sedarotech.com if this issue persists.`
                    );
                    setLoading(false);
                  },
                })
              );
            }
            // Abort job
            else {
              setLoading(true);
              dispatch(
                abortJob({
                  branchId: id,
                  id: latestJob?.id,
                  failureCallback: (response: IErrorResponse) => {
                    enqueueSnackbar(response?.error?.message || 'Error aborting job.');
                    setLoading(false);
                  },
                })
              );
            }
          }}
          disabled={loading}
          framed={running}
          replaceSpinner
        >
          {running ? 'Abort' : 'Simulate'}
        </StyledButton>
      </div>
      {latestJob?.dateCreated && (
        <>
          <p className={classes.date}>
            Last simulated: {latestJob.dateCreated.local().format('M/DD/YY h:mma')}
          </p>
          {(latestJob.status === ModuleVables.CompletedStatuses.ERROR.value ||
            latestJob.status === ModuleVables.CompletedStatuses.FAILED.value) &&
            latestJob.message && (
              <>
                <StyledDivider
                  style={{
                    width: 30,
                    margin: '0 auto',
                  }}
                />
                <p className={classes.errorMessage}>
                  <strong style={{ color: theme.palette.error.main }}>Error: </strong>
                  {latestJob.message.split('\n')[0]}
                </p>
              </>
            )}

          <StyledDivider />
          <DataWindow />
        </>
      )}
    </>
  );
};

export default SimulationControls;
