import { useActiveEntities } from 'hooks';
import { IColumn } from 'components/general/types';
import { SensorVables, TSensorTypes } from 'utils/vable';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { ISensor } from 'components/general/types/gnc';
import guidance from './guidance';
import SensorDialog from './SensorDialog';

interface IProps {
  index: string;
}

const sensorsColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (row) => SensorVables.ComponentType[row.componentType as TSensorTypes].label,
  },
];

const SensorsSegment = ({ index }: IProps) => {
  const { sensors } = useActiveEntities();

  return (
    <EntityTableSegment<ISensor>
      title="Sensors"
      index={index}
      guidance={guidance}
      entityColumns={sensorsColumns}
      entityData={sensors}
      DialogComponent={SensorDialog}
      modelName="sensor"
    ></EntityTableSegment>
  );
};

export default SensorsSegment;
