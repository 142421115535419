import WGroup from 'components/general/WGroup';
import Wizard from 'components/general/wizards/Wizard';
import { wGroupIndicesATGNC } from 'components/AgentTemplateEditView/menu/gnc';

import ActuatorsSegment from './ActuatorsSegment';
import AlgorithmsSegment from './AlgorithmsSegment';
import PointModesSegment from './PointingModesSegment';
import SensorsSegment from './SensorsSegment';
import SpacecraftSegment from './SpacecraftSegment';

const GNCEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATGNC.GNC}>
      <Wizard>
        <SpacecraftSegment index={wGroupIndicesATGNC.SPACECRAFT} />
        <SensorsSegment index={wGroupIndicesATGNC.SENSORS} />
        <ActuatorsSegment index={wGroupIndicesATGNC.ACTUATORS} />
        <AlgorithmsSegment index={wGroupIndicesATGNC.ALGORITHMS} />
        <PointModesSegment index={wGroupIndicesATGNC.POINT_MODES} />
      </Wizard>
    </WGroup>
  );
};

export default GNCEditBoard;
