import TargetsEditBoard from './TargetsEditBoard';
import CDHEditBoard from './CDHEditBoard';
import CommsEditBoard from './CommsEditBoard';
import GNCEditBoard from './GNCEditBoard';
import PowerEditBoard from './PowerEditBoard';
import ThermalEditBoard from './ThermalEditBoard';

const EditBoards = () => {
  return (
    <>
      <TargetsEditBoard />
      <GNCEditBoard />
      <CDHEditBoard />
      <PowerEditBoard />
      <ThermalEditBoard />
      <CommsEditBoard />
    </>
  );
};

export default EditBoards;
