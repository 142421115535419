import { useContext, useMemo, useCallback } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useEntityForm, useActiveEntities } from 'hooks';
import { ActuatorVables } from 'utils/vable';
import { BfVectorAccent, PowerAccent } from 'components/general/Accent/variants';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { ISelectOption } from 'components/general/types';
import validation from './validation';
import { useGuidance } from './guidance';
import getThermalProps from 'hooks/getThermalProps';
import { IActuator } from 'components/general/types/gnc';
import { SpacecraftContext } from 'providers';
import { InputAdornment } from '@material-ui/core';
import { translateIn, translateOut } from 'utils/forms';
import { SubsystemVables } from 'utils/vable';

interface IForm {
  name: string;
  manufacturer: string;
  partNumber: string;
  componentType: ISelectOption | '';
  bodyFrameVector: ISelectOption | '';
  powerSource: ISelectOption | '';
  ratedMagneticMoment: number | '';
  powerAtRatedMagneticMoment: number | '';
  inertia: number | '';
  ratedTorque: number | '';
  ratedMomentum: number | '';
  efficiency: number | '';
}

const defaultValues: IForm = {
  name: '',
  manufacturer: '',
  partNumber: '',
  componentType: '',
  bodyFrameVector: '',
  powerSource: '',
  ratedMagneticMoment: '',
  powerAtRatedMagneticMoment: '',
  inertia: '',
  ratedTorque: '',
  ratedMomentum: '',
  efficiency: '',
};

interface IProps {
  control: TEntityDialogControl<IActuator>;
}
const ActuatorDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { action },
  } = control;
  const { subsystems, bodyFrameVectors, busRegulators, powerProcessor } = useActiveEntities();
  const { setSpacecraftDialogConfig, SpacecraftTabs } = useContext(SpacecraftContext);

  // Set up styles
  const classes = useStyles();

  const options = useMemo(() => {
    return {
      bodyFrameVector: bodyFrameVectors.map((c) => {
        return { value: c.id, label: c.name };
      }),
      componentType: ActuatorVables.ComponentType.options,
      powerSource: [{ value: powerProcessor.id, label: powerProcessor.name }].concat(
        busRegulators.map((br) => {
          return { value: br.id, label: br.name };
        })
      ),
    };
  }, [bodyFrameVectors, busRegulators, powerProcessor]);

  const customTranslateIn = useCallback(
    (actuator, defaultValues, options, datetimes, percentages) => {
      // Power source can be powerProcessor or busRegulator
      actuator.powerSource = actuator.busRegulator || actuator.powerProcessor;
      return translateIn(actuator, defaultValues, options, datetimes, percentages);
    },
    []
  );

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options, datetimes, percentages) => {
      const result = translateOut(values, allowedEmptyFields, options, datetimes, percentages);
      // Power source can be powerProcessor or busRegulator
      if (result.powerSource === powerProcessor.id) {
        result.powerProcessor = result.powerSource;
        result.busRegulator = null;
      } else {
        result.busRegulator = result.powerSource;
        result.powerProcessor = null;
      }
      delete result.powerSource;

      return result;
    },
    [powerProcessor]
  );

  const { thermalPropsInput, thermalDefaultValues } = getThermalProps();

  const entityForm = useEntityForm<IActuator, IForm & typeof thermalDefaultValues>({
    entityTypeText: 'Actuator',
    entityDialogControl: control,
    defaultValues: { ...defaultValues, ...thermalDefaultValues },
    additionalCreateValues: {
      subsystem: subsystems.find((s) => s.category === SubsystemVables.Categories.GNC.value)?.id,
    },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
      percentages: ['efficiency'],
      allowedEmptyFields: ['manufacturer', 'partNumber'],
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Actuator Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
        </div>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('manufacturer')}
            label="Manufacturer"
            placeholder="Manufacturer"
            type="text"
            optional
          />
          <LabeledInput
            {...getFieldProps('partNumber')}
            label="Part Number"
            placeholder="Part Number"
            type="text"
            optional
          />
        </div>
        <div className={classes.inputGroup}>
          <BfVectorAccent
            onAddAction={() =>
              setSpacecraftDialogConfig({ open: true, tabNumber: SpacecraftTabs.GEOMETRY })
            }
          >
            <LabeledSelect
              {...getFieldProps('bodyFrameVector')}
              label="Torque Body Frame Vector"
              options={options.bodyFrameVector}
            />
          </BfVectorAccent>
          <PowerAccent header="Power Source">
            <LabeledSelect {...getFieldProps('powerSource')} options={options.powerSource} />
          </PowerAccent>
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('componentType')}
            label="Actuator Type"
            options={options.componentType}
            isDisabled={action !== 'create'}
          />

          {typeof values.componentType === 'object' &&
            values.componentType.value === ActuatorVables.ComponentType.MAGNETORQUER.value && (
              <>
                <div className={classes.indent}>
                  <LabeledInput
                    label="Rated Magnetic Moment"
                    {...getFieldProps('ratedMagneticMoment')}
                    type="number"
                    endAdornment={<InputAdornment position="end">A·m²</InputAdornment>}
                  />
                  <LabeledInput
                    label="Power At Rated Magnetic Moment"
                    {...getFieldProps('powerAtRatedMagneticMoment')}
                    type="number"
                    endAdornment={<InputAdornment position="end">W</InputAdornment>}
                  />
                </div>
              </>
            )}
          {typeof values.componentType === 'object' &&
            values.componentType.value === ActuatorVables.ComponentType.REACTION_WHEEL.value && (
              <div className={classes.indent}>
                <LabeledInput
                  label="Inertia"
                  {...getFieldProps('inertia')}
                  type="number"
                  endAdornment={<InputAdornment position="end">kg·m²</InputAdornment>}
                />
                <LabeledInput
                  label="Rated Torque"
                  {...getFieldProps('ratedTorque')}
                  type="number"
                  endAdornment={<InputAdornment position="end">N·m</InputAdornment>}
                />
                <LabeledInput
                  label="Rated Angular Momentum"
                  {...getFieldProps('ratedMomentum')}
                  type="number"
                  endAdornment={<InputAdornment position="end">kg·m²/s</InputAdornment>}
                />
                <LabeledInput
                  label="Efficiency"
                  {...getFieldProps('efficiency')}
                  type="number"
                  endAdornment={<InputAdornment position="end">%</InputAdornment>}
                />
              </div>
            )}
        </div>
        <div className={classes.inputGroup}>{thermalPropsInput(getFieldProps)}</div>
      </div>
    </EntityDialog>
  );
};

export default ActuatorDialog;
