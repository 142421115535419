const thermalChunk =
  'Specify the maximum and minimum temperatures for this Component. The thermal capacitance can be estimated by multiplying the specific heat capacity (e.g. Aluminum has a specific heat capacity of 897 J/(kg*K)) of its materials by its mass.';
const thermalHeading = 'Component Thermal Properties';
const componentThermalGuidance = {
  temperature: {
    heading: 'Initial Temperature',
    body: [
      {
        chunk: 'Define the initial temperature of this Component when the simulation begins.',
      },
    ],
  },
  hotTempRating: {
    heading: thermalHeading,
    body: [
      {
        chunk: thermalChunk,
      },
    ],
  },
  coldTempRating: {
    heading: thermalHeading,
    body: [
      {
        chunk: thermalChunk,
      },
    ],
  },
  thermalCapacitance: {
    heading: thermalHeading,
    body: [
      {
        chunk: thermalChunk,
      },
    ],
  },
};
export default componentThermalGuidance;
