import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  branchExplorerRoot: {
    margin: 30,
    maxWidth: 1200,
    minWidth: 400,
  },
  title: {
    borderBottom: '1px solid #dddddd',
    paddingBottom: 10,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  repoInfo: {
    marginBottom: 25,
    maxWidth: '60%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
