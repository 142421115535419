import * as Cesium from 'cesium';
import { cross, dot, multiply, norm, pi, subtract, tan } from 'mathjs';
import { makeStream, useStream } from 'providers/DataProvider';
import { useMemo } from 'react';
import { a2Period, ae2PerigeeAlt, computeMoonEphemeris } from 'utils/orbit';
import { jd2Mjd, mjd2Moment } from 'utils/time';
import { SurfaceVables } from 'utils/vable';
import {
  cesiumBfVectorLabelOffsetScale,
  cesiumBfVectorScaleDown,
  cesiumBfVectorScaleUp,
  cesiumScaleAltitude,
  cesiumScaleLinearIntercept,
  cesiumScaleLinearSlope,
  enuOffsetHeading,
  enuOffsetPitch,
  enuOffsetRoll,
  perigeeAltScaleCap,
} from './constants';

export const useCesiumData = (
  model,
  modelId,
  perigeeAlt,
  models,
  _state,
  startMjd,
  stopMjd,
  agentToSimulatedAgent
) => {
  // If given a model, use the given model
  // If not given a model, use the first model in the scenario
  // If no models, use nothing
  const [_usedModel, _usedModelConOps] = useMemo(
    () => [
      (model?.conOps ? modelId : undefined) || Object.keys(models)[0],
      model?.conOps?.id || Object.values(models)[0]?._root?.conOps,
    ],
    [model, modelId, models]
  );

  // agents from useActiveEntities may have been updated since the simulation occurred,
  // so the agents list has to come from the simulation itself
  const agents = Object.values(_state?.metaData?.structure?.scenario?.Agent || {});

  const { transposeSeries } = useStream(_usedModel, 'position', `${_usedModelConOps}.attitude`);

  const [startTime, stopTime] = useMemo(
    () => [
      Cesium.JulianDate.fromIso8601(mjd2Moment(startMjd).format()),
      Cesium.JulianDate.fromIso8601(mjd2Moment(stopMjd).format()),
    ],
    [startMjd, stopMjd]
  );

  const targetsData = useMemo(() => {
    return agents.flatMap((agent) => {
      if ((models[agent.id] || agent.peripheral) && agent.id !== modelId) {
        if (agent.peripheral) {
          let position3D = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
          if (agent?.differentiatingState.targetType === 'GROUND_TARGET') {
            position3D = new Cesium.ConstantPositionProperty(
              Cesium.Cartesian3.fromRadians(
                (agent?.differentiatingState.lon * pi) / 180,
                (agent?.differentiatingState.lat * pi) / 180,
                agent?.differentiatingState.alt
              )
            );
            let cesiumTarget = {
              lineOfSight: new Cesium.ConstantProperty(false),
              position3D,
              targetType: agent?.differentiatingState.targetType,
              name: agent.name,
            };
            return cesiumTarget;
          } else if (agent?.differentiatingState.targetType === 'SPACE_TARGET') {
            const orbitId = agent.orbit;
            const {
              series: [, , orbitalElements],
              transposeSeries,
            } = makeStream(
              ['position', `${orbitId}.orbitalElements`],
              _state,
              agentToSimulatedAgent[agent.id]
            );
            let cesiumTarget = {
              targetType: 'SPACE_TARGET',
              name: agent.name,
              orbitalPeriod: a2Period(orbitalElements.a[0]),
              perigeeAlt: ae2PerigeeAlt(orbitalElements.a[0], orbitalElements.e[0]),
              position3D: new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL),
              lineOfSight: new Cesium.ConstantProperty(false),
            };
            for (const [t, position] of transposeSeries()) {
              const time = Cesium.JulianDate.addSeconds(
                startTime,
                (t - startMjd) * 86400,
                new Cesium.JulianDate()
              );
              let targetEciSatPosition = Cesium.Cartesian3.fromElements(
                position.eci[0] * 1000,
                position.eci[1] * 1000,
                position.eci[2] * 1000
              );
              cesiumTarget.position3D.addSample(time, targetEciSatPosition);
            }
            return cesiumTarget;
          }
        } else {
          const agentRoot = models[agent.id]._root;
          const {
            series: [, , , orbitalElements],
            transposeSeries,
          } = makeStream(
            [
              'position',
              `${agentRoot.conOps}.attitude`,
              `${agentRoot.missionOrbit}.orbitalElements`,
            ],
            _state,
            agentToSimulatedAgent[agent.id]
          );
          let cesiumTarget = {
            targetType: 'SPACE_TARGET',
            name: agent.name,
            orbitalPeriod: a2Period(orbitalElements.a[0]),
            perigeeAlt: ae2PerigeeAlt(orbitalElements.a[0], orbitalElements.e[0]),
            position3D: new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL),
            quaternion: new Cesium.SampledProperty(Cesium.Quaternion),
            lineOfSight: new Cesium.ConstantProperty(false),
            cadSignedUrl: models[agent.id]._blocksById[agentRoot.satellite].cadSignedUrl,
            cadScaleFactor: models[agent.id]._blocksById[agentRoot.satellite].cadScaleFactor,
          };
          for (const [t, position, attitude] of transposeSeries()) {
            const time = Cesium.JulianDate.addSeconds(
              startTime,
              (t - startMjd) * 86400,
              new Cesium.JulianDate()
            );
            let targetEciSatPosition = Cesium.Cartesian3.fromElements(
              position.eci[0] * 1000,
              position.eci[1] * 1000,
              position.eci[2] * 1000
            );
            cesiumTarget.position3D.addSample(time, targetEciSatPosition);

            // Created sampled interpolant for Cesium from satellite attitude in ENU
            // Need to apply static HPR offset rotation to ecef to body rotations to be compliant with Cesium ENU
            // The ECEF to ENU offset here only consistently works with CAD models, different offsets are needed
            // for other entities.
            let targetEcef2enuOffsetQuaternion = Cesium.Quaternion.fromHeadingPitchRoll(
              new Cesium.HeadingPitchRoll(enuOffsetHeading, enuOffsetPitch, enuOffsetRoll),
              new Cesium.Quaternion()
            );
            let targetEcef2bodyQuaternion = new Cesium.Quaternion(
              attitude.ecef[0],
              attitude.ecef[1],
              attitude.ecef[2],
              attitude.ecef[3]
            );

            cesiumTarget.quaternion.addSample(
              time,
              Cesium.Quaternion.multiply(
                targetEcef2bodyQuaternion,
                targetEcef2enuOffsetQuaternion,
                new Cesium.Quaternion()
              )
            );
          }
          return cesiumTarget;
        }
      }
      return [];
    });
  }, [agents, _state, startTime, startMjd, models, modelId, agentToSimulatedAgent]);

  const bodyFrameVectors = model.BodyFrameVector.all();
  const surfaces = model.Surface.all();
  const fieldsOfView = model.FieldOfView.all();

  return useMemo(() => {
    if (_usedModel) {
      let time;
      let eciSatPosition;
      let moonPosition;
      let ecef2enuOffsetQuaternion;
      let eci2BodyQuaternion;
      let eci2BodyRotMat;
      let ecef2bodyQuaternion;
      let thisBfVectorPos2;
      let thisBfVectorOffset;
      let thisBfVectorPos2Longer;
      let eciFovPositionOffset;
      let thisSurfaceBfVector;
      let thisSurfaceNormalOrigin;
      let thisSurfaceNormalEndPoint;
      let thisBfVectorScale;
      let thisSurfaceNormal;
      let thisSurfaceLabelPoint;
      let axis;
      let angle;
      let fovQuaternion;
      let rejectH;
      let rejectHDefault;
      let sensorDistance;
      let sensorLengthCartesian;
      let sensorLengthMag;
      let quaternionSampled;
      let eciSatPositionSampled;
      let moonEphem;
      let boreAngle;
      let boreCart;
      let boreQuaternion;
      let eciFovPosition;
      let fovRotMat;
      let heightVec;
      let boreVec;
      let hDefault;
      let hDefaultCart;
      let bfVectors;
      let sunTrackingSurfaces;
      let fovs;

      // Preload necessary non-series props and initialize Sampled Properties
      bfVectors = bodyFrameVectors.map(({ name }) => {
        return {
          bfVectorPos2: new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL),
          bfVectorPos2Longer: new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL),
          name,
        };
      });

      sunTrackingSurfaces = surfaces
        .filter((surface) => surface.motionType === SurfaceVables.MotionTypes.SUN_TRACKING.value)
        .map(({ name }) => {
          return {
            name,
            originPoint: new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL),
            endPoint: new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL),
            labelPoint: new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL),
          };
        });

      fovs = fieldsOfView.map(
        ({
          name,
          halfAngle,
          heightHalfAngle,
          widthHalfAngle,
          boresightBodyFrameVector,
          heightBodyFrameVector,
          fieldOfViewType,
        }) => {
          return {
            name,
            halfAngle,
            heightHalfAngle,
            widthHalfAngle,
            boresightBodyFrameVector,
            heightBodyFrameVector,
            fieldOfViewType,
            position3D: new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL),
            quaternion: new Cesium.SampledProperty(Cesium.Quaternion),
            radius: new Cesium.SampledProperty(Number),
          };
        }
      );

      eciSatPositionSampled = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
      moonPosition = new Cesium.SampledPositionProperty(Cesium.ReferenceFrame.INERTIAL);
      sensorLengthCartesian = new Cesium.SampledProperty(Cesium.Cartesian3);
      sensorLengthMag = new Cesium.SampledProperty(Number);
      quaternionSampled = new Cesium.SampledProperty(Cesium.Quaternion);

      let i = 0;
      for (const [t, position, attitude] of transposeSeries()) {
        // Time
        time = Cesium.JulianDate.addSeconds(
          startTime,
          (t - startMjd) * 86400,
          new Cesium.JulianDate()
        );
        const ephemeris = computeMoonEphemeris(jd2Mjd(time.dayNumber + time.secondsOfDay / 86400));
        moonEphem = new Cesium.Cartesian3.fromElements(
          ephemeris[0] * 1000,
          ephemeris[1] * 1000,
          ephemeris[2] * 1000
        );
        moonPosition.addSample(time, moonEphem);

        // Creating sampled interpolant from satellite positions in ECI
        eciSatPosition = Cesium.Cartesian3.fromElements(
          position.eci[0] * 1000,
          position.eci[1] * 1000,
          position.eci[2] * 1000
        );
        eciSatPositionSampled.addSample(time, eciSatPosition);

        sensorDistance = Math.min(
          perigeeAltScaleCap * 1000,
          Cesium.Cartesian3.magnitude(
            Cesium.Cartesian3.subtract(
              eciSatPosition,
              Cesium.Ellipsoid.WGS84.scaleToGeodeticSurface(eciSatPosition),
              new Cesium.Cartesian3()
            )
          )
        );
        sensorLengthMag.addSample(time, sensorDistance);
        sensorLengthCartesian.addSample(
          time,
          new Cesium.Cartesian3(sensorDistance, sensorDistance, sensorDistance)
        );

        // Created sampled interpolant for Cesium from satellite attitude in ENU
        // Need to apply static HPR offset rotation to ecef to body rotations to be compliant with Cesium ENU
        // The ECEF to ENU offset here only consistently works with CAD models, different offsets are needed
        // for other entities.
        ecef2enuOffsetQuaternion = Cesium.Quaternion.fromHeadingPitchRoll(
          new Cesium.HeadingPitchRoll(enuOffsetHeading, enuOffsetPitch, enuOffsetRoll),
          new Cesium.Quaternion()
        );
        ecef2bodyQuaternion = new Cesium.Quaternion(
          attitude.ecef[0],
          attitude.ecef[1],
          attitude.ecef[2],
          attitude.ecef[3]
        );
        quaternionSampled.addSample(
          time,
          Cesium.Quaternion.multiply(
            ecef2bodyQuaternion,
            ecef2enuOffsetQuaternion,
            new Cesium.Quaternion()
          )
        );

        // BF Vectors are defined by positions, not quaternions, so we need an ECI-to-Body rotation matrix to
        // compute the endpoints of the BF vectors (start points are satellite position in ECI)
        eci2BodyQuaternion = new Cesium.Quaternion(
          attitude.eci[0],
          attitude.eci[1],
          attitude.eci[2],
          attitude.eci[3]
        );
        eci2BodyRotMat = new Cesium.Matrix3.fromQuaternion(
          eci2BodyQuaternion,
          new Cesium.Matrix3()
        );

        // Creating two BF vectors, one with Cesium scaling applied and another without
        let j = 0;
        const cappedPerigeeAlt = Math.min(perigeeAlt, perigeeAltScaleCap); // To limit scale in HEO or deep space
        for (let bfVector of bodyFrameVectors) {
          thisBfVectorOffset = Cesium.Cartesian3.multiplyByScalar(
            Cesium.Matrix3.multiplyByVector(
              eci2BodyRotMat,
              Cesium.Cartesian3.fromArray(bfVector.unitVector),
              new Cesium.Cartesian3()
            ),
            cappedPerigeeAlt <= cesiumScaleAltitude
              ? 1000 * cesiumBfVectorScaleDown * cappedPerigeeAlt
              : (cesiumScaleLinearSlope * cappedPerigeeAlt + cesiumScaleLinearIntercept) *
                  cesiumBfVectorScaleUp,
            new Cesium.Cartesian3()
          );

          thisBfVectorPos2 = Cesium.Cartesian3.add(
            eciSatPosition,
            thisBfVectorOffset,
            new Cesium.Cartesian3()
          );

          thisBfVectorPos2Longer = Cesium.Cartesian3.add(
            eciSatPosition,
            Cesium.Cartesian3.multiplyByScalar(
              thisBfVectorOffset,
              cesiumBfVectorLabelOffsetScale,
              new Cesium.Cartesian3()
            ),
            new Cesium.Cartesian3()
          );

          bfVectors[j].bfVectorPos2.addSample(time, thisBfVectorPos2);
          bfVectors[j].bfVectorPos2Longer.addSample(time, thisBfVectorPos2Longer);
          j++;
        }

        // Sun-Tracking Vectors
        // TODO: condition is "if power", remove this eslint-disable when fixed
        // eslint-disable-next-line no-constant-condition
        if (false /* if power */) {
          j = 0;
          for (let surface of surfaces) {
            if (surface.motionType === SurfaceVables.MotionTypes.SUN_TRACKING.value) {
              // Get articulation angle
              let articulationAngle = surface.series.data[i].articulationAngle;

              // Get reference frame vectors
              thisSurfaceBfVector = surface.bodyFrameVector;
              let bfVector = Cesium.Cartesian3.fromArray(thisSurfaceBfVector.unitVector);
              let normalVector = Cesium.Cartesian3.fromArray(surface.normalVector);

              // Body frame vector offset
              thisBfVectorScale =
                cappedPerigeeAlt <= cesiumScaleAltitude
                  ? 1000 * cesiumBfVectorScaleDown * cappedPerigeeAlt
                  : (cesiumScaleLinearSlope * cappedPerigeeAlt + cesiumScaleLinearIntercept) *
                    cesiumBfVectorScaleUp;
              thisBfVectorOffset = Cesium.Cartesian3.multiplyByScalar(
                Cesium.Matrix3.multiplyByVector(eci2BodyRotMat, bfVector, new Cesium.Cartesian3()),
                thisBfVectorScale,
                new Cesium.Cartesian3()
              );

              // Origin of normal vector
              thisSurfaceNormalOrigin = Cesium.Cartesian3.add(
                eciSatPosition,
                thisBfVectorOffset,
                new Cesium.Cartesian3()
              );

              // Normal vector in ECI
              let rotationQuaternion = Cesium.Quaternion.fromAxisAngle(
                bfVector,
                articulationAngle,
                new Cesium.Quaternion()
              );
              let rotationMatrix = Cesium.Matrix3.fromQuaternion(
                rotationQuaternion,
                new Cesium.Matrix3()
              );
              let rotatedNormalVector = Cesium.Matrix3.multiplyByVector(
                rotationMatrix,
                normalVector,
                new Cesium.Cartesian3()
              );
              thisSurfaceNormal = Cesium.Cartesian3.multiplyByScalar(
                Cesium.Matrix3.multiplyByVector(
                  eci2BodyRotMat,
                  rotatedNormalVector,
                  new Cesium.Cartesian3()
                ),
                thisBfVectorScale / 3,
                new Cesium.Cartesian3()
              );

              // Endpoint of normal vector
              thisSurfaceNormalEndPoint = Cesium.Cartesian3.add(
                thisSurfaceNormalOrigin,
                thisSurfaceNormal,
                new Cesium.Cartesian3()
              );
              thisSurfaceLabelPoint = Cesium.Cartesian3.add(
                thisSurfaceNormalOrigin,
                Cesium.Cartesian3.multiplyByScalar(
                  thisSurfaceNormal,
                  cesiumBfVectorLabelOffsetScale,
                  new Cesium.Cartesian3()
                ),
                new Cesium.Cartesian3()
              );

              sunTrackingSurfaces[j].originPoint.addSample(time, thisSurfaceNormalOrigin);
              sunTrackingSurfaces[j].endPoint.addSample(time, thisSurfaceNormalEndPoint);
              sunTrackingSurfaces[j].labelPoint.addSample(time, thisSurfaceLabelPoint);
              j++;
            }
          }
        }

        // Data for field of view (FoV) visualization in Cesium
        j = 0;
        for (let fov of fovs) {
          fovs[j].name = fov.name;

          // Circular cross-section FoVs
          if (fov.fieldOfViewType === 'CIRC_FIELD_OF_VIEW') {
            let vector = fov.boresightBodyFrameVector.unitVector;

            // Circular FoV uses a cylinder primitive with zero radius on one end, requiring an offset to be
            // properly located at the satellite position. We set the height of the cone to the altitude of the sat
            eciFovPositionOffset = Cesium.Cartesian3.multiplyByScalar(
              Cesium.Matrix3.multiplyByVector(
                eci2BodyRotMat,
                Cesium.Cartesian3.fromArray(vector),
                new Cesium.Cartesian3()
              ),
              sensorDistance / 2,
              new Cesium.Cartesian3()
            );
            eciFovPosition = Cesium.Cartesian3.add(
              eciSatPosition,
              eciFovPositionOffset,
              new Cesium.Cartesian3()
            );

            // Default cone pointing is -z in body frame, so we need to compute rotation to boresight vector
            // using the rotation axis and angle
            if (Math.abs(vector[0]) === 0 && Math.abs(vector[1]) === 0) {
              if (vector[2] < 0) {
                fovQuaternion = new Cesium.Quaternion(0, 0, 0, 1);
              } else {
                fovQuaternion = new Cesium.Quaternion(0, 1, 0, 0);
              }
            } else {
              axis = new Cesium.Cartesian3.fromElements(-vector[1], vector[0], 0);
              angle = Math.acos(-vector[2]);
              fovQuaternion = new Cesium.Quaternion.fromAxisAngle(axis, -angle);
            }

            // Add sample to Cesium interpolant sample sets to orient (in ENU) and position (in ECI) the
            // vectors. ECEF-to-body is multiplied by the body to FoV boresight quaternion to orient the
            // FoV.
            fovs[j].quaternion.addSample(
              time,
              Cesium.Quaternion.multiply(
                ecef2bodyQuaternion,
                fovQuaternion,
                new Cesium.Quaternion()
              ),
              new Cesium.Quaternion()
            );
            fovs[j].position3D.addSample(time, eciFovPosition);
            fovs[j].radius.addSample(time, tan(fov.halfAngle.rad) * sensorDistance);

            // Rectangular FoVs
          } else if (fov.fieldOfViewType === 'RECT_FIELD_OF_VIEW') {
            heightVec = fov.heightBodyFrameVector.unitVector;
            boreVec = fov.boresightBodyFrameVector.unitVector;

            // Default rect pointing is +y in body frame, so we need to compute rotation to boresight vector
            // using the rotation axis and angle
            if (Math.abs(boreVec[0]) === 0 && Math.abs(boreVec[2]) === 0) {
              if (boreVec[1] < 0) {
                fovQuaternion = new Cesium.Quaternion(0, 0, 1, 0);
              } else {
                fovQuaternion = new Cesium.Quaternion(0, 0, 0, 1);
              }
            } else {
              axis = new Cesium.Cartesian3.fromElements(-boreVec[2], 0, boreVec[0]);
              angle = Math.acos(boreVec[1]);
              fovQuaternion = new Cesium.Quaternion.fromAxisAngle(axis, -angle);
            }

            // For rectangular FoVs, we have to make a second rotation about the boresight to align the "height"
            // axis of the FoV cross-section with the projection of the height BF vector in the plane perpendicular
            // to the FoV boresight. This is the rejection of the the height BF vector on the boresight vector.
            // default orientation of the height axis is x. We compute the rotation angle about the boresight by
            // computing the angle between the height BF vector projection and the default axis after the the initial
            // boresight alignment rotation. We then use this angle and the boresight BF vector to generate an
            // additional quaternion to fully orient the FoV primitive.
            rejectH = subtract(heightVec, multiply(dot(heightVec, boreVec), boreVec));
            hDefault = [1, 0, 0];
            fovRotMat = new Cesium.Matrix3.fromQuaternion(fovQuaternion, new Cesium.Matrix3());
            hDefaultCart = Cesium.Matrix3.multiplyByVector(
              fovRotMat,
              Cesium.Cartesian3.fromArray(hDefault),
              new Cesium.Cartesian3()
            );
            hDefault = [hDefaultCart['x'], hDefaultCart['y'], hDefaultCart['z']];
            rejectHDefault = subtract(hDefault, multiply(dot(hDefault, boreVec), boreVec));
            boreAngle = Math.acos(
              dot(rejectH, rejectHDefault) / norm(rejectH) / norm(rejectHDefault)
            );
            // Accounting for direction of rotation
            if (dot(cross(rejectHDefault, boreVec), rejectH) > 0) {
              boreAngle = -boreAngle;
            }

            boreCart = new Cesium.Cartesian3.fromArray(boreVec);
            boreQuaternion = new Cesium.Quaternion.fromAxisAngle(boreCart, boreAngle);

            // Combining rotations from body to fov
            fovQuaternion = Cesium.Quaternion.multiply(
              boreQuaternion,
              fovQuaternion,
              new Cesium.Quaternion()
            );

            // Add sample to Cesium interpolant sample sets to orient (in ENU) and position (in ECI) the
            // vectors. ECEF-to-body is multiplied by the body to FoV boresight quaternion to orient the
            // FoV.
            fovs[j].quaternion.addSample(
              time,
              Cesium.Quaternion.multiply(
                ecef2bodyQuaternion,
                fovQuaternion,
                new Cesium.Quaternion()
              ),
              new Cesium.Quaternion()
            );
            fovs[j].position3D.addSample(time, eciSatPosition);
          }
          j++;
        }

        i++;
      }

      return {
        satellite: {
          position3D: eciSatPositionSampled,
          quaternion: quaternionSampled,
        },
        targets: targetsData,
        bfVectors,
        sunTrackingSurfaces,
        fovs,
        sensorLengthCartesian,
        sensorLengthMag,
        startTime,
        stopTime,
        moonPosition,
      };
    } else return { targets: targetsData, startTime, stopTime };
  }, [
    targetsData,
    bodyFrameVectors,
    perigeeAlt,
    surfaces,
    fieldsOfView,
    transposeSeries,
    startMjd,
    startTime,
    _usedModel,
    stopTime,
  ]);
};
