import { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IConstraint } from '../../general/types';
import { ellipsesOverflow } from '../../general/sharedStyles';
import { checkIsTruncated } from '../../general/utils';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    fontSize: '0.2rem',
    display: 'inline',
    maxWidth: '3.9rem',
    ...ellipsesOverflow,
  },
  complianceCircle: ({ compliant }: { compliant: boolean }) => ({
    borderRadius: '50%',
    background: compliant ? theme.palette.success.pastel : theme.palette.error.pastel,
    width: 5,
    height: 5,
    marginLeft: '0.1rem',
  }),
}));

const Constraint = ({ name, compliant }: IConstraint) => {
  const styles = useStyles({ compliant });

  const nameRef = useRef<HTMLParagraphElement>(null);
  const nameTruncated = checkIsTruncated(nameRef.current);

  return (
    <div className={styles.root}>
      <Tooltip title={nameTruncated ? name : ''} placement="right" arrow>
        <p className={styles.text} ref={nameRef}>
          {name}
        </p>
      </Tooltip>
      <div className={styles.complianceCircle} />
    </div>
  );
};

export default Constraint;
