import { useActiveEntities, useLatestJob, useSelectById } from 'hooks';
import useMountStatus from 'hooks/useMountStatus';
import { round } from 'mathjs';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { makeModel } from 'middleware/SatelliteApi/template';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getSearchParams, useSearchParams } from 'routes';
import { isLiveDemo } from 'utils/debt';
import {
  concatSeriesData,
  getResponsibleStream,
  getSeriesDataStartTimestamp,
  getSeriesDataStopTimestamp,
  getSeriesForKeys,
  initSeriesData,
  makeSeriesTransposeIterator,
  sliceSeriesDataByTimestamp,
} from './series';

/**
 * Provides all the simulation data in bulk for the current scenario.
 * - Includes the simulation data itself _and_ the metadata.
 * - Includes utility functions for querying the data and compiling data for echarts.
 * - Includes useful mappings to better interact with the data.
 * - Includes uncompiled models _without_ simulation data.
 *
 * Subscribing to this context implicitly subscribes a component to:
 * - ActiveBranchContext: provides the current branch for which to fetch data
 */
export const DataContext = createContext();
export const useDataContext = () => useContext(DataContext);

const INITIAL_LIMIT = 2500; // points
const INITIAL_OFFSET = 0.5; // days
const TIME_RESOLUTION = 10 ** -11; // days

const DataProvider = ({ children }) => {
  const dispatch = useDispatch();
  const {
    Data: {
      actions: { getData },
    },
    MissionVersion: {
      actions: { updateAnalyzeState },
    },
  } = SatelliteApi;

  const { share } = getSearchParams();
  const { branch } = useActiveEntities();

  const [state, setState] = useState({
    seriesData: initSeriesData(),
    metaData: {},
    jobId: null,
  });

  const latestJob = useLatestJob();
  const currentJob = useSelectById('Job', state.jobId);

  const [injestConfig, setInjestConfig] = useState({
    rate: null, // Rate data is available (sim MJDs per wall second)
    seq: [],
    start: null,
    simTime: null,
  });

  const startTime = getSeriesDataStartTimestamp(state.seriesData).common || 0;
  const stopTime = getSeriesDataStopTimestamp(state.seriesData).common || 0;
  const start = injestConfig.start || latestJob?.startTime;

  // eslint-disable-next-line
  const updateInjestConfig = useCallback(
    (simTime, end) => {
      if (simTime < 0) return; // Unchanged
      setInjestConfig((curr) => {
        const newState = {
          ...curr,
          simTime,
        };
        if (end) {
          const midTime = start + (end - start) / 2;
          const wallTime = new Date().getTime() / 1000;
          if (curr.seq.length > 0) {
            const prev = curr.seq[curr.seq.length - 1];
            const prevMidTime = prev[0];
            const prevWallTime = prev[1];
            newState.rate = ((midTime - prevMidTime) * 86400) / (wallTime - prevWallTime);
            if (curr.rate) {
              const alpha = 0.75;
              newState.rate = alpha * curr.rate + (1 - alpha) * newState.rate;
            }
          }
          newState.seq = [...curr.seq, [midTime, wallTime]];
          newState.start = end + TIME_RESOLUTION; // Extend slightly past last fetched data
        }
        return newState;
      });
    },
    [start]
  );

  let [fetching, setFetching] = useState(false);
  const isMounted = useMountStatus();

  const [searchParams, setSearchParams] = useSearchParams();

  const _fetchData = useCallback(
    (start, stop, limit, retryDelay = 2000) => {
      setFetching(true);
      // Back off request if too large
      if (isLiveDemo(branch)) {
        stop = Math.min(Math.max(stop, start + 0.005), start + 0.0125); // FIXME: Hardcoded step size
        stop = start + 0.0125 * 2;
      }
      const latestJobId = latestJob.id;
      dispatch(
        getData({
          queryParams: {
            id: latestJob.dataId,
            start,
            stop,
            share,
            axisOrder: 'TIME_MINOR',
            limit,
          },
          successCallback: (data) => {
            // If user has navigated away, don't continue the fetching loop and bog down the front-end
            if (!isMounted()) return;

            const { meta: metaData, series } = data;

            let { seriesData, jobId } = state;
            if (jobId !== latestJobId) seriesData = initSeriesData();
            else {
              const cache = branch.analyzeState?.dataState;
              if (
                // If the cache is stale, reset seriesdata in preparation for the new data
                cache &&
                (cache.start !== searchParams.start ||
                  cache.stop !== searchParams.stop ||
                  cache.limit !== searchParams.limit)
              )
                seriesData = initSeriesData();
            }
            concatSeriesData(seriesData, series);

            // Store state for this provider
            setState((state) => ({ ...state, metaData, seriesData, jobId: latestJobId }));

            // Immediately cache state in redux
            dispatch(
              updateAnalyzeState({
                id: branch.id,
                dataState: {
                  seriesData,
                  metaData,
                  jobId: latestJobId,
                  start,
                  stop,
                  limit,
                },
                fetchWhenTrue: false,
              })
            );

            const last = getSeriesDataStopTimestamp(seriesData).absolute;
            // updateInjestConfig(metaData.stop, last);
            if (round(metaData.stop, 8) > round(last, 8) && isLiveDemo(branch)) {
              _fetchData(last + TIME_RESOLUTION, metaData.stop);
            } else {
              setFetching(false);
              if (isLiveDemo(branch)) {
                setTimeout(() => {
                  _fetchData(last + TIME_RESOLUTION, 100000000000); // Overridden in fetchData
                }, 10000);
              }
            }
          },
          failureCallback: (response) => {
            if (!isMounted()) return;

            if (response) console.log('error:', response);
            else console.log('error: an error occurred while digesting data');
            if (response?.simulationTime) {
              // updateInjestConfig(response?.simulationTime);
            }
            setTimeout(() => {
              _fetchData(start, stop, limit, retryDelay * 2); // Exponential backoff
            }, retryDelay);
          },
        })
      );
    },
    [
      branch,
      dispatch,
      getData,
      latestJob,
      updateAnalyzeState,
      // updateInjestConfig,
      share,
      isMounted,
      state,
      searchParams,
    ]
  );

  const fetchData = useCallback(
    (start, stop, limit) => {
      _fetchData(start, stop, limit);
      // Maintain search params in URL
      setSearchParams({ start, stop, limit });
    },
    [_fetchData, setSearchParams]
  );

  // Get simulation data
  useEffect(() => {
    if (latestJob?.id && !fetching) {
      if (branch.analyzeState?.dataState?.jobId) {
        // Cache is populated
        if (branch.analyzeState.dataState.jobId !== state.jobId) {
          // Avoid unnecessary renders
          setState(branch.analyzeState.dataState);
        }
        // Maintain search params in URL
        setSearchParams({
          start: searchParams.start || branch.analyzeState.dataState.start,
          stop: searchParams.stop || branch.analyzeState.dataState.stop,
          limit: searchParams.limit || branch.analyzeState.dataState.limit,
        });
      } else if (branch.analyzeState?.fetchWhenTrue) {
        // Cache invalid or empty and job complete (the only thing that sets invalid truthy is getJob when job is complete)
        fetchData(
          // Current search params trump defaults but clamp to job start/stop
          Math.max(latestJob.startTime, searchParams.start || start),
          Math.min(
            latestJob.progress?.currentTime || latestJob.stopTime,
            searchParams.stop || start + INITIAL_OFFSET
          ),
          searchParams.limit ||
            Math.ceil(INITIAL_LIMIT / Object.keys(latestJob.simulatedAgents).length)
        );
      } // Else noop while job is running
    }
  }, [latestJob?.id, branch, state]); //eslint-disable-line

  const queryData = useCallback(
    (timestamp, agentId) => {
      const { seriesData } = state;
      return sliceSeriesDataByTimestamp(seriesData, timestamp, agentId);
    },
    [state]
  );

  const [simulatedAgentToAgent, agentToSimulatedAgent] = useMemo(() => {
    if (currentJob?.simulatedAgents) {
      return [
        Object.entries(currentJob.simulatedAgents).reduce((acc, [k, v]) => {
          acc[v] = k;
          return acc;
        }, {}),
        currentJob.simulatedAgents,
      ];
    }
    return [{}, {}];
  }, [currentJob?.simulatedAgents]);

  const staticModels = useMemo(() => {
    const structure = state.metaData.structure;
    const results = {
      agents: {},
    };
    if (structure) {
      results.scenario = makeModel(structure.scenario, structure.schemas.scenario);
      for (const simulatedAgentId in structure.agents) {
        results.agents[simulatedAgentToAgent[simulatedAgentId]] = makeModel(
          structure.agents[simulatedAgentId],
          structure.schemas.agent
        );
      }
    }
    return results;
  }, [state, simulatedAgentToAgent]);

  const resolveSeriesDataKeyPair = useCallback(
    (agentId, agentLocalPath) => {
      const columnKey = `${agentToSimulatedAgent[agentId]}.${agentLocalPath}`;
      try {
        const streamId = getResponsibleStream(state.seriesData, columnKey).id;
        return { xKey: `${streamId}.time`, yKey: `${streamId}.${columnKey}` };
      } catch (e) {
        console.warn(
          `Series data under the key ${columnKey} does not exist. This means either (1) the key is invalid or (2) this model does not contain the state referenced by the key.`
        );
      }
    },
    [state, agentToSimulatedAgent]
  );

  let value = useMemo(
    () => ({
      seriesData: state.seriesData,
      meta: state.metaData,
      startTime: startTime || 0,
      stopTime: stopTime || 0,
      queryData,
      simulatedAgentToAgent,
      agentToSimulatedAgent,
      staticModels,
      _state: state,
      injestConfig,
      resolveSeriesDataKeyPair,
      fetchData,
      fetching,
      jobId: currentJob?.id,
      currentJob,
    }),
    [
      queryData,
      state,
      simulatedAgentToAgent,
      agentToSimulatedAgent,
      staticModels,
      startTime,
      stopTime,
      injestConfig,
      resolveSeriesDataKeyPair,
      fetchData,
      fetching,
      currentJob,
    ]
  );

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;

export const useStream = (agentId, ...keys) => {
  const { _state, agentToSimulatedAgent } = useContext(DataContext);
  const _keys = JSON.stringify(keys);

  return useMemo(() => {
    return makeStream(_keys, _state, agentToSimulatedAgent[agentId]);
    // NOTE: We want the contents of `keys` to cause a re-memo, not a reference to the array itself
    // Use a string to do that until react lets us just spread `keys` in the dep array
    // https://github.com/facebook/react/issues/18229
  }, [_keys, _state, agentToSimulatedAgent, agentId]);
};

// Returns empty data if agentId is falsy
export const makeStream = (_keys, _state, simulatedAgentId) => {
  const keys = typeof _keys === 'string' ? JSON.parse(_keys) : _keys;
  const { seriesData } = _state;
  if (keys.length < 1) {
    return {
      series: [],
      transposeSeries: () => [],
    };
  }

  if (!simulatedAgentId) {
    let series = [[]].concat(keys.map(() => []));
    return {
      series,
      transposeSeries: () => makeSeriesTransposeIterator(series),
    };
  }

  const simulatedAgentKeys = keys.map((key) => `${simulatedAgentId}.${key}`);
  const series = getSeriesForKeys(seriesData, simulatedAgentKeys);
  return {
    series,
    transposeSeries: () => makeSeriesTransposeIterator(series),
  };
};
