import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  hr: {
    width: '100%',
    margin: '15px 0',
    marginTop: 0,
  },
  contact: {
    marginTop: 20,
  },
}));

export default useStyles;
