import { useMemo } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import validation from './validation';
import { useGuidance } from './guidance';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { IFieldOfView, IFovConstraint } from 'components/general/types/spacecraft';
import { ISelectOption } from 'components/general/types';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';

interface IProps {
  control: TEntityDialogControl<IFovConstraint>;
  fieldOfView?: IFieldOfView;
}

interface IForm {
  name: string;
  referenceVector: ISelectOption | '';
  keepout: boolean;
  destructive: boolean;
}

const defaultValues: IForm = {
  name: '',
  referenceVector: '',
  keepout: false,
  destructive: false,
};

const FovConstraintDialog = ({ control, fieldOfView }: IProps) => {
  const classes = useStyles();
  const { referenceVectors } = useActiveEntities();

  const options = useMemo(() => {
    return {
      referenceVector: referenceVectors.map((v) => ({ value: v.id, label: v.name })),
    };
  }, [referenceVectors]);

  const entityForm = useEntityForm<IFovConstraint, IForm>({
    entityTypeText: 'Field of View Constraint',
    overrideEntityText: 'FovConstraint',
    entityDialogControl: control,
    defaultValues,
    additionalCreateValues: { fieldOfView: fieldOfView?.id },
    validationSchema: validation,
    formikOptionalParams: {
      useGuidance,
      options,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values } = formik;

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            label="Constraint Name"
            placeholder="Name"
            {...getFieldProps('name')}
            autoFocus
          />
          <LabeledSelect
            label="Constraint Direction"
            options={options.referenceVector}
            {...getFieldProps('referenceVector')}
            noOptionsMessage={() => 'Create a Reference Vector'}
          />
          <LabeledCheckbox {...getFieldProps('keepout')} label="Keepout?" />
          {values.keepout && (
            <div className={classes.indent}>
              <LabeledCheckbox {...getFieldProps('destructive')} label="Destructive?" />
            </div>
          )}
        </div>
      </div>
    </EntityDialog>
  );
};

export default FovConstraintDialog;
