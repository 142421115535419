import { createContext, useState, useEffect, useMemo, ReactNode } from 'react';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useDispatch } from 'react-redux';
import { useUser, useSnackbar } from 'hooks';
import { useSelectAll } from 'hooks';
import { SUPPORT_EMAIL } from 'config';
import _ from 'lodash';
import { IMission } from 'components/general/types';

interface IRepoContext {
  repos: IMission[];
  digestedRepos: boolean;
}

interface IProps {
  children: ReactNode;
}

const defaultState: IRepoContext = {
  repos: [],
  digestedRepos: false,
};

/**
 * Fetches all repositories and branches for the current user.
 * - Does NOT include model data. That is handled by ActiveBranchProvider.
 */
export const RepositoriesContext = createContext<IRepoContext>(defaultState);

const RepositoriesProvider = (props: IProps) => {
  const { children } = props;

  const [gotUser, setGotUser] = useState(false);
  const [gotMissions, setGotMissions] = useState(false);
  const [digestedRepos, setDigestedRepos] = useState(true);

  const user = useUser();
  const { Mission, User } = SatelliteApi;
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  let repos = useSelectAll('Mission');
  // only display repos that the user owns or collaborates on, so branches viewed via shareable link don't end up being listed
  repos = repos.filter((repo) => {
    return repo.user === user?.id || repo.collaborators.includes(user?.id || 0);
  });

  useEffect(() => {
    // Use effect to limit requests to fetch missions. Note, this will occasionally fetch user once more than necessary
    // (if user deletes a repo navigates away and comes back, or if user refreshes on this page), but overall number of
    // requests sent is decreased.

    /** IDs of all repos accessible to this user */
    const shouldBeHere = user?.accessibleRepos || [];
    /** IDs of all repos currently in the store */
    const areHere: number[] = repos.map((r) => r.id);

    // `xor` finds any differences between the two
    if (!gotUser && _.xor(shouldBeHere, areHere).length) {
      setGotUser(true);
      dispatch(User.actions.getUser());
      return;
    }

    // `difference` finds any that are in the first but not the second
    if (!gotMissions && _.difference(shouldBeHere, areHere).length) {
      setDigestedRepos(false);
      setGotMissions(true);
      dispatch(
        Mission.actions.getMissions({
          queryParams: {
            expand: {
              user: {},
              collaborators: {},
              branches: {
                $remove: {
                  data: {},
                  dataSchema: {},
                  blockIdToTypeMap: {},
                  blockClassToBlockGroupMap: {},
                  blockGroupNames: {},
                },
              },
            },
          },
          successCallback: () => setDigestedRepos(true),
          failureCallback: () =>
            enqueueSnackbar(
              `Failed to retrieve repositories. Please refresh the page and reach out to ${SUPPORT_EMAIL} if error persists.`
            ),
        })
      );
    }
  }, [repos, user, dispatch, Mission, User, enqueueSnackbar, gotMissions, gotUser]);

  const contextValue = useMemo(() => {
    return {
      repos,
      digestedRepos,
    };
  }, [repos, digestedRepos]);

  return (
    <RepositoriesContext.Provider value={contextValue}>{children}</RepositoriesContext.Provider>
  );
};

export default RepositoriesProvider;
