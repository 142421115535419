import LabeledInput from 'components/general/inputs/LabeledInput';
import StyledButton from 'components/general/StyledButton';
import StyledLink from 'components/general/StyledLink';
import WaveContainer from 'components/general/WaveContainer';
import AuthWidget from 'components/general/widgets/AuthWidget';
import { EMAIL_REGEX } from 'config';
import { useFormikForm, useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, withRouter } from 'react-router-dom';
import Routes from 'routes';
import * as Yup from 'yup';
import useStyles from './styles';

const defaultValues = {
  email: '',
  password: '',
};

const requiredMessage = 'All fields are required.';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .required('Your email is required.')
    .max(320, 'Email must be no more than 320 characters.')
    .matches(EMAIL_REGEX, 'Please enter a valid email address.'),
  password: Yup.string().required(requiredMessage),
});

/* Login
 * Renders login component which displays login form and handles login validation and dispatch
 * Redirects to intended route, after authentication, if provided.  If no prior route is
 * provided, redirects to ROOT. If user if already authenticated, redirects to ROOT
 */
const LoginView = () => {
  const {
    User: {
      actions: { login, getUser },
    },
  } = SatelliteApi;
  const user = useUser();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  let history = useHistory();
  let location = useLocation();

  const classes = useStyles();

  let { from } = location.state || { from: { pathname: Routes.ROOT() } };

  useEffect(() => {
    // Redirect to ROOT if user is already authenticated
    if (user && !loading) {
      history.push(Routes.ROOT());
    }
  }, [history, user, loading]);

  const onSubmit = (values) => {
    setLoading(true);
    dispatch(
      login({
        ...values,
        successCallback: () => {
          dispatch(
            getUser({
              successCallback: () => {
                setLoading(false);
                // Callback to redirect user to their intended route (otherwise root) once successfully authenticated
                history.replace(from);
              },
            })
          );
        },
        failureCallback: () => {
          enqueueSnackbar('Incorrect email and/or password.');
          setLoading(false);
          setFieldValue('password', '');
        },
      })
    );
  };

  const { formik } = useFormikForm(defaultValues, onSubmit, loginSchema);
  const { getFieldProps, handleSubmit, setFieldValue } = formik;

  return (
    <WaveContainer>
      <AuthWidget>
        <form onSubmit={handleSubmit}>
          <LabeledInput
            {...getFieldProps('email')}
            label="Email"
            type="text"
            placeholder="Email"
            autoComplete="on"
            autoFocus
          />
          <LabeledInput
            {...getFieldProps('password')}
            label="Password"
            type="password"
            placeholder="Password"
            autoComplete="current-password"
          />
          <StyledLink to={Routes.PASSWORD_RESET()} className={classes.forgotLink}>
            Forgot password?
          </StyledLink>
          <StyledButton type="submit" disabled={loading} loading={loading} tallMargin fullWidth>
            Login
          </StyledButton>
          <StyledButton
            type="button"
            disabled={loading}
            onClick={() => history.push({ pathname: Routes.SIGNUP(), state: { from } })}
            framed
            fullWidth
          >
            Sign up
          </StyledButton>
        </form>
      </AuthWidget>
    </WaveContainer>
  );
};

export default withRouter(LoginView);
