import WGroup from 'components/general/WGroup';
import ViewPortInlay from 'components/general/ViewPortInlay';
import { wGroupIndicesAgentComms } from 'components/AgentAnalyzeView/menu/comms';

const CommsAnalyzeBoard = () => {
  return (
    <WGroup index={wGroupIndicesAgentComms.COMMS}>
      <ViewPortInlay text="Data Handling module coming soon..." />
    </WGroup>
  );
};

export default CommsAnalyzeBoard;
