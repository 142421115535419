import * as Yup from 'yup';

const percent = (fieldName: string) => {
  return Yup.number()
    .required(fieldName + ' is required.')
    .max(100, fieldName + ' cannot be greater than 100%.')
    .min(0, fieldName + ' cannot be less than 0%.');
};

const temp = (fieldName: string) => {
  return Yup.number()
    .required(fieldName + ' is required.')
    .min(-273.15, fieldName + ' cannot be less than absolute zero.');
};

const validation = Yup.object().shape({
  partNumber: Yup.string()
    .required('A part number is required.')
    .max(32, 'Part number must be no more than 32 characters.'),
  manufacturer: Yup.string().max(32, 'Manufacturer must be no more than 32 characters.'),
  irEmissivity: percent('IR emissivity'),
  solarAbsorptivity: percent('Solar absorptivity'),
  diffuseSolarReflectivity: percent('Diffuse solar reflectivity'),
  specularSolarReflectivity: percent('Specular solar reflectivity'),
  hotTempRating: temp('Hot temperature rating'),
  coldTempRating: temp('Cold temperature rating').test(
    'Cold less than Hot',
    'Cold temperature rating cannot be greater than hot temperature rating.',
    function (coldTempRating: number | undefined) {
      if (this.parent.hotTempRating && coldTempRating) {
        return coldTempRating <= this.parent.hotTempRating;
      }
      return true;
    }
  ),
});

export default validation;
