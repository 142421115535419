import ConditionDialog from './ConditionDialog';
import { useActiveEntities } from 'hooks';
import EntityTableSegment from 'components/general/wizards/EntityTableSegment';
import { wGroupIndicesATCDH } from 'components/AgentTemplateEditView/menu/cdh';

const columns = [
  {
    title: 'Name',
    field: 'name',
  },
];

const ConditionsSegment = () => {
  const { conditions } = useActiveEntities();

  return (
    <EntityTableSegment
      title="Conditions"
      index={wGroupIndicesATCDH.CONDITIONS}
      guidance={{
        heading: 'Create and Edit Conditions',
        body: [
          {
            chunk:
              'Conditions are assigned to operational modes to drive ConOps logic. For example, you can use a condition to require line of sight to your groundstation for entry into downlink operational mode.',
          },
          {
            subHeading: 'Link to Operational Modes',
            chunk:
              'Each operational mode in your ConOps can be assigned one or more conditions. For any non-default operational mode to be the active mode, all of its conditions at that time step must be satisfied.',
          },
        ],
      }}
      entityColumns={columns}
      entityData={conditions}
      DialogComponent={ConditionDialog}
      modelName="conditions"
    ></EntityTableSegment>
  );
};

export default ConditionsSegment;
