import { useContext, useMemo } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import WidgetTable from 'components/general/widgets/WidgetTable';
import AttitudeDisplay from 'components/general/SpacecraftDialog/general/AttitudeDisplay';
import { array2String } from 'utils/strings';
import { SpacecraftContext } from 'providers';
import Panel from '../general/Panel';
import { FieldOfViewVables, SurfaceVables } from 'utils/vable';
import { useActiveEntities, useEntityDialogControl } from 'hooks';
import BodyFrameVectorDialog from './BodyFrameVectorDialog';
import ExternalSurfacesDialog from './ExternalSurfacesDialog';
import FieldOfViewDialog from './FieldOfViewDalog';

const guidance = {
  heading: 'Create and Edit Body Frame Vectors, External Surfaces, and Fields of View',
  body: [
    {
      chunk:
        'Body Frame Vectors and Surfaces are fundamental to building up a physical model of your satellite. Fields of View (FoVs) are defined based on a direction and either a circular or rectangular sensor plane. They are used in a simulation to direct and scope the sensor view. They are assigned to Sensors in the GNC edit board.',
    },
    {
      subHeading: 'Links to Modeling',
      chunk:
        'Body Frame Vectors are used to drive Pointing Modes, define the orientation of sensor boresights and comm antennas, and to describe exterior geometry. Surfaces are used as the mounting location for solar panels and used to compute atmospheric drag, radiative heat transfer, and solar radiation pressure perturbations.',
    },
    {
      subHeading: 'Key Considerations',
      chunk:
        'Take care when establishing your principal directions and origin. If you have a CAD model of your satellite, align your Sedaro Satellite body frame with the coordinate system in the CAD model - this will make extracting key vectors straightforward.',
    },
  ],
};

const bfvColumns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Unit Vector',
    render: (rowData) => array2String(rowData.unitVector),
  },
];

const fieldOfViewColumns = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (rowData) => FieldOfViewVables.FieldOfViewTypes[rowData.fieldOfViewType].label,
  },
];

const GeometryPanel = (props) => {
  const { ...remainingProps } = props;

  const { satellite, bodyFrameVectors, surfaces, fieldsOfView } = useActiveEntities();

  // Surface columns must be created within the component to display BFV names
  const surfaceColumns = useMemo(() => {
    return [
      {
        title: 'Name',
        field: 'name',
      },
      {
        title: 'Motion Type',
        field: 'motionType',
        render: (rowData) => SurfaceVables.MotionTypes[rowData.motionType].label,
      },
      {
        title: 'Vector',
        render: (rowData) => rowData.bodyFrameVector.name,
      },
    ];
  }, []);

  const bfVectorEntityDialogControl = useEntityDialogControl();
  const surfaceEntityDialogControl = useEntityDialogControl();
  const fieldOfViewEntityDialogControl = useEntityDialogControl();

  const classes = useStyles();

  const { closeSpacecraftDialog } = useContext(SpacecraftContext);

  return (
    <>
      <Panel
        title="Geometry"
        guidance={guidance}
        secondaryActionText="Close"
        onClose={closeSpacecraftDialog}
        {...remainingProps}
      >
        <h6>Body Frame Vectors</h6>
        <WidgetTable
          className={classes.table}
          columns={bfvColumns}
          data={bodyFrameVectors}
          modelName="body frame vector"
          onFabClick={bfVectorEntityDialogControl.openDialogForNew}
          onActionClick={bfVectorEntityDialogControl.openDialogForExisting}
        />

        <h6>External Surfaces</h6>
        <WidgetTable
          className={classes.table}
          columns={surfaceColumns}
          data={surfaces}
          modelName="external surface"
          onFabClick={surfaceEntityDialogControl.openDialogForNew}
          onActionClick={surfaceEntityDialogControl.openDialogForExisting}
        />

        <h6>Fields of View</h6>
        <WidgetTable
          className={classes.table}
          columns={fieldOfViewColumns}
          data={fieldsOfView}
          modelName="field of view"
          onFabClick={fieldOfViewEntityDialogControl.openDialogForNew}
          onActionClick={fieldOfViewEntityDialogControl.openDialogForExisting}
        />
        {!bfVectorEntityDialogControl.dialogConfig.open &&
          !surfaceEntityDialogControl.dialogConfig.open && (
            <AttitudeDisplay
              bodyFrameVectors={bodyFrameVectors}
              file={{
                fileUrl: satellite.cadSignedUrl,
                fileName: satellite.cadFileName,
              }}
              rightSide={true}
            />
          )}
      </Panel>
      {bfVectorEntityDialogControl.dialogConfig.open && (
        <BodyFrameVectorDialog control={bfVectorEntityDialogControl} />
      )}
      {surfaceEntityDialogControl.dialogConfig.open && (
        <ExternalSurfacesDialog control={surfaceEntityDialogControl} />
      )}
      {fieldOfViewEntityDialogControl.dialogConfig.open && (
        <FieldOfViewDialog control={fieldOfViewEntityDialogControl} />
      )}
    </>
  );
};

export default GeometryPanel;
