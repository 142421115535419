import {
  useContext,
  forwardRef,
  useState,
  useEffect,
  useCallback,
  memo,
  useMemo,
  useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  Viewer,
  Sun,
  Entity,
  Camera,
  CameraFlyTo,
  Clock,
  LabelGraphics,
  CylinderGraphics,
  EllipsoidGraphics,
  PolylineGraphics,
  SkyBox,
  SkyAtmosphere,
  ShadowMap,
  Scene,
} from 'resium';
import { routePathsCommon } from 'routes';
import Moon from './moon';
import * as Cesium from 'cesium';
import TargetEntity from './TargetEntity';
import useStyles from './styles';
import { TimeContext, useDataContext } from 'providers';
import blueMarble from 'multimedia/world.200407.3x5400x2700.jpg';
import { jd2Mjd, mjd2Moment } from 'utils/time';
import { a2Period } from 'utils/orbit';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import theme from 'theme';
import clsx from 'clsx';
import StyledButton from 'components/general/StyledButton';
import Widget from 'components/general/widgets/Widget';
import { useActiveEntities } from 'hooks';
import { cesiumClockMultiplierScaleDefault } from '../general/constants';
import { cadScale } from './utils';
import { ContextNavContext } from 'providers';
import { wGroupIndicesAgentCustom } from 'components/AgentAnalyzeView/menu/custom';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCube,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faTags,
  faArrowsAlt,
  faDotCircle,
  faCircleNotch,
  faVideoCamera,
} from '@fortawesome/free-solid-svg-icons';
import { useAnalyticsContext } from 'providers/AnalyticsProvider';

// ==================================================================================================================
// Constants
// ==================================================================================================================
const scenarioPlaybackRegex = new RegExp(
  // lint thinks escapes here are unneccesary b/c doesn't know it's becoming regex
  `/${routePathsCommon.SCENARIO}/\\d*/${routePathsCommon.ANALYZE}/.*` // eslint-disable-line
);

const imageryProvider = new Cesium.SingleTileImageryProvider({
  url: blueMarble,
  credit: 'NASA Visible Earth',
});
const cesiumLabelTextScale = 0.45;
const cesiumBfVectorLabelTextScale = 0.55;
const cesiumBfVectorLineWidth = 8;
const cesiumOrbitTailFactor = 0.975;
const cesiumSatOrbitColor = Cesium.Color.LIMEGREEN;
const cesiumBfVectorColor = Cesium.Color.CYAN;
// REF: sunTrackingVariables
// TODO: Comment cesiumSatOrbitLineWidth back in when ready
// const cesiumSurfaceVectorColor = Cesium.Color.YELLOW;
const cesiumSatOrbitLineWidth = 3;
// Leap seconds to offset when comparing UTC (Coordinated Universal Time) and TAI (International
// Atomic Time) This is needed because Cesium.JulianDate stores time in TAI, but our data is in
// UTC. The last item in the leapSeconds array is always the most current # leap seconds.
const secondsDiffUtcToTai =
  Cesium.JulianDate.leapSeconds[Cesium.JulianDate.leapSeconds.length - 1].offset;

// ==================================================================================================================
// Other
// ==================================================================================================================

const IndexedClock = forwardRef((props, ref) => {
  let { startTime: temp, animate, ...rest } = props;

  const { time } = useContext(TimeContext);
  const { startTime: globalStartTime } = useDataContext();

  const startTime = Cesium.JulianDate.fromIso8601(mjd2Moment(globalStartTime).format());
  const [currentTime, setCurrentTime] = useState(startTime);

  useEffect(() => {
    if (time !== globalStartTime) {
      setCurrentTime(Cesium.JulianDate.fromIso8601(mjd2Moment(time).format()));
    }
  }, []); // eslint-disable-line

  return (
    <Clock
      ref={ref}
      startTime={startTime}
      currentTime={currentTime}
      {...rest}
      shouldAnimate={animate}
    />
  );
});
IndexedClock.displayName = 'IndexedClock';

// ==================================================================================================================
// Generate Mission Module Playback Viewer
// ==================================================================================================================

const PlaybackViewer = (props) => {
  const {
    viewerRef,
    cameraRef,
    clockRef,
    sceneRef,
    satelliteEntityRef,
    initiallyTrack = true,
    // fitHeight = true,
    contextValues,
    animate,
  } = props;

  const { repo } = useActiveEntities();

  const [, setTrackedSatellite] = useState(false);
  const [followSc, setFollowSc] = useState(true);
  const [showBfVectors, setShowBfVectors] = useState(true);
  const [showFovs, setShowFovs] = useState(true);
  const [showTargetNames, setShowTargetNames] = useState(true);
  const [showOrbitTails, setShowOrbitTails] = useState(true);
  const [cadScaleMultiplier, setCadScaleMultiplier] = useState(1);

  const moonRef = useRef(null);
  const playbackViewerContentRef = useRef(null);

  const { pathname } = useLocation();
  const isScenarioPlayback = useMemo(() => scenarioPlaybackRegex.test(pathname), [pathname]);

  useEffect(() => {
    if (!viewerRef.current) return;
    let e =
      viewerRef.current.cesiumElement._container.children[0].children[1].children[0].children[0];
    if (e.className === 'cesium-credit-logoContainer') {
      e.style.display = 'none';
    }
    if (viewerRef.current.cesiumElement._animation) {
      viewerRef.current.cesiumElement._animation.container.style.visibility = 'hidden';
    }
    // remove the event handler for double clicking to zoom in on entities
    if (viewerRef.current.cesiumElement.cesiumWidget) {
      viewerRef.current.cesiumElement.cesiumWidget.screenSpaceEventHandler.removeInputAction(
        Cesium.ScreenSpaceEventType.LEFT_DOUBLE_CLICK
      );
    }
  }, [viewerRef]);

  // // This is needed for when `requestRenderMode` below is set to `true` -- we need to manually trigger a rerender.
  // useEffect(() => {
  //   if (sceneRef.current) {
  //     sceneRef.current.cesiumElement.requestRender();
  //   }
  // }, [cesiumLabels]);

  const {
    TimeContext: { setTime },
    DataContext: {
      // REF: sunTrackingVariables
      // TODO: Comment data back in when ready
      // data,
      cesiumData,
      perigeeAlt,
      calculateMultiplier,
      orbitalElementsSeries,
      model,
    },
  } = contextValues;

  // const [viewerPosition, setViewerPosition] = useState();
  const [extraZoom2D, setExtraZoom2D] = useState(false);
  const [firstLoad, setFirstLoad] = useState(false);

  const largerScreen = useMediaQuery(theme.breakpoints.up('lg'));
  // const xlScreen = useMediaQuery(theme.breakpoints.up('xl')); // used to recalculate cesium height due to added padding at xl breakpoint
  const cadScaleFactor = model.satellite?.cadScaleFactor || 1;
  // const playbackElement = document.getElementById('PlaybackControls');

  const activeKey = useContext(ContextNavContext)?.state?.activeKey;
  const classes = useStyles({
    // viewerPosition,
    // fitHeight,
    playbackViewerHeight:
      activeKey === wGroupIndicesAgentCustom.PLAYBACK
        ? '38.75rem'
        : window.innerHeight - playbackViewerContentRef.current?.getBoundingClientRect().top,
  });

  useEffect(() => {
    if (initiallyTrack && viewerRef.current && satelliteEntityRef.current) {
      if (!firstLoad) {
        setFirstLoad(true);
      } else {
        setTrackedSatellite(true);
        viewerRef.current.cesiumElement.trackedEntity = satelliteEntityRef.current.cesiumElement;
      }
    }
  }, [firstLoad, setFirstLoad, setTrackedSatellite, satelliteEntityRef, viewerRef, initiallyTrack]);

  // Resize cesium viewer depending on the height of the simEx
  // useEffect(() => {
  //   if (fitHeight) {
  //     const observer = new ResizeObserver((entries) => {
  //       // calculate height of simEx + height of the playback widget
  //       setViewerPosition(entries[0].contentRect.height + playbackElement.scrollHeight);
  //     });
  //     const simEx = document.getElementById('SimEx');
  //     if (simEx) {
  //       if (viewerRef.current && playbackElement && largerScreen) {
  //         // ResizeObserver is similar to window.eventListener('resize') but for specific element
  //         observer.observe(simEx); // observe any changes in height of SimEx
  //       }
  //       if (!largerScreen) {
  //         observer.disconnect(); // disconnect observer if under 1280px
  //       }
  //     }
  //     return () => observer.disconnect(); // disconnect observer on unmount
  //   }
  // }, [
  //   viewerRef,
  //   playbackElement,
  //   largerScreen,
  //   // adding xlScreen to dependencies so it reevaluates height due to increased padding at xlScreen width
  //   xlScreen,
  //   classes.playbackViewerContent,
  //   fitHeight,
  // ]);

  const updatePlaybackState = useCallback(
    (currentTime) => {
      const time = jd2Mjd(
        // `Cesium.JulianDate` time is in TAI, & `data[#].time` is in UTC. TAI is `secondsDiffUtcToTai`
        // seconds ahead of UTC (37 as of 2021). So, take away leap seconds to convert back to UTC in
        // order to accurately search our `data` for the closest index.
        currentTime.dayNumber + (currentTime.secondsOfDay - secondsDiffUtcToTai) / 86400
      );
      setTime(time);
    },
    [setTime]
  );

  const [currTimeListenerInitialized, setCurrTimeListenerInitialized] = useState(false);
  useEffect(() => {
    // create listener to currentTime, use to trigger `updatePlaybackState`. We previously used onTick or onPostRender
    // to trigger updatePlaybackState, but this caused lag in Cesium animation while updatePlaybackState was executing
    if (!currTimeListenerInitialized && viewerRef.current) {
      Cesium.knockout
        .getObservable(viewerRef.current.cesiumElement.clockViewModel, 'currentTime')
        .subscribe(updatePlaybackState);
      setCurrTimeListenerInitialized(true);
    }
  }, [viewerRef, updatePlaybackState, currTimeListenerInitialized, setCurrTimeListenerInitialized]);

  const semiMajor = useMemo(() => {
    return orbitalElementsSeries.a?.length && orbitalElementsSeries.a[0] > 0
      ? orbitalElementsSeries.a[0]
      : 6378;
  }, [orbitalElementsSeries]);

  const bodyFrameVectors = useMemo(() => {
    if (!cesiumData.bfVectors) return [];
    return cesiumData.bfVectors.map((bfVector) => {
      return [
        <Entity
          key={bfVector.name}
          name={`Body Frame Vector: ${bfVector.name}`}
          availability={
            new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: cesiumData.startTime,
                stop: cesiumData.stopTime,
              }),
            ])
          }
          position={bfVector.bfVectorPos2}
          show={
            viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
            Cesium.SceneMode.SCENE2D
          }
        >
          <PolylineGraphics
            positions={
              new Cesium.PositionPropertyArray([
                cesiumData.satellite.position3D,
                bfVector.bfVectorPos2,
              ])
            }
            show={showBfVectors}
            width={cesiumBfVectorLineWidth}
            material={new Cesium.PolylineArrowMaterialProperty(cesiumBfVectorColor)}
            arcType={Cesium.ArcType.NONE}
          />
        </Entity>,
        <Entity
          key={bfVector.name + 'Label'}
          name={bfVector.name}
          availability={
            new Cesium.TimeIntervalCollection([
              new Cesium.TimeInterval({
                start: cesiumData.startTime,
                stop: cesiumData.stopTime,
              }),
            ])
          }
          position={bfVector.bfVectorPos2Longer}
          show={
            viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
            Cesium.SceneMode.SCENE2D
          }
        >
          <LabelGraphics
            text={bfVector.name}
            show={showBfVectors}
            // scale={cesiumBfVectorLabelTextScale}
            fillColor={cesiumBfVectorColor}
            scaleByDistance={
              new Cesium.NearFarScalar(
                6378 * 1000,
                cesiumBfVectorLabelTextScale,
                semiMajor * 1000 * 20,
                cesiumBfVectorLabelTextScale / 10
              )
            }
          />
        </Entity>,
      ];
    });
  }, [cesiumData, showBfVectors, viewerRef, semiMajor]);

  const sunTrackingSurfaces = useMemo(() => {
    // TODO: Return real data below
    // REF: sunTrackingVariables

    return [];
    // return cesiumData.sunTrackingSurfaces.map((surface) => {
    //   return [
    //     <Entity
    //       key={surface.name}
    //       name={`Surface: ${surface.name}`}
    //       availability={
    //         new Cesium.TimeIntervalCollection([
    //           new Cesium.TimeInterval({
    //             start: cesiumData.startTime,
    //             stop: cesiumData.stopTime,
    //           }),
    //         ])
    //       }
    //       position={surface.originPoint}
    //       show={
    //         viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
    //         Cesium.SceneMode.SCENE2D
    //       }
    //     >
    //       <PolylineGraphics
    //         positions={new Cesium.PositionPropertyArray([surface.originPoint, surface.endPoint])}
    //         show={showBfVectors}
    //         width={cesiumBfVectorLineWidth}
    //         material={new Cesium.PolylineArrowMaterialProperty(cesiumSurfaceVectorColor)}
    //         arcType={Cesium.ArcType.NONE}
    //       />
    //     </Entity>,
    //     <Entity
    //       key={surface.name + 'Label'}
    //       name={surface.name}
    //       availability={
    //         new Cesium.TimeIntervalCollection([
    //           new Cesium.TimeInterval({
    //             start: cesiumData.startTime,
    //             stop: cesiumData.stopTime,
    //           }),
    //         ])
    //       }
    //       position={surface.labelPoint}
    //       show={
    //         viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode !==
    //         Cesium.SceneMode.SCENE2D
    //       }
    //     >
    //       <LabelGraphics
    //         text={surface.name}
    //         show={showBfVectors}
    //         fillColor={cesiumSurfaceVectorColor}
    //         scaleByDistance={
    //           new Cesium.NearFarScalar(
    //             6378 * 1000,
    //             cesiumBfVectorLabelTextScale,
    //             data[0].orbitalElements.a * 1000 * 20,
    //             cesiumBfVectorLabelTextScale / 10
    //           )
    //         }
    //       />
    //     </Entity>,
    //   ];
    // });
  }, []); // cesiumData, showBfVectors, data, viewerRef

  const targets = useMemo(() => {
    if (!cesiumData.targets) return [];
    return cesiumData.targets.map((target) => (
      <TargetEntity
        key={`${target.targetType.charAt(0)}T_${target.name}`}
        target={target}
        data={cesiumData}
        showOrbitTails={showOrbitTails}
        orbitTailFactor={cesiumOrbitTailFactor}
        showLabels={showTargetNames}
        labelTextScale={cesiumLabelTextScale}
        viewerRef={viewerRef}
        cadSignedUrl={target.cadSignedUrl}
        cadScaleFactor={target.cadScaleFactor * cadScaleMultiplier}
      />
    ));
  }, [cesiumData, showTargetNames, cadScaleMultiplier, showOrbitTails, viewerRef]);

  const sensors = useMemo(() => {
    if (!cesiumData.fovs) return [];
    return cesiumData.fovs.map((field) => {
      const commonEntityProps = {
        key: field.name,
        name: `FoV: ${field.name}`,
        position: field.position3D,
        orientation: field.quaternion,
      };
      const commonGraphicProps = {
        material: Cesium.Color.PURPLE.withAlpha(0.25),
        outline: false,
      };

      if (
        field.fieldOfViewType === 'RECT_FIELD_OF_VIEW' &&
        field.heightHalfAngle.rad < Cesium.Math.toRadians(90) &&
        field.widthHalfAngle.rad < Cesium.Math.toRadians(90)
      ) {
        return (
          <Entity {...commonEntityProps} key={field.name}>
            <EllipsoidGraphics
              {...commonGraphicProps}
              // #TODO: May want to remove dynamically determined radii every timestep, potentially user defined length
              radii={cesiumData.sensorLengthCartesian}
              innerRadii={new Cesium.Cartesian3(10.0, 10.0, 10.0)}
              stackPartitions={3}
              slicePartitions={3}
              subdivisions={3}
              minimumClock={Cesium.Math.toRadians(90) - field.heightHalfAngle.rad}
              maximumClock={Cesium.Math.toRadians(90) + field.heightHalfAngle.rad}
              minimumCone={Cesium.Math.toRadians(90) - field.widthHalfAngle.rad}
              maximumCone={Cesium.Math.toRadians(90) + field.widthHalfAngle.rad}
              show={showFovs}
            />
          </Entity>
        );
      } else if (
        field.fieldOfViewType === 'CIRC_FIELD_OF_VIEW' &&
        field.halfAngle.rad < Cesium.Math.toRadians(90)
      ) {
        return (
          <Entity {...commonEntityProps} key={field.name}>
            <CylinderGraphics
              {...commonGraphicProps}
              // #TODO: May want to remove dynamically determined length every timestep, potentially user defined length
              length={cesiumData.sensorLengthMag}
              heightReference={Cesium.HeightReference.CLAMPED_TO_GROUND}
              bottomRadius={field.radius}
              topRadius={0}
              show={showFovs}
            />
          </Entity>
        );
      }
      return null;
    });
  }, [cesiumData, showFovs]);

  return (
    <Widget className={classes.playbackViewer} padding="narrow" height="100%" minWidth={0}>
      <div className={classes.playbackViewerContent} ref={playbackViewerContentRef}>
        <Viewer
          className={clsx(classes.cesiumViewer, largerScreen && classes.expandedViewer)}
          ref={viewerRef}
          navigationHelpButton={false}
          timeline={false}
          fullscreenButton={false}
          baseLayerPicker={false}
          //extend={Cesium.viewerCesiumInspectorMixin}
          imageryProvider={imageryProvider}
          geocoder={false}
          sceneModePicker={true}
          trackedEntity={isScenarioPlayback ? null : satelliteEntityRef.current?.cesiumElement}
          onTrackedEntityChange={(v) => {
            setTrackedSatellite(!!v);
          }}
        >
          <SkyAtmosphere show={false} />
          <SkyBox show={true} />
          <ShadowMap darkness={0} />
          <Camera ref={cameraRef} />
          <IndexedClock
            ref={clockRef}
            startTime={cesiumData.startTime}
            stopTime={cesiumData.stopTime}
            multiplier={calculateMultiplier(cesiumClockMultiplierScaleDefault)}
            clockRange={Cesium.ClockRange.CLAMPED}
            animate={animate}
          />
          <Sun />
          <Moon onlySunLighting={false} />
          <Scene
            ref={sceneRef}
            // set `requestRenderMode` to `true` to trigger `onPostRender` only when the frame changes rather than 60 times / second
            // also make sure to comment back in the useEffect above that requests a rerender when cesiumLabels change
            // requestRenderMode={true}
            onMorphStart={() => {
              // only show "Follow Spacecraft" button when in 3d view
              viewerRef.current.cesiumElement.clockViewModel.shouldAnimate = false;
              let currentlyOn3dView =
                viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode ===
                Cesium.SceneMode.SCENE3D;
              setFollowSc(currentlyOn3dView);
              // toggle trackedSatellite to false when not in 3d view
              if (!currentlyOn3dView) setTrackedSatellite(false);
              // Pause playback when transitioning views to make the animation more smooth
            }}
            // When switching to SceneMode.SCENE2D view, camera zooms in at the end of scene transition.
            // This likely comes from an oddity in the Cesium library.
            //    onMorphComplete() here handles a subsequent zoom out.
            onMorphComplete={function () {
              Cesium.requestAnimationFrame(function () {
                // requestAnimationFrame() is necessary to ensure the zoom out happens AFTER the zoom in.
                if (
                  viewerRef.current?.cesiumElement.sceneModePicker._viewModel.sceneMode ===
                  Cesium.SceneMode.SCENE2D
                ) {
                  // Toggle the zoom (CameraFlyTo element below) if Scene switched to 2D
                  setExtraZoom2D(true);
                } else {
                  // Turn off the zoom if Scene switch to 3D or Columbus
                  setExtraZoom2D(false);
                }
              });
            }}
          />
          {extraZoom2D && (
            // Toggled by changing scene to 2D
            <CameraFlyTo
              destination={Cesium.Cartesian3.fromDegrees(0, 0, 40000000)}
              duration={1} // Edit for duration of zoom animation, 0 for no animation, default is ~3
            />
          )}
          {cesiumData.moonPosition && (
            <Entity position={cesiumData.moonPosition} name="Moon" ref={moonRef}>
              <LabelGraphics
                text="Moon"
                scale={cesiumLabelTextScale}
                fillColor={cesiumSatOrbitColor}
                // pixelOffset={Cesium.Cartesian2.fromElements(80, 80, new Cesium.Cartesian2())}
                show={true}
              />
            </Entity>
          )}
          {!_.isEmpty(model) && cesiumData.satellite && (
            <Entity
              ref={satelliteEntityRef}
              name="Satellite"
              availability={
                new Cesium.TimeIntervalCollection([
                  new Cesium.TimeInterval({
                    start: cesiumData.startTime,
                    stop: cesiumData.stopTime,
                  }),
                ])
              }
              position={cesiumData.satellite.position3D}
              orientation={cesiumData.satellite.quaternion}
              model={{
                uri: model.satellite.cadSignedUrl,
                // shadows: Cesium.ShadowMode.DISABLED,
                // luminanceAtZenith: 0,
                // imageBasedLightingFactor: new Cesium.Cartesian2(0.5, 0.5),
                // minimumPixelSize: 150,
                maximumScale: 2000000 * cadScaleFactor * cadScaleMultiplier, // cadModelScaleFactor is now included in the 2E6
                // Two different linear fits are used based on perigee altitude (y = mx + b)
                scale: cadScale(perigeeAlt, cadScaleFactor) * cadScaleMultiplier,
              }}
              path={{
                // resolution prop for path is the max seconds to step when sampling the position, so we set to infinity to not limit the sampling
                resolution: Infinity,
                show: showOrbitTails,
                trailTime: a2Period(semiMajor) * cesiumOrbitTailFactor,
                leadTime: 0,
                material: cesiumSatOrbitColor,
                width: cesiumSatOrbitLineWidth,
              }}
            >
              <LabelGraphics
                text={repo.name}
                scale={cesiumLabelTextScale}
                fillColor={cesiumSatOrbitColor}
                pixelOffset={Cesium.Cartesian2.fromElements(80, 80, new Cesium.Cartesian2())}
                show={false}
              />
            </Entity>
          )}
          {targets}
          {bodyFrameVectors}
          {sunTrackingSurfaces}
          {sensors}
        </Viewer>
        <div className={classes.buttonContainer}>
          <StyledButton
            className={classes.viewerButton}
            min
            type="button"
            onClick={() => setCadScaleMultiplier(cadScaleMultiplier * 1.1)}
            dontDisableInReadOnly
          >
            <div>
              <FontAwesomeIcon icon={faCube} /> <FontAwesomeIcon icon={faAngleDoubleUp} />
            </div>
          </StyledButton>
          <StyledButton
            className={classes.viewerButton}
            min
            type="button"
            onClick={() => setCadScaleMultiplier(cadScaleMultiplier * 0.9)}
            dontDisableInReadOnly
          >
            <div>
              <FontAwesomeIcon icon={faCube} /> <FontAwesomeIcon icon={faAngleDoubleDown} />
            </div>
          </StyledButton>
          {!isScenarioPlayback && (
            <StyledButton
              className={classes.viewerButton}
              min
              type="button"
              onClick={() => {
                setShowBfVectors((prev) => !prev);
              }}
              off={!showBfVectors}
              dontDisableInReadOnly
            >
              <div>
                <FontAwesomeIcon icon={faArrowsAlt} />
              </div>
            </StyledButton>
          )}
          <StyledButton
            className={classes.viewerButton}
            min
            type="button"
            onClick={() => {
              setShowTargetNames((prev) => !prev);
            }}
            off={!showTargetNames}
            dontDisableInReadOnly
          >
            <div>
              <FontAwesomeIcon icon={faTags} />
            </div>
          </StyledButton>
          {!isScenarioPlayback && (
            <StyledButton
              className={classes.viewerButton}
              min
              type="button"
              onClick={() => {
                setShowFovs((prev) => !prev);
              }}
              off={!showFovs}
              dontDisableInReadOnly
            >
              <div>
                <FontAwesomeIcon icon={faDotCircle} />
              </div>
            </StyledButton>
          )}
          <StyledButton
            className={classes.viewerButton}
            min
            type="button"
            onClick={() => {
              setShowOrbitTails((prev) => !prev);
            }}
            off={!showOrbitTails}
            dontDisableInReadOnly
          >
            <div>
              <FontAwesomeIcon icon={faCircleNotch} />
            </div>
          </StyledButton>
          {viewerRef.current && followSc && (
            <>
              {!isScenarioPlayback && (
                <StyledButton
                  className={classes.viewerButton}
                  min
                  type="button"
                  onClick={() => {
                    // If the satellite is currently tracked, setting it back to undefined resets the view to not follow it. Otherwise track the satellite
                    if (
                      viewerRef.current?.cesiumElement.trackedEntity ===
                      satelliteEntityRef.current?.cesiumElement
                    ) {
                      cameraRef.current.cesiumElement.flyHome();
                      viewerRef.current.cesiumElement.trackedEntity = undefined;
                    } else {
                      // cancelFlight will cancel the current flight if there is one, otherwise it does nothing. This protects against the user clicking the button before the flight finishes
                      cameraRef.current.cesiumElement.cancelFlight();
                      viewerRef.current.cesiumElement.trackedEntity =
                        satelliteEntityRef.current.cesiumElement;
                    }
                  }}
                  off={!viewerRef.current?.cesiumElement.trackedEntity}
                  dontDisableInReadOnly
                >
                  <div>
                    <FontAwesomeIcon icon={faVideoCamera} />
                  </div>
                </StyledButton>
              )}
              {/* Disabled Follow Moon button everywhere b/c it goes to the middle of the mood, fix that to add back in */}
              {/* <StyledButton
                className={classes.viewerButton}
                min
                type="button"
                onClick={() => {
                  // If the satellite is currently tracked, setting it back to undefined resets the view to not follow it. Otherwise track the satellite
                  if (
                    viewerRef.current?.cesiumElement.trackedEntity ===
                    moonRef.current?.cesiumElement
                  ) {
                    cameraRef.current.cesiumElement.flyHome();
                    viewerRef.current.cesiumElement.trackedEntity = undefined;
                  } else {
                    // cancelFlight will cancel the current flight if there is one, otherwise it does nothing. This protects against the user clicking the button before the flight finishes
                    cameraRef.current.cesiumElement.cancelFlight();
                    viewerRef.current.cesiumElement.trackedEntity = moonRef.current.cesiumElement;
                  }
                }}
                off={!viewerRef.current?.cesiumElement.trackedEntity}
                dontDisableInReadOnly
              >
                Follow Moon
              </StyledButton> */}
            </>
          )}
        </div>
      </div>
    </Widget>
  );
};

const withReducedContext = (WrappedComponent) => {
  const Result = (props) => {
    const { setTime } = useContext(TimeContext);
    const {
      activeAgentData: {
        cesiumData,
        perigeeAlt,
        calculateMultiplier,
        orbitalElementsSeries,
        staticModel: model,
      },
    } = useAnalyticsContext();

    const contextValues = useMemo(() => {
      return {
        TimeContext: { setTime },
        DataContext: {
          cesiumData,
          perigeeAlt,
          calculateMultiplier,
          orbitalElementsSeries,
          model,
        },
      };
    }, [setTime, cesiumData, perigeeAlt, calculateMultiplier, orbitalElementsSeries, model]);

    return <WrappedComponent {...props} contextValues={contextValues} />;
  };
  Result.displayName = 'WithReducedContext';
  return Result;
};

export default withReducedContext(memo(PlaybackViewer));
