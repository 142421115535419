import { useMemo, useEffect } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm, useEntityDialogControl } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import validation from './validation';
import { useGuidance } from './guidance';
import { FieldOfViewVables } from 'utils/vable';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import { IColumn, ISelectOption, TDialogConfig } from 'components/general/types';
import FovConstraintDialog from './FovConstraintDialog';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { InputAdornment } from '@material-ui/core';
import { IFieldOfView, IFovConstraint } from 'components/general/types/spacecraft';
import GncAccent from 'components/general/Accent/variants/GncAccent';

interface IProps {
  control: TEntityDialogControl<IFieldOfView>;
}

interface IForm {
  boresightBodyFrameVector: ISelectOption | '';
  fieldOfViewType: ISelectOption | '';
  halfAngle: number | '';
  heightBodyFrameVector: ISelectOption | '';
  heightHalfAngle: number | '';
  name: string;
  widthHalfAngle: number | '';
}

const defaultValues: IForm = {
  boresightBodyFrameVector: '',
  fieldOfViewType: '',
  halfAngle: '',
  heightBodyFrameVector: '',
  heightHalfAngle: '',
  name: '',
  widthHalfAngle: '',
};

const fovConstraintsColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
];

const FieldOfViewDialog = ({ control }: IProps) => {
  const {
    dialogConfig: { entity: fieldOfView, action },
    setDialogConfig,
  } = control;

  const classes = useStyles();
  const { bodyFrameVectors, model } = useActiveEntities();
  const bodyFrameVectorsList = useMemo(
    () => bodyFrameVectors.map((bfv) => ({ value: bfv.id, label: bfv.name })),
    [bodyFrameVectors]
  );

  const fovConstraintDialogControl = useEntityDialogControl<IFovConstraint>();
  const associatedConstraints = useMemo(() => fieldOfView?.constraints || [], [fieldOfView]);

  // This useEffect re-opens the dialog for the same field of view after edit/create/delete of a constraint => rerenders dialog,
  // otherwise the new constraint does not show up in the list of associated constraints
  useEffect(() => {
    setDialogConfig((prev: TDialogConfig<IFieldOfView>) => {
      if (prev.open && prev.entity && prev.action === 'edit')
        return {
          open: true,
          action: 'edit',
          entity: model.FieldOfView.byId(prev.entity.id) as IFieldOfView,
        };
      return prev;
    });
  }, [associatedConstraints, model, setDialogConfig]);

  const options = useMemo(() => {
    return {
      boresightBodyFrameVector: bodyFrameVectorsList,
      heightBodyFrameVector: bodyFrameVectorsList,
      fieldOfViewType: FieldOfViewVables.FieldOfViewTypes.options,
    };
  }, [bodyFrameVectorsList]);

  const entityForm = useEntityForm<IFieldOfView, IForm>({
    entityTypeText: 'Field Of View',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    editAfterCreate: true,
    formikOptionalParams: { useGuidance, options },
  });

  const { formik } = entityForm;
  const { values, getFieldProps } = formik;

  return (
    <>
      <EntityDialog entityForm={entityForm}>
        <div className={classes.inputs}>
          <div className={classes.inputGroup}>
            <LabeledInput
              label="Field of View Name"
              placeholder="Field of View Name"
              {...getFieldProps('name')}
              autoFocus
            />
          </div>
          <div className={classes.inputGroup}>
            <LabeledSelect
              label="Boresight Body Frame Vector"
              options={options.boresightBodyFrameVector}
              {...getFieldProps('boresightBodyFrameVector')}
              noOptionsMessage={() => 'Create a Body Frame Vector'}
            />
          </div>
          <div className={classes.inputGroup}>
            <LabeledSelect
              {...getFieldProps('fieldOfViewType')}
              label="Field of View Type"
              options={options.fieldOfViewType}
              isDisabled={action !== 'create'}
            />
            <div className={classes.indent}>
              {values.fieldOfViewType === FieldOfViewVables.FieldOfViewTypes.RECT_FIELD_OF_VIEW && (
                <>
                  <LabeledSelect
                    label="Vertical Axis Body Frame Vector"
                    options={options.heightBodyFrameVector}
                    {...getFieldProps('heightBodyFrameVector')}
                    noOptionsMessage={() => 'Create a Body Frame Vector'}
                  />
                  <LabeledInput
                    {...getFieldProps('heightHalfAngle')}
                    label="Height Half Angle"
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  />
                  <LabeledInput
                    {...getFieldProps('widthHalfAngle')}
                    label="Width Half Angle"
                    type="number"
                    endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                  />
                </>
              )}
              {values.fieldOfViewType === FieldOfViewVables.FieldOfViewTypes.CIRC_FIELD_OF_VIEW && (
                <LabeledInput
                  {...getFieldProps('halfAngle')}
                  label="Conical Half Angle"
                  type="number"
                  endAdornment={<InputAdornment position="end">deg</InputAdornment>}
                />
              )}
            </div>
          </div>
        </div>
        {action === 'create' ? (
          <p>Create a field of view to add constraints.</p>
        ) : (
          <div className={classes.inputGroup}>
            <GncAccent header="Field of View Constraints">
              <WidgetTable
                className={classes.table}
                columns={fovConstraintsColumns}
                data={associatedConstraints}
                modelName="field of view constraint"
                onFabClick={fovConstraintDialogControl.openDialogForNew}
                onActionClick={fovConstraintDialogControl.openDialogForExisting}
              />
            </GncAccent>
          </div>
        )}
      </EntityDialog>
      {fovConstraintDialogControl.dialogConfig.open && (
        <FovConstraintDialog control={fovConstraintDialogControl} fieldOfView={fieldOfView} />
      )}
    </>
  );
};

export default FieldOfViewDialog;
