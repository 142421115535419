import { TDPSchemaList } from 'components/general/types';

const tsThermalInterfaceDPSchema: TDPSchemaList = [
  {
    name: '"{name}" Thermal Interface',
    each: 'ThermalInterface',
    subtitle: '',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Thermal Heat Flow Rate',
          unit: 'W',
          keys: ['heatFlowRate'],
          legend: ['Component Heat Flow Rate'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Temperatures',
          unit: 'C',
          keys: ['hotTemp.celsius', 'coldTemp.celsius'],
          legend: ['Hot Temp', 'Cold Temp'],
        },
        right: {
          label: 'Delta',
          unit: 'C',
          keys: ['tempDelta'],
          legend: ['Temperature Delta'],
        },
      },
      {
        type: 'time-series',
        left: {
          label: 'Hot/Cold Margin',
          unit: 'C',
          keys: ['hotMargin', 'coldMargin'],
          legend: ['Hot Margin', 'Cold Margin'],
        },
      },
    ],
  },
];

export default tsThermalInterfaceDPSchema;
