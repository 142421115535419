import { useState, useEffect, useCallback } from 'react';
import useStyles from './PriorityQueue.styles';
import clsx from 'clsx';
import Muuri from 'muuri';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from 'components/general/CircularProgress';
import PriorityDecoration from 'components/general/PriorityDecoration';
import ClearIcon from '@material-ui/icons/Clear';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { toTitleCase } from 'utils/strings';

// Helper function to get the specific entity ID off of Muuri Grid Objects
const getMuuriObjectDataEntityId = (gridItem) => {
  return parseInt(gridItem._element.attributes.getNamedItem('data-entity-id').value);
};

const SelectPriorityQueue = (props) => {
  const {
    data,
    name,
    getFieldProps,
    selectPlaceholder,
    setGridState,
    options,
    setData,
    setQueueDirty,
  } = props;

  const { guide } = getFieldProps;
  const [grid, setGrid] = useState();

  const _onChange = useCallback(
    (_grid) => {
      // // On a drag and drop we just get the new order of entity ID's
      const newOrder = _grid.getItems().map((gridItem) => getMuuriObjectDataEntityId(gridItem));
      setGridState(newOrder);
    },
    [setGridState]
  );

  useEffect(() => {
    if (data) {
      let g = new Muuri(`.priority-queue-grid-${name}`, {
        dragEnabled: true,
        layoutDuration: 0,
        showDuration: 0,
        hideDuration: 0,
      });
      setGrid(g);

      // On drag release event binding to update entity priorities
      g.on('dragReleaseEnd', function (item) {
        _onChange(g);
        setQueueDirty(true);
      });
      // pass on change here so order is established after adding/deleting objects
      _onChange(g);

      return () => g.destroy();
    }
  }, [data]); //eslint-disable-line

  useEffect(() => {
    if (grid) {
      grid.refreshItems().layout(true);
    }
  }, [data]); //eslint-disable-line

  const classes = useStyles();

  const entityAdd = useCallback(
    (entity) => {
      setData([...data, entity]);
      setQueueDirty(true);
    },
    [setData, data, setQueueDirty]
  );

  const deleteOnClick = useCallback(
    (entityId) => {
      const newArray = data.filter((entry) => {
        return entry.id !== entityId;
      });
      // grid.remove still needs to be called in order to render the deletion on the grid
      // Must find specific grid object to remove using gridItems()
      const _entityId = parseInt(entityId);
      const gridItemToRemove = grid
        .getItems()
        .find((gridItem) => _entityId === getMuuriObjectDataEntityId(gridItem));
      grid.remove([gridItemToRemove]);
      setData(newArray);
      setQueueDirty(true);
    },
    [grid, setData, data, setQueueDirty]
  );

  if (!data) {
    return (
      <div className={classes.loading}>
        <CircularProgress loading={true} />
      </div>
    );
  }

  return (
    <>
      <LabeledSelect
        className={classes.select}
        placeholder={selectPlaceholder}
        // Add our own custom on change
        onChange={(e) => entityAdd(e)}
        options={options}
        // Keep the value as null as this select is only used to add entities
        name={name}
        guide={guide}
        value={null}
        noOptionsMessage={() => `Create a ${toTitleCase(name)}`}
      />
      <div className={classes.root}>
        <PriorityDecoration
          className={clsx(classes.decoration, classes.decorationForSelectVariant)}
        />
        <div className={classes.queue}>
          <div className={clsx(`priority-queue-grid-${name}`, classes.grid)}>
            {data.map((entity) => {
              return (
                <div
                  className={classes.slot}
                  data-entity-id={entity.id}
                  data-entity-value={entity}
                  key={'pq-' + entity.id}
                >
                  <div className={classes.slotContent}>
                    <Grid container spacing={1} alignItems={'center'}>
                      <Grid item xs={7} className={classes.name}>
                        {entity.name}
                      </Grid>
                    </Grid>
                    <IconButton
                      edge={'end'}
                      size={'small'}
                      onClick={() => deleteOnClick(entity.id)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectPriorityQueue;
