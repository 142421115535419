import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from 'theme';

export const useStyles = makeStyles((theme) => ({
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.mainNested,
    borderRadius,
    padding: '1rem',
  },
  slider: {
    width: '90%',
    alignSelf: 'center',
  },
  sliderTitleFirst: {
    marginBlockStart: '0',
    marginBlockEnd: '0.5rem',
  },
  sliderTitle: {
    marginBlockStart: '0.75rem',
    marginBlockEnd: '0.5rem',
  },
}));

export const useStylesNode = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
  },
  label: {
    color: 'black',
    fontSize: 14,
    lineHeight: '14px',
    letterSpacing: '-0.5px', // squish letters closer together
    margin: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  temp: {
    margin: 0,
    color: 'black',
  },
  child: {
    margin: 0,
    color: 'black',
  },
});

export const useStylesToolTipTitle = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      margin: 0,
      padding: 0,
    },
  },
});
