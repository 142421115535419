import { ReactNode, useContext } from 'react';
import StyledButton from './StyledButton';
import { SpacecraftContext } from 'providers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSatellite } from '@fortawesome/free-solid-svg-icons';
import { ISpacecraftDialogConfig } from './types';
import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { hotkeys } from 'config';

interface IProps {
  tabNumber?: number;
  children?: ReactNode;
  className?: string;
  iconButton?: boolean;
  framed?: boolean;
  borderless?: boolean;
}

const SpacecraftDialogButton = (props: IProps) => {
  const { tabNumber, children, className, iconButton, ...remainingProps } = props;
  const { setSpacecraftDialogConfig } = useContext(SpacecraftContext);

  const onClick = () =>
    setSpacecraftDialogConfig((prev: ISpacecraftDialogConfig) => ({
      ...prev,
      open: true,
      tabNumber: tabNumber || prev.tabNumber,
    }));

  if (iconButton) {
    return (
      <Tooltip
        title={`Edit Spacecraft [${hotkeys.SC_DIALOG}]`}
        arrow
        className={className}
        {...remainingProps}
      >
        <IconButton onClick={onClick}>
          <FontAwesomeIcon icon={faSatellite} />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <div className={className}>
      <StyledButton onClick={onClick} fullWidth {...remainingProps}>
        <FontAwesomeIcon icon={faSatellite} />
        <span style={{ paddingLeft: 6 }}>{children}</span>
      </StyledButton>
    </div>
  );
};

export default SpacecraftDialogButton;
