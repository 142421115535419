import { TDPSchemaList } from 'components/general/types';

const tsPerturbationsDPSchema: TDPSchemaList = [
  {
    name: 'Total Torques',
    subtitle: 'Total torque, total perturbation torque, and total control torque',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Torque, S/C Body-Frame',
          unit: 'N-m',
          keys: [
            'torque.eci.0',
            'torque.eci.1',
            'torque.eci.2',
            'perturbationTorque.0',
            'perturbationTorque.1',
            'perturbationTorque.2',
            'controlTorque.0',
            'controlTorque.1',
            'controlTorque.2',
          ],
          legend: [
            'Total: x',
            'Total: y',
            'Total: z',
            'Perturbations: x',
            'Perturbations: y',
            'Perturbations: z',
            'Control: x',
            'Control: y',
            'Control: z',
          ],
        },
      },
    ],
  },
  {
    name: 'Gravity Gradient Torque',
    subtitle: 'Point-mass gravity field model',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Torque, S/C Body-Frame',
          unit: 'N-m',
          keys: ['gravityGradientTorque.0', 'gravityGradientTorque.1', 'gravityGradientTorque.2'],
          legend: ['x-Axis', 'y-Axis', 'z-Axis'],
        },
      },
    ],
  },
  {
    name: 'Drag Torque',
    subtitle: 'Exponential atmosphere model, panelized external geometry',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Torque, S/C Body-Frame',
          unit: 'N-m',
          keys: ['dragTorque.0', 'dragTorque.1', 'dragTorque.2'],
          legend: ['x-Axis', 'y-Axis', 'z-Axis'],
        },
      },
    ],
  },
  {
    name: 'Solar Radiation Pressure Torque',
    subtitle: 'Panelized external geometry',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Torque, S/C Body-Frame',
          unit: 'N-m',
          keys: [
            'srpTorque.0',
            'srpTorque.1',
            'srpTorque.2',
          ],
          legend: [
            'x-Axis',
            'y-Axis',
            'z-Axis',
          ],
        },
      },
    ],
  },
];

export default tsPerturbationsDPSchema;
