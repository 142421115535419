import { useHistory } from 'react-router-dom';
import WGroup from 'components/general/WGroup';
import { wGroupIndicesScenario } from 'components/ScenarioView/menu';
import RowedExplorer from 'components/general/RowedExplorer';
import Row from 'components/general/RowedExplorer/Row';
import { useActiveEntities } from 'hooks';
import Routes, { getSearchParams } from 'routes';
import { GNC } from 'components/AgentTemplateEditView/menu/gnc';
import { TARGETS } from 'components/AgentTemplateEditView/menu/targets';
import LoadingView from 'components/LoadingView';
import { useParams } from 'react-router-dom';
import { IUrlParams } from 'components/general/types';
import { useDataContext } from 'providers';

const Agents = () => {
  const { branch, agents } = useActiveEntities();
  const history = useHistory();
  const { id } = useParams<IUrlParams>();
  const { share } = getSearchParams();
  const { staticModels } = useDataContext();

  const makeButtonProps = (agentTemplateId: number) => {
    return {
      text: 'Edit Agent Template',
      onClick: () => history.push(Routes.AGENT_TEMPLATE_EDIT(agentTemplateId, TARGETS)),
    };
  };

  // second half of this (after "||") is for when user navigates to "Agent Template" and then clicks "back". The active
  // branch has to reset back to what it should be, which takes some time.
  if (!branch || branch.id !== Number(id)) return <LoadingView />;

  return (
    <WGroup index={wGroupIndicesScenario.ANALYZE_AGENTS}>
      <RowedExplorer name={branch.name} description={branch.description} rowsTitle="Agents">
        {agents.flatMap(({ id, name, templateRef, peripheral }) => {
          // flatMap turns empty array into nothing, thus nothing rendered for peripheral agents
          if (!staticModels.agents[id] || peripheral) return [];
          return (
            <Row
              key={id}
              name={name}
              onClick={() =>
                history.push(
                  Routes.AGENT_ANALYZE(branch.id, GNC) +
                    `?agentId=${id}${share ? '&share=' + share : ''}`
                )
              }
              description={`Source Branch ID: ${templateRef}`}
              buttonProps={makeButtonProps(templateRef)}
            />
          );
        })}
      </RowedExplorer>
    </WGroup>
  );
};

export default Agents;
