import { useCallback, useContext, useMemo } from 'react';
import TimeSeriesChart from 'components/general/chartComponents/TimeSeriesChart';
import Widget from 'components/general/widgets/Widget';
import WGroup from 'components/general/WGroup';
import { IAxisDef, IGenericObject, IPlotDef, TDPSchemaList } from 'components/general/types';
import { TCompiledModel, useModel } from 'middleware/SatelliteApi/template';
import { getSearchParams } from 'routes';
import { DataContext } from 'providers/DataProvider';

interface IProps {
  wGroupIndex: string;
  data: IGenericObject;
  dpSchema: TDPSchemaList;
}

type TKeyTup = [string, IGenericObject];

const ambiguousToIndexable = (ref: IGenericObject) => (ref?.all ? ref.all() : ref);
const eachToPrefix = (def: IGenericObject, model: TCompiledModel) => {
  let each: TKeyTup[] = [['', model]];
  if (def.each) {
    let ref: IGenericObject = model;
    for (const k of def.each.split('.')) {
      ref = ref[k];
    }
    each = ambiguousToIndexable(ref).map((e: IGenericObject, i: number) => [
      `${def.each}.$${i}.`,
      e,
    ]);
  }
  return each;
};

const DataPlottingWidgetGroup = ({ wGroupIndex, data, dpSchema }: IProps) => {
  const { staticModels, resolveSeriesDataKeyPair } = useContext(DataContext);
  const { agentId } = getSearchParams();
  const model = useModel(staticModels.agents[agentId]);

  const _genPlot = useCallback(
    (plotDef: IPlotDef, [parentBase, initialRef]) => {
      if (plotDef.right?.keys.some((name: string) => name.includes('$each.'))) {
        throw Error("Only a single right-axis series is currently supported: right.keys: ['...']");
      }

      // Expand keys
      const expand = (key: string) => {
        if (key.includes('root')) {
          const after = key.split('root.');
          key = after[after.length - 1];
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let ref: any = model;
        let endOfString = 0;
        const keys: [string, IGenericObject][] = [];
        for (const k of key.split('.')) {
          endOfString += k.length + 1;
          const restOfString = (endOfString > key.length ? '' : '.') + key.slice(endOfString);
          ref = ambiguousToIndexable(ref);
          if (k.charAt(0) === '$') {
            if (k === '$each') {
              for (const block of ref) {
                keys.push([block.id + restOfString, block]);
              }
              return keys;
            } else {
              ref = ref[k.slice(1)];
              if (ref && !restOfString.includes('$')) {
                keys.push([ref.id + restOfString, ref]);
                return keys;
              }
              continue;
            }
          }
          if (!ref) break;
          if (ref.id) {
            if (Array.isArray(ref[k])) {
              if (!ref[k].length) {
                // Empty relationship
                return keys;
              } else if (ref[k][0].id) {
                // Non-empty relationship, expand in next iteration
                ref = ref[k];
                continue;
              } else {
                // At the containing block
                keys.push([ref.id + '.' + k + restOfString, ref]);
                return keys;
              }
              // Else keep going
            } else if (ref.rels?.has(k) && !ref[k]) {
              // Empty relationship
              return keys;
            } else if (!ref[k] || !ref[k].id) {
              // At the containing block
              keys.push([ref.id + '.' + k + restOfString, ref]);
              return keys;
            }
          }
          ref = ref[k];
        }
        keys.push([key, model]);
        return keys;
      };
      const each = eachToPrefix(plotDef, initialRef);
      const leftKeys = each.map(([base, p]) => [
        plotDef.left.keys.flatMap((k, i) => expand(parentBase + base + k).map((e) => [...e, i])),
        p,
      ]);
      const rightkeys = each.map(([base, p]) => [
        plotDef.right && expand(parentBase + base + plotDef.right.keys[0])[0],
        p,
      ]);

      const parseLegend = (axisDef: IAxisDef, i: number, entity: IGenericObject) => {
        const l = axisDef.legend && axisDef.legend[i];
        if (!entity) return l;
        return l?.replaceAll('{name}', entity.name);
      };

      return leftKeys.flatMap(([keys, parentEntity], i) => {
        const [rightKey] = rightkeys[i];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const lines = keys.flatMap(([key, entity, i]: any) => {
          const keypair = resolveSeriesDataKeyPair(agentId, key);
          if (!keypair) return [];
          return [
            {
              name: parseLegend(plotDef.left, i, entity) || key,
              ...keypair,
            },
          ];
        });
        if (plotDef.right && rightKey) {
          const keypair = resolveSeriesDataKeyPair(agentId, rightKey[0]);
          if (keypair)
            lines.push({
              name: parseLegend(plotDef.right, 0, rightKey[1]) || rightKey[0],
              ...keypair,
              right: true,
            });
        }

        if (!lines.length) return [];

        const title = plotDef.name?.replaceAll('{name}', parentEntity.name);
        return (
          <TimeSeriesChart
            title={title}
            key={JSON.stringify(lines)}
            data={data}
            withZoom={true}
            lines={lines}
            leftLabel={plotDef.left.label}
            leftUnits={plotDef.left.unit}
            rightLabel={plotDef.right?.label}
            rightUnits={plotDef.right?.unit}
          />
        );
      });
    },
    [model, data, resolveSeriesDataKeyPair, agentId]
  );

  const widgets = useMemo(
    () =>
      dpSchema.flatMap((widgetDef, i) => {
        const each = eachToPrefix(widgetDef, model);
        return each.map((tup, j) => {
          const [, p] = tup;
          const name = widgetDef.name.replaceAll('{name}', p.name);
          const plots = widgetDef.plots.flatMap((plot) => _genPlot(plot, tup));
          if (!plots.length) return null;
          return (
            <Widget title={name} collapsibleConfig subtitle={widgetDef.subtitle} key={`${i}_${j}`}>
              {plots}
            </Widget>
          );
        });
      }),
    [dpSchema, model, _genPlot]
  );

  return <WGroup index={wGroupIndex}>{widgets}</WGroup>;
};

export default DataPlottingWidgetGroup;
