import * as Yup from 'yup';
import { ISelectOption } from 'components/general/types';
import { ActuatorVables } from 'utils/vable';
import getThermalProps from 'hooks/getThermalProps';

const { thermalValidation } = getThermalProps();

const validation = Yup.object().shape({
  name: Yup.string().required('An actuator name is required.'),
  manufacturer: Yup.string(),
  partNumber: Yup.string(),
  bodyFrameVector: Yup.object().required('Select a torque body frame vector.'),
  componentType: Yup.object().required('Select an actuator type.'),
  ratedMagneticMoment: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === ActuatorVables.ComponentType.MAGNETORQUER.value,
    then: Yup.number().required('Rated magnetic moment is required.'),
  }),
  powerAtRatedMagneticMoment: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === ActuatorVables.ComponentType.MAGNETORQUER.value,
    then: Yup.number().required('Power at rated magnetic moment is required.'),
  }),
  inertia: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === ActuatorVables.ComponentType.REACTION_WHEEL.value,
    then: Yup.number().required('Inertia is required.').moreThan(0, 'Inertia must be positive.'),
  }),
  ratedTorque: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === ActuatorVables.ComponentType.REACTION_WHEEL.value,
    then: Yup.number()
      .required('Rated torque is required.')
      .moreThan(0, 'Rated torque must be positive.'),
  }),
  ratedMomentum: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === ActuatorVables.ComponentType.REACTION_WHEEL.value,
    then: Yup.number()
      .required('Rated angular momentum is required.')
      .moreThan(0, 'Rated angular momentum must be positive.'),
  }),
  efficiency: Yup.number().when('componentType', {
    is: (componentType: ISelectOption) =>
      componentType?.value === ActuatorVables.ComponentType.REACTION_WHEEL.value,
    then: Yup.number()
      .required('Efficiency is required.')
      .moreThan(0, 'Efficiency must be greater than 0%.')
      .max(100, 'Efficiency cannot be above 100%.'),
  }),
  ...thermalValidation,
});

export default validation;
