import { TDPSchemaList } from 'components/general/types';

const tsComponentDissipationDPSchema: TDPSchemaList = [
  {
    name: 'Dissipation Overview',
    subtitle: 'Dissipation by Subsystem',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Thermal Dissipation',
          unit: 'W',
          keys: ['Subsystem.$each.dissipations.total'],
          legend: ['"{name}" Total Dissipation'],
        },
      },
    ],
  },
  {
    name: '"{name}" Subsystem Dissipation',
    subtitle: 'Dissipation by Component',
    each: 'Subsystem',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Dissipation',
          unit: 'W',
          keys: ['dissipations.total', 'components.$each.dissipations.total'],
          legend: ['Total Dissipation', '"{name}" Dissipation'],
        },
      },
    ],
  },
];

export default tsComponentDissipationDPSchema;
