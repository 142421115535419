import { ReactElement, useContext } from 'react';
import { IGenericObject, IMenu } from '../types';
import DataPlottingWidgetGroup from './DataPlottingWidgetGroup';
import _ from 'lodash';
import { SUB_ITEMS, TITLE, ITEMS, DP_SCHEMA } from 'config';
import { RangeContext } from 'providers';
// import TimeSeriesPlaybackControls from 'components/general/PlaybackWGroups/TimeSeriesPlaybackControls';

interface IProps {
  menu: IMenu;
}

const createDPWGroups = (menu: IMenu, data: IGenericObject) => {
  const DPWGroups: ReactElement[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const _recurIterKeyVals = (obj: { [key: string]: any }, keys = '') => {
    _.forOwn(obj, (v, k) => {
      if (k === DP_SCHEMA) {
        DPWGroups.push(
          <DataPlottingWidgetGroup
            wGroupIndex={keys}
            data={data}
            dpSchema={v}
            key={JSON.stringify(v)}
          />
        );
        return;
      }

      let newKeys = keys;
      if (![ITEMS, SUB_ITEMS, TITLE].includes(k)) {
        if (newKeys.length) newKeys += '.';
        newKeys += k;
      }

      if (typeof v === 'object' && v !== null) {
        _recurIterKeyVals(v, newKeys);
      }
    });
  };

  _recurIterKeyVals(menu);
  return DPWGroups;
};

const DataPlotting = ({ menu }: IProps) => {
  const { echartsData } = useContext(RangeContext);

  const DPWGroups = createDPWGroups(menu, echartsData);

  return (
    <>
      {/* TODO: commented out for small sat, coment back in later */}
      {/* <TimeSeriesPlaybackControls /> */}
      {DPWGroups}
    </>
  );
};

export default DataPlotting;
