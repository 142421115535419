import BaseNode from './general/BaseNode';
import ACSNodeHandle, { Position } from './general/ACSNodeHandle';
import { IAlgoNodeData } from './general/types';

const customContainerStyle = {
  height: '3rem',
};

const AlgoNode = ({ data }: IAlgoNodeData) => {
  return (
    <BaseNode title={data.title} subtitle={data.subtitle} containerStyle={customContainerStyle}>
      <ACSNodeHandle type="target" position={Position.Left} />
      <ACSNodeHandle type="source" position={Position.Right} />
    </BaseNode>
  );
};

export default AlgoNode;
