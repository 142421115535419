import { makeStyles } from '@material-ui/core/styles';
import { rowStyles } from 'components/general/RowedExplorer/Row/styles';

interface IProps {
  selected: boolean;
  enabledDelete: boolean;
}

const useStyles = makeStyles((theme) => ({
  ...rowStyles,
  row: (props: IProps) => ({
    ...rowStyles.row,
    // backgroundColor: props.selected ? theme.palette.background.mainNested : '',
    backgroundColor: props.selected ? 'rgb(32, 53, 69)' : '',
    '& h3': {
      color: props.selected ? theme.palette.primary.main : theme.palette.primary.contrastText,
    },
  }),
  branchDescription: {
    padding: '0.5rem 0.25rem 0.5rem 0',
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
