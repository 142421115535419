import { TDPSchemaList } from 'components/general/types';

const tsSubsysLoadingDPSchema: TDPSchemaList = [
  {
    name: '"{name}" Subsystem Electronic Loading', 
    subtitle: 'Loading by component',
    each: 'Subsystem',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Power Consumed',
          unit: 'W',
          keys: ['components.$each.powerConsumed'],
          legend: ['{name}'],
        },
      },
    ],
  },
];

export default tsSubsysLoadingDPSchema;
