import { useCallback, useContext, useMemo, useEffect } from 'react';
import Widget from 'components/general/widgets/Widget';
import ReactFlow, { addEdge, useNodesState, useEdgesState, Controls } from 'react-flow-renderer';
import usePrepNodesAndEdges, { nodeTypes } from './usePrepNodesAndEdges';
import {
  ISensorNode,
  IAlgoNode,
  IActuatorNode,
  ACTUATOR_REACTION_WHEEL,
  ACTUATOR_MAGNETORQUER,
  MOMENTUM,
  TORQUE,
  MAGNETIC_MOMENT,
} from './general/types';
import { MomentContext } from 'providers';
import { AlgorithmVables, ComponentVables, TAlgorithmTypes } from 'utils/vable';
import { IComponent } from 'components/general/types/power';
import { vectorNorm } from 'utils/math';
import { IAlgorithm, ISensor } from 'components/general/types/gnc';
import { ContextNavContext } from 'providers';
import { wGroupIndicesAgentCustom } from 'components/AgentAnalyzeView/menu/custom';
import { TBlockId } from 'components/general/types';

const ACSStateWidget = () => {
  const { model } = useContext(MomentContext);

  const actuatorNodes: IActuatorNode[] = useMemo(
    () =>
      model.satellite.reactionWheels
        .map(
          (
            rw: IComponent & {
              momentum: number;
              ratedMomentum: number;
              torque: { eci: number };
              ratedTorque: number;
            }
          ) => ({
            id: rw.id,
            targets: [],
            sending: true,
            data: {
              title: rw.name,
              subtitle: ComponentVables.ComponentType[rw.componentType]?.label,
              type: ACTUATOR_REACTION_WHEEL,
              percentages: {
                [MOMENTUM]: Math.abs(rw.momentum / rw.ratedMomentum),
                [TORQUE]: Math.abs(vectorNorm(rw.torque.eci || rw.torque) / rw.ratedTorque),
              },
            },
          })
        )
        .concat(
          model.satellite.magnetorquers.map(
            (
              mt: IComponent & {
                magneticMoment: number;
                ratedMagneticMoment: number;
              }
            ) => ({
              id: mt.id,
              targets: [],
              sending: true,
              data: {
                title: mt.name,
                subtitle: ComponentVables.ComponentType[mt.componentType]?.label,
                type: ACTUATOR_MAGNETORQUER,
                percentages: {
                  [MAGNETIC_MOMENT]: Math.abs(
                    (mt.magneticMoment ? mt.magneticMoment : 0) / mt.ratedMagneticMoment
                  ),
                },
              },
            })
          )
        ),
    [model]
  );

  const acAlgoNodes: IAlgoNode[] = useMemo(
    () =>
      (model.Algorithm.all() as IAlgorithm[]).flatMap((algo) => {
        if (algo.algorithmType !== AlgorithmVables.AlgorithmType.ATTITUDE_CONTROL.value) return []; // OD | AD Algo
        const sending = (targetId: TBlockId) => {
          const type = actuatorNodes.find((e) => e.id === targetId)?.data.type;
          if (type === ACTUATOR_REACTION_WHEEL) return algo.reactionWheelCommands != null;
          else if (type === ACTUATOR_MAGNETORQUER) return algo.magnetorquerCommands != null;
          else throw new Error('Unknown actuator type');
        };
        return {
          id: algo.id,
          targets: actuatorNodes.map((e: IActuatorNode) => e.id),
          sending,
          data: {
            title: algo.name,
            subtitle: 'Attitude Control Algorithm',
          },
        };
      }),
    [model, actuatorNodes]
  );

  const [adOdAlgoNodes, sensorNodes] = useMemo(() => {
    let odAlgo: TBlockId | null = null;
    let adAlgo: TBlockId | null = null;

    const asOdAlgos: IAlgoNode[] = [];
    for (const algo of model.Algorithm.all() as IAlgorithm[]) {
      if (algo.algorithmType === AlgorithmVables.AlgorithmType.ATTITUDE_CONTROL.value) continue; // AC Algo
      if (algo.algorithmType === AlgorithmVables.AlgorithmType.ORBIT_DETERMINATION.value) {
        odAlgo = algo.id;
      } else {
        adAlgo = algo.id;
      }
      const node = {
        id: algo.id,
        targets: [acAlgoNodes[0]?.id], // FIXME: Make robust
        sending:
          (algo.algorithmType === AlgorithmVables.AlgorithmType.ORBIT_DETERMINATION.value
            ? algo.positionSolution
            : algo.attitudeSolution) != null,
        data: {
          title: algo.name,
          subtitle:
            AlgorithmVables.AlgorithmType[algo.algorithmType as TAlgorithmTypes].label +
            ' Algorithm',
        },
      };
      if (algo.algorithmType === AlgorithmVables.AlgorithmType.ORBIT_DETERMINATION.value) {
        asOdAlgos.unshift(node);
      } else {
        asOdAlgos.push(node);
      }
    }

    const sensors: ISensorNode[] = [];
    for (const sensor of model.satellite.sensors as ISensor[]) {
      const keepIns =
        sensor.fieldOfView?.constraints
          .filter((constraint) => !constraint.keepout)
          .map((constraint) => ({ name: constraint.name, compliant: constraint.compliance })) || [];
      const keepOuts =
        sensor.fieldOfView?.constraints
          .filter((constraint) => constraint.keepout)
          .map((constraint) => ({ name: constraint.name, compliant: constraint.compliance })) || [];

      const type = ComponentVables.ComponentType[sensor.componentType];
      const isOd = type.value === ComponentVables.ComponentType.POSITION_SENSOR.value;
      const node = {
        id: sensor.id,
        targets: [isOd ? odAlgo : adAlgo],
        sending: sensor.measurement != null,
        data: {
          title: sensor.name,
          subtitle: type?.label,
          keepIns,
          keepOuts,
        },
      };
      if (isOd) {
        sensors.unshift(node as ISensorNode);
      } else {
        sensors.push(node as ISensorNode);
      }
    }
    return [asOdAlgos, sensors];
  }, [model, acAlgoNodes]);

  const [preparedNodes, preparedEdges] = usePrepNodesAndEdges(
    sensorNodes,
    adOdAlgoNodes,
    acAlgoNodes,
    actuatorNodes
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(preparedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(preparedEdges);

  useEffect(() => {
    setNodes(preparedNodes);
    setEdges(preparedEdges);
  }, [preparedNodes, preparedEdges, setNodes, setEdges]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), [setEdges]);
  const activeKey = useContext(ContextNavContext)?.state?.activeKey;

  return (
    <Widget
      title="ACS State"
      subtitle="State of Sensors, Algorithms, and Actuators"
      collapsibleConfig
    >
      <ReactFlow
        nodeTypes={nodeTypes}
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView
        style={{ height: activeKey === wGroupIndicesAgentCustom.PLAYBACK ? 590 : 700 }}
        attributionPosition="bottom-right"
        deleteKeyCode={[]}
      >
        <Controls />
      </ReactFlow>
    </Widget>
  );
};

export default ACSStateWidget;
