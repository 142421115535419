import { useState, useEffect, useContext } from 'react';
import AttitudeDisplay from 'components/general/SpacecraftDialog/general/AttitudeDisplay';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import Dropzone from 'react-dropzone';
import StyledButton from 'components/general/StyledButton';
import Tooltip from '@material-ui/core/Tooltip';
import Panel from './general/Panel';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { SpacecraftContext } from 'providers';
import { useDispatch } from 'react-redux';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useInReadOnlyBranch, useActiveEntities, useSnackbar } from 'hooks';

// Required import (as of September '22, on v4.2) – gives babylon the ability to load glb and glTF files
import '@babylonjs/loaders/glTF';

const guidance = {
  heading: 'Define your Spacecraft CAD Model',
  body: [
    {
      chunk: 'Select a default CAD model or upload your own CAD model as a GLB file.',
    },
    {
      subHeading: 'Principal Axes',
      chunk:
        'It is important to ensure that the the principal axes in your CAD model are consistent with your spacecraft body-frame. This will ensure that spacecraft geometry parameters and attitude simulation results are consistent with your definition of the body-frame.',
    },
    {
      subHeading: 'Applications',
      chunk:
        'The spacecraft CAD model is a useful tool for visualizing attitude dynamics throughout Sedaro Satellite. This CAD model allows you to verify the orientation of spacecraft body frame vectors in the Geometry Panel of the Spacecraft Dialog. This CAD model will also appear in 3D playback animations - allowing you to easily verify that simulated attitude dynamics are consistent with the intended behavior.',
    },
  ],
};
// bytes (7mb)
const maxFileSize = (5242880 * 7) / 5;

const CADModelPanel = (props) => {
  const { setOnSubmit, ...remainingProps } = props;

  const dispatch = useDispatch();
  const inReadOnlyBranch = useInReadOnlyBranch();

  const {
    Satellite: {
      actions: { deleteCadFile, uploadCadFile, toggleDefaultModel },
    },
  } = SatelliteApi;
  const { satellite, branch, bodyFrameVectors } = useActiveEntities();

  const options = satellite.DEFAULT_CAD_MODELS;

  const { closeSpacecraftDialog } = useContext(SpacecraftContext);

  const [cadModelPreview, setCadModelPreview] = useState({
    fileUrl: satellite.cadSignedUrl,
    fileName: satellite.cadFileName,
  });

  const [fileToUpload, setFileToUpload] = useState(null);
  const [loading, setLoading] = useState(false);

  // allows for save button to be enabled when selecting default models
  const [defaultModelSelected, setDefaultModelSelected] = useState(false);
  const [defaultModel, setDefaultModel] = useState(
    options.find((option) => satellite.cadSignedUrl === option.cadSignedUrl)
  );

  // calculated and set in the CADModel component
  const [cadScaleFactor, setCadScaleFactor] = useState(1);

  // set in CADModel component and used to reset model information if there is an error displaying user's file
  const [modelLoadError, setModelLoadError] = useState(false);

  const [disableSubmit, setDisableSubmit] = useState(true);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  // Reset state back to default if there is an error loading the model
  useEffect(() => {
    if (modelLoadError) {
      setCadModelPreview({
        fileUrl: satellite.cadSignedUrl,
        fileName: satellite.cadFileName,
      });
      setModelLoadError(false);
      setFileToUpload(null);
      setDisableSubmit(true);
    }
  }, [modelLoadError]); // eslint-disable-line

  const changeDefaultModel = async (option) => {
    if (option.cadSignedUrl === cadModelPreview.fileUrl) return;
    setLoading(true);
    setCadModelPreview({
      fileUrl: option.cadSignedUrl,
      fileName: '',
    });
    if (option.cadSignedUrl === satellite.cadSignedUrl) {
      setDisableSubmit(true);
      setDefaultModelSelected(false);
    } else {
      setDisableSubmit(false);
      setDefaultModelSelected(true);
    }
    setDefaultModel(option);
  };

  const handleResetModel = async () => {
    // Delete button
    if (satellite.cadFileName && !defaultModelSelected && !fileToUpload) {
      dispatch(
        deleteCadFile({
          branchId: branch.id,
          id: satellite.id,
          successCallback: (response) => {
            setCadModelPreview({
              fileUrl: response.cadSignedUrl,
              fileName: response.cadFileName,
            });
            setDefaultModel(options[0]);
          },
          failureCallback: (response) => {
            enqueueSnackbar(response.error.message);
          },
        })
      );
    }

    // Reset button
    else {
      setCadModelPreview({
        fileUrl: satellite.cadSignedUrl,
        fileName: satellite.cadFileName,
      });
      setFileToUpload(null);
      setDisableSubmit(true);
      setDefaultModel(options.find((option) => satellite.cadSignedUrl === option.cadSignedUrl));
    }
    setDefaultModelSelected(false);
  };

  // Necessary to bypass bug in Babylon for files under 20 characters
  const prevalidateFileForBabylon = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState === FileReader.DONE) {
          try {
            let arrayBuffer = evt.target.result;
            let array = new Uint8Array(arrayBuffer, 0, 20);
            resolve(array);
          } catch (e) {
            enqueueSnackbar(
              'There was an error loading your file. Please check that your file is not corrupted.'
            );
            setLoading(false);
            reject(e);
          }
        }
      };
    });
  };

  const handleFileAccept = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    // prevent uploading the exact same file twice
    if (
      file.lastModified === cadModelPreview.lastModified &&
      file.name === cadModelPreview.fileName
    )
      return;

    setLoading(true);

    // glTF Validation
    try {
      await prevalidateFileForBabylon(file);
    } catch (e) {
      return;
    }

    setFileToUpload(file);
    // Convert file to blob first for babylon preview
    const blob = new Blob([file]);
    setCadModelPreview({ fileUrl: blob, fileName: file.name, lastModified: file.lastModified });
    setDisableSubmit(false);

    // change defaultModelSelected back to false when user uploads to trigger correct onSubmit logic
    setDefaultModelSelected(false);
  };

  const handleFileReject = (rejectedFiles) => {
    if (rejectedFiles[0].file.size > maxFileSize) enqueueSnackbar('File must be less than 7MB.');
    else enqueueSnackbar('File must be a valid .glb or .glTF');
  };

  const onSubmit = async () => {
    setDisableSubmit(true);
    setLoading(true);
    // onSubmit for handling changing default model
    if (defaultModelSelected) {
      dispatch(
        toggleDefaultModel({
          branchId: branch.id,
          id: satellite.id,
          modelId: defaultModel.value,
          successCallback: (response) => {
            setCadModelPreview({ fileUrl: response.cadSignedUrl, fileName: '' });
            setDefaultModel(response);
            setLoading(false);
            setFileToUpload(null);
            setDefaultModelSelected(false);
          },
          failureCallback: (response) => {
            setLoading(false);
            setDisableSubmit(false);
            enqueueSnackbar(response.error.message);
          },
        })
      );
    }

    // onSubmit for cad file upload
    else {
      const formData = new FormData();

      formData.append('cadFile', fileToUpload);
      formData.append('cadScaleFactor', cadScaleFactor);

      dispatch(
        uploadCadFile({
          branchId: branch.id,
          id: satellite.id,
          formData,
          successCallback: (response) => {
            enqueueSnackbar('Model uploaded successfully!', { variant: 'success' });
            setCadModelPreview({
              fileUrl: response.cadSignedUrl,
              fileName: response.cadFileName,
            });
            setDefaultModel(null);
            setFileToUpload(null);
            setLoading(false);
            setDefaultModelSelected(false);
          },
          failureCallback: (response) => {
            setLoading(false);
            setDisableSubmit(false);
            enqueueSnackbar(response.error.message);
          },
        })
      );
    }
  };

  return (
    <Panel
      title="CAD Model"
      guidance={guidance}
      disableSubmit={disableSubmit}
      onClose={closeSpacecraftDialog}
      onSubmit={onSubmit}
      loading={loading}
      setLoading={setLoading}
      submitActionText="Save"
      secondaryActionText="Close"
      {...remainingProps}
    >
      <div
        className={
          cadModelPreview.fileName
            ? classes.cadFileUploaderContainer
            : classes.cadFileUploaderButton
        }
      >
        {cadModelPreview.fileName && (
          <Tooltip title={cadModelPreview.fileName.length > 60 ? cadModelPreview.fileName : ''}>
            <h4
              className={`sub ${classes.cadFileName}`}
            >{`Current Filename: ${cadModelPreview.fileName}`}</h4>
          </Tooltip>
        )}
        <StyledButton
          onClick={handleResetModel}
          disabled={!defaultModelSelected && !cadModelPreview.fileName}
          framed
          type="button"
          error
        >
          {defaultModelSelected || fileToUpload ? 'Reset' : 'Delete'}
        </StyledButton>
      </div>
      <AttitudeDisplay
        setCadScaleFactor={setCadScaleFactor}
        file={cadModelPreview}
        modelLoadError={modelLoadError}
        setModelLoadError={setModelLoadError}
        setLoading={setLoading}
        bodyFrameVectors={bodyFrameVectors}
      />
      <h3 className="sub">Upload a CAD model</h3>
      <Dropzone
        maxSize={maxFileSize}
        multiple={false}
        onDropRejected={handleFileReject}
        accept={{ 'model/gltf-binary': ['.glb'] }} // TODO: How can get .gtlf files to upload properly?
        onDropAccepted={handleFileAccept}
        disabled={inReadOnlyBranch}
      >
        {({ getRootProps, getInputProps }) => {
          return (
            <div
              {...getRootProps({ className: classes.cadFileUploader })}
              style={{ cursor: inReadOnlyBranch ? 'no-drop' : 'auto' }}
            >
              <input type="file" {...getInputProps()} />
              <h4 className="sub">
                + Drag and drop file here or{' '}
                <StyledButton type="button" framed onClick={(e) => e.preventDefault()}>
                  Browse
                </StyledButton>
              </h4>
            </div>
          );
        }}
      </Dropzone>
      <h3 className={`sub ${classes.cadFileUploaderSubText}`}>or select a preloaded model</h3>
      <LabeledSelect
        label="Default Models"
        options={options}
        onChange={(value) => changeDefaultModel(value)}
        value={defaultModel}
      />
    </Panel>
  );
};

export default CADModelPanel;
