/**
 *
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 * DO NOT USE
 *
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 * NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE NOTICE
 *
 * This file uses RECHARTS (ewwww) and is therefore OLD AND OBSOLETE
 *
 * DO NOT USE THIS COMPONENT UNTIL IT IS UPDATED TO USE ECHARTS
 *
 * If you are updating it yourself, please remove this notice when finished.
 *
 *
 */

/* eslint-disable */

import { useCallback, useState } from 'react';
import theme from 'theme';
import { ResponsiveContainer, PieChart, Pie, Sector, Cell, Text } from 'recharts';
import useStyles from './styles';

const TwoLevelActivePieChart = (props) => {
  const { data, units, title } = props;
  const classes = useStyles();

  const sliceColors = Object.values(theme.palette.charts.primary);

  const RenderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;

    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';
    const valueLabelPrecision = 3;

    return (
      <g>
        <Text width={75} x={cx} y={cy} textAnchor="middle" verticalAnchor="middle" fill={fill}>
          {payload.name}
        </Text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          textAnchor={textAnchor}
          fill={theme.palette.background.contrastText}
        >
          {`${value.toPrecision(valueLabelPrecision)} ${units}`}
        </text>
        <text
          x={ex + (cos >= 0 ? 1 : -1) * 12}
          y={ey}
          dy={18}
          textAnchor={textAnchor}
          fill={theme.palette.background.contrastText}
        >
          {/* hide decimal places if 100% */}
          {`(${percent < 1 ? (percent * 100).toFixed(2) : percent * 100}%)`}
        </text>
      </g>
    );
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback((conOpsPieData, index) => setActiveIndex(index), [setActiveIndex]);

  return (
    <>
      {title && <h2 className={classes.chartTitle}>{title}</h2>}
      <h2 className={classes.chartTitle}>STOP USING RECHARTS</h2>
      <ResponsiveContainer aspect={1.78} maxHeight={400}>
        <PieChart
          // margins are added between the responsive container and the pie chart to account for labels not being cut off
          margin={{ right: 150, left: 150, top: 25, bottom: 25 }}
        >
          <Pie
            activeIndex={activeIndex}
            activeShape={<RenderActiveShape />}
            data={data}
            dataKey="value"
            innerRadius={'55%'}
            stroke={theme.palette.background.main}
            onMouseEnter={onPieEnter}
            // Center y is shifted up slightly to account for padding in the header so graph appears more centered
            cy={'47%'}
          >
            {data.map((_, index) => {
              let offset = 0;
              if (index === data.length - 1 && index % sliceColors.length === 0) {
                offset = 3;
              }
              const color = sliceColors[(index + offset) % sliceColors.length];
              return <Cell key={color + index} fill={color} />;
            })}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </>
  );
};

export default TwoLevelActivePieChart;
