import { makeGuidance } from 'hooks';

// TODO
const fieldOfViewPermanentGuidance = 'Build up a Field of View (FoV) to be linked to sensors created on the GNC edit board. Select the static Body Frame Vector to align with the FoV boresight and choose a FoV type: Circular or Rectangular';
const rectChunk = 'Fill out the width and height half angles to describe a rectangular FoV. Choose a Vector for the direction of the height angle from boresight. Width angle is the resultant vector that is orthogonal to the vertical vector in the same plane.';
const circChunk ='Fill out the conical half angle to describe the circular FoV. ';
export const useGuidance = makeGuidance({
  _default: {
    heading: 'Fields of View',
    body: fieldOfViewPermanentGuidance,
  },
  halfAngle: {
    heading: 'Circular FoV',
    body: [
      {
        chunk: circChunk,
      },
    ],
// TODO, add DRAWING
//todo
  },
  heightBodyFrameVector: {
    heading: 'Rectangular FoV',
    body: [
      {
        chunk: rectChunk,
      },
    ],

  },
  // TODO, add DRAWING
  //todo
  heightHalfAngle: {
    heading: 'Rectangular FoV',
    body: [
      {
        chunk: rectChunk,
      },
    ],

  },
  widthHalfAngle:{
    heading: 'Rectangular FoV',
    body: [
      {
        chunk: rectChunk,
      },
    ],

  },
});
