import { makeGuidance } from 'hooks';


const spacecraftHeading = 'Edit the Spacecraft';

const spacecraftMainParagraph = 'Input spacecraft mass in kg. Input the inertia matrix calculated based on the size and mass of your spacecraft.'; //TODO: specify dry/wet when propulsion added

export const spacecraftGuidance = {
  _default: {
    heading: spacecraftHeading,
    body: [
      {
        chunk: spacecraftMainParagraph,
      },
    ],
  },

};

const useGuidance = makeGuidance(spacecraftGuidance);
export default useGuidance;
