const guidance = {
  heading: 'Components',
  body: [
    {
      chunk:
        "The power consumption of your satellite's components are assigned here. In Sedaro Satellite, a component can be a discrete power sink and heat dissipator (among other things) - such as a flight computer, radio, actuator, heater, etc.",
    },
    {
      subHeading: 'Manage Components',
      chunk:
        'Create custom components, organized by subsystem, or import them from our COTS library with their predefined loads and load states.',
    },
  ],
};

export default guidance;
