import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { useActiveEntities, useEntityForm } from 'hooks';
import LabeledInput from 'components/general/inputs/LabeledInput';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import validation from './validation';
import { useGuidance } from './guidance';
import { IActuator, IAlgorithm } from 'components/general/types/gnc';
// import { InputAdornment } from '@material-ui/core';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import { AlgorithmVables } from 'utils/vable';
import { ISelectOption, TBlockId } from 'components/general/types';
import GncAccent from 'components/general/Accent/variants/GncAccent';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { ISensor } from 'components/general/types/gnc';
import { translateIn, translateOut } from 'utils/forms';

interface IProps {
  control: TEntityDialogControl<IAlgorithm>;
}

interface IParsedRelation {
  id: TBlockId;
  name: string;
  tableData: {
    checked: boolean;
  };
}

interface IForm {
  name: string;
  rate: number | '';
  actuators?: IActuator[];
  algorithmType: ISelectOption | '';
  algorithmSubtype: ISelectOption | '';
  angularVelocitySensors?: ISensor[];
  gainK: number | '';
  gainG: number | '';
  gainC: number | '';
  epsilon: number | '';
  gainP: number | '';
  gainI: number | '';
  gainD: number | '';
  opticalAttitudeSensors?: ISensor[];
  positionSensor: ISelectOption | '';
  positionSensors?: ISensor[];
  vectorSensors?: ISensor[];
}

const defaultValues: IForm = {
  name: '',
  rate: '',
  algorithmType: '',
  algorithmSubtype: '',
  gainK: '',
  gainG: '',
  gainC: '',
  gainP: '',
  gainI: '',
  gainD: '',
  epsilon: '',
  positionSensor: '',
};

const tableColumns = [
  {
    title: 'Name',
    field: 'name',
  },
];

const AlgorithmDialog = (props: IProps) => {
  // Handle props
  const { control } = props;
  const {
    dialogConfig: { entity: algorithm, action },
  } = control;
  const {
    actuators,
    angularVelocitySensors,
    directionSensors,
    vectorSensors,
    opticalAttitudeSensors,
    positionSensors,
  } = useActiveEntities();

  // Set up styles
  const classes = useStyles();

  /*********************************************************************************************/
  /***************************************** RELATIONS *****************************************/
  /*********************************************************************************************/

  // Prep actuators
  const actuatorTableRef = useRef(null);
  const [parsedActuators, setParsedActuators] = useState<IParsedRelation[]>([]);

  const initActuators = useCallback(() => {
    let _parsedActuators: IParsedRelation[] = [];
    if (actuators) {
      _parsedActuators = actuators.map((actuator: IActuator) => ({
        id: actuator.id,
        name: actuator.name,
        tableData: {
          checked: !!algorithm?.actuators?.map(({ id }) => id).includes(actuator.id),
        },
      }));
    }
    setParsedActuators(_parsedActuators);
  }, [algorithm, actuators]);

  useEffect(() => {
    initActuators();
  }, [initActuators]);

  // Prep angular velocity sensors
  const angularVelocitySensorTableRef = useRef(null);
  const [parsedAngularVelocitySensors, setParsedAngularVelocitySensors] = useState<
    IParsedRelation[]
  >([]);

  const initAngularVelocitySensors = useCallback(() => {
    let _parsedAngularVelocitySensors: IParsedRelation[] = [];
    if (angularVelocitySensors) {
      _parsedAngularVelocitySensors = angularVelocitySensors.map(
        (angularVelocitySensor: ISensor) => ({
          id: angularVelocitySensor.id,
          name: angularVelocitySensor.name,
          tableData: {
            checked: !!algorithm?.angularVelocitySensors
              ?.map(({ id }) => id)
              .includes(angularVelocitySensor.id),
          },
        })
      );
    }
    setParsedAngularVelocitySensors(_parsedAngularVelocitySensors);
  }, [algorithm, angularVelocitySensors, setParsedAngularVelocitySensors]);

  useEffect(() => {
    initAngularVelocitySensors();
  }, [initAngularVelocitySensors]);

  // Prep optical attitude sensors
  const opticalAttitudeSensorTableRef = useRef(null);
  const [parsedOpticalAttitudeSensors, setParsedOpticalAttitudeSensors] = useState<
    IParsedRelation[]
  >([]);

  const initOpticalAttitudeSensors = useCallback(() => {
    let _parsedOpticalAttitudeSensors: IParsedRelation[] = [];
    if (opticalAttitudeSensors) {
      _parsedOpticalAttitudeSensors = opticalAttitudeSensors.map(
        (opticalAttitudeSensor: ISensor) => ({
          id: opticalAttitudeSensor.id,
          name: opticalAttitudeSensor.name,
          tableData: {
            checked: !!algorithm?.opticalAttitudeSensors
              ?.map(({ id }) => id)
              .includes(opticalAttitudeSensor.id),
          },
        })
      );
    }
    setParsedOpticalAttitudeSensors(_parsedOpticalAttitudeSensors);
  }, [algorithm, opticalAttitudeSensors, setParsedOpticalAttitudeSensors]);

  useEffect(() => {
    initOpticalAttitudeSensors();
  }, [initOpticalAttitudeSensors]);

  // Prep position sensors
  const positionSensorTableRef = useRef(null);
  const [parsedPositionSensors, setParsedPositionSensors] = useState<IParsedRelation[]>([]);

  const initPositionSensors = useCallback(() => {
    let _parsedPositionSensors: IParsedRelation[] = [];
    if (positionSensors) {
      _parsedPositionSensors = positionSensors.map((positionSensor: ISensor) => ({
        id: positionSensor.id,
        name: positionSensor.name,
        tableData: {
          checked: !!algorithm?.positionSensors?.map(({ id }) => id).includes(positionSensor.id),
        },
      }));
    }
    setParsedPositionSensors(_parsedPositionSensors);
  }, [algorithm, positionSensors, setParsedPositionSensors]);

  useEffect(() => {
    initPositionSensors();
  }, [initPositionSensors]);

  // Prep direction/vector sensors
  const vectorSensorTableRef = useRef(null);
  const [parsedVectorSensors, setParsedVectorSensors] = useState<IParsedRelation[]>([]);

  const initVectorSensors = useCallback(() => {
    let _parsedVectorSensors: IParsedRelation[] = [];
    if (directionSensors && vectorSensors) {
      const directionAndVectorSensors = vectorSensors.concat(directionSensors);
      _parsedVectorSensors = directionAndVectorSensors.map((vectorSensor: ISensor) => ({
        id: vectorSensor.id,
        name: vectorSensor.name,
        tableData: {
          checked: !!algorithm?.vectorSensors?.map(({ id }) => id).includes(vectorSensor.id),
        },
      }));
    }
    setParsedVectorSensors(_parsedVectorSensors);
  }, [algorithm, directionSensors, vectorSensors, setParsedVectorSensors]);

  useEffect(() => {
    initVectorSensors();
  }, [initVectorSensors]);

  // Set up select options
  const [algorithmType, setAlgorithmType] = useState<ISelectOption | ''>('');
  const options = useMemo(
    () => ({
      // TODO: Allow thrust control algorithms to be created
      algorithmType: AlgorithmVables.AlgorithmType.options.filter(
        (option) => option.value !== AlgorithmVables.AlgorithmType.THRUST_CONTROL.value
      ),
      algorithmSubtype: (() => {
        // set subtype based on main type
        switch (algorithmType) {
          case AlgorithmVables.AlgorithmType.ATTITUDE_CONTROL:
            return AlgorithmVables.AttitudeControlType.options;
          case AlgorithmVables.AlgorithmType.ATTITUDE_DETERMINATION:
            return AlgorithmVables.AttitudeDeterminationType.options;
          default:
            // case AlgorithmVables.AlgorithmType.ORBIT_DETERMINATION:
            return AlgorithmVables.OrbitDeterminationType.options;
        }
      })(),
      positionSensor: positionSensors.map((c) => {
        return { value: c.id, label: c.name };
      }),
    }),
    [positionSensors, algorithmType]
  );

  /*********************************************************************************************/
  /*********************************************************************************************/

  // REF: positionSensor
  // GPS Orbit Determination algorithms have one positionSensor
  // Translate that into its own field here so it can be interacted with as a LabeledSelect
  const customTranslateIn = useCallback((algorithm, defaultValues, options) => {
    if (
      algorithm.algorithmSubtype === AlgorithmVables.OrbitDeterminationType.GPS.value &&
      algorithm.positionSensors.length > 0
    )
      algorithm.positionSensor = algorithm.positionSensors[0].id;
    return translateIn(algorithm, defaultValues, options);
  }, []);

  const customTranslateOut = useCallback(
    (algorithm, allowedEmptyFields, options) => {
      // REF: positionSensor
      // ... and translate (positionSensor = sensor) back out into (positionSensors = [sensor])
      if (
        algorithm.algorithmSubtype.value === AlgorithmVables.OrbitDeterminationType.GPS.value &&
        algorithm.positionSensor
      )
        algorithm.positionSensors = [algorithm.positionSensor.value];
      else if (algorithm.positionSensors)
        algorithm.positionSensors = parsedPositionSensors
          .filter((positionSensor) => positionSensor.tableData?.checked)
          .map((positionSensor) => positionSensor.id);

      if (algorithm.actuators)
        algorithm.actuators = parsedActuators
          .filter((actuator) => actuator.tableData?.checked)
          .map((actuator) => actuator.id);
      if (algorithm.angularVelocitySensors)
        algorithm.angularVelocitySensors = parsedAngularVelocitySensors
          .filter((angularVelocitySensor) => angularVelocitySensor.tableData?.checked)
          .map((angularVelocitySensor) => angularVelocitySensor.id);
      if (algorithm.opticalAttitudeSensors)
        algorithm.opticalAttitudeSensors = parsedOpticalAttitudeSensors
          .filter((opticalAttitudeSensor) => opticalAttitudeSensor.tableData?.checked)
          .map((opticalAttitudeSensor) => opticalAttitudeSensor.id);
      if (algorithm.vectorSensors)
        algorithm.vectorSensors = parsedVectorSensors
          .filter((vectorSensor) => vectorSensor.tableData?.checked)
          .map((vectorSensor) => vectorSensor.id);
      return translateOut(algorithm, allowedEmptyFields, options);
    },
    [
      parsedActuators,
      parsedPositionSensors,
      parsedAngularVelocitySensors,
      parsedOpticalAttitudeSensors,
      parsedVectorSensors,
    ]
  );

  const entityForm = useEntityForm<IAlgorithm, IForm>({
    entityTypeText: 'Algorithm',
    entityDialogControl: control,
    defaultValues,
    validationSchema: validation,
    extendReset: () => {
      initActuators();
      initAngularVelocitySensors();
      initOpticalAttitudeSensors();
      initPositionSensors();
      initVectorSensors();
    },
    formikOptionalParams: {
      useGuidance,
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
    },
  });

  const { formik } = entityForm;
  const { getFieldProps, values, setFieldValue } = formik;

  useEffect(() => {
    setAlgorithmType(values.algorithmType);
  }, [values.algorithmType]);

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            label="Algorithm Name"
            type="text"
            placeholder="Name"
            autoFocus
          />
          {/* <LabeledInput  // TODO: Re-enable this when update rate works
            {...getFieldProps('rate')}
            label="Update Rate"
            type="number"
            endAdornment={<InputAdornment position="end">Hz</InputAdornment>}
          /> */}
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('algorithmType')}
            label="Algorithm Type"
            options={options.algorithmType}
            isDisabled={action !== 'create'}
            formikOnChange={(selection: ISelectOption) => {
              setFieldValue('algorithmType', selection);
              setFieldValue('algorithmSubtype', '');
            }}
          />
          {values.algorithmType && (
            <div className={classes.indent}>
              <LabeledSelect
                {...getFieldProps('algorithmSubtype')}
                label="Algorithm Subtype"
                options={options.algorithmSubtype}
                isDisabled={action !== 'create'}
              />
              {values.algorithmSubtype === AlgorithmVables.AttitudeControlType.SLIDING_MODE && (
                <div className={classes.indent}>
                  <LabeledInput {...getFieldProps('gainK')} label="Gain K" type="number" />
                  <LabeledInput {...getFieldProps('gainG')} label="Gain G" type="number" />
                  <LabeledInput {...getFieldProps('gainC')} label="Gain C" type="number" />
                  <LabeledInput {...getFieldProps('epsilon')} label="Epsilon" type="number" />
                </div>
              )}
              {values.algorithmSubtype === AlgorithmVables.AttitudeControlType.PID && (
                <div className={classes.indent}>
                  <LabeledInput {...getFieldProps('gainP')} label="Gain P" type="number" />
                  <LabeledInput {...getFieldProps('gainI')} label="Gain I" type="number" />
                  <LabeledInput {...getFieldProps('gainD')} label="Gain D" type="number" />
                  <LabeledInput {...getFieldProps('gainC')} label="Gain C" type="number" />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {values.algorithmType === AlgorithmVables.AlgorithmType.ATTITUDE_CONTROL &&
        values.algorithmSubtype && (
          <GncAccent header={'Actuators'}>
            <WidgetTable
              tableRef={actuatorTableRef}
              className={classes.table}
              columns={tableColumns}
              data={parsedActuators}
              setData={setParsedActuators}
              emptyMessage={'No actuators found'}
              title="Select Actuators"
              search={true}
              selection={true}
              // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
              // It is used through a level of indirection here to set the value on the form data and therefore mark
              // the form as dirty. This will enable the save button for the form.
              onSelectionChange={(newTableData) => setFieldValue('actuators', newTableData)}
            />
          </GncAccent>
        )}
      {values.algorithmType === AlgorithmVables.AlgorithmType.ATTITUDE_DETERMINATION &&
        values.algorithmSubtype && (
          <>
            <GncAccent header={'Angular Velocity Sensors'}>
              <WidgetTable
                tableRef={angularVelocitySensorTableRef}
                className={classes.table}
                columns={tableColumns}
                data={parsedAngularVelocitySensors}
                setData={setParsedAngularVelocitySensors}
                emptyMessage={'No angular velocity sensors found'}
                title="Select Angular Velocity Sensors"
                search={true}
                selection={true}
                // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
                // It is used through a level of indirection here to set the value on the form data and therefore mark
                // the form as dirty. This will enable the save button for the form.
                onSelectionChange={(newTableData) =>
                  setFieldValue('angularVelocitySensors', newTableData)
                }
              />
            </GncAccent>
            {(values.algorithmSubtype === AlgorithmVables.AttitudeDeterminationType.AVERAGING ||
              values.algorithmSubtype === AlgorithmVables.AttitudeDeterminationType.MEKF) && (
              <GncAccent header={'Optical Attitude Sensors'}>
                <WidgetTable
                  tableRef={opticalAttitudeSensorTableRef}
                  className={classes.table}
                  columns={tableColumns}
                  data={parsedOpticalAttitudeSensors}
                  setData={setParsedOpticalAttitudeSensors}
                  emptyMessage={'No optical attitude sensors found'}
                  title="Select Optical Attitude Sensors"
                  search={true}
                  selection={true}
                  // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
                  // It is used through a level of indirection here to set the value on the form data and therefore mark
                  // the form as dirty. This will enable the save button for the form.
                  onSelectionChange={(newTableData) =>
                    setFieldValue('opticalAttitudeSensors', newTableData)
                  }
                />
              </GncAccent>
            )}
            {values.algorithmSubtype === AlgorithmVables.AttitudeDeterminationType.TRIAD && (
              <GncAccent header={'Vector and Direction Sensors'}>
                <WidgetTable
                  tableRef={vectorSensorTableRef}
                  className={classes.table}
                  columns={tableColumns}
                  data={parsedVectorSensors}
                  setData={setParsedVectorSensors}
                  emptyMessage={'No vector sensors found'}
                  title="Select Vector Sensors"
                  search={true}
                  selection={true}
                  // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
                  // It is used through a level of indirection here to set the value on the form data and therefore mark
                  // the form as dirty. This will enable the save button for the form.
                  onSelectionChange={(newTableData) => setFieldValue('vectorSensors', newTableData)}
                />
              </GncAccent>
            )}
          </>
        )}
      {values.algorithmType === AlgorithmVables.AlgorithmType.ORBIT_DETERMINATION && (
        <>
          {values.algorithmSubtype === AlgorithmVables.OrbitDeterminationType.EKF && (
            <GncAccent header={'Position Sensors'}>
              <WidgetTable
                tableRef={positionSensorTableRef}
                className={classes.table}
                columns={tableColumns}
                data={parsedPositionSensors}
                setData={setParsedPositionSensors}
                emptyMessage={'No position sensors found'}
                title="Select Position Sensors"
                search={true}
                selection={true}
                // onSelectionChange is a material table prop that runs the passed function whenever any selection is made.
                // It is used through a level of indirection here to set the value on the form data and therefore mark
                // the form as dirty. This will enable the save button for the form.
                onSelectionChange={(newTableData) => setFieldValue('positionSensors', newTableData)}
              />
            </GncAccent>
          )}
          {values.algorithmSubtype === AlgorithmVables.OrbitDeterminationType.GPS && (
            <div className={classes.inputs}>
              <GncAccent header={'Position Sensor'}>
                <LabeledSelect
                  {...getFieldProps('positionSensor')}
                  options={options.positionSensor}
                />
              </GncAccent>
            </div>
          )}
        </>
      )}
    </EntityDialog>
  );
};

export default AlgorithmDialog;
