import theme from 'theme';
import moment from 'moment';
import { mjd2Moment, dateFormatLong } from 'utils/time';

const GregorianTickFormatter = (props) => {
  const { x, y, payload, dayOnly, julian } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text fill={theme.palette.background.contrastText} transform="rotate(0)">
        <tspan x={0} y={0} dy={18} textAnchor="middle">
          {julian
            ? mjd2Moment(payload.value).format('MM-DD-YYYY')
            : moment(payload.value, dateFormatLong).format('MM-DD-YYYY')}
        </tspan>
        {!dayOnly && (
          <tspan x={0} y={0} dy={34} textAnchor="middle">
            {julian
              ? mjd2Moment(payload.value).format('HH:mm:ss')
              : moment(payload.value, dateFormatLong).format('HH:mm:ss')}
          </tspan>
        )}
      </text>
    </g>
  );
};

export default GregorianTickFormatter;
