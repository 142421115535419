import { SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';

export const GNC = 'gnc';
const SPACECRAFT = 'spacecraft';
const SENSORS = 'sensors';
const ACTUATORS = 'actuators';
const ALGORITHMS = 'algorithms';
const POINT_MODES = 'pointing-modes';

const aTGNCMenu: IMenu['items'] = {
  [GNC]: {
    [TITLE]: 'GNC',
    [SUB_ITEMS]: {
      [SPACECRAFT]: {
        [TITLE]: 'Spacecraft',
      },
      [SENSORS]: {
        [TITLE]: 'Sensors',
      },
      [ACTUATORS]: {
        [TITLE]: 'Actuators',
      },
      [ALGORITHMS]: {
        [TITLE]: 'Algorithms',
      },
      [POINT_MODES]: {
        [TITLE]: 'Pointing Modes',
      },
    },
  },
};

export const wGroupIndicesATGNC = {
  GNC: `${GNC}*`,
  SPACECRAFT: `${GNC}.${SPACECRAFT}`,
  SENSORS: `${GNC}.${SENSORS}`,
  ACTUATORS: `${GNC}.${ACTUATORS}`,
  ALGORITHMS: `${GNC}.${ALGORITHMS}`,
  POINT_MODES: `${GNC}.${POINT_MODES}`,
};

export default aTGNCMenu;
