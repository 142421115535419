import Constraint from './Constraint';
import { IConstraint } from '../../general/types';
import { makeStyles } from '@material-ui/core/styles';
import { nodeFieldSetLegendStyle, nodeFieldSetStyle } from '../../general/sharedStyles';

const useStyles = makeStyles({
  root: nodeFieldSetStyle,
  legend: nodeFieldSetLegendStyle,
});

interface IProps {
  legend: string;
  constraints: IConstraint[];
}

const Constraints = ({ legend, constraints }: IProps) => {
  const styles = useStyles();

  if (!constraints.length) return null;

  return (
    <fieldset className={styles.root}>
      <legend className={styles.legend}>{legend}</legend>
      {constraints.map((c, i) => (
        <Constraint key={i} name={c.name} compliant={c.compliant} />
      ))}
    </fieldset>
  );
};

export default Constraints;
