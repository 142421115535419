import { makeStyles } from '@material-ui/core/styles';

const iconDims = 30;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: 10,
    position: 'relative',
    borderRadius: 3,
    boxShadow: theme.shadows[3],
    margin: '5px 0px',
  },
  heading: {
    color: theme.palette.primary.light,
    marginBottom: 5,
  },
  body: {
    '& p': {
      marginTop: 0,
    },
  },
  icon: {
    color: theme.palette.background.main,
    height: iconDims,
    width: iconDims,
    backgroundColor: theme.palette.info.main,
    lineHeight: `${iconDims}px`,
    textAlign: 'center',
    borderRadius: '50%',
    position: 'absolute',
    right: 10,
    top: -(iconDims / 2),
  },
  subHeading: {
    color: theme.palette.primary.light,
    marginBottom: 0,
  },
  chunk: {
    display: 'flex',
    alignItems: 'center',
  },
  fullChunk: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  graphic: {
    padding: '0px 20px',
    textAlign: 'right',

    '& > img': {
      maxHeight: 75,
      height: '100%',
      width: 'auto',
    },
  },
  fullGraphic: {
    padding: '20px 20px',

    '& > img': {
      maxWidth: 350,
      width: '100%',
      height: 'auto',
    },
  },
}));

export default useStyles;
