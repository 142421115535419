import { DP_SCHEMA, SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';
import { CDH } from 'components/AgentTemplateEditView/menu/cdh';
import { routePathsCommon } from 'routes';
import { tsTargetDPSchema } from '../AnalyzeBoards/CdhAnalyzeBoards/timeSeries';

const { TS, WS, FSS, PLAYBACK } = routePathsCommon;

const OP_MODES = 'operational-modes';
const TARGETS = 'targets';

const agentCdhMenu: IMenu['items'] = {
  [CDH]: {
    [TITLE]: 'Command & Control',
    [SUB_ITEMS]: {
      [PLAYBACK]: {
        [TITLE]: 'Playback',
      },
      [TS]: {
        [TITLE]: 'Time Series',
        [SUB_ITEMS]: {
          [OP_MODES]: {
            [TITLE]: 'Operational Modes',
          },
          [TARGETS]: {
            [TITLE]: 'Targets',
            [DP_SCHEMA]: tsTargetDPSchema,
          },
        },
      },
      // [WS]: {
      //   [TITLE]: 'Window Statistics',
      // },
      // [FSS]: {
      //   [TITLE]: 'Full Simulation Statistics',
      // },
    },
  },
};

export const wGroupIndicesAgentCdh = {
  CDH: `${CDH}*`,
  PLAYBACK: `${CDH}.${PLAYBACK}`,
  TS: `${CDH}.${TS}`,
  WS: `${CDH}.${WS}`,
  FSS: `${CDH}.${FSS}`,
  OP_MODES: `${CDH}.${TS}.${OP_MODES}`,
};

export default agentCdhMenu;
