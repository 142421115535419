import { useLocation } from 'react-router-dom';
import { routePathsCommon } from 'routes';
import missionDesignerMenu from './missionDesignerMenu';
import AgentTemplateEditMenu from 'components/AgentTemplateEditView/menu';
import AgentAnalyzeMenu from 'components/AgentAnalyzeView/menu';
import ScenarioMenu from 'components/ScenarioView/menu';
import { IMenu } from 'components/general/types';

const { MISSION_DESIGNER, AGENT_TEMPLATE_EDIT, AGENT_ANALYZE, SCENARIO } = routePathsCommon;

const menus: { [key: string]: IMenu } = {
  [MISSION_DESIGNER]: missionDesignerMenu,
  // TODO ZACH: remove MISSION_DESIGNER and ENERGY_BALANCE
  [AGENT_TEMPLATE_EDIT]: AgentTemplateEditMenu,
  [AGENT_ANALYZE]: AgentAnalyzeMenu,
  [SCENARIO]: ScenarioMenu,
};

const useMenu: () => IMenu = () => {
  const location = useLocation();
  // `menuKey` = 'scenario', 'agent-template-edit' ...etc., otherwise ''
  const menuKey = location.pathname.split('/')[1] || '';

  const menu = menus[menuKey] || null;
  // menu will either be the respective menu if there is one, otherwise `null`
  return menu;
};

export default useMenu;
