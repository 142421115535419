import { schema } from 'normalizr';
import { ComponentVables } from 'utils/vable';
import { isProd } from 'config';

const MODULE_ROUTES = {
  POWER: '/power/',
  GNC: '/gnc/',
  CDH: '/cdh/',
  THERMAL: '/thermal/',
};

// General
const BASE_ROUTE_USER = '/user/';
const BASE_ROUTE_SUBSCRIPTIONS = '/subscriptions/';
const BASE_ROUTE_REPOS = 'repositories/';

// System
const BASE_ROUTE_SYSTEM = '/system/';
const BASE_ROUTE_SUBSYSTEM = 'subsystems/';
const BASE_ROUTE_LOADS = 'loads/';
const BASE_ROUTE_GEOMETRY = 'geometry/';

// GNC
const BASE_ROUTE_SENSORS = 'sensors/';
const BASE_ROUTE_ACTUATORS = 'actuators/';
const BASE_ROUTE_ALGORITHMS = 'algorithms/';
const BASE_ROUTE_REFERENCE_VECTORS = 'reference-vectors/';

// C&DH
const BASE_ROUTE_CONOPS = 'conops/';

// Power
const BASE_ROUTE_BATTERY = 'batteries/';
const BASE_ROUTE_SOLAR_ARRAYS = 'solar-arrays/';
const BASE_ROUTE_EPS = 'eps/';
const BASE_ROUTE_COMPONENT = 'components/';

// Thermal
const BASE_ROUTE_TEMP_CONTROLLERS = 'temp-controllers/';
const BASE_ROUTE_FOV = 'fields-of-view/';
const BASE_ROUTE_POINTING_MODES = 'pointing-modes/';

// Component to Route Map:
const { ComponentType } = ComponentVables;
const componentToRouteMap = {
  [ComponentType.GENERIC
    .value]: `${BASE_ROUTE_SYSTEM}${BASE_ROUTE_SUBSYSTEM}${BASE_ROUTE_COMPONENT}`,
  [ComponentType.BATTERY_PACK.value]: `${MODULE_ROUTES.POWER}${BASE_ROUTE_BATTERY}packs/`,
  [ComponentType.SOLAR_PANEL.value]: `${MODULE_ROUTES.POWER}${BASE_ROUTE_SOLAR_ARRAYS}panels/`,
  [ComponentType.POWER_PROCESSOR.value]: `${MODULE_ROUTES.POWER}${BASE_ROUTE_EPS}power-processor/`,
  [ComponentType.REACTION_WHEEL
    .value]: `${MODULE_ROUTES.GNC}${BASE_ROUTE_ACTUATORS}reaction-wheels/`,
  [ComponentType.MAGNETORQUER.value]: `${MODULE_ROUTES.GNC}${BASE_ROUTE_ACTUATORS}magnetorquers/`,
  [ComponentType.DIRECTION_SENSOR
    .value]: `${MODULE_ROUTES.GNC}${BASE_ROUTE_SENSORS}direction-sensors/`,
  [ComponentType.POSITION_SENSOR
    .value]: `${MODULE_ROUTES.GNC}${BASE_ROUTE_SENSORS}position-sensors/`,
  [ComponentType.OPTICAL_ATTITUDE_SENSOR
    .value]: `${MODULE_ROUTES.GNC}${BASE_ROUTE_SENSORS}optical-attitude-sensors/`,
  [ComponentType.ANGULAR_VELOCITY_SENSOR
    .value]: `${MODULE_ROUTES.GNC}${BASE_ROUTE_SENSORS}angular-velocity-sensors/`,
  [ComponentType.HEATER.value]: `${MODULE_ROUTES.THERMAL}${BASE_ROUTE_TEMP_CONTROLLERS}heaters/`,
  [ComponentType.COOLER.value]: `${MODULE_ROUTES.THERMAL}${BASE_ROUTE_TEMP_CONTROLLERS}coolers/`,
  [ComponentType.VECTOR_SENSOR.value]: `${MODULE_ROUTES.GNC}${BASE_ROUTE_SENSORS}vector-sensors/`,
};
/*
- ? denotes optional field
endpoints = {
  EndpointModel: {
    baseRoute: 'API_URL' | fn => routeSelectorFn,
    routeSuffixes: {
      endpointAction1: {
        method: 'HTML method (lowercase)',
        suffix?: 'id/additional/url/params',
        customSaga?: boolean - set to true if a custom saga needs to be generated,
        ignoreResponse?: boolean - whether the data created with this endpoint should be added to Redux
      },
      ...
    },
    relatedModels?: [
      {
        modelName: 'ModelName',
        field?: 'modelName' - field name on EndpointModel,
        relatedField?: 'relatedFieldName' - field name on related model,
        many?: boolean - whether or not many-to-many relationship }
      },
      ...
    ],
    sortBy?: 'param to sort by' (if no sortBy or reverseSortBy flag is added, defaults to dateModified ascending)
    reverseSortBy?: 'param to sort by'
    noModel?: boolean - whether or not to generate models, entityAdapters, and schemas,
    noEndpoint?: boolean - whether or not to generate actions and sagas
    superSlice?: 'ModelName' - model that this endpoint inherits from that should also be updated
  }
}
*/

export const defaultEndpoints = {
  create: { method: 'post' },
  get: { method: 'get' },
  gets: { method: 'get' },
  update: { method: 'patch' },
  delete: { method: 'delete' },
};

// TODO: What is this comment referring to? Have we deleted it or replaced it?
/*
-----------------------------------------------------------------
IMPORTANT:
When adding new models to endpoints object, make sure to add the
ModelName to the ISatelliteApi interface in api.d.ts as well
-----------------------------------------------------------------
*/

export const endpoints = {
  // ----------------------------------------------------------------------------------------------
  // General
  // ----------------------------------------------------------------------------------------------
  Agent: {
    baseRoute: '/agents/',
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  ApiKey: {
    baseRoute: `${BASE_ROUTE_USER}manage-key/`,
    routeSuffixes: {
      getKeys: { method: 'get' },
      createKey: { method: 'post' },
      revokeKey: { method: 'patch' },
    },
    noModel: true,
  },
  BodyFrameVector: {
    baseRoute: `${BASE_ROUTE_SYSTEM}geometry/body-frame-vectors/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  ClockConfig: {
    baseRoute: '/clock-configs/',
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  Job: {
    baseRoute: ({ branchId }) => `/simulations/branches/${branchId}/control/`,
    routeSuffixes: {
      abortJob: { method: 'delete' },
      create: { method: 'post' },
      get: { method: 'get' },
      gets: { method: 'get' },
    },
    relatedModels: [{ modelName: 'MissionVersion', field: 'branch' }],
    sim: true,
  },
  Mission: {
    baseRoute: `/models/${BASE_ROUTE_REPOS}`,
    routeSuffixes: {
      ...defaultEndpoints,
      cloneMission: { method: 'post' },
      removeCollaborator: { method: 'patch' },
      inviteCollaborator: { method: 'post', suffix: 'collaborators/' },
    },
    sortBy: 'dateModified',
    relatedModels: [
      { modelName: 'MissionVersion', field: 'branches', many: true, relatedField: 'repository' },
      {
        modelName: 'Collaborator',
        field: 'collaborators',
        many: true,
        relatedField: 'collaboratedRepositories',
      },
      // Add the owner of a mission to the Collaborators slice
      // This is done to access the current user as a collaborator, which is needed for when users view collaborators on repos they don't own.
      {
        modelName: 'Collaborator',
        field: 'user',
      },
    ],
  },
  Collaborator: {
    baseRoute: `/models/${BASE_ROUTE_REPOS}`,
    routeSuffixes: {
      verifyCollaborator: { method: 'patch', suffix: 'collaborators/' },
    },
    relatedModels: [
      {
        modelName: 'Mission',
        field: 'collaboratedRepositories',
        many: true,
        relatedField: 'collaborators',
      },
    ],
  },
  MissionVersion: {
    baseRoute: `/models/branches/`,
    routeSuffixes: {
      ...defaultEndpoints,
      invalidateSimulation: { method: 'patch', customSaga: true },
      updateAnalyzeState: { method: 'patch', customSaga: true },
      getAndMakeActive: { method: 'get', customSaga: true },
      branchOffOfBranch: { method: 'post' },
      commit: { method: 'post', suffix: '/commits/', ignoreResponse: true },
      getGitHistory: { method: 'get', suffix: '/commits/', ignoreResponse: true },
      getCommittedBranchForGitDiff: { method: 'get', suffix: '/committed/', ignoreResponse: true },
      getSavedBranchForGitDiff: { method: 'get', suffix: '/saved/', ignoreResponse: true },
      gitMerge: { method: 'post', suffix: '/merge/' },
      checkSharePw: { method: 'post', suffix: '/share-auth/', ignoreResponse: true },
      editRoot: { method: 'patch', suffix: '/root/', ignoreResponse: true },
    },
    sortBy: 'dateModified',
    relatedModels: [
      { modelName: 'Mission', field: 'repository', relatedField: 'branches' },
      { modelName: 'User', field: 'user' },
      // { modelName: 'ConOps', field: 'conOps', relatedField: 'missionVersion' },
      // { modelName: 'Satellite', field: 'satellite', relatedField: 'missionVersion' },
      // { modelName: 'MissionOrbit', field: 'missionOrbit', relatedField: 'missionVersion' },
    ],
  },
  Satellite: {
    baseRoute: `${BASE_ROUTE_SYSTEM}satellite/`,
    routeSuffixes: {
      get: { method: 'get' },
      deleteCadFile: { method: 'delete', suffix: '/cad/' },
      toggleDefaultModel: { method: 'patch', suffix: '/cad/' },
      uploadCadFile: { method: 'post', suffix: '/cad/' },
      updateSpacecraft: { method: 'patch' },
    },
    block: true,
  },
  Subscription: {
    baseRoute: BASE_ROUTE_SUBSCRIPTIONS,
    routeSuffixes: {
      create: { method: 'post', suffix: 'checkout/' },
      getCustomerCheckout: { method: 'post', suffix: 'checkout/' },
      getCustomerPortal: { method: 'get', suffix: 'customer-portal/' },
    },
    noModel: true,
  },
  Subsystem: {
    baseRoute: `${BASE_ROUTE_SYSTEM}${BASE_ROUTE_SUBSYSTEM}`,
    routeSuffixes: {
      ...defaultEndpoints,
      get: { method: 'get' },
    },
    reverseSortBy: 'dateCreated',
    block: true,
  },
  Surface: {
    baseRoute: `${BASE_ROUTE_SYSTEM}${BASE_ROUTE_GEOMETRY}surfaces/`,
    routeSuffixes: {
      ...defaultEndpoints,
      get: { method: 'get' },
    },
    block: true,
  },
  SurfaceMaterial: {
    baseRoute: `${BASE_ROUTE_SYSTEM}${BASE_ROUTE_GEOMETRY}surfaces/materials/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  User: {
    baseRoute: BASE_ROUTE_USER,
    routeSuffixes: {
      authorize: { method: 'patch', suffix: 'authorize/', ignoreResponse: true },
      login: { method: 'post', suffix: 'login/', ignoreResponse: true },
      logout: { method: 'delete', suffix: 'logout/', ignoreResponse: true },
      get: { method: 'get' },
      update: { method: 'patch' },
      register: { method: 'post', suffix: 'register/', ignoreResponse: true },
      requestPasswordReset: { method: 'post', suffix: 'reset-password/', ignoreResponse: true },
      resetPassword: { method: 'patch', suffix: 'reset-password/', ignoreResponse: true },
      resendVerificationEmail: { method: 'get', suffix: 'verify/', ignoreResponse: true },
      verify: { method: 'patch', suffix: 'verify/' },
    },
  },
  // ----------------------------------------------------------------------------------------------
  // Mission Module
  // ----------------------------------------------------------------------------------------------
  Condition: {
    baseRoute: (condition) => {
      const endpoint = `${MODULE_ROUTES.CDH}${BASE_ROUTE_CONOPS}`;
      if (condition.paramACategory === 'TARGET_GROUP') {
        return endpoint + 'group-conditions/';
      }
      return endpoint + 'conditions/';
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  ConOps: {
    baseRoute: `${MODULE_ROUTES.CDH}${BASE_ROUTE_CONOPS}`,
    routeSuffixes: {
      get: { method: 'get' },
    },
    block: true,
  },
  OperationalMode: {
    baseRoute: `${MODULE_ROUTES.CDH}${BASE_ROUTE_CONOPS}operational-modes/`,
    routeSuffixes: {
      ...defaultEndpoints,
      updatePriorities: { method: 'patch', customSaga: true },
    },
    sortBy: 'priority',
    block: true,
  },
  Orbit: {
    baseRoute: '/orbits/',
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  Target: {
    // differentiates between the different target endpoints
    baseRoute: (target) => {
      const targetEndpoint = `${MODULE_ROUTES.CDH}${BASE_ROUTE_CONOPS}`;
      const targetType = target.targetType;
      const formatTargetType = `${targetType.toLowerCase().replaceAll('_', '-')}s/`;
      return targetEndpoint + formatTargetType;
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  TargetGroup: {
    baseRoute: `${MODULE_ROUTES.CDH}${BASE_ROUTE_CONOPS}target-groups/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },

  // ----------------------------------------------------------------------------------------------
  // Power Module
  // ----------------------------------------------------------------------------------------------
  Battery: {
    baseRoute: `${MODULE_ROUTES.POWER}${BASE_ROUTE_BATTERY}`,
    routeSuffixes: {
      get: { method: 'get' },
      update: { method: 'patch' },
    },
    block: true,
  },
  BatteryCell: {
    baseRoute: `${MODULE_ROUTES.POWER}${BASE_ROUTE_BATTERY}cells/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  BusRegulator: {
    baseRoute: `${MODULE_ROUTES.POWER}${BASE_ROUTE_EPS}bus-regulators/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  Component: {
    baseRoute: ({ componentType }) => {
      if (componentType in componentToRouteMap) {
        return componentToRouteMap[componentType];
      }
      if (!isProd()) {
        console.error(`In endpoint.js:\n\nNo route configured for the given component type "${componentType}", so defaulting to the generic component route.\n\nHint: see "componentToRouteMap"
        `);
      }
      return componentToRouteMap[ComponentType.GENERIC.value];
    },
    routeSuffixes: {
      ...defaultEndpoints,
      get: { method: 'get' },
    },
    // schemaUnions: {
    //   // https://github.com/paularmstrong/normalizr/blob/master/docs/api.md#uniondefinition-schemaattribute
    //   cell: [
    //     {
    //       SOLAR_PANEL: 'SolarCell',
    //       BATTERY_PACK: 'BatteryCell',
    //     },
    //     (value, parent, key) => parent.componentType,
    //   ],
    // },
    block: true,
  },
  Load: {
    baseRoute: `${BASE_ROUTE_SYSTEM}${BASE_ROUTE_LOADS}`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  LoadState: {
    baseRoute: `${BASE_ROUTE_SYSTEM}${BASE_ROUTE_LOADS}states/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  SolarArray: {
    baseRoute: `${MODULE_ROUTES.POWER}${BASE_ROUTE_SOLAR_ARRAYS}`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  SolarCell: {
    baseRoute: `${MODULE_ROUTES.POWER}${BASE_ROUTE_SOLAR_ARRAYS}cells/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },

  // ----------------------------------------------------------------------------------------------
  // GNC Module
  // ----------------------------------------------------------------------------------------------
  Actuator: {
    baseRoute: (actuator) => {
      const endpoint = `${MODULE_ROUTES.GNC}${BASE_ROUTE_ACTUATORS}`;
      const actuatorType = actuator.componentType;
      const formatActuatorType = `${actuatorType.replaceAll('_', '-').toLowerCase()}s/`;
      return endpoint + formatActuatorType;
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  Algorithm: {
    baseRoute: (algorithm) => {
      const endpoint = `${MODULE_ROUTES.GNC}${BASE_ROUTE_ALGORITHMS}`;
      const algorithmType = algorithm.algorithmType;
      const algorithmSubtype = algorithm.algorithmSubtype;
      const formatAlgorithmType = `${algorithmType.replaceAll('_', '-').toLowerCase()}/`;
      const formatAlgorithmSubtype = `${algorithmSubtype.replaceAll('_', '-').toLowerCase()}/`;
      return endpoint + formatAlgorithmType + formatAlgorithmSubtype;
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  FieldOfView: {
    baseRoute: (fov) => {
      const endpoint = `${MODULE_ROUTES.GNC}${BASE_ROUTE_SENSORS}${BASE_ROUTE_FOV}`;
      const fovType = fov.fieldOfViewType;
      const formatFovType = `${fovType.slice(0, 4).toLowerCase()}-fields-of-view/`;
      return endpoint + formatFovType;
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  FovConstraint: {
    baseRoute: `${MODULE_ROUTES.GNC}${BASE_ROUTE_SENSORS}${BASE_ROUTE_FOV}constraints/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  PointingMode: {
    baseRoute: (pm) => {
      const endpoint = `${MODULE_ROUTES.GNC}${BASE_ROUTE_POINTING_MODES}`;
      const pmType = pm.pointingModeType;
      const formatPmType = `${pmType.replaceAll('_', '-').toLowerCase()}/`;
      return endpoint + formatPmType;
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  ReferenceVector: {
    baseRoute: (refVector) => {
      const endpoint = `${MODULE_ROUTES.GNC}${BASE_ROUTE_REFERENCE_VECTORS}`;
      const refVectorType = refVector.vectorType;
      const formatRefVectorType = `${refVectorType.replaceAll('_', '-').toLowerCase()}-vectors/`;
      return endpoint + formatRefVectorType;
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  Sensor: {
    baseRoute: (sensor) => {
      const endpoint = `${MODULE_ROUTES.GNC}${BASE_ROUTE_SENSORS}`;
      const sensorType = sensor.componentType;
      const formatSensorType = `${sensorType.replaceAll('_', '-').toLowerCase()}s/`;
      return endpoint + formatSensorType;
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },

  // ----------------------------------------------------------------------------------------------
  // Thermal Module
  // ----------------------------------------------------------------------------------------------
  TemperatureController: {
    baseRoute: (tempController) => {
      const endpoint = `${MODULE_ROUTES.THERMAL}${BASE_ROUTE_TEMP_CONTROLLERS}`;
      const controllerType = tempController.componentType;
      const formatControllerType = `${controllerType.toLowerCase()}s/`;
      return endpoint + formatControllerType;
    },
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  ControlState: {
    baseRoute: `${MODULE_ROUTES.THERMAL}${BASE_ROUTE_TEMP_CONTROLLERS}states/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  ThermalInterface: {
    baseRoute: `${MODULE_ROUTES.THERMAL}thermal-interfaces/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },
  ThermalInterfaceMaterial: {
    baseRoute: `${MODULE_ROUTES.THERMAL}thermal-interface-materials/`,
    routeSuffixes: {
      ...defaultEndpoints,
    },
    block: true,
  },

  // ----------------------------------------------------------------------------------------------
  // COTS Library
  // ----------------------------------------------------------------------------------------------
  Cots: {
    baseRoute: `${BASE_ROUTE_SYSTEM}${BASE_ROUTE_SUBSYSTEM}${BASE_ROUTE_COMPONENT}cots/`,
    routeSuffixes: {
      getCots: { method: 'get', ignoreResponse: true },
      create: { method: 'post' },
    },
    noModel: true,
    block: true,
  },

  // ----------------------------------------------------------------------------------------------
  // Data Service
  // ----------------------------------------------------------------------------------------------
  Data: {
    baseRoute: `/data/`,
    routeSuffixes: {
      get: { method: 'get' },
    },
    noModel: true,
  },
};

const baseModels = {};

// normalizr schemas are used in conjunction with RTK's createEntityAdapter to ensure data is normalized and predictable
const generateSchema = (name, options = {}) => {
  return new schema.Entity(name, options);
};

// populate baseModels object with a base schema for each endpoint
for (const endpoint in endpoints) {
  // if (!endpoints[endpoint].noModel && !endpoints[endpoint].block) {
  if (!endpoints[endpoint].noModel) {
    baseModels[endpoint] = generateSchema(endpoint);
  }
}

// recursive function that builds a nested schema with the specified depth
const nestedSchemaBuilder = (baseModel, depth) => {
  if (depth > 0 && endpoints[baseModel].relatedModels) {
    // generate model relationships
    const options = {};
    // loop through each endpoint's relatedModels to populate options object
    for (const _schema of endpoints[baseModel].relatedModels) {
      if (endpoints[baseModel].schemaUnions && _schema.field in endpoints[baseModel].schemaUnions) {
        options[_schema.field] = new schema.Union(
          Object.entries(endpoints[baseModel].schemaUnions[_schema.field][0]).reduce(
            (acc, [k, v]) => {
              acc[k] = nestedSchemaBuilder(v, depth - 1);
              return acc;
            },
            {}
          ),
          endpoints[baseModel].schemaUnions[_schema.field][1]
        );
      } else if (_schema.many) {
        options[_schema.field] = [nestedSchemaBuilder(_schema.modelName, depth - 1)];
      } else if (_schema.field) {
        options[_schema.field] = nestedSchemaBuilder(_schema.modelName, depth - 1);
      }
    }
    // use options object to generate new model with proper relationships
    return generateSchema(baseModel, options);
  } else {
    // if relatedModels not provided, just assign the base model to the endpoint
    return baseModels[baseModel];
  }
};

// Determines the depth of the built schema object, 5 is used for now to encompass everything
const schemaDepth = 1;

for (const baseModel in baseModels) {
  endpoints[baseModel].model = nestedSchemaBuilder(baseModel, schemaDepth);
}
