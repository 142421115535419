import { ISelectOption } from 'components/general/types';
import { FieldOfViewVables } from 'utils/vable';
import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('A name is required.'),
  boresightBodyFrameVector: Yup.object().required('Boresight body frame vector is required.'),
  fieldOfViewType: Yup.object().required('Field of view type is required.'),
  halfAngle: Yup.number().when('fieldOfViewType', {
    is: (fieldOfViewType: ISelectOption) =>
      fieldOfViewType?.value === FieldOfViewVables.FieldOfViewTypes.CIRC_FIELD_OF_VIEW.value,
    then: Yup.number().required('Conical half angle is required.'),
  }),
  heightHalfAngle: Yup.number().when('fieldOfViewType', {
    is: (fieldOfViewType: ISelectOption) =>
      fieldOfViewType?.value === FieldOfViewVables.FieldOfViewTypes.RECT_FIELD_OF_VIEW.value,
    then: Yup.number().required('Height half angle is required.'),
  }),
  widthHalfAngle: Yup.number().when('fieldOfViewType', {
    is: (fieldOfViewType: ISelectOption) =>
      fieldOfViewType?.value === FieldOfViewVables.FieldOfViewTypes.RECT_FIELD_OF_VIEW.value,
    then: Yup.number().required('Width half angle is required.'),
  }),
  heightBodyFrameVector: Yup.object().when('fieldOfViewType', {
    is: (fieldOfViewType: ISelectOption) =>
      fieldOfViewType?.value === FieldOfViewVables.FieldOfViewTypes.RECT_FIELD_OF_VIEW.value,
    then: Yup.object().required('Height body frame vector is required.'),
  }),
});

export default validation;
