import WGroup from 'components/general/WGroup';
// import Wizard from 'components/general/wizards/Wizard';
import ViewPortInlay from 'components/general/ViewPortInlay';
import { wGroupIndicesATComms } from 'components/AgentTemplateEditView/menu/comms';

const CommsEditBoard = () => {
  return (
    <WGroup index={wGroupIndicesATComms.COMMS}>
      {/* <Wizard> */}
      <ViewPortInlay text="Data Handling module coming soon..." />
      {/* </Wizard> */}
    </WGroup>
  );
};

export default CommsEditBoard;
