import { SUB_ITEMS, TITLE } from 'config';
import { IMenu } from 'components/general/types';

export const COMMS = 'comms';
const COMING_SOON = 'coming-soon';

const aTCommsMenu: IMenu['items'] = {
  [COMMS]: {
    [TITLE]: 'Data Handling',
    [SUB_ITEMS]: {
      [COMING_SOON]: {
        [TITLE]: 'Coming soon...',
      },
    },
  },
};
export const wGroupIndicesATComms = {
  COMMS: `${COMMS}*`,
};

export default aTCommsMenu;
