import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { logger } from 'redux-logger';
import { isProd } from './config';

import MissionExplorerReducer from 'components/RootView/missionExplorerSlice';
import CustomSagas from 'middleware/SatelliteApi/customSagas';
import { SatelliteApi } from 'middleware/SatelliteApi/api';

// workaround for error using material-table with RTK since material-table modifies its own props
import { setAutoFreeze } from 'immer';
setAutoFreeze(false);

const {
  sagaBinders,
  entities,
  User: {
    actions: { logout },
  },
} = SatelliteApi;

const uiReducer = combineReducers({
  missionExplorer: MissionExplorerReducer,
});

const appReducer = combineReducers({
  entities: entities.reducer,
  ui: uiReducer,
});

const rootReducer = (state, action) => {
  if (action.type === logout.type + '_SUCCESS' || action.type === 'INVALID_TOKEN') {
    state = undefined;
  }

  return appReducer(state, action);
};

function* RootSaga() {
  yield all([...Object.values(sagaBinders).map((sagaBinder) => sagaBinder()), CustomSagas()]);
}

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
if (!isProd()) {
  middleware.push(logger);
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
    ...middleware,
  ],
  devTools: !isProd(),
});

sagaMiddleware.run(RootSaga);

export default store;
