import { makeGuidance } from 'hooks';
import { IGenericObject } from 'components/general/types';
const algorithmHeading =
  'Create and Edit Algorithms for: Attitude Control, Attitude Determination, and Orbit Determination.';

const algorithmMainParagraph =
  'Algorithms represent the components of on-board decision making for Guidance, Navigation, and Control. Each Algorithm will only run in certain, user-defined operational modes dictated by the active Pointing Mode. Use the Algorithms table to add or edit Algorithms.';

const pidChunk =
  'A basic linear control method. This method relies on Reaction Wheels as the primary actuator with Magnetorquers as an optional secondary for continuous desaturation of the wheels.';
const slideChunk =
  'A nonlinear attitude control method. This method relies on Reaction Wheels as the primary actuator with Magnetorquers as an optional secondary for continuous desaturation of the wheels.';
const averageChunk =
  'A simple sensor averaging algorithm that requires at least one attitude and one angular rate sensor. This algorithm only considers the current sensor estimates and does not account for satellite dynamics.';
const MEKFChunk =
  'A Multiplicative Extended Kalman Filter that fuses input from any number of sensors into a single attitude estimate. The filter factors in the expected attitude dynamics of the satellite to predict future states and corrects those predictions based on sensor measurements. At least one attitude sensor is required.';
const triadChunk =
  'The Triad algorithm produces attitude estimates based on the input from two or more direction sensors. Associated angular rate estimates are derived by averaging measurements from angular rate sensors.';
const EKFChunk =
  'An Extended Kalman Filter that fuses input from any number of sensors into a single position and velocity estimate. The filter factors in the expected orbital dynamics of the satellite to preduct future states and corrects those predictions based on sensor measurements. At least one sensor is required.';
const GPSChunk =
  'A simple estimation method based on the output of a single GPS unit. Position estimates are derived directly from the sensor and velocity measurements are estimated from two sequential measurements.';

const algoTypeGuidance = {
  ATTITUDE_CONTROL: {
    heading: 'Attitude Control Algorithms',
    body: [
      {
        subHeading: 'PID Control',
        chunk: pidChunk,
      },
      {
        subHeading: 'Sliding Mode Control',
        chunk: slideChunk,
      },
    ],
  },
  ATTITUDE_DETERMINATION: {
    heading: 'Attitude Determination Algorithms',
    body: [
      {
        subHeading: 'Averaging',
        chunk: averageChunk,
      },
      {
        subHeading: 'MEKF',
        chunk: MEKFChunk,
      },
      {
        subHeading: 'Triad',
        chunk: triadChunk,
      },
    ],
  },
  ORBIT_DETERMINATION: {
    heading: 'Orbit Determination Algorithms',
    body: [
      {
        subHeading: 'EKF',
        chunk: EKFChunk,
      },
      {
        subHeading: 'GPS Direct',
        chunk: GPSChunk,
      },
    ],
  },
};
const algoSubTypeGuidance = {
  SLIDING_MODE: {
    heading: 'Sliding Mode Control',
    body: [
      {
        chunk: slideChunk,
      },
      {
        subHeading: 'Gains',
        chunk:
          'This controller requires tuning specific to the spacecraft properties. Four parameters must be configured here:',
      },
      {
        chunk:
          'K - alters the relative weighting between angular rate and angle error, higher values give more weight to angle error.',
      },
      {
        chunk:
          'G - scales the overall speed of convergence. Higher values will converge faster but may saturate the actuators.',
      },
      {
        chunk: 'C - dictates the strength of the secondary actuator desaturation torques.',
      },
      {
        chunk:
          'Epsilon - defines a neutral zone for the controller near the desired attitude. Larger values produce more error, but reduce chattering and power use.',
      },
      {
        subHeading: 'Actuators',
        chunk:
          'Select any number of actuators from the list for use by the controller using the actuator table. Unselected actuators will remain idle. It`s important to select a set of actuators that provide full control authority for the satellite or else the controller may fail to achieve the desired pointing.',
      },
    ],
  },
  PID: {
    heading: 'Proportional-Integral-Derivative Control',
    body: [
      {
        chunk: slideChunk,
      },
      {
        subHeading: 'Gains',
        chunk:
          'This controller requires tuning specific to the spacecraft properties. Four parameters must be configured here:',
      },
      {
        chunk: 'P - weighting applied to the multiplicative quaternion error.',
      },
      {
        chunk: 'I - weighting applied to the integral of the multiplicative quaternion error.',
      },
      {
        chunk: 'D - weighting applied to the angular rate error.',
      },
      {
        chunk: 'C - dictates the strength of the secondary actuator desaturation torques.',
      },
      {
        subHeading: 'Actuators',
        chunk:
          'Select any number of actuators from the list for use by the controller using the actuator table. Unselected actuators will remain idle. It`s important to select a set of actuators that provide full control authority for the satellite or else the controller may fail to achieve the desired pointing.',
      },
    ],
  },
  AVERAGING: {
    heading: 'Averaging Algorithm',
    body: [
      {
        chunk: averageChunk,
      },
      {
        subHeading: 'Sensors',
        chunk:
          'Assign sensors to this algorithm using the sensor table. The Averaging Algorithm requires at least one angular velocity and one attitude sensor to produce an estimate.',
      },
    ],
  },
  MEKF: {
    heading: 'MEKF Algorithm',
    body: [
      {
        chunk: MEKFChunk,
      },
      {
        subHeading: 'Sensors',
        chunk:
          'Assign sensors to this algorithm using the sensor table. The MEKF Algorithm requires at least one attitude sensor to produce an estimate.',
      },
    ],
  },
  TRIAD: {
    heading: 'Triad Algorithm',
    body: [
      {
        chunk: triadChunk,
      },
      {
        subHeading: 'Sensors',
        chunk:
          'Assign sensors to this algorithm using the sensor table. The Triad Algorithm requires at least two direction sensors and one angular velocity sensor to produce an estimate.',
      },
    ],
  },
  EKF: {
    heading: 'EKF Algorithm',
    body: [
      {
        chunk: EKFChunk,
      },
      {
        subHeading: 'Sensors',
        chunk:
          'Assign sensors to this algorithm using the sensor table. The EKF algorithm requires at least one sensor to produce an estimate.',
      },
    ],
  },
  GPS: {
    heading: 'GPS Direct',
    body: [
      {
        chunk: GPSChunk,
      },
      {
        subHeading: 'GPS Sensor',
        chunk: 'Select the GPS sensor this algorithm will use.',
      },
    ],
  },
};

function getAlgoTypeGuidance(algoTypeKey: keyof typeof algoTypeGuidance) {
  return {
    heading: algoTypeGuidance[algoTypeKey].heading,
    body: algoTypeGuidance[algoTypeKey].body,
  };
}
function getAlgoSubTypeGuidance(subTypeKey: keyof typeof algoSubTypeGuidance) {
  return {
    heading: algoSubTypeGuidance[subTypeKey].heading,
    body: algoSubTypeGuidance[subTypeKey].body,
  };
}

export const algorithmGuidance = {
  _default: {
    heading: algorithmHeading,
    body: [
      {
        chunk:
          'Input a descriptive name for the algorithm, the update rate the algorithm should run at, and select the apropriate algorithm type from the options below.',
      },
      {
        subHeading: 'Attitude Control',
        chunk:
          'Attitude controllers drive associated Actuators to orient the spacecraft according to the currently active Pointing Mode. Controllers require an attitude estimator to operate. If no estimator is available, the controller will command zero torque on all associated actuators.',
      },
      {
        subHeading: 'Attitude Determination',
        chunk:
          'Attitude estimators fuse sensor data into a single estimate of the spacecraft attitude. This estimate can be used for decision-making by other Algorithms. Each estimator type requires one or more associated sensors to operate.',
      },
      {
        subHeading: 'Orbit Determination',
        chunk:
          'Orbit estimators fuse sensor data into a single estimate of the spacecraft position and velocity. This estimate can be used for decision-making by other Algorithms. Each estimator type requires one or more associated sensors to operate.',
      },
    ],
  },

  algorithmSubtype: (values: IGenericObject) => {
    const algorithmType = values.algorithmType.value;
    return getAlgoTypeGuidance(algorithmType as keyof typeof algoTypeGuidance);
  },

  angularVelocitySensors: (values: IGenericObject) => {
    const algorithmSubType = values.algorithmSubtype.value;
    return getAlgoSubTypeGuidance(algorithmSubType as keyof typeof algoSubTypeGuidance);
  },
  gainK: { alias: 'angularVelocitySensors' },
  gainG: { alias: 'angularVelocitySensors' },
  gainC: { alias: 'angularVelocitySensors' },
  gainP: { alias: 'angularVelocitySensors' },
  gainI: { alias: 'angularVelocitySensors' },
  gainD: { alias: 'angularVelocitySensors' },
  epsilon: { alias: 'angularVelocitySensors' },
  opticalAttitudeSensors: { alias: 'angularVelocitySensors' },
  positionSensor: { alias: 'angularVelocitySensors' },
  positionSensors: { alias: 'angularVelocitySensors' },
  vectorSensors: { alias: 'angularVelocitySensors' },
};

export const useGuidance = makeGuidance(algorithmGuidance);

const algorithmsSegmentGuidance = {
  heading: 'Algorithms',
  body: [
    {
      chunk: algorithmMainParagraph,
    },
  ],
};

export default algorithmsSegmentGuidance;
