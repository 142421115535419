import _ from 'lodash';
import { useMemo } from 'react';
import useActiveEntities from './useActiveEntities';
import useUser from './useUser';

const useIsUsersBranch = (): boolean => {
  const { branch } = useActiveEntities();
  const user = useUser();

  return useMemo(
    () =>
      user &&
      !_.isEmpty(branch) &&
      // @ts-ignore
      (branch.user === user?.id || branch.user.id === user?.id),
    // TODO ZACH: can probably change this in the future. Would be better if it were always consistent if it's the user object or user id that's nested, but it's not.
    [user, branch]
  );
};

export default useIsUsersBranch;
