import { slice } from 'utils/series';
import _ from 'lodash';

const nestedSlice = (data, i, seriesLength, obj = {}) => {
  for (const key in data) {
    if (_.isPlainObject(data[key])) {
      return nestedSlice(data[key], i, seriesLength);
    } else {
      return slice(data, i, seriesLength);
    }
  }
};

export const parse = {
  ConOps: (data) => {
    const { ConOps } = data;
    for (const model of Object.values(ConOps)) {
      if (model.series) {
        let series = [];
        let i = 0;
        let length = Math.min(model.series.limit, model.series.length);
        for (i; i < length; i++) {
          series.push({
            attitudes: model.series.data.attitudes && slice(model.series.data.attitudes, i, length),
            activeOpMode: model.series.data.activeOpMode[i],
          });
        }
        model.series.data = series;
      }
    }
    return ConOps;
  },
  MissionOrbit: (data) => {
    const { MissionOrbit } = data;
    for (const model of Object.values(MissionOrbit)) {
      if (model.series) {
        const series = model.series;
        let s = [];
        let i = 0;
        let length = Math.min(series.limit, series.length);

        for (i; i < length; i++) {
          s.push({
            positions: slice(series.data.positions, i, length),
            orbitalElements: slice(series.data.orbitalElements, i, length),
            velocities: slice(series.data.velocities, i, length),
            beta: series.data.beta[i],
            shadow: series.data.shadow[i],
            times: series.data.times && slice(series.data.times, i, length),
          });
        }
        model.series.data = s;
      }
    }
    return MissionOrbit;
  },
  MissionVersion: (data) => {
    const { MissionVersion } = data;
    for (const model of Object.values(MissionVersion)) {
      if (model.series) {
        let series = [];
        let i = 0;
        let length = Math.min(model.series.limit, model.series.length);
        for (i; i < length; i++) {
          series.push({
            times: model.series.data.times && slice(model.series.data.times, i, length),
          });
        }
        model.series.data = series;
      }
    }
    return MissionVersion;
  },
  OperationalMode: (data) => {
    const { OperationalMode } = data;
    for (const model of Object.values(OperationalMode)) {
      if (model.series) {
        let series = [];
        let i = 0;
        let length = Math.min(model.series.limit, model.series.length);

        for (i; i < length; i++) {
          let activeTargetIndex = {};
          if (model.series.data.activeTargetByTargetGroup) {
            for (let key of Object.keys(model.series.data.activeTargetByTargetGroup)) {
              activeTargetIndex[key] = slice(
                model.series.data.activeTargetByTargetGroup[key],
                i,
                length,
                'activeTarget'
              );
            }
          }
          series.push({
            compliance: model.series.data.compliance[i],
            activeTargetIndex,
          });
        }
        model.series.data = series;
      }
    }
    return OperationalMode;
  },
  // work around for correctly parsing the orbit data for SpaceTargets
  Orbit: (data) => {
    return data.Orbit;
  },
  Target: (data) => {
    const { Target, Orbit } = data;
    for (const model of Object.values(Target)) {
      if (model.series) {
        let series = [];
        let i = 0;
        let length = Math.min(model.series.limit, model.series.length);
        for (i; i < length; i++) {
          let targetTypeData = {};
          if (model.targetType === 'GROUND_TARGET') {
            targetTypeData = {
              satAz: model.series.data.satAz[i],
              satEl: model.series.data.satEl[i],
              solarAz: model.series.data.solarAz[i],
              solarEl: model.series.data.solarEl[i],
              times: model.series.data.times && slice(model.series.data.times, i, length),
              positions: slice(model.series.data.positions, i, length),
            };
          } else if (model.targetType === 'CELESTIAL_TARGET') {
            targetTypeData = {
              positions: slice(model.series.data.positions, i, length),
            };
          } else if (model.targetType === 'SPACE_TARGET') {
            const orbit = Orbit[model.orbit];
            targetTypeData = {
              positions: slice(orbit.series.data.positions, i, length),
              orbitalElements: slice(orbit.series.data.orbitalElements, i, length),
              times: orbit.series.data.times && slice(orbit.series.data.times, i, length),
            };
          }
          series.push({
            lineOfSight: model.series.data.lineOfSight[i],
            range: model.series.data.range[i],
            ...targetTypeData,
          });
        }
        model.series.data = series;
      }
    }
    return Target;
  },
  default: (data) => {
    for (const model of Object.values(data)) {
      if (model.series) {
        const seriesInfo = model.series;
        const seriesData = model.series.data;
        let series = [];
        let length = Math.min(seriesInfo.limit, seriesInfo.length);
        for (let i = 0; i < length; i++) {
          const timeStepObj = {};
          for (const key in seriesData) {
            if (_.isPlainObject(seriesData[key])) {
              timeStepObj[key] = nestedSlice(seriesData[key], i, length);
            } else {
              timeStepObj[key] = seriesData[key][i];
            }
          }
          series.push(timeStepObj);
        }
        model.series.data = series;
      }
    }
    return data;
  },
};
