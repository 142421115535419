import { makeGuidance } from 'hooks';

// TODO
const targetGroupsHeading = 'Create and Edit Target Groups';

const targetGroupsMainParagraph =
  'Group targets together so only one operational mode and one pointing mode are required to capture behavior that is consistent for multiple targets. A target group can be used in place of a single target to define ConOps condition parameters and/or pointing mode directions. Common applications of target groups include ground stations, relay satellites, constellations, and imaging targets.';

export const targetGroupsGuidance = {
  _default: {
    heading: targetGroupsHeading,
    body: [
      {
        chunk: targetGroupsMainParagraph,
      },
    ],
  },
};

export const useGuidance = makeGuidance(targetGroupsGuidance);

const targetGroupsSegmentGuidance = {
  heading: 'Target Groups',
  body: [
    {
      chunk: targetGroupsMainParagraph,
    },
  ],
};

export default targetGroupsSegmentGuidance;
