import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  plotWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 100%',
    height: '100%',
    userSelect: 'none',
    marginBottom: '1em',
  },
  zoomControls: (props) => ({
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    height: props.zoomed ? 40 : 0,
    marginBottom: props.zoomed ? 15 : 0,
    width: '100%',
    overflow: 'hidden',
    transition: 'height 500ms ease-in-out, margin-bottom 500ms ease-in-out',
  }),
  slider: {
    margin: '0 20px',
  },
}));

export default useStyles;
