import { makeGuidance } from 'hooks';

const batteryHeading = 'Create and Edit the Battery';

const batteryMainParagraph =
  'The battery provides power to the satellite when the solar arrays are not able to generate the power consumed by all the active loads. The battery is composed of one or more packs, each of which are treated as an individual heat source, as they are often located in different parts of the satellite.';

const overrideGuidance = {
  heading: batteryHeading,
  body: [
    {
      chunk: batteryMainParagraph,
    },
    {
      subHeading: 'Battery Parameter Overrides',
      chunk:
        'By default, Sedaro Satellite computes the minimum state-of-charge and maximum charge and discharge currents for the battery given the parameters of its cells.  Enter values in these fields to override and/or derate these computed values.',
    },
  ],
};

export const batteryGuidance = {
  _default: {
    heading: batteryHeading,
    body: [
      {
        chunk: batteryMainParagraph,
      },
    ],
  },

  configurationType: {
    heading: batteryHeading,
    body: [
      {
        chunk: batteryMainParagraph,
      },
      {
        subHeading: 'Battery Pack Configuration',
        chunk: 'The battery packs can be configured either in series or in parallel.',
      },
    ],
  },

  initialSoc: {
    heading: batteryHeading,
    body: [
      {
        chunk: batteryMainParagraph,
      },
      {
        subHeading: 'Initial State of Charge',
        chunk:
          "Define the battery's initial state-of-charge at the beginning of your mission. This can be used to account for the self-discharging that occurs between final GSE charge and deployment on orbit.",
      },
    ],
  },

  minSocOverride: overrideGuidance,

  maxChargeCurrentOverride: overrideGuidance,

  maxDischargeCurrentOverride: overrideGuidance,
};

const useGuidance = makeGuidance(batteryGuidance);
export default useGuidance;
