import { TDPSchemaList } from 'components/general/types';

const tsDissipationDPSchema: TDPSchemaList = [
  {
    name: 'Dissipation Overview',
    subtitle: 'Dissipation of each subsystem',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Dissipation',
          unit: 'W',
          keys: ['Subsystem.$each.dissipations.total'],
          legend: ['{name}'],
        },
      },
    ],
  },
  {
    name: 'Power Processing Dissipation',
    subtitle:
      'Dissipation of the power controller losses, internal loads, and bus regulator losses',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Dissipation',
          unit: 'W',
          keys: [
            'satellite.powerProcessor.dissipations.powerController',
            'satellite.powerProcessor.dissipations.internalLoads',
            'satellite.powerProcessor.dissipations.busRegulators',
            'satellite.powerProcessor.busRegulators.$each.dissipations.total',
          ],
          legend: [
            'Power Controller Losses',
            'Internal Loads',
            'Bus Regulator Total',
            '{name} Losses',
          ],
        },
      },
    ],
  },
  {
    name: 'Power Generation Dissipation: "{name}" Solar Array',
    subtitle: 'Dissipations by solar panel',
    each: 'SolarArray',
    plots: [
      {
        type: 'time-series',
        each: 'panels',
        name: '"{name}" Solar Panel',
        left: {
          label: 'Dissipation',
          unit: 'W',
          keys: ['dissipations.total', 'dissipations.blockingDiode', 'dissipations.internalLoads'],
          legend: ['Total', 'Blocking Diode Losses', 'Internal Loads'],
        },
      },
    ],
  },
  {
    name: 'Energy Storage Dissipation',
    subtitle: 'Equivalent Series resistance (ESR) and internal load dissipations by battery pack',
    each: 'Battery',
    plots: [
      {
        type: 'time-series',
        each: 'packs',
        name: '"{name}" Battery Pack',
        left: {
          label: 'Dissipation',
          unit: 'W',
          keys: ['dissipations.total', 'dissipations.esr', 'dissipations.internalLoads'],
          legend: ['Total', 'ESR Losses', 'Internal Loads'],
        },
      },
    ],
  },
  {
    name: '"{name}" Subsystem Dissipation',
    subtitle: 'Dissipation by component',
    each: 'Subsystem',
    plots: [
      {
        type: 'time-series',
        left: {
          label: 'Dissipation',
          unit: 'W',
          keys: ['components.$each.dissipations.total'],
          legend: ['{name}'],
        },
      },
    ],
  },
];

export default tsDissipationDPSchema;
