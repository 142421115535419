import _ from 'lodash';
import { binarySearch } from 'utils/arrays';

// For detailed documentation, see https://gitlab.sedaro.com/sedaro-satellite/common/-/wikis/SeriesData

/**
 * Initialize a new SeriesData structure. Mainly due to React/Redux considerations, this is a simple javascript object.
 * It will need to be passed to each function below when invoked.
 * @return {Object} SeriesData instance
 */
export const initSeriesData = () => ({
  streams: {},
  columnar: {},
  responsibility: {},
  hierarchical: {},
  kerf: {
    hierarchy: {},
    leaves: {},
  },
  startTimes: {},
  stopTimes: {},
});

/**
 * Return the start timestamp for the SeriesData structure. An object is returned of the following
 * form, where common is the earliest timestamp that is shared by all streams and absolute is the timestamp that is most
 * earliest.
 * @param  {Object} seriesData SeriesData instance
 * @return {Object} common and absolute start timestamps
 */
export const getSeriesDataStartTimestamp = (seriesData) => {
  const values = Object.values(seriesData.startTimes);
  if (values.length === 0) return { common: null, absolute: null };
  return {
    common: Math.max(...values),
    absolute: Math.min(...values),
  };
};

/**
 * Return the stop timestamp for the SeriesData structure. An object is returned of the following
 * form, where common is the latest timestamp that is shared by all streams and absolute is the timestamp that is most
 * latest.
 * @param  {Object} seriesData SeriesData instance
 * @return {Object} common and absolute stop timestamps
 */
export const getSeriesDataStopTimestamp = (seriesData) => {
  const values = Object.values(seriesData.stopTimes);
  if (values.length === 0) return { common: null, absolute: null };
  return {
    common: Math.min(...values),
    absolute: Math.max(...values),
  };
};

/**
 * Return the metadata for the Stream that contains the passed column (dot-delimited) key (without the streamId).
 * @param  {Object} seriesData SeriesData instance
 * @param  {String} streamlessColumnKey the dot-delimited key to lookup, without the streamId
 * @throws {Error} if the key is not found or if the parent key spans more than 1 Stream
 * @return {Object} Stream metadata
 */
export const getResponsibleStream = (seriesData, streamlessColumnKey) => {
  let stream = seriesData.responsibility[streamlessColumnKey];
  if (!stream) {
    const subset = _.get(seriesData.hierarchical, streamlessColumnKey);
    if (!subset) throw new Error('Stream not found for key ' + streamlessColumnKey);
    const streamIds = new Set();
    for (const key in flatten(subset)) {
      streamIds.add(seriesData.responsibility[streamlessColumnKey + '.' + key].id);
    }
    if (streamIds.size > 1)
      throw new Error('Series data parent key ' + streamlessColumnKey + ' spans multiple streams');
    if (streamIds.size < 1)
      throw new Error(
        'This should never happen. Talk to Bas if it does. It would indicate that empty parent keys exist in seriesData'
      );
    stream = seriesData.streams[streamIds.values().next().value];
  }
  return stream;
};

/**
 * Returns an Array of Series for the passed column (dot-delimited) keys (without the streamId).
 * @param  {Object} seriesData SeriesData instance
 * @param  {String} streamlessColumnKey the dot-delimited key to lookup, without the streamId
 * @throws {Error} if the key is not found or if the parent key spans more than 1 Stream
 * @return {Array} List of Series
 */
export const getSeriesForKeys = (seriesData, streamlessColumnKeys) => {
  const streamIds = new Set();
  for (const key of streamlessColumnKeys) {
    const stream = getResponsibleStream(seriesData, key);
    if (!stream) throw Error(`State variable ${key} not found.`);
    streamIds.add(stream.id);
  }
  if (streamIds.size > 1) {
    throw Error(
      `State variables ${streamlessColumnKeys.join(
        ', '
      )} are not contained within the same stream and therefore do not share a time axis.`
    );
  }
  const streamId = streamIds.values().next().value;
  return ['time', ...streamlessColumnKeys].map((key) => {
    const series = seriesData.columnar[streamId][key];
    if (!series) return _.get(seriesData.hierarchical, key);
    return series;
  });
};

/**
 * Warning: For performance reasons, this function mutates all previously sliced Kerfs. There is only a single Kerf per SeriesData "instance".
 * Populate a Kerf for a given timestamp. If an Agent ID is provided, only the Kerf for that Agent will be populated and returned. If no Agent ID is provided, the Kerf will be populated for all Agents.
 * @param  {Object} seriesData SeriesData instance
 * @param  {Number} timestamp Timestamp to slice by
 * @param  {String} agentId Agent ID to filter by (optional)
 * @return {Object} Populated Kerf
 */
export const sliceSeriesDataByTimestamp = (seriesData, timestamp, agentId) => {
  // FUTURE: consider adding caching later of prior slices.  If timestamp doesn't result in a new columnIndex for a
  // given stream, data is unchanged for all keys in that stream.

  // Clone kerf
  // Disabled for now due to performance reasons
  // const kerf = _.cloneDeep(seriesData.kerf); // Existing inefficiency - we clone the entire kerf, even if we only need a single agent
  const kerf = seriesData.kerf;

  // Populate it by iterating over leaves for agent(s), slicing columns, and populating cloned kerf
  const agentIds = agentId ? [agentId] : Object.keys(kerf.leaves);
  const columnIndexCache = {};
  for (const agentId of agentIds) {
    for (const key in kerf.leaves[agentId]) {
      const streamId = seriesData.responsibility[key].id;
      if (!columnIndexCache[streamId]) {
        columnIndexCache[streamId] = binarySearch(seriesData.columnar[streamId].time, timestamp, {
          fuzzy: true,
        });
      }
      const leaf = kerf.leaves[agentId][key];
      if (columnIndexCache[streamId] < 0) {
        // Not found
        leaf.p[leaf.r] = null; // Leave values as null for now, consider following exception later
        // throw new Error('Timestamp ' + timestamp + ' does not exist in stream ' + streamId);
      } else {
        leaf.p[leaf.r] = seriesData.columnar[streamId][key][columnIndexCache[streamId]];
      }
    }
  }

  if (!agentId) return kerf.hierarchy;
  return { [agentId]: kerf.hierarchy[agentId] };
};

// Convert nested objects to an object of depth == 1 with dot delim'd keys
// Stops at arrays
const flatten = (obj, prefix = '') => {
  return Object.keys(obj).reduce((acc, key) => {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;
    // Don't flatten beyond Array of primitives (these are leaves (i.e. columns))
    if (
      (Array.isArray(value) && value[0] && typeof value[0] === 'object') ||
      (!Array.isArray(value) && typeof value === 'object')
    ) {
      return { ...acc, ...flatten(value, newKey) };
    }
    return { ...acc, [newKey]: value };
  }, {});
};

/**
 * Concatenate Data Service series data into the SeriesData "instance". This function makes the following assumptions about incoming data:
 *   - Each series is locally sequential
 *   - Future series are not overlapping with existing series
 *   - Future series for an existing stream are continuous (i.e. no gaps)
 * See https://gitlab.sedaro.com/sedaro-satellite/common/-/wikis/SeriesData#concatseriesdata for additional details
 * @param  {Object} seriesData SeriesData instance
 * @param  {Object} incomingSeries Series data to concatenate
 * @param  {Object} config Configuration object
 */
export const concatSeriesData = (seriesData, incomingSeries, config = {}) => {
  const { skipInitialFrame = false } = config;
  for (const streamId in incomingSeries) {
    let [time, data] = incomingSeries[streamId];

    // TODO: Design out `slice`.  This was added to get around DS initial state issue
    const slice = skipInitialFrame && !seriesData.streams[streamId] ? [1] : null;
    time = slice ? time.slice(...slice) : time;

    if (!seriesData.startTimes[streamId] || seriesData.startTimes[streamId] > time[0])
      seriesData.startTimes[streamId] = time[0];
    if (!seriesData.stopTimes[streamId] || seriesData.stopTimes[streamId] < time[time.length - 1])
      seriesData.stopTimes[streamId] = time[time.length - 1];

    // Init stream if it doesn't exist
    if (!seriesData.streams[streamId]) {
      seriesData.streams[streamId] = { length: 0, id: streamId };
      seriesData.columnar[streamId] = {};
    }
    const streamsColumns = seriesData.columnar[streamId];

    // Flatten for easy handling
    const flatData = flatten(data);

    // Add new keys to seriesData
    const streamLength = seriesData.streams[streamId].length;
    for (const key in flatData) {
      // {} -> {a: []} // delete key containing parent key
      // {a: []} -> {} // pad a: this will happen during length reconciliation

      // If key doesn't already exist, pad with nulls up to pre-concat length
      if (!streamsColumns[key]) {
        streamsColumns[key] = new Array(streamLength).fill(null);
        seriesData.responsibility[key] = seriesData.streams[streamId];
      }
      // Concat
      streamsColumns[key] = streamsColumns[key].concat(
        slice ? flatData[key].slice(...slice) : flatData[key]
      );
    }

    // Concat time
    if (!streamsColumns.time) streamsColumns.time = []; // NOTE: `time` is not captured in `responsibility` as it is given
    streamsColumns.time = streamsColumns.time.concat(time);

    // Remove invalid parent series columns
    // This is expected to be faster than avoiding creating them in the first place
    const alphabetizedKeys = Object.keys(streamsColumns).sort();
    for (let i = 0; i < alphabetizedKeys.length - 1; i++) {
      const key = alphabetizedKeys[i];
      if (alphabetizedKeys[i + 1].startsWith(key + '.')) {
        delete streamsColumns[key];
      }
    }

    // Reconcile length of any series in stream but not in flatData
    // i.e. added during a previous concat
    for (const key in streamsColumns) {
      if (streamsColumns[key].length === streamLength) {
        streamsColumns[key] = streamsColumns[key].concat(new Array(time.length).fill(null));
      }
    }

    // Update pointers in hierarchical format
    // Also update kerf
    // Do this after all the reconciliation above to avoid potential errors regarding _.set and dot-delimited keys
    for (const key in streamsColumns) {
      // This could be sped up with caching of hierarchy "tiers"
      if (key !== 'time') {
        const keys = key.split('.');
        const key0 = keys[0];
        const keyN = keys[keys.length - 1];

        _.setWith(seriesData.hierarchical, key, streamsColumns[key], Object);

        // Avoid unnecessary work
        if (!seriesData.kerf.leaves[key0]) seriesData.kerf.leaves[key0] = {};
        if (!seriesData.kerf.leaves[key0][key]) {
          const pr = { r: keyN };
          seriesData.kerf.leaves[key0][key] = pr;
          _.setWith(seriesData.kerf.hierarchy, key, null, (nsValue, _key, nsObject) => {
            if (!nsValue) {
              pr.p = Object();
              return pr.p;
            } else {
              pr.p = nsValue;
            }
          });
        }
      }
    }

    // Update stream meta data
    seriesData.streams[streamId].length += time.length;
  }
};

export const _sliceHierarchy = (h, i) => {
  return Object.keys(h).reduce((acc, key) => {
    if (Array.isArray(h[key])) {
      acc[key] = h[key][i];
    } else {
      acc[key] = _sliceHierarchy(h[key], i);
    }
    return acc;
  }, {});
};

export function* makeSeriesTransposeIterator(series) {
  for (let i = 0; i < series[0].length; i++) {
    yield series.map((s) => {
      if (Array.isArray(s)) {
        return s[i];
      }
      return _sliceHierarchy(s, i);
    });
  }
}
