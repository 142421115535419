import StyledButton from 'components/general/StyledButton';
import WaveContainer from 'components/general/WaveContainer';
import AuthWidget from 'components/general/widgets/AuthWidget';
import LoadingView from 'components/LoadingView';
import { SUPPORT_EMAIL } from 'config';
import { useSnackbar, useUser } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import { useHistory, withRouter } from 'react-router-dom';
import Routes from 'routes';
import useStyles from './styles';

const AuthorizeView = () => {
  const {
    User: {
      actions: { authorize, logout },
    },
  } = SatelliteApi;

  const user = useUser();

  const dispatch = useDispatch();

  const { token } = useParams();
  let history = useHistory();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();

  useEffect(() => {
    if (token !== ':token') {
      dispatch(
        authorize({
          authorizationToken: token,
          successCallback: (success) => {
            history.replace(Routes.ROOT());
            enqueueSnackbar(success.message, { variant: 'success' });
          },
          failureCallback: (failure) => {
            // If non-admin user attempts to authorize, endpoint is ambiguously "not found"
            if (failure.error.message.includes('not accessible')) {
              history.replace(Routes.NOT_FOUND());
            } else if (failure.error.message.includes('already')) {
              // Else if user doesn't need authorization and/or was authorized already
              history.replace(Routes.AUTHORIZE());
              enqueueSnackbar(failure.error.message);
            } else {
              // Otherwise, a legitimate error occurred
              history.replace(Routes.AUTHORIZE());
              enqueueSnackbar('Something went wrong. Please try again later.');
            }
          },
        })
      );
    } else if (!user.isFlagged) {
      // If user doesn't need authorization, keep this view away from them
      history.replace(Routes.ROOT());
    }
  }, [token]); //eslint-disable-line

  if (token !== ':token') {
    return <LoadingView />;
  }
  return (
    <WaveContainer>
      <AuthWidget>
        <form>
          <h2 className={classes.heading}>Authorization</h2>
          <h5>
            We are authorizing your account now.
            <br />
            Please allow 24 hours for this process to be completed. Once confirmed, you will
            automatically be taken to Sedaro Satellite upon your next login.
          </h5>
          <br />
          <p className={classes.footer}>
            If 24 hours have passed and you still cannot login, please contact us at {SUPPORT_EMAIL}
            .
          </p>
          <StyledButton
            type="button"
            onClick={() => {
              closeSnackbar();
              dispatch(logout());
            }}
            framed
            tallMargin
            fullWidth
          >
            Logout
          </StyledButton>
        </form>
      </AuthWidget>
    </WaveContainer>
  );
};

export default withRouter(AuthorizeView);
