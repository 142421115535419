import { useCallback, useMemo, useEffect } from 'react';
import LabeledDateTimePicker from 'components/general/inputs/LabeledPickers/LabeledDateTimePicker';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledInput from 'components/general/inputs/LabeledInput';
import LabeledSelect from 'components/general/inputs/LabeledSelect';
import InputAdornment from '@material-ui/core/InputAdornment';
import MdAccent from 'components/general/Accent/variants/MdAccent';
import { translateIn, translateOut } from 'utils/forms';
import useGuidance from './guidance';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import { createOptionsFromEntities, createNestedOption } from 'utils/forms';
import conditionSchema from './validation';
import { useActiveEntities, useEntityForm } from 'hooks';
import EntityDialog from 'components/general/dialogs/EntityDialog';
import { ConditionVables } from 'utils/vable';

const defaultValues = {
  name: '',
  paramACategory: '',
  targetA: '',
  paramA: '',
  value: '',
  relationship: '',
  scalar: false,
  paramBCategory: '',
  targetB: '',
  paramB: '',
};

const sharedParameterCategories = [
  { value: 'SATELLITE', label: 'Satellite Parameter' },
  { value: 'TARGET', label: 'Target Parameter' },
];

const trueAnomOption = {
  value: 'TRUE_ANOM',
  label: 'True Anomaly',
  units: 'deg',
};
const meanAnomOption = {
  value: 'MEAN_ANOM',
  label: 'Mean Anomaly',
  units: 'deg',
};
const localSiderealTimeOption = {
  value: 'LOCAL_SIDEREAL_TIME',
  label: 'Local Sidereal Time',
  units: 'deg',
};
const llaOptions = (includeHeading) => [
  {
    value: 'LAT',
    label: 'Latitude' + (includeHeading ? ' (North)' : ''),
    units: 'deg',
  },
  {
    value: 'LON',
    label: 'Longitude' + (includeHeading ? ' (East)' : ''),
    units: 'deg',
  },
  { value: 'ALT', label: 'Altitude', units: 'km' },
];
const baseTargetOptions = [
  { value: 'LO_SIGHT', label: 'Line of Sight', units: 'bool' },
  { value: 'RANGE', label: 'Range', units: 'km' },
];
const constantOnlyScalarOptions = [
  { value: 'BATTERY_SOC', label: 'Battery State of Charge', units: '%' },
];

const targetOptionSpecs = {
  SATELLITE: [
    {
      value: 'SHADOW',
      label: 'Eclipse State',
      units: 'eclipse',
    },
    { value: 'BETA', label: 'Beta Angle', units: 'deg' },
    meanAnomOption,
    trueAnomOption,
    localSiderealTimeOption,
    ...llaOptions(true),
    ...constantOnlyScalarOptions,
  ],
  SPACE_TARGET: [
    ...baseTargetOptions,
    meanAnomOption,
    trueAnomOption,
    localSiderealTimeOption,
    ...llaOptions(false),
  ],
  CELESTIAL_TARGET: [...baseTargetOptions, ...llaOptions(false)],
  GROUND_TARGET: [
    ...baseTargetOptions,
    { value: 'SOLAR_AZ', label: 'Solar Azimuth', units: 'deg' },
    { value: 'SOLAR_EL', label: 'Solar Elevation', units: 'deg' },
    { value: 'SAT_AZ', label: 'Satellite Azimuth', units: 'deg' },
    { value: 'SAT_EL', label: 'Satellite Elevation', units: 'deg' },
    localSiderealTimeOption,
  ],
};

const scalarOption = { value: 'SCALAR', label: 'Constant' };

const ConditionDialog = (props) => {
  // Props
  const { control } = props;
  const { dialogConfig } = control;
  const { entity: condition } = dialogConfig;

  const { targets, targetGroups, conOps } = useActiveEntities();

  // Hooks
  const classes = useStyles();

  // Create Options
  const targetsList = useMemo(() => createOptionsFromEntities(targets, ['targetType']), [targets]);

  const options = useMemo(() => {
    return {
      paramACategory: [...sharedParameterCategories, { value: 'TIME', label: 'Date and Time' }],
      paramBCategory: [...sharedParameterCategories, scalarOption],
      value: [
        {
          value: 'CONSTANT',
          label: 'Constant Value',
        },
        { value: 'PARAM', label: 'Dynamic Value' },
      ],
      relationship: ConditionVables.ConditionRelationshipTypes.options,
      targetA: [
        createNestedOption(
          targetGroups,
          'Target Groups',
          {
            paramACategory: { value: 'TARGET', label: 'Target Parameter' },
          },
          'targetGroupA',
          ['targetType'],
          {
            targetGroupA: 'self',
            paramACategory: 'TARGET_GROUP',
            targetA: undefined,
          }
        ),
        createNestedOption(
          targets,
          'Targets',
          {
            paramACategory: { value: 'TARGET', label: 'Target Parameter' },
          },
          'targetA',
          ['targetType'],
          {
            targetA: 'self',
            paramACategory: 'TARGET',
            targetGroupA: undefined,
          }
        ),
      ],
      targetB: targetsList,
      paramA:
        targetOptionSpecs[
          condition?.targetA
            ? condition?.targetA?.targetType
            : condition?.targetGroupA
            ? condition?.targetGroupA?.targetType
            : 'SATELLITE'
        ],
      paramB: targetOptionSpecs[condition?.targetB ? condition?.targetB?.targetType : 'SATELLITE'],
    };
  }, [targetsList, targetGroups, targets, condition]);

  // Helper functions and variables
  const isScalarATime = useCallback((values, direction) => {
    if (direction === 'out') {
      return values.paramBCategory?.value === 'SCALAR' && values.paramACategory?.value === 'TIME';
    } else {
      return values?.paramBCategory === 'SCALAR' && values?.paramACategory === 'TIME';
    }
  }, []);

  const customTranslateOut = useCallback(
    (values, allowedEmptyFields, options) => {
      // format data if form uses checkbox
      if (values.paramA.units === 'bool' || values.paramA.units === 'eclipse') {
        if (values.scalar === true) {
          values.scalar = 1;
        } else {
          values.scalar = 0;
        }
      }
      // Turn percentage values into decimals
      const percentages = [];
      if (values.paramA.units === '%') {
        percentages.push('scalar');
      }
      // Check if scalar is a time to pass in the proper datetimes array to translateOut
      let datetimes = undefined;
      if (isScalarATime(values, 'out')) {
        datetimes = ['scalar'];
      }
      // Delete the value field as it is not required by the backend
      delete values.value;
      return translateOut(values, allowedEmptyFields, options, datetimes, percentages);
    },
    [isScalarATime]
  );

  const customTranslateIn = useCallback(
    (condition, defaultValues, options) => {
      // set the value of scalar on translate in since the backend does not have this field
      if (condition.paramBCategory === 'SCALAR') {
        condition.value = 'CONSTANT';
      } else {
        condition.value = 'PARAM';
      }
      // Then set scalar to a boolean if using a boolean
      if (condition.paramA === 'SHADOW' || condition.paramA === 'LO_SIGHT') {
        condition.scalar = !!condition.scalar;
      }
      // Or a percent if using a percent
      const percentages = [];
      if (condition.paramA === 'BATTERY_SOC') {
        percentages.push('scalar');
      }
      let datetimes = undefined;
      if (isScalarATime(condition)) {
        datetimes = ['scalar'];
      }
      return translateIn(condition, defaultValues, options, datetimes, percentages);
    },
    [isScalarATime]
  );

  // Form hook up
  const entityForm = useEntityForm({
    entityTypeText: 'Condition',
    entityDialogControl: control,
    defaultValues,
    validationSchema: conditionSchema,
    additionalCreateValues: { conOps: conOps.id },
    formikOptionalParams: {
      options,
      translateIn: customTranslateIn,
      translateOut: customTranslateOut,
      useGuidance,
      allowedEmptyFields: ['paramB'],
    },
  });
  const { formik } = entityForm;
  const { getFieldProps, values, setFieldValue, setValues, dirty } = formik;
  const { paramA, paramACategory, paramBCategory, targetA, targetB, relationship, value } = values;

  // Helper variables
  const displayCheckbox = useMemo(
    () => paramA?.units === 'bool' || paramA?.units === 'eclipse',
    [paramA]
  );

  // Use effect to set scalar to the correct data type
  // As well as set fields that are not displaying but need to be set for the backend
  useEffect(() => {
    // Only need to run the useEffect if the form is dirty, not on initial load
    if (dirty) {
      // Whenever condition is utilizing a date or time field then the scalar needs to be explicitly null
      // Otherwise the datetimepicker bug of showing the current time occurs
      if (paramACategory?.value === 'TIME' && value.value === 'CONSTANT') {
        setFieldValue('scalar', null);
      }
      // Booleans need to explicitly be true or false for the checked property to display correct
      // In this case only the checkbox is displayed so paramBCategory and relationship are manually set
      else if (displayCheckbox) {
        setFieldValue('scalar', false);
        setFieldValue('relationship', { value: 'EQUAL', label: 'Equal to ( == )' });
        setFieldValue('paramBCategory', scalarOption);
      } else {
        // Otherwise set scalar back to the default value of empty string
        setFieldValue('scalar', '');
      }
    }
  }, [paramACategory, paramA, value]); //eslint-disable-line

  // Render constant renders the scalar field, which shifts depending on the parameters being used
  const renderConstant = () => {
    if (relationship?.value && paramACategory?.value === 'TIME') {
      return <LabeledDateTimePicker {...getFieldProps('scalar')} label="Mission Time (UTC)" />;
    } else if (displayCheckbox) {
      return (
        <LabeledCheckbox
          {...getFieldProps('scalar')}
          label={paramA?.units === 'eclipse' ? 'In Eclipse?' : `${paramA.label}?`}
        ></LabeledCheckbox>
      );
      // Otherwise we render a standard input for all other scalar fields
    } else if (value?.value === 'CONSTANT' && paramA?.value) {
      return (
        <LabeledInput
          {...getFieldProps('scalar')}
          label={paramA?.label}
          type="number"
          endAdornment={<InputAdornment position="end">{paramA?.units}</InputAdornment>}
        />
      );
    }
  };

  // Render the actual select component
  const renderParameterSelect = (suffix, key) => {
    return (
      <LabeledSelect
        {...getFieldProps(`param${suffix}`)}
        label={`Parameters`}
        options={targetOptionSpecs[key]}
        formikOnChange={(val) => {
          // When swapped from a boolean checkbox, set all subsequent fields back to default values
          if (suffix === 'A' && val.value !== paramA?.value) {
            if (displayCheckbox)
              setValues({
                ...values,
                paramBCategory: defaultValues.paramBCategory,
                relationship: defaultValues.relationship,
                value: defaultValues.value,
              });
            // If a constant only param (i.e. not compared to dynamic value, e.g. battery soc),
            // set value to constant
            if (constantOnlyScalarOptions.find((option) => option.value === val.value))
              setValues({
                ...values,
                value: options.value.find((option) => option.value === 'CONSTANT'),
                paramBCategory: scalarOption,
              });
          }
        }}
      />
    );
  };

  const renderParameters = (paramType, suffix, customOnChange) => {
    const key = 'target' + suffix;
    return (
      <>
        {paramType.value === 'SATELLITE' && (
          <div className={classes.indent}>{renderParameterSelect(suffix, 'SATELLITE')}</div>
        )}
        {paramType.value === 'TARGET' && (
          <MdAccent header="Target">
            <LabeledSelect
              {...getFieldProps(key)}
              options={options[key]}
              defaultValue={values[key]}
              formikOnChange={(val) => {
                if (customOnChange) customOnChange(val);
                setFieldValue(key, val);
              }}
              noOptionsMessage={() => 'Create a Target or Target Group'}
            />
            {suffix === 'A' && targetA && renderParameterSelect(suffix, targetA.value.targetType)}
            {suffix === 'B' && targetB && renderParameterSelect(suffix, targetB?.value.targetType)}
          </MdAccent>
        )}
      </>
    );
  };

  const resetTargetParams = (suffix, newValue, prevValue) => {
    if (newValue.value.targetType !== prevValue?.value?.targetType) {
      setFieldValue(`param${suffix}`, defaultValues['param' + suffix]);
    }
  };

  return (
    <EntityDialog entityForm={entityForm}>
      <div className={classes.inputs}>
        <div className={classes.inputGroup}>
          <LabeledInput
            {...getFieldProps('name')}
            placeholder="Condition Name"
            label="Name"
            autoFocus
          ></LabeledInput>
        </div>
        <div className={classes.inputGroup}>
          <LabeledSelect
            {...getFieldProps('paramACategory')}
            label="Parameter A Type"
            options={options.paramACategory}
            formikOnChange={(val) => {
              if (val.value !== paramACategory.value) {
                setValues({
                  ...defaultValues,
                  name: values.name,
                });
              }
            }}
          />
          {paramACategory &&
            renderParameters(paramACategory, 'A', (val) => {
              if (paramACategory?.value === 'TARGET') resetTargetParams('A', val, targetA);
            })}
        </div>
        {(paramACategory?.value === 'TIME' || (paramA?.value && !displayCheckbox)) && (
          <div className={classes.inputGroup}>
            <LabeledSelect
              {...getFieldProps('value')}
              label="Compare to..."
              options={options.value}
              formikOnChange={(val) => {
                // if value is changed then we reset all subsequent fields
                if (val.value !== value.value) {
                  setValues({
                    ...values,
                    relationship: defaultValues.relationship,
                    paramBCategory: defaultValues.paramBCategory,
                    paramB: defaultValues.paramB,
                    targetB: defaultValues.targetB,
                  });
                }
                // if switched to compare with scalar we must also set
                if (val.value === 'CONSTANT') {
                  setFieldValue('paramBCategory', scalarOption);
                }
              }}
              isOptionDisabled={(option) =>
                constantOnlyScalarOptions.find((option) => option.value === paramA?.value) !==
                  undefined && option?.value !== 'CONSTANT'
              }
            />
            {value?.value && !displayCheckbox && (
              // Only render relationship if is filled out and a checkbox is not displayed
              <LabeledSelect
                {...getFieldProps('relationship')}
                label="Relationship"
                options={options.relationship}
              />
            )}
          </div>
        )}
        {value?.value === 'PARAM' && relationship?.value && (
          // paramater B category is only needed if the user is comparing to an additional parameter and relationship has been filled out
          <div className={classes.inputGroup}>
            <LabeledSelect
              {...getFieldProps('paramBCategory')}
              label="Parameter B Type"
              options={sharedParameterCategories}
            />
            {paramBCategory &&
              renderParameters(paramBCategory, 'B', (val) => {
                if (paramBCategory.value === 'TARGET') resetTargetParams('B', val, targetB);
              })}
          </div>
        )}
        {relationship?.value && (value?.value === 'CONSTANT' || displayCheckbox) && (
          // Render constant is displayed when a checkbox is needed or the comparison value field is set to constant
          <div className={classes.inputGroup}>{renderConstant()}</div>
        )}
      </div>
    </EntityDialog>
  );
};

export default ConditionDialog;
