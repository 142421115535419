import { useContext } from 'react';
import useStyles from 'components/general/wizards/WizardSegment/styles';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { SpacecraftContext } from 'providers';
import Panel from '../general/Panel';
import { useActiveEntities, useEntityDialogControl } from 'hooks';
import ReferenceVectorDialog from './ReferenceVectorDialog';
import { IColumn } from 'components/general/types';
import { ReferenceVectorVables, TReferenceVectorTypes } from 'utils/vable';
import { IReferenceVector } from 'components/general/types/spacecraft';

const guidance = {
  heading: 'Create and Edit Reference Vectors',
  body: [
    {
      chunk:
        'Create local direction vectors and vectors from the spacecraft to celestial bodies and targets. These vectors are used in other parts of the model to define behaviors such as pointing, condition compliance, targeting, and attitude determination.',
    },
  ],
};

const vectorsColumns: IColumn[] = [
  {
    title: 'Name',
    field: 'name',
  },
  {
    title: 'Type',
    render: (rowData) =>
      ReferenceVectorVables.VectorTypes[rowData.vectorType as TReferenceVectorTypes].label,
  },
];

const ReferenceVectorsPanel = ({ ...remainingProps }) => {
  const { referenceVectors } = useActiveEntities();

  const refVectorEntityDialogControl = useEntityDialogControl<IReferenceVector>();
  const classes = useStyles();

  const { closeSpacecraftDialog } = useContext(SpacecraftContext);

  return (
    <>
      <Panel
        title="Reference Vectors"
        guidance={guidance}
        secondaryActionText="Close"
        onClose={closeSpacecraftDialog}
        {...remainingProps}
      >
        <WidgetTable
          className={classes.table}
          columns={vectorsColumns}
          data={referenceVectors}
          modelName="reference vector"
          onFabClick={refVectorEntityDialogControl.openDialogForNew}
          onActionClick={refVectorEntityDialogControl.openDialogForExisting}
        />
      </Panel>
      <ReferenceVectorDialog control={refVectorEntityDialogControl} />
    </>
  );
};

export default ReferenceVectorsPanel;
