import { TDPSchemaList } from 'components/general/types';

const tsTargetDPSchema: TDPSchemaList = [
  {
    name: 'Ground Target: "{name}"',
    each: 'satellite.groundTargets',
    subtitle: '',
    plots: [
      {
        name: 'Azimuth, Elevation, and Range',
        type: 'time-series',
        left: {
          label: 'Angle',
          unit: 'deg',
          keys: ['azimuth.deg', 'elevation.deg'],
          legend: ['Azimuth', 'Elevation'],
        },
        right: {
          label: 'Range',
          unit: 'km',
          keys: ['range'],
          legend: ['Range'],
        },
      },
      {
        name: 'Access and Shadow Status',
        type: 'time-series',
        left: {
          label: 'Status',
          unit: '',
          keys: ['lineOfSight', 'shadow'],
          legend: ['Line of Sight', 'Shadow Status'],
        },
      },
      {
        name: 'Solar Location and Sidereal Time',
        type: 'time-series',
        left: {
          label: 'Angle',
          unit: 'deg',
          keys: ['solarAz.deg', 'solarEl.deg'],
          legend: ['Solar Azimuth', 'Solar Elevation'],
        },
        right: {
          label: 'Local Sidereal Time',
          unit: 'deg',
          keys: ['lst.deg'],
          legend: ['Local Sidereal Time'],
        },
      },
    ],
  },
  {
    name: 'Space Target: "{name}"',
    each: 'satellite.spaceTargets',
    subtitle: '',
    plots: [
      {
        name: 'Azimuth, Elevation, and Range',
        type: 'time-series',
        left: {
          label: 'Angle',
          unit: 'deg',
          keys: ['azimuth.deg', 'elevation.deg'],
          legend: ['Azimuth', 'Elevation'],
        },
        right: {
          label: 'Range',
          unit: 'km',
          keys: ['range'],
          legend: ['Range'],
        },
      },
      {
        name: 'Access, Shadow Status, and Beta Angle',
        type: 'time-series',
        left: {
          label: 'Status',
          unit: '',
          keys: ['lineOfSight', 'shadow'],
          legend: ['Line of Sight', 'Shadow Status'],
        },
        right: {
          label: 'Angle',
          unit: 'deg',
          keys: ['beta.deg'],
          legend: ['Beta Angle'],
        },
      },
    ],
  },
  {
    name: 'Celestial Target: "{name}"',
    each: 'satellite.celestialTargets',
    subtitle: '',
    plots: [
      {
        name: 'Azimuth, Elevation, and Access',
        type: 'time-series',
        left: {
          label: 'Angle',
          unit: 'deg',
          keys: ['azimuth.deg', 'elevation.deg'],
          legend: ['Azimuth', 'Elevation'],
        },
        right: {
          label: 'Status',
          unit: '',
          keys: ['lineOfSight'],
          legend: ['Line Of Sight'],
        },
      },
    ],
  },
];

export default tsTargetDPSchema;
