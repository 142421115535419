import { useEffect, useState } from 'react';
import Dialog from 'components/general/dialogs/Dialog';
import Grid from '@material-ui/core/Grid';
import useStyles from './styles';
import GuidanceCard from 'components/general/GuidanceCard';
import LabeledCheckbox from 'components/general/inputs/LabeledCheckbox';
import LabeledPasswordInput, {
  passwordSchema,
} from 'components/general/inputs/LabeledInput/LabeledPasswordInput';
import { useFormikForm, useActiveEntities, useSnackbar } from 'hooks';
import { SatelliteApi } from 'middleware/SatelliteApi/api';
import * as Yup from 'yup';
import { IErrorResponse } from 'components/general/types';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@material-ui/core';

interface IProps {
  open: boolean;
  closeDialog: () => void;
}

export interface IForm {
  shareable: boolean;
  sharePwRqd: boolean;
  password: string;
}

const validationSchema = Yup.object().shape({
  shareable: Yup.boolean(),
  sharePwRqd: Yup.boolean(),
  password: Yup.string().when('sharePwRqd', { is: true, then: passwordSchema }),
});

const ShareableLinkDialog = ({ open, closeDialog }: IProps) => {
  const { branch } = useActiveEntities();
  const { shareable, sharePwRqd, uuid } = branch!;
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [copyClicked, setCopyClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const defaultValues = {
    shareable,
    sharePwRqd,
    password: '',
  };

  const {
    MissionVersion: {
      actions: { updateMissionVersion },
    },
  } = SatelliteApi;

  const onSubmit = (vals: IForm) => {
    // `sharePwRqd` isn't on model (so we don't send it back to the backend). It just indicates whether password field is empty on the model.
    const { shareable } = vals;
    let { password } = vals;
    // Sending back `password` as a string/empty string indicates that the link is not password protected
    password = password === undefined ? '' : password;
    setLoading(true);
    dispatch(
      updateMissionVersion({
        id: branch.id,
        shareable,
        password,
        successCallback: () => {
          enqueueSnackbar('Branch link sharing updated successfully.', {
            variant: 'success',
          });
          resetForm();
          setLoading(false);
        },
        failureCallback: (response: IErrorResponse) => {
          enqueueSnackbar(response.error.message);
          setLoading(false);
        },
      })
    );
  };

  const { formik } = useFormikForm<IForm, IForm>(
    defaultValues,
    onSubmit,
    validationSchema,
    defaultValues
  );

  const { handleSubmit, getFieldProps, resetForm, dirty, values, setValues } = formik;

  const classes = useStyles({ noClick: dirty || loading });

  useEffect(() => {
    if (open) resetForm();
  }, [open, resetForm]);

  const linkText = `${window.location.href}${
    window.location.href.includes('?') ? '&' : '?'
  }share=${uuid}`;
  const linkToolTip = loading
    ? 'Saving changes'
    : dirty
    ? 'Save changes to activate link'
    : copyClicked
    ? 'Copied'
    : 'Click to copy';
  const copyToClipboard = () => {
    if (dirty || loading) return;
    navigator.clipboard.writeText(linkText);
    setCopyClicked(true);
  };
  const resetCopyClicked = () => setCopyClicked(false);

  return (
    <Dialog
      title="Share this branch via link"
      large
      open={open}
      onClose={() => {
        closeDialog();
      }}
      onSubmit={handleSubmit}
      submitActionText="Save"
      secondaryActionText="Close"
      disableSubmit={!dirty}
      loading={loading}
    >
      <Grid className={classes.root} container spacing={1}>
        <Grid item md={7}>
          <LabeledCheckbox
            label="Enable view-only links"
            formikOnChange={() => setValues({ ...values, sharePwRqd: false, password: '' })}
            {...getFieldProps('shareable')}
          />
          {values.shareable && (
            <div className={classes.linkPreview}>
              <p className={classes.linkTitle}>Shareable link:</p>
              <Tooltip arrow placement="top" title={linkToolTip}>
                <p
                  className={classes.linkText}
                  onClick={copyToClipboard}
                  onMouseEnter={resetCopyClicked}
                >
                  {linkText}
                </p>
              </Tooltip>
            </div>
          )}
          {values.shareable && (
            <LabeledCheckbox
              label="Password protect"
              formikOnChange={() => setValues({ ...values, password: '' })}
              {...getFieldProps('sharePwRqd')}
            />
          )}
          {values.sharePwRqd && (
            <LabeledPasswordInput
              label={sharePwRqd ? 'Update Password' : 'Password'}
              {...getFieldProps('password')}
            />
          )}
        </Grid>
        <Grid item md={5}>
          <GuidanceCard
            guidance={{
              heading: 'Shareable Links',
              body: `Shareable links allow view-only access to your branch by anyone who has a link.
              You can toggle if a shareable link is live and whether or not it requires a password.
              When enabled, this dialog shows a shareable link to your branch starting from your current location.`,
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ShareableLinkDialog;
