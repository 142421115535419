import StyledButton from 'components/general/StyledButton';
import useStyles from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { IButtonProps, IIconProps } from '../index';
import { TClickEvent } from 'components/general/types';

interface IProps {
  name: string;
  description: string;
  onClick: () => void;
  ecitIconProps?: IIconProps;
  trashIconProps?: IIconProps;
  buttonProps?: IButtonProps;
}

const Row = (props: IProps) => {
  const { name, description, onClick, buttonProps, ecitIconProps, trashIconProps } = props;

  const {
    onClick: btnOnClick,
    text: btnText,
    tooltip: btnTooltip,
    disabled: btnDisabled,
  } = buttonProps || {};
  const {
    onClick: editIconOnClick,
    tooltip: editIconTooltip,
    disabled: editIconDisabled,
  } = ecitIconProps || {};
  const {
    onClick: trashIconOnClick,
    tooltip: trashIconTooltip,
    disabled: trashIconDisabled,
  } = trashIconProps || {};

  const classes = useStyles();

  return (
    <div className={classes.row} onClick={onClick}>
      <div className={classes.infoContainer}>
        <h3>{name}</h3>
        <div className={classes.dateText}>{description}</div>
      </div>
      <div className={classes.buttonContainer}>
        {btnOnClick && (
          <StyledButton
            framed
            onClick={(e: TClickEvent) => {
              e.stopPropagation();
              btnOnClick();
            }}
            tooltip={btnTooltip}
            disabled={btnDisabled}
          >
            {btnText}
          </StyledButton>
        )}
        {editIconOnClick && (
          <StyledButton
            framed
            className={classes.iconButton}
            onClick={(e: TClickEvent) => {
              e.stopPropagation();
              editIconOnClick();
            }}
            tooltip={editIconTooltip}
            disabled={editIconDisabled}
          >
            <FontAwesomeIcon icon={faPencilAlt} />
          </StyledButton>
        )}
        {trashIconOnClick && (
          <StyledButton
            className={clsx(classes.iconButton, classes.deleteButton)}
            onClick={(e: TClickEvent) => {
              e.stopPropagation();
              trashIconOnClick();
            }}
            tooltip={trashIconTooltip}
            disabled={trashIconDisabled}
          >
            <FontAwesomeIcon icon={faTrash} />
          </StyledButton>
        )}
      </div>
    </div>
  );
};

export default Row;
