import { useMemo } from 'react';
import WidgetTable from 'components/general/widgets/WidgetTable';
import { IColumn, IMission } from 'components/general/types';
import { IUser } from 'components/general/types/general';
import { useSelectByIds, useUser } from 'hooks';
import { TEntityDialogControl } from 'hooks/EntityDialogControlHook';
import useStyles from './styles';

interface IProps {
  repo: IMission;
  collaboratorsDialogControl: TEntityDialogControl<IUser>;
}

const CollaboratorsTable = (props: IProps) => {
  const { repo, collaboratorsDialogControl } = props;
  const { setDialogConfig: setCollaboratorDialogConfig } = collaboratorsDialogControl;
  const user = useUser();

  const collaborators = useSelectByIds(
    'Collaborator',
    // REF A: repo.user is the owner and always needs to be first for the styling in the table to work properly (not showing
    // option to delete self (owner) from collaboration table). Thus put owners id first and disable sorting.
    [repo.user, ...repo?.collaborators],
    false
  );

  const classes = useStyles();

  const isUsersRepo = useMemo(() => {
    return repo.user === user?.id;
  }, [user, repo]);

  const collaboratorColumns: IColumn[] = useMemo(() => {
    return [
      {
        title: 'Name',
        render: (collaboratorRowData) =>
          `${collaboratorRowData.firstName} ${collaboratorRowData.lastName}`,
      },
      {
        title: 'Email',
        field: 'email',
      },
      {
        title: 'Role',
        render: (collaboratorRowData) =>
          repo.user === collaboratorRowData.id ? 'Owner' : 'Collaborator',
      },
    ];
  }, [repo]);

  const openCollabDialog = () => setCollaboratorDialogConfig({ open: true, action: 'create' });

  return (
    <>
      {repo && (
        <div className={classes.collaboratorsTableContainer}>
          <h3 style={{ marginTop: 20 }}>Collaborators</h3>
          <WidgetTable
            data={collaborators}
            columns={collaboratorColumns}
            emptyMessage="Add collaborators to this repository using the + button."
            actionTooltip="Remove collaborator"
            // when onFabClick is undefined, it makes it so the fab doesn't render, so undefined here makes it so only
            // the owner of the repo has access to the button to add collaborators
            onFabClick={isUsersRepo ? openCollabDialog : undefined}
            onActionClick={(rowData) =>
              setCollaboratorDialogConfig({
                open: true,
                action: 'delete',
                entity: rowData as IUser,
              })
            }
            disableActionMenuOptions={{
              actions: ['edit'],
            }}
            disableActionsMenu={!isUsersRepo}
            noXray
            sort={() => false} // Disable sorting REF A
          />
        </div>
      )}
    </>
  );
};

export default CollaboratorsTable;
