import { makeStyles } from '@material-ui/core/styles';
import { rowStyles } from 'components/general/RowedExplorer/Row/styles';

const useStyles = makeStyles(() => ({
  ...rowStyles,
  userIconContainer: {
    marginRight: 5,
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
